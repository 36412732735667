<script setup>
import { reactive, ref } from "vue";
import Banner from "@/components/custom/Banner.vue";

const navigation = reactive({
  title: "Ancillary Services",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Flight Search Result",
      path: "/sales/flight/flight-list",
    },
    {
      name: "Itinerary Review",
      path: "/sales/flight/flight-itinerary-review",
    },
    {
      name: "Flight Passengers",
      path: "/sales/flight/flight-passengers",
    },
    {
      name: "Ancillary Services",
    },
  ],
});

let showServices = ref(false);
function show() {
  showServices.value = !showServices.value;
}
</script>

<template>
  <!-- Common Banner Area -->
  <Banner :navigate="navigation" />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="tou_booking_form_Wrapper">
            <div class="booking_tour_form">
              <h3 class="heading_theme">Ancillary Services</h3>
              <p style="font-size: 20px" class="mb30">
                More ancillary services will be available after ticketing. This
                fare do not support hold booking with ancillary services. Please
                purchase ancillary services after ticketing. Below ancillary
                services lists are for reference only.
              </p>
              <div
                class="modal fade"
                id="baggageModal"
                tabindex="-1"
                aria-labelledby="baggageModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-fullscreen">
                  <div class="modal-content ancillary-shopping">
                    <p>Add check-in baggage for...</p>
                    <h3 class="heading_theme">
                      Hong Kong HKG -> Singapore SIN
                    </h3>
                    <p>Web, 9 Jul</p>
                    <div class="room_select_area">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            Huang Ning +30kg
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            Huang June
                          </button>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div class="room_booking_area">
                            <p>Each passenger can select one option</p>
                            <div class="row ancillary-group">
                              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div
                                  class="theme_common_box_two ancillary-item"
                                >
                                  <div class="dashboard_top_icon text-center">
                                    <i class="fas fa-suitcase"></i>
                                    <hr />
                                  </div>
                                  <div class="dashboard_top_text">
                                    <p><span>30kg</span></p>
                                    <p>Included</p>
                                  </div>
                                  <div
                                    class="dashboard_top_text text-center padding-bottom-20 padding-top-20"
                                  >
                                    <button class="btn btn_theme">
                                      <i class="fa fa-check"></i> Selected
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div
                                  class="theme_common_box_two ancillary-item"
                                >
                                  <div class="dashboard_top_icon text-center">
                                    <i class="fas fa-suitcase"></i>
                                    <hr />
                                  </div>
                                  <div class="dashboard_top_text">
                                    <p><span>+5kg</span></p>
                                    <p>HKD 35</p>
                                  </div>
                                  <div
                                    class="dashboard_top_text text-center padding-bottom-20 padding-top-20"
                                  >
                                    <button class="btn btn_theme">Add</button>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div
                                  class="theme_common_box_two ancillary-item"
                                >
                                  <div class="dashboard_top_icon text-center">
                                    <i class="fas fa-suitcase"></i>
                                    <hr />
                                  </div>
                                  <div class="dashboard_top_text">
                                    <p><span>+10kg</span></p>
                                    <p>HKD 70</p>
                                  </div>
                                  <div
                                    class="dashboard_top_text text-center padding-bottom-20 padding-top-20"
                                  >
                                    <button class="btn btn_theme">Add</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="room_booking_area text-end">
                            <div class="ancillary-nav">
                              <div class="price-content">
                                HKD 0.00<br />Baggage add-ons
                              </div>
                              <div>
                                <button
                                  class="btn btn_theme"
                                  data-bs-dismiss="modal"
                                >
                                  Save Changes
                                </button>
                                <button class="btn btn_theme">
                                  Next flight
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal fade"
                id="mealModal"
                tabindex="-1"
                aria-labelledby="mealModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-fullscreen">
                  <div class="modal-content ancillary-shopping">
                    <p>Grab a bite for...</p>
                    <h3 class="heading_theme">
                      Hong Kong HKG -> Singapore SIN
                    </h3>
                    <p>Web, 9 Jul</p>
                    <div class="room_select_area">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            Huang Ning Now Selecting
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            Huang June
                          </button>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div class="room_booking_area">
                            <p>Each passenger can select one option</p>
                            <div class="row ancillary-group">
                              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div
                                  class="theme_common_box_two ancillary-item"
                                >
                                  <div class="dashboard_top_icon text-center">
                                    <i class="fas fa-bell-concierge"></i>
                                    <hr />
                                  </div>
                                  <div class="dashboard_top_text">
                                    <h4>
                                      Haerbal Chicken with Mixed Brown Rice
                                    </h4>
                                    Included
                                  </div>
                                  <div
                                    class="dashboard_top_text text-center padding-bottom-20 padding-top-20"
                                  >
                                    <button class="btn btn_theme">
                                      <i class="fa fa-check"></i> Selected
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div
                                  class="theme_common_box_two ancillary-item"
                                >
                                  <div class="dashboard_top_icon text-center">
                                    <i class="fas fa-bell-concierge"></i>
                                    <hr />
                                  </div>
                                  <div class="dashboard_top_text">
                                    <h4>
                                      Haerbal Chicken with Mixed Brown Rice
                                    </h4>
                                    Included
                                  </div>
                                  <div
                                    class="dashboard_top_text text-center padding-bottom-20 padding-top-20"
                                  >
                                    <button class="btn btn_theme">Add</button>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div
                                  class="theme_common_box_two ancillary-item"
                                >
                                  <div class="dashboard_top_icon text-center">
                                    <i class="fas fa-bell-concierge"></i>
                                    <hr />
                                  </div>
                                  <div class="dashboard_top_text">
                                    <h4>
                                      Haerbal Chicken with Mixed Brown Rice
                                    </h4>
                                    Included
                                  </div>
                                  <div
                                    class="dashboard_top_text text-center padding-bottom-20 padding-top-20"
                                  >
                                    <button class="btn btn_theme">Add</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="room_booking_area text-end">
                            <div class="ancillary-nav">
                              <div class="price-content">
                                HKD 0.00<br />Meal add-ons
                              </div>
                              <div>
                                <button
                                  class="btn btn_theme"
                                  data-bs-dismiss="modal"
                                >
                                  Save Changes
                                </button>
                                <button class="btn btn_theme">
                                  Next flight
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal fade"
                id="seatModal"
                tabindex="-1"
                aria-labelledby="seatModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-fullscreen">
                  <div class="modal-content ancillary-shopping">
                    <p>Select your seats for...</p>
                    <h3 class="heading_theme">
                      Hong Kong HKG -> Singapore SIN
                    </h3>
                    <p>Web, 9 Jul</p>
                    <div class="room_select_area">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            Huang Ning 4A ScootPlus
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            Huang June
                          </button>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div class="room_booking_area">
                            <h4>Seat Legend</h4>
                            <p>
                              Filter your seat options by selecting or
                              unselecting below seat types
                            </p>
                            <div class="row ancillary-group">
                              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div class="ancillary-item">
                                  <i class="bi bi-person-fill-up bi-2"></i
                                  >TakenScootPlus
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div class="ancillary-item">
                                  <i class="bi bi-person-fill-slash bi-2"></i
                                  >Taken or unavailable
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div class="ancillary-item">
                                  <i class="bi bi-person-fill bi-2"></i>Mine,
                                  All mine
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-lg-9">
                              <table class="table table-borderless">
                                <thead>
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col">A</th>
                                    <th scope="col"></th>
                                    <th scope="col">C</th>
                                    <th scope="col">D</th>
                                    <th scope="col">E</th>
                                    <th scope="col">F</th>
                                    <th scope="col"></th>
                                    <th scope="col">H</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col">K</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <i
                                        class="bi bi-box-arrow-in-left bi-2"
                                      ></i>
                                    </td>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <i
                                        class="bi bi-box-arrow-in-right bi-2"
                                      ></i>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td scope="row"></td>

                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td></td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td></td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td scope="row"></td>

                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td></td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td></td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td scope="row"></td>

                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td></td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td></td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td scope="row"></td>

                                    <td>
                                      <i class="bi bi-person-fill bi-2"></i>
                                    </td>
                                    <td></td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td>
                                      <i class="bi bi-person-fill-up bi-2"></i>
                                    </td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td></td>
                                    <td>
                                      <i
                                        class="bi bi-person-fill-slash bi-2"
                                      ></i>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="room_booking_area text-end">
                            <div class="ancillary-nav">
                              <div class="price-content">
                                HKD 0.00<br />Meal add-ons
                              </div>
                              <div>
                                <button
                                  class="btn btn_theme"
                                  data-bs-dismiss="modal"
                                >
                                  Save Changes
                                </button>
                                <button class="btn btn_theme">
                                  Next flight
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div
                    class="theme_common_box_two ancillary-item"
                    data-bs-toggle="modal"
                    data-bs-target="#baggageModal"
                  >
                    <div class="dashboard_top_icon text-center">
                      <i class="fas fa-suitcase"></i>
                      <h4>Add Baggage</h4>
                    </div>
                    <div class="dashboard_top_text ancillary-content">
                      <p><span>Start from</span> HKD 300.00</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div
                    class="theme_common_box_two ancillary-item"
                    data-bs-toggle="modal"
                    data-bs-target="#seatModal"
                  >
                    <div class="dashboard_top_icon text-center">
                      <i class="fas fa-bell-concierge"></i>
                      <h4>Edit Seat</h4>
                    </div>
                    <div class="dashboard_top_text ancillary-content">
                      <p><span>Start from</span> HKD 100.00</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div
                    class="theme_common_box_two ancillary-item"
                    data-bs-toggle="modal"
                    data-bs-target="#mealModal"
                  >
                    <div class="dashboard_top_icon text-center">
                      <i class="fas fa-bell-concierge"></i>
                      <h4>Add Meal</h4>
                    </div>
                    <div class="dashboard_top_text ancillary-content">
                      <p><span>Start from</span> HKD 100.00</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="booking_tour_form_submit">
            <router-link
              to="/sales/flight/flight-passengers"
              class="btn btn_theme btn_md"
              >Go back</router-link
            >
            <router-link
              to="/sales/flight/flight-booking-preview"
              class="btn btn_theme btn_md"
              >Seat Map</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
