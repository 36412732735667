<script setup>
import { useRouter } from "vue-router";
let props = defineProps({
  routeName: {
    default: undefined,
  },
  buttonName: {
    default: undefined,
  },
});

const router = useRouter();
function goBack() {
  if (props.routeName) {
    router.push({ name: props.routeName });
  } else {
    router.back();
  }
}
</script>

<template>
  <div class="header_back">
    <a @click="goBack">
      <i
        class="bi bi-arrow-left-square"
        size="40"
        style="
          font-size: 28px;
          color: #02b880;
          cursor: pointer;
          margin-right: 10px;
        "
      ></i>
      <span style="font-size: 27px" v-if="buttonName">{{ buttonName }}</span>
      <span style="font-size: 27px" v-else>{{ $t("back") }}</span>
    </a>
  </div>
</template>

<style scoped></style>
