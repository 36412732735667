import BookingSuccessView from "@/views/sales/common/BookingSuccessView.vue";
import BookingResultsView from "@/views/sales/booking/BookingResultsView.vue";

export default [
  {
    path: "/sales/booking",
    redirect: "/sales/booking/results",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "bookings-result",
        name: "sales-bookings-result",
        component: BookingResultsView,
        meta: { requiresAuth: true },
      },

      {
        path: "booking-success",
        name: "sales-booking-success",
        component: BookingSuccessView,
        meta: { requiresAuth: true },
      },
    ],
  },
];
