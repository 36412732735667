export class HotelItemsFilterFormModel {
  /**
   *
   * @param hotelName string 可选，酒店名
   * @param ttiCode number 酒店唯一代码
   * @param starRatings number数组 可选，酒店星级
   * @param userRating  可选，用户评分
   * @param propertyClass array，可选，住宿类型
   * @param mealPlans array，可选，餐饮类型
   * @param priceRange  array，可选，价格区间
   */
  constructor(
    hotelName,
    ttiCode,
    starRatings,
    userRating,
    propertyClass,
    mealPlans,
    priceRange
  ) {
    this.ttiCode = ttiCode;
    this.hotelName = hotelName;
    this.starRatings = starRatings;
    this.userRating = userRating;
    this.propertyClass = propertyClass;
    this.mealPlans = mealPlans;
    this.priceRange = priceRange;
  }
}
