import { HotelItemVO } from "@/viewobject/sales/hotel/availability/hotelItemVO";
import { HotelRoomTypeVO } from "@/viewobject/sales/hotel/availability/hotelRoomTypeVO";
import DateUtils from "@/utils/dateUtils"; //region Build HotelAvailRQ
import { HotelRoomVO } from "@/viewobject/sales/hotel/availability/hotelRoomVO";

//region Build HotelAvailRQ
function buildHotelAvailRQ(hotelAvailFormModel) {
  const bestOnlyIndicator = hotelAvailFormModel.bestOnlyIndicator;
  const languageCode = hotelAvailFormModel.languageCode;
  const locationType = hotelAvailFormModel.locationType;
  const locationCode = hotelAvailFormModel.locationCode;
  const locationName = hotelAvailFormModel.locationName;
  const checkInDate = hotelAvailFormModel.checkInDate;
  const checkOutDate = hotelAvailFormModel.checkOutDate;
  const roomPassengers = hotelAvailFormModel.roomPassengers;

  let searchLevel = "VeryRecent";
  let hotelRef = {
    hotelCityCode: null,
    hotelCodeContext: "PLACE",
  };

  if ("AIRPORT" === locationType) {
    hotelRef.hotelCodeContext = "AIRPORT";
  }

  if ("HOTEL" === locationType) {
    searchLevel = "Live";
    hotelRef.tticode = locationCode;
    hotelRef.hotelCodeContext = "HOTEL";
  } else {
    hotelRef.hotelCityCode = locationCode;
  }

  let criterion = {
    hotelRefList: [hotelRef],
    stayDateRange: {
      start: checkInDate,
      end: checkOutDate,
    },
  };

  let roomStayCandidates = [];

  if (roomPassengers && roomPassengers.length > 0) {
    roomPassengers.forEach((roomPassenger) => {
      let adultNum = roomPassenger.adultNum;

      let roomStayCandidate = {
        quantity: 1,
        guestCounts: {
          guestCountList: [
            {
              ageQualifyingCode: "10",
              count: adultNum,
            },
          ],
        },
      };

      roomStayCandidates.push(roomStayCandidate);
      let childAges = roomPassenger.childAges;
      if (childAges != null && childAges.length > 0) {
        childAges.forEach((childAge) => {
          let age = childAge.age;
          let childGuest = {
            ageQualifyingCode: "8",
            age: age,
            count: 1,
          };
          roomStayCandidate.guestCounts.guestCountList.push(childGuest);
        });
      }
    });
  }

  return {
    primaryLangID: languageCode,
    availRequestSegments: {
      maximumWaitTime: 120000,
      availRequestSegments: [
        {
          hotelSearchCriteria: {
            availableOnlyIndicator: true,
            bestOnlyIndicator: bestOnlyIndicator,
            criterionList: [criterion],
          },
          roomStayCandidates: roomStayCandidates,
        },
      ],
    },
    searchCacheLevel: searchLevel,
  };
}

//endregion Build HotelAvailRQ

//region Build HotelItemVOs
function buildHotelItems(hotelAvailRS, hotelDescriptiveRS) {
  if (
    hotelAvailRS == null ||
    hotelAvailRS.hotelStays == null ||
    hotelAvailRS.hotelStays.length === 0 ||
    hotelAvailRS.roomStays == null ||
    hotelAvailRS.roomStays.roomStayList == null ||
    hotelAvailRS.roomStays.roomStayList.length === 0
  )
    return null;

  const hotelStays = hotelAvailRS.hotelStays;
  let hotelStayMap = buildHotelStayMap(hotelStays);

  const hotelItemVOMap = {};
  const hotelRoomTypeVOMap = {};

  for (const roomStay of hotelAvailRS.roomStays.roomStayList) {
    if (!roomStay.roomTypes || roomStay.roomTypes.length === 0) {
      continue;
    }

    const ttiCode = roomStay.basicPropertyInfo.tticode;
    const infoSource = roomStay.infoSource;
    const roomTypeCode = roomStay.roomTypes[0].roomTypeCode;

    let baseHotelItemVO = hotelItemVOMap[ttiCode];
    //酒店重要信息只初始化一次
    if (!baseHotelItemVO) {
      const hotelStay = hotelStayMap[ttiCode];
      baseHotelItemVO = buildBaseHotelItemVO(hotelStay, hotelDescriptiveRS);

      let vendorMessages = roomStay.basicPropertyInfo.vendorMessages;
      if (vendorMessages) {
        vendorMessages.forEach((vendorMessage) => {
          let policyCode = vendorMessage.infoType;
          let policyName = null;
          switch (policyCode) {
            case "CHECK_IN":
              policyName = "Check In";
              break;
            case "FEES":
              policyName = "Fees";
              break;
            case "POLICY":
              policyName = "Important Information";
              break;
            default:
          }

          let hotelPolicyVO = baseHotelItemVO.addNewPolicy(
            policyCode,
            policyName,
            null
          );
          if (vendorMessage.subSections) {
            vendorMessage.subSections[0].paragraphs.forEach((paragraph) => {
              hotelPolicyVO.addNewPolicyContent(paragraph.name, paragraph.text);
            });
          }
        });
      }

      hotelItemVOMap[ttiCode] = baseHotelItemVO;
      hotelStayMap[ttiCode] = null; //清除数据
    }

    baseHotelItemVO.addSupplierCode(infoSource);

    //每个房型信息只初始化一次
    const hotelRoomTypeVOKey = ttiCode + ":" + roomTypeCode;
    let hotelRoomTypeVO = hotelRoomTypeVOMap[hotelRoomTypeVOKey];
    if (!hotelRoomTypeVO) {
      hotelRoomTypeVO = buildBaseRoomTypeVO(roomStay);
      baseHotelItemVO.addRoomType(hotelRoomTypeVO);
      hotelRoomTypeVOMap[hotelRoomTypeVOKey] = hotelRoomTypeVO;
    }

    const roomVO = buildRoomVO(roomStay);
    const avgBaseUnitPrice = roomVO.avgBaseUnitPrice;
    const totalPrice = roomVO.totalPrice;
    const currencyCode = roomVO.currencyCode;
    if (
      !baseHotelItemVO.bestAvgUnitPrice ||
      baseHotelItemVO.bestAvgUnitPrice > avgBaseUnitPrice
    ) {
      baseHotelItemVO.bestAvgUnitPrice = avgBaseUnitPrice;
      baseHotelItemVO.bestTotalPrice = totalPrice;
      baseHotelItemVO.currencyCode = currencyCode;
    }
    //Travel policy
    if (
      baseHotelItemVO.approvalRequired === null &&
      baseHotelItemVO.outOfPolicy === null
    ) {
      baseHotelItemVO.approvalRequired = roomVO.approvalRequired;
      baseHotelItemVO.outOfPolicy = roomVO.outOfPolicy;
      baseHotelItemVO.reasons = roomVO.reasons;
    } else if (
      baseHotelItemVO.approvalRequired &&
      !roomVO.approvalRequired &&
      !roomVO.outOfPolicy
    ) {
      baseHotelItemVO.approvalRequired = roomVO.approvalRequired;
      baseHotelItemVO.outOfPolicy = roomVO.outOfPolicy;
      baseHotelItemVO.reasons = roomVO.reasons;
    } else if (baseHotelItemVO.outOfPolicy && !roomVO.outOfPolicy) {
      baseHotelItemVO.approvalRequired = roomVO.approvalRequired;
      baseHotelItemVO.outOfPolicy = roomVO.outOfPolicy;
      baseHotelItemVO.reasons = roomVO.reasons;
    }

    hotelRoomTypeVO.addRoom(roomVO);
  }

  return Object.values(hotelItemVOMap);
}

function buildHotelStayMap(hotelStays) {
  let hotelStayMap = {};
  hotelStays.forEach((hotelStay) => {
    const basicPropertyInfo = hotelStay.basicPropertyInfo;
    const tticode = basicPropertyInfo.tticode;
    hotelStayMap[tticode] = hotelStay;
  });
  return hotelStayMap;
}

function buildBaseHotelItemVO(hotelStay, hotelDescriptiveRS) {
  const basicPropertyInfo = hotelStay.basicPropertyInfo;
  const tticode = basicPropertyInfo.tticode;
  const hotelName = basicPropertyInfo.hotelName;
  const cityName = basicPropertyInfo.hotelCtyName;
  const starRating = basicPropertyInfo.rating;
  const mainImageURL = basicPropertyInfo.mainImageURL;
  const position = basicPropertyInfo.position;
  const latitude = position.latitude;
  const longitude = position.longitude;
  const address = basicPropertyInfo.address.addressString;
  const countryName = basicPropertyInfo.address.countryName;
  const awards = basicPropertyInfo.awards;
  const description = basicPropertyInfo.shortDescriptions;

  let userRating = 0;
  if (awards != null && awards.length > 0) {
    awards.forEach((award) => {
      if (award.provider === "TRIPADVISOR") {
        userRating =
          award.rating == null || award.rating === "" || award.rating === "null"
            ? 0
            : parseFloat(award.rating);
      }
    });
  }

  let baseHotelItemVO = new HotelItemVO(
    mainImageURL,
    hotelName,
    cityName,
    starRating,
    userRating,
    address,
    null,
    null,
    null,
    null,
    longitude,
    latitude,
    tticode,
    description,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );

  if (
    hotelDescriptiveRS != null &&
    hotelDescriptiveRS.hotelDescriptiveContents != null &&
    hotelDescriptiveRS.hotelDescriptiveContents.hotelDescriptiveContentList !=
      null &&
    hotelDescriptiveRS.hotelDescriptiveContents.hotelDescriptiveContentList
      .length !== 0
  ) {
    //酒店详情页
    let content =
      hotelDescriptiveRS.hotelDescriptiveContents
        .hotelDescriptiveContentList[0];
    // 服务设施
    if (content.hotelInfo.serviceList != null) {
      content.hotelInfo.serviceList.forEach((service) => {
        baseHotelItemVO.addNewAmenity(service.codeDetail, service.type);
      });
    }
    // 房间设施
    if (
      content.facilityInfo &&
      content.facilityInfo.guestRooms &&
      content.facilityInfo.guestRooms.guestRoomList &&
      content.facilityInfo.guestRooms.guestRoomList[0].amenities &&
      content.facilityInfo.guestRooms.guestRoomList[0].amenities.amenityList
    ) {
      let amenityList =
        content.facilityInfo.guestRooms.guestRoomList[0].amenities.amenityList;
      amenityList.forEach((amenity) => {
        baseHotelItemVO.addNewAmenity(amenity.descriptiveText, "ROOM_VIEW");
      });
    }

    // 图片，文字描述
    if (
      content.multimediaDescriptions != null &&
      content.multimediaDescriptions.multimediaDescriptionList != null &&
      content.multimediaDescriptions.multimediaDescriptionList.length > 0
    ) {
      let multimediaDescription =
        content.multimediaDescriptions.multimediaDescriptionList[0];

      if (multimediaDescription.imageItems) {
        multimediaDescription.imageItems.forEach((imageItem) => {
          if (
            imageItem.imageFormatList != null &&
            imageItem.imageFormatList.length > 0 &&
            "bigger" == imageItem.imageFormatList[0].dimensionCategory
          ) {
            baseHotelItemVO.addNewPhoto(
              "hotel",
              imageItem.url,
              imageItem.caption
            );
          }
        });

        if (
          baseHotelItemVO.photos == null ||
          baseHotelItemVO.photos.length === 0 ||
          baseHotelItemVO.photos.length === 1
        ) {
          multimediaDescription.imageItems.forEach((imageItem) => {
            if (
              imageItem.imageFormatList != null &&
              imageItem.imageFormatList.length > 0 &&
              "standard" == imageItem.imageFormatList[0].dimensionCategory
            ) {
              baseHotelItemVO.addNewPhoto(
                "hotel",
                imageItem.url,
                imageItem.caption
              );
            }
          });
        }
      }
    }
  }

  return baseHotelItemVO;
}

function buildBaseRoomTypeVO(roomStay) {
  const guestCountsOta = roomStay.guestCounts
    ? roomStay.guestCounts.guestCountList
    : {};
  const roomTypeOta = roomStay.roomTypes[0];

  // 房型信息相关
  let occupanciesOta =
    roomTypeOta && roomTypeOta.occupancies ? roomTypeOta.occupancies[0] : {};
  let roomTypeName = null;
  let roomTypeCode = null;
  let roomDescription = null;

  if (roomTypeOta) {
    roomTypeName = roomTypeOta.roomType;
    roomTypeCode = roomTypeOta.roomTypeCode;
    roomDescription = roomTypeOta.roomDescription
      ? roomTypeOta.roomDescription.text
      : "";
  }

  let minOccupancy = occupanciesOta ? occupanciesOta.minOccupancy : null;
  let maxOccupancy = occupanciesOta ? occupanciesOta.maxOccupancy : null;
  let ttiCode = roomStay.basicPropertyInfo.tticode;

  //住客相关
  let adultNum = 0;
  let childNum = 0;
  if (guestCountsOta && guestCountsOta.length > 0) {
    guestCountsOta.forEach((guestCount) => {
      switch (guestCount.ageQualifyingCode) {
        case "10":
          adultNum += guestCount.count;
          break;
        case "8":
          childNum += guestCount.count;
          break;
        default:
      }
    });
  }

  return new HotelRoomTypeVO(
    roomTypeName,
    roomTypeCode,
    ttiCode,
    roomDescription,
    minOccupancy,
    maxOccupancy,
    null,
    null
  );
}

function buildRoomVO(roomStay) {
  const outOfPolicy = roomStay.outOfPolicy;
  const approvalRequired = roomStay.approvalRequired;
  const reasons = roomStay.reasons;
  const ratePlanOta = roomStay.ratePlans[0];
  const roomTypeOta = roomStay.roomTypes[0];
  const guestCounts =
    roomStay && roomStay.guestCounts ? roomStay.guestCounts.guestCountList : [];
  const roomRateOta =
    roomStay.roomRates &&
    roomStay.roomRates.roomRates &&
    roomStay.roomRates.roomRates.length > 0
      ? roomStay.roomRates.roomRates[0]
      : null;
  const supplier = roomStay.infoSource;
  const specialRequests = roomStay.specialRequests;
  const ratesOta =
    roomRateOta && roomRateOta.rates ? roomRateOta.rates.rateList : [];
  const cancelPenaltiesOta =
    roomStay.cancelPenalties != null
      ? roomStay.cancelPenalties.cancelPenalties
      : null;
  const totalOta = roomStay.total;
  const referenceId = roomStay.reference != null ? roomStay.reference.id : null;
  const timeSpan = roomStay.timeSpan;

  let totalPrice = null;
  let roomPrice = null;
  let currency = null;

  if (totalOta) {
    totalPrice = totalOta.amountIncludingMarkup;
    roomPrice = totalOta.amountBeforeTax;
    // let feesTaxesInc = totalPrice - roomPrice; // 包含在总价内的费用和税费
    currency = totalOta.currencyCode;
  }

  const availableQuantity = roomTypeOta ? roomTypeOta.quantity : null;
  const bedTypeName = roomTypeOta ? roomTypeOta.bedTypeName : null;
  const nightsStay = ratesOta.length;
  const checkIn = timeSpan.start;
  const checkOut = timeSpan.end;
  const mealsIncluded = ratePlanOta.mealsIncluded;
  const mealPlanIndicator = mealsIncluded.mealPlanIndicator;
  const avgBaseUnitPrice = Math.ceil(totalPrice / nightsStay);
  const totalFeesTaxesPrice = Math.round((totalPrice - roomPrice) * 100) / 100;

  let mealName = null;
  if (mealPlanIndicator) {
    mealName = "Breakfast";
  } else {
    mealName = "Room Only";
  }

  if (
    mealPlanIndicator &&
    ratePlanOta.mealsIncluded.shortName &&
    ratePlanOta.mealsIncluded.shortName !== ""
  ) {
    mealName = ratePlanOta.mealsIncluded.shortName;
  }

  // 特殊请求
  let specialRequest = null;
  if (
    specialRequests != null &&
    specialRequests.specialRequests != null &&
    specialRequests.specialRequests.length > 0
  ) {
    specialRequest = specialRequests.specialRequests[0].text;
  }

  //房间描述相关
  let roomRateDescriptionsOta = roomRateOta.roomRateDescriptionList;
  let rateComments = null;
  let promotionDescriptions = null;
  if (roomRateDescriptionsOta) {
    roomRateDescriptionsOta.forEach((roomRateDescription) => {
      if ("RATE_COMMENTS" === roomRateDescription.name) {
        rateComments = roomRateDescription.textList[0].stringValue;
      } else if ("RATE_DESCRIPTIONS" === roomRateDescription.name) {
        promotionDescriptions = [];
        if (roomRateDescription.textList != null) {
          roomRateDescription.textList.forEach((text) => {
            promotionDescriptions.push(text.stringValue);
          });
        }
      }
    });
  }

  let roomVO = new HotelRoomVO(
    null,
    supplier,
    bedTypeName,
    mealName,
    null,
    null,
    availableQuantity,
    checkIn,
    checkOut,
    nightsStay,
    null,
    outOfPolicy,
    approvalRequired,
    reasons,
    currency,
    avgBaseUnitPrice,
    roomPrice,
    totalFeesTaxesPrice,
    null,
    totalPrice,
    referenceId,
    specialRequest,
    null,
    null,
    rateComments,
    promotionDescriptions,
    null
  );
  //餐饮详情
  roomVO.addNewMealPlan(
    mealsIncluded.breakfast,
    mealsIncluded.lunch,
    mealsIncluded.dinner
  );

  // 每日价格相关
  ratesOta.sort(function (a, b) {
    return new Date(a.effectiveDate) > new Date(b.effectiveDate) ? 1 : -1;
  });

  ratesOta.forEach((rateOta) => {
    const feesOta = rateOta.fees;
    let feesAmount = 0;
    //费用描述数组
    const feeDescriptions = [];
    if (feesOta) {
      feesOta.forEach((feeOta) => {
        let amount = feeOta.amount;
        feesAmount += amount;

        if (feeOta.type === "INCLUSIVE") {
          const descriptions = feeOta.descriptions;

          if (descriptions && descriptions.length > 0) {
            const description = descriptions[0];
            if (description) {
              let feeDesArrFormat = description.name;
              if (
                description.listItems &&
                description.listItems.length > 0 &&
                description.listItems[0]
              ) {
                feeDesArrFormat =
                  feeDesArrFormat +
                  " (" +
                  description.listItems[0].textFormat +
                  ") ";
              }

              feeDescriptions.push(feeDesArrFormat);
            }
          }
        }
      });
    }

    const effectiveDate = rateOta.effectiveDate;
    const expireDate = rateOta.expireDate;
    const amountBeforeTax =
      rateOta.total != null ? rateOta.total.amountBeforeTax : null;
    const amountAfterTax =
      rateOta.total != null ? rateOta.total.amountAfterTax : null;
    const amountIncludingMarkup =
      rateOta.total != null ? rateOta.total.amountIncludingMarkup : null;

    roomVO.addNewDailyRate(
      effectiveDate,
      expireDate,
      amountBeforeTax,
      feesAmount,
      amountIncludingMarkup,
      currency,
      feeDescriptions
    );
  });

  //酒店费用，需要到酒店付款
  if (roomRateOta.total) {
    let taxesOta = roomRateOta.total.taxes;
    if (taxesOta && taxesOta.taxes) {
      taxesOta.taxes.forEach((tax) => {
        if (
          tax.type === "EXCLUSIVE" &&
          tax.taxDescriptions &&
          tax.taxDescriptions.length > 0 &&
          tax.taxDescriptions[0]
        ) {
          roomVO.addNewFeeTaxExc(
            tax.amount,
            currency,
            tax.taxDescriptions[0].name,
            tax.taxDescriptions[0].listItems &&
              tax.taxDescriptions[0].listItems.length > 0 &&
              tax.taxDescriptions[0].listItems[0]
              ? tax.taxDescriptions[0].listItems[0].textFormat
              : null
          );
        }
      });
    }
  }

  let refundable = false;

  if (cancelPenaltiesOta != null && cancelPenaltiesOta.length > 0) {
    cancelPenaltiesOta.forEach((cancelPenalty) => {
      if (cancelPenalty.deadline != null) {
        refundable = true;
        const absoluteDeadlineTimeStamp =
          cancelPenalty.deadline.absoluteDeadline;
        const absoluteDeadlineDate = new Date(absoluteDeadlineTimeStamp);
        const dateFormat = DateUtils.formatDate(absoluteDeadlineDate);
        const amountPercent = cancelPenalty.amountPercent;

        let beginDate = dateFormat;
        let nmbrOfUnit = amountPercent != null ? amountPercent.amount : null;
        let unitType = "Amount";

        let offsetTimeUnit = cancelPenalty.deadline.offsetTimeUnit;
        let offsetUnitMultiplier = cancelPenalty.deadline.offsetUnitMultiplier;

        if (
          amountPercent != null &&
          (amountPercent.amount == null || amountPercent.amount == 0) &&
          amountPercent.percent != null
        ) {
          nmbrOfUnit = (amountPercent.percent / 100) * totalPrice;
        } else if (
          amountPercent != null &&
          amountPercent.nmbrOfNights != null
        ) {
          // 若是按照天数收费
          unitType = "Night";
          nmbrOfUnit = amountPercent.nmbrOfNights;
        }

        roomVO.addNewCancelPolicy(
          absoluteDeadlineTimeStamp,
          currency,
          nmbrOfUnit,
          unitType,
          offsetTimeUnit,
          offsetUnitMultiplier
        );
      }
    });
  }

  if (guestCounts && guestCounts.length > 0) {
    for (const guestCount of guestCounts) {
      const resGuestRPH = guestCount.resGuestRPH;
      roomVO.addNewGuestRPH(resGuestRPH);
    }
  }
  roomVO.refundable = refundable;
  return roomVO;
}

//endregion Build HotelItemVOs

/**
 *
 * @param hotelItems 数据源数组
 * @param currentPage 指定页
 * @param limit 每页数据量
 * @returns {*|null}
 */
function findSpecificHotelItems(hotelItems, currentPage, limit) {
  let start = 0;
  let end = start + currentPage * limit;
  if (hotelItems && end > hotelItems.length) {
    end = hotelItems.length;
  }

  const hotelItemsCopy = JSON.parse(JSON.stringify(hotelItems));
  return hotelItemsCopy.slice(start, end);
}

export default {
  buildHotelAvailRQ,
  buildHotelItems,
  buildBaseRoomTypeVO,
  buildRoomVO,
  findSpecificHotelItems,
};
