<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useTripStore } from "@/stores/v2/trip/tripStore";
import { useTemplateStore } from "@/stores/template";
import { useBookingStore } from "@/stores/booking";
import { Modal } from "ant-design-vue";

const props = defineProps({
  bookingStatus: {},
  tripItemType: {},
  invoiceId: {},
  bookingId: {},
  items: {},
  showContinueToPayment: {
    type: Boolean,
    default: false,
  },
  equipmentType: {},
  recordLocator: {},
  idContext: {},
  tripHotelItem: {},
});

const AModal = Modal;
const router = useRouter();
const emits = defineEmits();
const templateStore = useTemplateStore();
const bookingStore = useBookingStore();
const tripStore = useTripStore();
const tripHotelItemRef = ref(null);
const voucherItemsRef = ref(null);
const chooseItemIdRef = ref(null);
watch(
  () => props.tripHotelItem,
  (newValue) => {
    if (newValue) {
      tripHotelItemRef.value = newValue;

      if (newValue) {
        const bookingHotelItemIds = newValue.bookingHotelItemIds;
        const hotelName = newValue.hotelName;
        const tripHotelItemId = newValue.tripHotelItemId;

        let checkInDate = null;
        let checkOutDate = null;
        const roomType =
          newValue.roomTypes && newValue.roomTypes.length > 0
            ? newValue.roomTypes[0]
            : null;
        if (roomType) {
          const rooms = roomType.rooms;
          if (rooms && rooms.length > 0) {
            const room = rooms[0];
            if (room) {
              checkInDate = room.checkInDate;
              checkOutDate = room.checkOutDate;
            }
          }
        }

        voucherItemsRef.value = [];
        if (bookingHotelItemIds && bookingHotelItemIds.length > 0) {
          let roomNum = 1;
          bookingHotelItemIds.sort().forEach((bookingHotelItemId) => {
            voucherItemsRef.value.push({
              itemId: bookingHotelItemId,
              name:
                "Room " +
                roomNum++ +
                "-" +
                hotelName +
                "(" +
                checkInDate +
                " to " +
                checkOutDate +
                ")",
            });
          });
        } else {
          voucherItemsRef.value.push({
            itemId: tripHotelItemId,
            name: hotelName + "(" + checkInDate + " to " + checkOutDate + ")",
          });
        }

        chooseItemIdRef.value = voucherItemsRef.value[0].itemId;
      }
    }
  },
  { deep: true, immediate: true }
);

function confirmPrintVoucher(chooseItemId) {
  templateStore.pageLoader({ mode: "on" });
  tripStore
    .tripHotelPrintVoucher(props.bookingId, "hotel", chooseItemId)
    .then((res) => {
      templateStore.pageLoader({ mode: "off" });
    })
    .catch((err) => {
      templateStore.pageLoader({ mode: "off" });
    });
}

function cancelBooking(bookingId) {
  templateStore.pageLoader({ mode: "on" });
  bookingStore.cancelBooking(bookingId).then(async (res) => {
    templateStore.pageLoader({ mode: "off" });
    if (res.success) {
      emits("syncSessionAndSearch");
    } else {
      AModal.error({
        title: "This is an error message",
        content: res.message,
        centered: true,
        width: 600,
      });
    }
  });
}

function continueToPayment(bookingId, invoiceId) {
  router.push({
    name: "payment-methods",
    query: {
      bookingId: bookingId,
      invoiceId: invoiceId,
      productType: "hotel",
    },
  });
}
</script>

<template>
  <!--  <div-->
  <!--    class="btn-group me-2 mb-2"-->
  <!--    role="group"-->
  <!--    v-if="bookingStatus !== 'CANCELLED'"-->
  <!--  >-->
  <!--    <button-->
  <!--      type="button"-->
  <!--      class="btn btn_theme btn-sm"-->
  <!--      :class="{ btn_md: equipmentType !== 'iphone' }"-->
  <!--      data-bs-toggle="modal"-->
  <!--      data-bs-target="#modal-cancel-booking"-->
  <!--    >-->
  <!--      {{ $t("cancel-booking") }}-->
  <!--    </button>-->
  <!--  </div>-->

  <div class="btn-group me-2 mb-2" role="group" v-if="tripItemType === 'HOTEL'">
    <button
      type="button"
      class="btn btn_theme"
      :class="{ btn_md: equipmentType !== 'iphone' }"
      data-bs-toggle="modal"
      data-bs-target="#modal-print-voucher"
    >
      {{ $t("print-voucher") }}
    </button>
  </div>
  <div class="btn-group me-2 mb-2" role="group" v-if="showContinueToPayment">
    <button
      type="button"
      class="btn btn_theme"
      :class="{ btn_md: equipmentType !== 'iphone' }"
      @click="continueToPayment(bookingId, invoiceId)"
    >
      {{ $t("continue-to-payment") }}
    </button>
  </div>
  <!-- Cancel Booking Slide Right -->
  <div
    class="modal fade"
    id="modal-cancel-booking"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-cancel-booking"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-slideright" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("cancel-booking") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            {{
              $t("sure-cancel-booking", {
                bookingId: bookingId,
              })
            }}?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ $t("close") }}
          </button>
          <button
            type="button"
            class="btn btn_theme"
            data-bs-dismiss="modal"
            @click="cancelBooking(bookingId)"
          >
            {{ $t("confirm") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END Cancel Booking Slide Right -->

  <!-- Print Voucher Slide Right -->
  <div
    class="modal fade"
    id="modal-print-voucher"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-print-voucher"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-slideright" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("print-voucher") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            {{
              $t("sure-print-voucher", {
                bookingId: bookingId,
              })
            }}?
          </p>
          <div class="tour_search_type">
            <div
              class="form-check"
              v-for="(voucherItem, voucherItemIndex) in voucherItemsRef"
              :key="voucherItemIndex"
            >
              <input
                :id="'voucher' + voucherItemIndex"
                v-model="chooseItemIdRef"
                :checked="voucherItemIndex === 0"
                :value="voucherItem.itemId"
                class="form-check-input"
                name="example-radios-default"
                type="radio"
              />
              <label
                class="form-check-label"
                :for="'voucher' + voucherItemIndex"
                >{{ voucherItem.name }}</label
              >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ $t("close") }}
          </button>
          <button
            type="button"
            class="btn btn_theme"
            data-bs-dismiss="modal"
            @click="confirmPrintVoucher(chooseItemIdRef)"
          >
            {{ $t("confirm") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END Print Voucher Slide Right -->
</template>

<style scoped></style>
