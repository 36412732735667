<template>
  <section id="theme_search_form">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <div class="theme_search_form_tabbtn">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="flights-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#flights"
                    type="button"
                    role="tab"
                    aria-controls="flights"
                    aria-selected="true"
                  >
                    <i class="fas fa-plane-departure"></i>Flights
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="tours-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tours"
                    type="button"
                    role="tab"
                    aria-controls="tours"
                    aria-selected="false"
                  >
                    <i class="fas fa-globe"></i>Tours
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="hotels-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#hotels"
                    type="button"
                    role="tab"
                    aria-controls="hotels"
                    aria-selected="false"
                  >
                    <i class="fas fa-hotel"></i>Hotels
                  </button>
                </li>
              </ul>
            </div>

            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="flights"
                role="tabpanel"
                aria-labelledby="flights-tab"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="flight_categories_search">
                      <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="oneway-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#oneway_flight"
                            type="button"
                            role="tab"
                            aria-controls="oneway_flight"
                            aria-selected="true"
                          >
                            One Way
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="roundtrip-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#roundtrip"
                            type="button"
                            role="tab"
                            aria-controls="roundtrip"
                            aria-selected="false"
                          >
                            Roundtrip
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="multi_city-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#multi_city"
                            type="button"
                            role="tab"
                            aria-controls="multi_city"
                            aria-selected="false"
                          >
                            Multi city
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tab-content" id="myTabContent1">
                  <div
                    class="tab-pane fade show active"
                    id="oneway_flight"
                    role="tabpanel"
                    aria-labelledby="oneway-tab"
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="oneway_search_form">
                          <form action="#!">
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div class="search_boxed">
                                  <p>From</p>
                                  <input type="text" value="New York" />
                                  <span
                                    >JFK - John F. Kennedy
                                    International...</span
                                  >
                                  <div class="plan_icon_posation">
                                    <i class="fas fa-plane-departure"></i>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div class="search_boxed">
                                  <p>To</p>
                                  <input type="text" value="London " />
                                  <span>LCY, London city airport </span>
                                  <div class="plan_icon_posation">
                                    <i class="fas fa-plane-arrival"></i>
                                  </div>
                                  <div class="range_plan">
                                    <i class="fas fa-exchange-alt"></i>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div class="form_search_date">
                                  <div
                                    class="search_boxed date_flex_area"
                                  >
                                    <div class="Journey_date">
                                      <p>Journey date</p>
                                      <input type="date" value="2022-05-05" />
                                      <span>Thursday</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <CountDropdown />

                              <div class="top_form_search_button">
                                <button class="btn btn_theme btn_md">
                                  Search
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="roundtrip"
                    role="tabpanel"
                    aria-labelledby="roundtrip-tab"
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="oneway_search_form">
                          <form action="#!">
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div class="search_boxed">
                                  <p>From</p>
                                  <input type="text" value="New York" />
                                  <span
                                    >JFK - John F. Kennedy
                                    International...</span
                                  >
                                  <div class="plan_icon_posation">
                                    <i class="fas fa-plane-departure"></i>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div class="search_boxed">
                                  <p>To</p>
                                  <input type="text" value="London " />
                                  <span>LCY, London city airport </span>
                                  <div class="plan_icon_posation">
                                    <i class="fas fa-plane-arrival"></i>
                                  </div>
                                  <div class="range_plan">
                                    <i class="fas fa-exchange-alt"></i>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div class="form_search_date">
                                  <div
                                    class="search_boxed date_flex_area"
                                  >
                                    <div class="Journey_date">
                                      <p>Journey date</p>
                                      <input type="date" value="2022-05-05" />
                                      <span>Thursday</span>
                                    </div>
                                    <div class="Journey_date">
                                      <p>Return date</p>
                                      <input type="date" value="2022-05-08" />
                                      <span>Saturday</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <CountDropdownRound />
                            </div>
                            <div class="top_form_search_button">
                              <button class="btn btn_theme btn_md">
                                Search
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="multi_city"
                    role="tabpanel"
                    aria-labelledby="multi_city-tab"
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="oneway_search_form">
                          <form action="#!">
                            <div class="multi_city_form_wrapper">
                              <div class="multi_city_form">
                                <div class="row">
                                  <div
                                    class="col-lg-3 col-md-6 col-sm-12 col-12"
                                  >
                                    <div class="search_boxed">
                                      <p>From</p>
                                      <input type="text" value="New York" />
                                      <span
                                        >DAC, Hazrat Shahajalal
                                        International...</span
                                      >
                                      <div class="plan_icon_posation">
                                        <i class="fas fa-plane-departure"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-lg-3 col-md-6 col-sm-12 col-12"
                                  >
                                    <div class="search_boxed">
                                      <p>To</p>
                                      <input type="text" value="London " />
                                      <span>LCY, London city airport </span>
                                      <div class="plan_icon_posation">
                                        <i class="fas fa-plane-arrival"></i>
                                      </div>
                                      <div class="range_plan">
                                        <i class="fas fa-exchange-alt"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-lg-4 col-md-6 col-sm-12 col-12"
                                  >
                                    <div class="form_search_date">
                                      <div
                                        class="search_boxed date_flex_area"
                                      >
                                        <div class="Journey_date">
                                          <p>Journey date</p>
                                          <input
                                            type="date"
                                            value="2022-05-05"
                                          />
                                          <span>Thursday</span>
                                        </div>
                                        <div class="Journey_date">
                                          <p>Return date</p>
                                          <input
                                            type="date"
                                            value="2022-05-10"
                                          />
                                          <span>Saturday</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <CountDropdownMulti />
                                </div>
                              </div>
                            </div>

                            <div v-for="(rowDat, index) in rowData">
                              <div class="multi_city_form">
                                <div class="row">
                                  <div class="col-lg-12" v-if="isHidden">
                                    <div class="multi_form_remove">
                                      <button
                                        type="button"
                                        @click="deleteRow(index)"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                  <div class="col-lg-3">
                                    <div class="search_boxed">
                                      <p>From</p>
                                      <input type="text" value="New York" />
                                      <span
                                        >DAC, Hazrat Shahajalal
                                        International...</span
                                      >
                                      <div class="plan_icon_posation">
                                        <i class="fas fa-plane-departure"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-3">
                                    <div class="search_boxed">
                                      <p>To</p>
                                      <input type="text" value="London " />
                                      <span>CXB, London Airport</span>
                                      <div class="plan_icon_posation">
                                        <i class="fas fa-plane-arrival"></i>
                                      </div>
                                      <div class="range_plan">
                                        <i class="fas fa-exchange-alt"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-4">
                                    <div class="form_search_date">
                                      <div
                                        class="search_boxed date_flex_area"
                                      >
                                        <div class="Journey_date">
                                          <p>Journey date</p>
                                          <input
                                            type="date"
                                            value="2022-05-18"
                                          />
                                          <span>Thursday</span>
                                        </div>
                                        <div class="Journey_date">
                                          <p>Return date</p>
                                          <input
                                            type="date"
                                            value="2022-05-20"
                                          />
                                          <span>Saturday</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <CountDropdownMulti />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="add_multy_form">
                                  <button type="button" @click="addRow">
                                    + Add another flight
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="top_form_search_button">
                              <button class="btn btn_theme btn_md">
                                Search
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="tours"
                role="tabpanel"
                aria-labelledby="tours-tab"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="tour_search_form">
                      <form action="#!">
                        <div class="row">
                          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="search_boxed">
                              <p>Destination</p>
                              <input
                                type="text"
                                placeholder="Where are you going?"
                              />
                              <span>Where are you going?</span>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div class="form_search_date">
                              <div class="search_boxed date_flex_area">
                                <div class="Journey_date">
                                  <p>Journey date</p>
                                  <input type="date" value="2022-05-03" />
                                  <span>Thursday</span>
                                </div>
                                <div class="Journey_date">
                                  <p>Return date</p>
                                  <input type="date" value="2022-05-05" />
                                  <span>Thursday</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <CountDropdownTour />
                          <div class="top_form_search_button">
                            <button class="btn btn_theme btn_md">Search</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="hotels"
                role="tabpanel"
                aria-labelledby="hotels-tab"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="tour_search_form">
                      <form action="#!">
                        <div class="row">
                          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="search_boxed">
                              <p>Destination</p>
                              <input
                                type="text"
                                placeholder="Where are you going?"
                              />
                              <span>Where are you going?</span>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div class="form_search_date">
                              <div class="search_boxed date_flex_area">
                                <div class="Journey_date">
                                  <p>Journey date</p>
                                  <input type="date" value="2022-05-03" />
                                  <span>Thursday</span>
                                </div>
                                <div class="Journey_date">
                                  <p>Return date</p>
                                  <input type="date" value="2022-05-05" />
                                  <span>Thursday</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <CountDropdownHotel />
                          <div class="top_form_search_button">
                            <button class="btn btn_theme btn_md">Search</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import CountDropdown from "@/components/templates/home/CountDropdown.vue";
import CountDropdownRound from "@/components/templates/home/CountDropdownRound.vue";
import CountDropdownMulti from "@/components/templates/home/CountDropdownMulti.vue";
import CountDropdownTour from "@/components/templates/home/CountDropdownTour.vue";
import CountDropdownHotel from "@/components/templates/home/CountDropdownHotel.vue";

export default {
  name: "Form",

  components: {
    CountDropdown,
    CountDropdownRound,
    CountDropdownMulti,
    CountDropdownTour,
    CountDropdownHotel,
  },

  data() {
    return {
      isHidden: false,
      rowData: [{ name: "" }],
    };
  },
  methods: {
    addRow: function () {
      let a = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5);

      if (document.querySelectorAll(".multi_city_form").length === 5) {
        alert("Max Citry Limit Reached!!");
        return;
      }

      this.isHidden = true;

      this.rowData.push({ name: "" });
    },
    deleteRow(index) {
      this.rowData.splice(index, 1);
    },
  },
};
</script>
