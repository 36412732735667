<script setup>
import FaqArea from "@/components/templates/pages/FaqArea.vue";
import Banner from "@/components/custom/Banner.vue";
import { useCmsContentStore } from "@/stores/cms";
const faqStore = useCmsContentStore();
faqStore.getAdvanceQuestions();
faqStore.getRegularQuestions();
faqStore.getGeneralQuestions();
import { reactive, ref, watchEffect } from "vue";
const cmsContentStore = useCmsContentStore();
cmsContentStore.getAboutContent();

const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrl.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.faqBackgroupImage
      ? bannerContentStore.productPageBackgroundImage.faqBackgroupImage.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});

const navigation = reactive({
  title: "Faq",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Faq",
    },
  ],
});
</script>
<template>
  <!-- Common Banner Area -->
  <Banner :navigate="navigation" :background-image-url="backgroundImageUrl" />

  <!-- Faqs Area -->
  <FaqArea
    :advance-questions="faqStore.advanceQuestions"
    :general-questions="faqStore.generalQuestions"
    :regular-questions="faqStore.regularQuestions"
  />
</template>
