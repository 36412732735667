<script setup>
import { ref, watch } from "vue";
import { useProfileStore } from "@/stores/profile";
import { useTemplateStore } from "@/stores/template";
import BaseCustomerProfileModifyForm from "@/views/profiles/items/form/BaseCustomerProfileModifyForm.vue";
import { useVuelidate } from "@vuelidate/core";
import { UserProfileFormModel } from "@/formmodel/user/profile/userProfileFormModel";

const templateStore = useTemplateStore();
let props = defineProps({
  profileInfo: {},
});

const user = ref(JSON.parse(sessionStorage.getItem("user")));
const profileStore = useProfileStore();

const basicProfileModifyShowRef = ref(false);
const profileFormModelRef = ref(null);

watch(
  () => props.profileInfo,
  (newValue) => {
    if (newValue) {
      profileFormModelRef.value = new UserProfileFormModel(
        newValue.nameTitle,
        newValue.gender,
        newValue.surname,
        newValue.givenName,
        newValue.emailAddress,
        newValue.countryAccessCode,
        newValue.phoneNumber
      );
    }
  },
  { immediate: true, deep: true }
);
const v = useVuelidate();

async function modifyProfile(profileFormModel) {
  templateStore.pageLoader({ mode: "on" });
  await profileStore.modifyUserProfile(profileFormModel);
  if (profileStore.profileModifyRS) {
    profileStore.loadUserProfile(user.username);
  }
  templateStore.pageLoader({ mode: "off" });
}

function editBasicProfile() {
  basicProfileModifyShowRef.value = !basicProfileModifyShowRef.value;
}
</script>

<template>
  <div v-if="!basicProfileModifyShowRef">
    <div class="row">
      <div class="col-10">
        <div class="font_weight font-24">
          {{ $t("basic-information") }}
        </div>
      </div>
      <div class="col-2 cursor-point">
        <a class="link-primary" @click="editBasicProfile">{{ $t("edit") }}</a>
      </div>
      <div class="col-12">
        <div v-if="!basicProfileModifyShowRef">
          <div class="row">
            <div class="col-12 mg-b-10">
              Make sure this information matches your travel ID,like your
              passport or license.
            </div>
            <div class="col-5">
              <div class="font_weight">{{ $t("name-title") }}</div>
              <div class="text-muted">MR</div>
            </div>
            <div class="col-5">
              <div class="font_weight">{{ $t("name-info") }}</div>
              <div class="text-muted">Liu Alva</div>
            </div>
            <div class="col-5">
              <div class="font_weight">{{ $t("date-of-birth") }}</div>
              <div class="text-muted">Not provided</div>
            </div>
            <div class="col-5">
              <div class="font_weight">{{ $t("gender") }}</div>
              <div class="text-muted">Not provided</div>
            </div>
            <div class="col-5">
              <div class="font_weight">Accessibility needs</div>
              <div class="text-muted">No,I don't have accessibility needs</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-t-20">
      <div class="col-10">
        <div class="font_weight font-24 mg-b-20">
          {{ $t("contact") }}
        </div>
      </div>
      <div class="col-2 cursor-point">
        <!--        <a class="link-primary">{{ $t("edit") }}</a>-->
      </div>
      <div class="col-12 mg-b-10">
        Receive account activity alerts and trip updates by sharing this
        information.
      </div>
      <div class="col-5">
        <div class="font_weight">{{ $t("mobile-number") }}</div>
        <div class="text-muted">+85291567366</div>
      </div>
      <div class="col-5">
        <div class="font_weight">{{ $t("email") }}</div>
        <div class="text-muted">alva.liu@skywidetech.com</div>
      </div>
      <div class="col-5">
        <div class="font_weight">Emergency contact</div>
        <div class="text-muted">Not provided</div>
      </div>

      <div class="col-5">
        <div class="font_weight">Address</div>
        <div class="text-muted">Not provided</div>
      </div>
    </div>
    <div class="row mg-t-20">
      <div class="col-6">
        <div class="font_weight font-24">
          {{ $t("more-details") }}
        </div>
        <div>
          Speed up your booking by securely saving essential travel details.
        </div>
        <div class="mg-t-10">
          <button class="btn btn-secondary" style="width: 80%">
            <div class="text-start">
              <div>Airport security</div>
              <div class="font-14">TSA PreCheck and Redress number</div>
            </div>
          </button>
        </div>
        <div class="mg-t-10">
          <button class="btn btn-secondary" style="width: 80%">
            <div class="text-start">
              <div>Travel Documents</div>
              <div class="font-14">Passport</div>
            </div>
          </button>
        </div>
        <div class="mg-t-10">
          <button class="btn btn-secondary" style="width: 80%">
            <div class="text-start">
              <div>Flight preferences</div>
              <div class="font-14">Seat preference and home airport</div>
            </div>
          </button>
        </div>
        <div class="mg-t-10">
          <button class="btn btn-secondary" style="width: 80%">
            <div class="text-start">
              <div>Reward programs</div>
              <div class="font-14">Frequent flyer and membership programs</div>
            </div>
          </button>
        </div>
      </div>
      <div class="col-6">
        <div class="font_weight font-24">Additional travelers</div>
        <div>
          Make booking a breeze by saving profiles or family,friends,or
          teammates who often travel with you.
        </div>
        <div class="mg-t-10">
          <button class="btn btn-secondary" style="width: 80%">
            Add additional traveler
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="profile_update_form" v-if="basicProfileModifyShowRef">
    <div class="row">
      <div class="col-10">
        <div class="font_weight font-24">
          {{ $t("basic-information") }}
        </div>
      </div>
      <div class="col-2 cursor-point">
        <a class="link-primary" @click="editBasicProfile">{{ $t("back") }}</a>
      </div>
    </div>
    <BaseCustomerProfileModifyForm
      :profile-form-model="profileFormModelRef"
      @modifyProfile="modifyProfile"
    />
  </div>
</template>

<style scoped></style>
