import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/modulesRouter";

import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "bootstrap"; // Template components
import BaseBlock from "@/components/custom/BaseBlock.vue"; // Main CSS Add
import "./assets/app.css";
import { pinia } from "@/stores";
import { useSecurityStore } from "@/stores/security";
import securityApi from "@/apis/securityApi";
import i18n from "@/i18n"; // Bootstrap framework
import * as bootstrap from "bootstrap";

window.bootstrap = bootstrap;

let app = createApp(App);
// Register global components
app.component("BaseBlock", BaseBlock);
// Use Pinia
app.use(pinia);

//User i18n
app.use(i18n);

//Vue Router
const securityStore = useSecurityStore(pinia);
router.beforeEach(async (to, from) => {
  // 检查页面是否需要认证，用户是否已经登录
  let user = JSON.parse(sessionStorage.getItem("user"));
  let query = from.query;
  query.routeName = from.name;

  //保存路由信息

  if (to.name === "security-sign-in" && from.name === "security-sign-in") {
    sessionStorage.setItem("routeInfo", JSON.stringify(query));
  }

  if (
    to.meta.requiresAuth &&
    to.name !== "security-sign-in" &&
    (securityStore.userInfo == null || securityStore.userInfo === "")
  ) {
    const { data } = await securityApi.getSessionInfo();

    securityStore.userInfo = data;
    if (securityStore.userInfo == null || securityStore.userInfo === "") {
      securityStore.$reset();
      sessionStorage.removeItem("user");
      return { name: "security-sign-in", query: query };
    } else {
      sessionStorage.setItem("user", JSON.stringify(data));
    }
  }
});
app.use(router);

app.mount("#app");
