<script setup>
import Banner from "@/components/custom/Banner.vue";
import { computed, reactive, ref, watch, watchEffect } from "vue";
import BaseHotelAndFlightPricePanel from "@/views/sales/package/items/pannel/BaseHotelAndFlightPricePanel";
import BasePackagePassengersForm from "@/views/sales/package/items/form/BasePackagePassengersForm";
import useVuelidate from "@vuelidate/core/dist";
import router from "@/router/modulesRouter";
import { useCmsContentStore } from "@/stores/cms";
import {useTemplateStore} from "@/stores/template";
const bannerContentStore = useCmsContentStore();
const templateStore = useTemplateStore();
const navigation = reactive({
  title: "Passenger Information",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Hotel Search Result",
      path: "/sales/package/hotel-list",
    },
    {
      name: "Hotel Details",
      path: "/sales/package/hotel-details",
    },
    {
      name: "Flight Search Result",
      path: "/sales/package/package-flight-list",
    },
    {
      name: "Itinerary Review",
      path: "/sales/package/package-itinerary-review",
    },
    {
      name: "Passenger Information",
    },
  ],
});

//region 数据初始化
let pkgItineraryReview = JSON.parse(
  sessionStorage.getItem("pkgItineraryReview")
);
let passengerTicketFareBreakdownItems = {};
let holdRoomInfo = {};
let roomInfo = {};
let roomResGuests = ref([]);
let roomResGuestFormModel = reactive(null);
if (pkgItineraryReview) {
  passengerTicketFareBreakdownItems =
    pkgItineraryReview.passengerTicketFareBreakdownItems;
  holdRoomInfo = pkgItineraryReview.holdRoomInfo;
  roomInfo =
    pkgItineraryReview.holdRoomInfo.roomViewItems &&
    pkgItineraryReview.holdRoomInfo.roomViewItems.length > 0
      ? pkgItineraryReview.holdRoomInfo.roomViewItems[0]
      : {};
  roomResGuestFormModel = reactive(pkgItineraryReview.roomResGuestFormModel);
  if (roomResGuestFormModel) {
    roomResGuests = roomResGuestFormModel.roomResGuests;
  }
}

let totalAmountContent = computed(() => {
  let totalAmount = 0;
  let currency = "";
  let adultTotalAmount = 0;
  let childTotalAmount = 0;
  let infantTotalAmount = 0;
  let adultNum = 0;
  let childNum = 0;
  let infantNum = 0;
  let taxesTotalAmount = 0;
  if (passengerTicketFareBreakdownItems) {
    passengerTicketFareBreakdownItems.forEach((breakdownItem) => {
      let totalFare = breakdownItem.totalFare;
      totalAmount += totalFare;
      taxesTotalAmount += breakdownItem.taxes;
      currency = breakdownItem.currency;

      if (breakdownItem.passengerTypeCode == "ADT") {
        adultNum++;
        adultTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "CNN") {
        childNum++;
        childTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "INF") {
        infantNum++;
        infantTotalAmount += breakdownItem.baseFare;
      }
    });
  }
  return {
    totalAmount,
    currency,
    adultTotalAmount,
    childTotalAmount,
    infantTotalAmount,
    infantNum,
    adultNum,
    childNum,
    taxesTotalAmount,
  };
});
//endregion 数据初始化

//region 辅助函数
const v$ = useVuelidate();
const passengerFormRef = ref(null);
async function netStep() {
  const result = await v$.value.$validate();
  validatePhoneAndPassport(); //展示错误效果
  if (!result) {
    return;
  }
  sessionStorage.setItem(
    "pkgRoomResGuestFormModel",
    JSON.stringify(roomResGuestFormModel)
  );
  router.push({ name: "sales-package-booking-preview" });
}

function validatePhoneAndPassport() {
  // passengerFormRef.value.validatePhoneAndPassport();
  passengerFormRef.value.forEach((ele) => {
    ele.validatePhoneAndPassport();
  });
}
//endregion

let bannerContent = reactive(null);
bannerContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrlRef = ref("");
let backgroundImageUrlRef = ref("");

watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrlRef.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.packageBackgroundImage
      ? bannerContentStore.productPageBackgroundImage.packageBackgroundImage.url
      : "";
  backgroundImageUrlRef.value = baseUrl + imageUrlRef.value;
});
let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <h3 class="heading_theme">{{ $t("passenger-information") }}</h3>
      <div class="row">
        <div class="col-lg-8">
          <BasePackagePassengersForm
            v-for="(roomResGuest, roomResGuestIndex) in roomResGuests"
            :key="roomResGuestIndex"
            :room-index="roomResGuestIndex + 1"
            ref="passengerFormRef"
            :room-res-guest="roomResGuest"
          />
        </div>
        <div class="col-lg-4">
          <div class="tou_booking_form_Wrapper">
            <BaseHotelAndFlightPricePanel
              v-if="totalAmountContent"
              :total-amount-content="totalAmountContent"
              :room-info="roomInfo"
              :room-num="
                holdRoomInfo.roomViewItems &&
                holdRoomInfo.roomViewItems.length > 0
                  ? holdRoomInfo.roomViewItems.length
                  : 0
              "
              :show-button="false"
            />
          </div>
        </div>
      </div>
      <div class="booking_tour_form_submit pkg_passenger_btn">
        <router-link
          to="/sales/package/itinerary-review"
          class="btn btn_theme btn_md"
          v-if="equipmentType != 'iphone'"
          >{{ $t("go-back") }}</router-link
        >
        <a
          @click="netStep"
          class="btn btn_theme "
          :class="{btn_md:equipmentType != 'iphone'}"
          style="margin-left: 20px"
          >{{ $t("continue") }}</a
        >
      </div>
    </div>
  </section>
</template>

<style scoped></style>
