<script setup>
import { ref, watch } from "vue";

import hotelFilterOptions from "@/formmodel/sales/hotel/availability/options/hotelFilterOptions";
import hotelSortOptions from "@/formmodel/sales/hotel/availability/options/hotelSortOptions";
import { useVuelidate } from "@vuelidate/core";
import { integer } from "@vuelidate/validators";

const props = defineProps({
  hotelItemsLength: {},
  hotelFilterFormModel: {},
  hotelSortFormModel: {},
  minPrice: {
    type: Number,
  },
  maxPrice: {
    type: Number,
  },
});

const emits = defineEmits("sortAndFilter");
const hotelSortFormModelRef = ref(null);
const hotelFilterFormModelRef = ref(null);

const priceMinAndMaxRef = ref({
  min: null,
  max: null,
});

watch(
  () => props.hotelSortFormModel,
  (newValue) => {
    hotelSortFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watch(
  () => props.hotelFilterFormModel,
  (newValue) => {
    hotelFilterFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

const priceRangeRule = () =>
  priceMinAndMaxRef.value.min < priceMinAndMaxRef.value.max ||
  (priceMinAndMaxRef.value.min === priceMinAndMaxRef.value.max &&
    priceMinAndMaxRef.value.min === null) ||
  (priceMinAndMaxRef.value.min === priceMinAndMaxRef.value.max &&
    priceMinAndMaxRef.value.min === "") ||
  (!priceMinAndMaxRef.value.min && !priceMinAndMaxRef.value.max);
const rules = {
  min: { integer, priceRangeRule },
  max: { integer, priceRangeRule },
};
const v = useVuelidate(rules, priceMinAndMaxRef);

async function changePrice(priceMinAndMax, sortFormModel, filterFormModel) {
  const validateResult = await v.value.$validate();
  if (!validateResult) return;

  if (priceMinAndMax.min === 0 && priceMinAndMax.max === 0) {
    priceMinAndMaxRef.value = {
      min: null,
      max: null,
    };
  }
  if (!priceMinAndMax.min && !priceMinAndMax.max) {
    filterFormModel.priceRange = [];
  } else {
    filterFormModel.priceRange = [priceMinAndMax.min, priceMinAndMax.max];
  }
  emits("sortAndFilter", sortFormModel, filterFormModel);
}

function sortAndFilter(sortFormModel, filterFormModel) {
  emits("sortAndFilter", sortFormModel, filterFormModel);
}

function resetUserRating(sortFormModel, filterFormModel) {
  filterFormModel.userRating = null;
  emits("sortAndFilter", sortFormModel, filterFormModel);
}
</script>

<template>
  <div class="flight_filter_sort">
    <div class="left_side_search_boxed">
      <h4 class="mg-b-20">
        {{
          $t("hotel-num", {
            hotelNum: hotelItemsLength,
          })
        }}
      </h4>
      <!-- 排序区域 -->
      <div class="mg-b-20">
        <div class="left_side_search_heading">
          <h5>{{ $t("sort-by") }}</h5>
        </div>
        <div class="filter_review" v-if="hotelSortOptions">
          <form class="review_star">
            <div
              class="form-check p-0"
              v-for="(sort, index) in hotelSortOptions.sortOptions"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="hotelSortFormModelRef"
                :id="'sort-radios' + index"
                :name="'sort-radios' + index"
                :value="{
                  type: sort.type,
                  order: sort.order,
                }"
                @change="
                  sortAndFilter(hotelSortFormModelRef, hotelFilterFormModelRef)
                "
              />
              <label class="form-check-label" :for="'sort-radios' + index">{{
                sort.name
              }}</label>
            </div>
          </form>
        </div>
      </div>
      <!-- 排序区域END -->

      <!-- 过滤区域 -->
      <div v-if="hotelFilterOptions">
        <div class="left_side_search_heading">
          <h5>{{ $t("filter-by") }}</h5>
        </div>
        <div class="filter_review">
          <form class="review_star">
            <label
              class="form-label text-danger"
              v-if="v.min.$errors.length > 0 || v.max.$errors.length > 0"
              >{{ $t("please-enter-price-range") }}
            </label>
            <label class="form-label" v-else
              >{{ $t("filter-by-price") }}
            </label>
            <div class="mg-t-10 form-group flex-between align-content-center">
              <input
                id="login-username"
                v-model="v.min.$model"
                :class="{
                  'is-invalid': v.min.$errors.length > 0,
                }"
                class="form-control"
                name="login-username"
                :placeholder="$t('min-price')"
                type="number"
                @blur="v.min.$touch"
                @change="
                  changePrice(
                    priceMinAndMaxRef,
                    hotelSortFormModelRef,
                    hotelFilterFormModelRef
                  )
                "
              />
              <div
                style="
                  padding-left: 10px;
                  padding-right: 10px;
                  padding-top: 25px;
                "
              >
                <div
                  style="width: 20px; height: 1px; background-color: #cccccc"
                ></div>
              </div>
              <input
                id="login-username"
                v-model="v.max.$model"
                :class="{
                  'is-invalid': v.max.$errors.length > 0,
                }"
                class="form-control"
                name="login-username"
                :placeholder="$t('max-price')"
                type="number"
                @blur="v.max.$touch"
                @change="
                  changePrice(
                    priceMinAndMaxRef,
                    hotelSortFormModelRef,
                    hotelFilterFormModelRef
                  )
                "
              />
            </div>
          </form>
        </div>
        <div class="filter_review">
          <form class="review_star">
            <label class="form-label">{{ $t("hotel-name") }} </label>
            <div
              class="tour_search_type mg-up-10 p-0"
              style="margin-left: -22px"
            >
              <div class="form-check">
                <input
                  v-model="hotelFilterFormModelRef.hotelName"
                  class="form-control empty-border-left"
                  placeholder="Input Hotel Name"
                  type="text"
                  @input="
                    sortAndFilter(
                      hotelSortFormModelRef,
                      hotelFilterFormModelRef
                    )
                  "
                />
              </div>
            </div>
          </form>
        </div>

        <div class="filter_review">
          <form class="review_star">
            <label class="form-label"> {{ $t("guest-rating") }}</label>
            <div
              class="form-check p-0"
              v-for="(rating, index) in hotelFilterOptions.guestRatingOption"
              :key="index"
            >
              <input
                :id="'example-radios-default' + index"
                v-model="hotelFilterFormModelRef.userRating"
                :name="'example-radios-default' + index"
                :value="rating.value"
                class="form-check-input"
                type="radio"
                @change="
                  sortAndFilter(hotelSortFormModelRef, hotelFilterFormModelRef)
                "
              />
              <label
                :for="'example-radios-default' + index"
                class="form-check-label"
                >{{ $t(rating.label) }}</label
              >
            </div>
            <div>
              <button
                class="btn btn_theme btn-sm"
                @click="
                  resetUserRating(
                    hotelSortFormModelRef,
                    hotelFilterFormModelRef
                  )
                "
              >
                {{ $t("reset") }}
              </button>
            </div>
          </form>
        </div>
        <div class="filter_review">
          <form class="review_star">
            <label class="form-label"> {{ $t("star-rating") }}</label>
            <div
              v-for="(rating, index) in hotelFilterOptions.starRatingOption"
              :key="index"
              class="form-check"
            >
              <input
                :id="'star-rating-radio' + index"
                v-model="hotelFilterFormModelRef.starRatings"
                :name="'example-checkbox-inline' + index"
                :value="rating.value"
                class="form-check-input"
                type="checkbox"
                @change="
                  sortAndFilter(hotelSortFormModelRef, hotelFilterFormModelRef)
                "
              />
              <label
                :for="'star-rating-radio' + index"
                class="form-check-label"
              >
                {{ rating.label }}
                <i
                  class="fas fa-star color_theme"
                  v-for="(num, index) in rating.label"
                ></i>
                <i
                  class="fas fa-star color_asse"
                  v-for="(num, index) in 5 - rating.label"
                ></i>
              </label>
            </div>
          </form>
        </div>
        <div class="filter_review">
          <form class="review_star">
            <label class="form-label"> {{ $t("meal-plans-available") }}</label>
            <div
              v-for="(mealPlan, index) in hotelFilterOptions.mealPlanOption"
              :key="index"
              class="form-check"
            >
              <input
                :id="'meal-checkbox-' + mealPlan.value"
                v-model="hotelFilterFormModelRef.mealPlans"
                :name="'example-checkbox-default' + index"
                :value="mealPlan.value"
                class="form-check-input"
                type="checkbox"
                @change="
                  sortAndFilter(hotelSortFormModelRef, hotelFilterFormModelRef)
                "
              />
              <label
                :for="'meal-checkbox-' + mealPlan.value"
                class="form-check-label"
                >{{ $t(mealPlan.label) }}</label
              >
            </div>
          </form>
        </div>
      </div>
      <!-- 过滤区域END -->
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
