<!--本组件包含酒店名，酒店图片，地址，星级，用户评分，房间类型，床型，入住/离店日期，住宿天数等-->
<script setup>
import { ref, watch } from "vue";
import HotelCancelPolicyContentPanel from "@/views/v2/sales/hotel/items/panel/HotelCancelPolicyContentPanel.vue";

const props = defineProps({
  tripHotelItem: {},
});

const hotelAvailFormModelRef = ref(
  sessionStorage.getItem("hotelAvailFormModel")
    ? JSON.parse(sessionStorage.getItem("hotelAvailFormModel"))
    : {}
);
const tripHotelItemRef = ref(null);
const roomTypeItemRef = ref(null);
const roomItemRef = ref(null);

watch(
  () => props.tripHotelItem,
  (newValue) => {
    tripHotelItemRef.value = newValue;

    if (newValue && newValue.roomTypes && newValue.roomTypes.length > 0) {
      roomTypeItemRef.value = newValue.roomTypes[0];
    }

    if (
      roomTypeItemRef.value &&
      roomTypeItemRef.value.rooms &&
      roomTypeItemRef.value.rooms.length > 0
    ) {
      roomItemRef.value = roomTypeItemRef.value.rooms[0];
    }
  },
  { deep: true, immediate: true }
);

function rateCommentReplace(rateComment) {
  if (rateComment) {
    return rateComment.replaceAll("//", "<br>").replaceAll("\n", "<br>");
  }
}
</script>

<template>
  <div class="tour_booking_form_box">
    <!--  酒店信息相关-->
    <div v-if="tripHotelItemRef">
      <p class="h4 mb-2">
        {{ tripHotelItemRef.hotelName }}
      </p>

      <address class="text-muted">
        {{ tripHotelItemRef.address }}
      </address>
    </div>
    <!--  酒店信息相关END-->

    <!--   房间信息相关-->
    <div v-if="roomItemRef">
      <p class="h6 mg-up-10" v-if="roomTypeItemRef">
        {{ roomTypeItemRef.roomTypeName }} x
        <span v-if="tripHotelItemRef">{{ tripHotelItemRef.roomQuantity }}</span>
      </p>
      <div v-if="hotelAvailFormModelRef">
        <div
          class="fs-sm fw-medium mb-2 text-muted"
          v-if="
            hotelAvailFormModelRef.roomPassengers &&
            hotelAvailFormModelRef.roomPassengers.length > 0
          "
        >
          <div
            v-for="(
              roomPassenger, roomIndex
            ) in hotelAvailFormModelRef.roomPassengers"
            :key="roomIndex"
          >
            {{ $t("room") }}
            {{ roomIndex + 1 }}:
            <span
              >{{ roomPassenger.adultNum }}
              {{ $t("adult") }}
              <span v-if="roomPassenger.childNum"> ,</span>
              <span
                v-if="
                  roomPassenger.childAges && roomPassenger.childAges.length > 0
                "
                >{{ roomPassenger.childNum }} {{ $t("child") }} (<span
                  v-for="(childAge, index) in roomPassenger.childAges.sort(
                    (a, b) => a.age - b.age
                  )"
                  :key="index"
                  >{{ childAge.age }}
                  <span v-if="index < roomPassenger.childAges.length - 1"
                    >,</span
                  > </span
                >{{ $t("years-old") }})
              </span></span
            >
          </div>
        </div>
      </div>

      <p v-if="roomItemRef.bedTypeName" class="fs-sm fw-medium mb-2">
        {{ $t("bed-type") }}:
        <span class="text-muted">{{ roomItemRef.bedTypeName }}</span>
      </p>
      <div v-if="roomItemRef.mealName">
        <i class="fa fa-bell-concierge"></i>
        {{ roomItemRef.mealName }}
      </div>

      <p
        v-if="roomItemRef.refundable"
        class="text-danger cursor-point"
        data-bs-target="#modal-cancel-policies"
        data-bs-toggle="modal"
      >
        {{ $t("refundable") }} <i class="fa fa-circle-info"></i>
      </p>
      <p v-else class="text-danger">
        {{ $t("non-refundable") }}
      </p>

      <p class="fs-sm fw-medium">
        {{ $t("check-in") }}
        <span class="text-muted">{{ roomItemRef.checkInDate }}</span>
      </p>
      <p class="fs-sm fw-medium">
        {{ $t("check-out") }}
        <span class="text-muted">{{ roomItemRef.checkOutDate }}</span>
      </p>
      <p class="fs-sm fw-medium mb-2">
        <span class="text-muted"
          >{{ roomItemRef.nightsStay }}-{{ $t("night-stay") }}</span
        >
      </p>
    </div>
    <!--   房间信息相关END-->
  </div>

  <!-- 退费政策 -->
  <div
    id="modal-cancel-policies"
    aria-labelledby="modal-cancel-policies"
    aria-hidden="true"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div
        v-if="roomItemRef && roomItemRef.cancelPolicies"
        class="modal-content"
      >
        <HotelCancelPolicyContentPanel
          :cancel-policies="roomItemRef.cancelPolicies"
          :room-type-name="
            roomTypeItemRef ? roomTypeItemRef.roomTypeName : null
          "
        />
      </div>
    </div>
  </div>
  <!-- 退费政策END -->
</template>

<style scoped lang="scss"></style>
