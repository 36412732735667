/***
 * 价格字符串转换成千分位
 * @param num
 * @returns {string}
 */
function formatPriceThousands(str) {
  return Number(str)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
}

// 获取assets静态资源
const getAssetsFile = (url) => {
  return new URL(`../../public/assets/flagIcon/${url}`, import.meta.url).href;
};

//计算数组中元素出现的次数 {"1":8,"2":25}
function countTimes(data) {
  let obj = {};
  return data.reduce(function (time, name) {
    if (name in time) {
      time[name]++;
    } else {
      time[name] = 1;
    }
    return time;
  }, {});
}

/**
 * 根据数组对象的某个字段去重
 * item.name  是[{name:1}] 根据每条数据的name值来去重
 * */
function unique(arr, val) {
  const res = new Map();
  return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
}

/**
 * 将字符串的第一个字母大写
 * @param str
 * @returns {string}
 */

function stringFirstUpper(str) {
  if (str) {
    return str[0].toUpperCase() + str.substring(1);
  } else {
    return null;
  }
}

export default {
  formatPriceThousands,
  getAssetsFile,
  countTimes,
  unique,
  stringFirstUpper,
};
