<script setup>
import CommonAuthorTwo from "@/views/security/item/CommonAuthorTwo.vue";
import Cta from "@/views/security/item/Cta.vue";
import { useCmsContentStore } from "@/stores/cms";
import { reactive, ref, watch } from "vue";
import Banner from "@/components/custom/Banner.vue";

const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();
cmsContentStore.getAboutContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const navigation = reactive({
  title: "Reset Password",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Reset Password",
    },
  ],
});
</script>
<template>
  <!-- Common Banner Area -->
  <Banner
    :background-image-url="backgroundImageUrlRef"
    :navigate="navigation"
  />

  <!--  Common Author Area -->
  <CommonAuthorTwo />

  <!-- Cta Area -->
  <Cta />
</template>
