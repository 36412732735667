/*
 * Main and demo navigation arrays
 *
 * 'to' attribute points to the route name, not the path url
 */

export default {
  tab_en: [{value:'ONE_WAY',label:'One Way'},{value:'RETURN',label:'Return'},{value:'MULTY_WAY',label:'Multy Way'}],
  tab_zh: [{value:'ONE_WAY',label:'单程'},{value:'RETURN',label:'往返'},{value:'MULTY_WAY',label:'多程'}],
  tab_tw: [{value:'ONE_WAY',label:'One Way'},{value:'RETURN',label:'Return'},{value:'MULTY_WAY',label:'Multy Way'}],
  tab_ja:[{value:'ONE_WAY',label:'One Way'},{value:'RETURN',label:'Return'},{value:'MULTY_WAY',label:'Multy Way'}],
  tab_ko: [{value:'ONE_WAY',label:'One Way'},{value:'RETURN',label:'Return'},{value:'MULTY_WAY',label:'Multy Way'}],
  tab_fr: [{value:'ONE_WAY',label:'One Way'},{value:'RETURN',label:'Return'},{value:'MULTY_WAY',label:'Multy Way'}],
};
