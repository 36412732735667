<script setup>
import { useTemplateStore } from "@/stores/template";
import { ref, watch } from "vue";
import FlightShoppingForm from "@/views/v2/sales/flight/items/form/FlightShoppingForm.vue";
import HotelAvailForm from "@/views/v2/sales/hotel/items/form/availability/HotelAvailForm.vue";

const templateStore = useTemplateStore();

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <section id="theme_search_form">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <div class="theme_search_form_tabbtn">
              <ul
                class="nav nav-tabs"
                role="tablist"
                v-if="equipmentType === 'iphone'"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="hotels-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#hotels"
                    type="button"
                    role="tab"
                    aria-controls="hotels"
                    aria-selected="false"
                    style="padding-left: 15px; padding-top: 10px"
                  >
                    <i class="fas fa-hotel"></i>
                  </button>
                </li>

                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="flights-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#flights"
                    type="button"
                    role="tab"
                    aria-controls="flights"
                    aria-selected="true"
                    style="padding-left: 15px; padding-top: 10px"
                  >
                    <i class="fas fa-plane-departure"></i>
                  </button>
                </li>

                <!--                <li class="nav-item" role="presentation">-->
                <!--                  <button-->
                <!--                    class="nav-link"-->
                <!--                    id="package-tab"-->
                <!--                    data-bs-toggle="tab"-->
                <!--                    data-bs-target="#package"-->
                <!--                    type="button"-->
                <!--                    role="tab"-->
                <!--                    aria-controls="package"-->
                <!--                    aria-selected="false"-->
                <!--                    style="padding-left: 15px; padding-top: 10px"-->
                <!--                  >-->
                <!--                    <i class="fas fa-hotel"></i>-->
                <!--                    +-->
                <!--                    <i class="fas fa-plane-departure"></i>-->
                <!--                  </button>-->
                <!--                </li>-->
              </ul>

              <ul class="nav nav-tabs" v-else role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="hotels-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#hotels"
                    type="button"
                    role="tab"
                    aria-controls="hotels"
                    aria-selected="false"
                  >
                    <i class="fas fa-hotel"></i>{{ $t("hotels") }}
                  </button>
                </li>

                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="flights-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#flights"
                    type="button"
                    role="tab"
                    aria-controls="flights"
                    aria-selected="true"
                  >
                    <i class="fas fa-plane-departure"></i>{{ $t("flights") }}
                  </button>
                </li>

                <!--                <li class="nav-item" role="presentation">-->
                <!--                  <button-->
                <!--                    class="nav-link"-->
                <!--                    id="package-tab"-->
                <!--                    data-bs-toggle="tab"-->
                <!--                    data-bs-target="#package"-->
                <!--                    type="button"-->
                <!--                    role="tab"-->
                <!--                    aria-controls="package"-->
                <!--                    aria-selected="false"-->
                <!--                  >-->
                <!--                    <i class="fas fa-globe"></i>{{ $t("package-flight-hotel") }}-->
                <!--                  </button>-->
                <!--                </li>-->
              </ul>
            </div>

            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="hotels"
                role="tabpanel"
                aria-labelledby="hotels-tab"
              >
                <HotelAvailForm
                  :equipment-type="equipmentType"
                  :jumboable="true"
                  :jump-to="'sales-hotel-list-v2'"
                  :is-from-home="true"
                />
              </div>
              <div
                class="tab-pane fade"
                id="flights"
                role="tabpanel"
                aria-labelledby="flights-tab"
              >
                <!--                <BaseFlightSearchForm-->
                <!--                  :equipment-type="equipmentType"-->
                <!--                  :jumboable="true"-->
                <!--                  :jump-to="'sales-flight-list'"-->
                <!--                />-->

                <FlightShoppingForm
                  :equipment-type="equipmentType"
                  :jumboable="true"
                  :jump-to="'sales-flight-list-v2'"
                  :is-from-home="true"
                />
              </div>

              <!--              <div-->
              <!--                class="tab-pane fade"-->
              <!--                id="package"-->
              <!--                role="tabpanel"-->
              <!--                aria-labelledby="package-tab"-->
              <!--              >-->
              <!--                <BasePackageSearchForm-->
              <!--                  :jumboable="true"-->
              <!--                  :equipment-type="equipmentType"-->
              <!--                  :jump-to="'sales-package-hotel-list'"-->
              <!--                />-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
