<script setup>
import { computed, reactive, ref, watch, watchEffect } from "vue";
import Banner from "@/components/custom/Banner.vue";
import dateUtils from "@/utils/dateUtils";
import { useFlightStore } from "@/stores/flight";
import * as dayjs from "dayjs";
import BaseFlightPriceBreakdownPanel from "@/views/sales/flight/items/panel/BaseFlightPriceBreakdownPanel.vue";
import BaseFlightTravelerPanel from "@/views/sales/flight/items/panel/BaseFlightTravelerPanel.vue";
import BaseFlightPriceSummaryPanel from "@/views/sales/flight/items/panel/BaseFlightPriceSummaryPanel.vue";
import BaseFlightItineraryPanel from "@/views/sales/flight/items/panel/BaseFlightItineraryPanel.vue";
import { useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";

const router = useRouter();
import { useCmsContentStore } from "@/stores/cms";

const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrl.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.flightBackgroundImage
      ? bannerContentStore.productPageBackgroundImage.flightBackgroundImage.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});
const navigation = reactive({
  title: "Booking Preview",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Flight Search Result",
      path: "/sales/flight/flight-list",
    },
    {
      name: "Itinerary Review",
      path: "/sales/flight/flight-itinerary-review",
    },
    {
      name: "flight-passengers",
      path: "/sales/flight/flight-passengers",
    },
    {
      name: "Booking Preview",
    },
  ],
});

let pricedFlightViewItemsGroup = JSON.parse(
  sessionStorage.getItem("pricedFlightViewItemsGroup")
);
let airportCityMap = JSON.parse(sessionStorage.getItem("airportCityMap"));
let offerViewItemMap = JSON.parse(sessionStorage.getItem("offerViewItemMap"));
let flightPassengersFormModel = JSON.parse(
  sessionStorage.getItem("flightPassengersFormModel")
);
let passengerTicketFareBreakdownItems = JSON.parse(
  sessionStorage.getItem("passengerTicketFareBreakdownItems")
);

let user = JSON.parse(sessionStorage.getItem("user"));
let totalAmountContent = computed(() => {
  let totalAmount = 0;
  let currency = "";
  let adultTotalAmount = 0;
  let childTotalAmount = 0;
  let infantTotalAmount = 0;
  let adultNum = 0;
  let childNum = 0;
  let infantNum = 0;
  let taxesTotalAmount = 0;
  if (flightStore.passengerTicketFareBreakdownItems) {
    flightStore.passengerTicketFareBreakdownItems.forEach((breakdownItem) => {
      let totalFare = breakdownItem.totalFare;
      totalAmount += totalFare;
      taxesTotalAmount += breakdownItem.taxes;
      currency = breakdownItem.currency;

      if (breakdownItem.passengerTypeCode === "ADT") {
        adultNum++;
        adultTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "CNN") {
        childNum++;
        childTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "INF") {
        infantNum++;
        infantTotalAmount += breakdownItem.baseFare;
      }
    });
  }
  return {
    totalAmount,
    currency,
    adultTotalAmount,
    childTotalAmount,
    infantTotalAmount,
    infantNum,
    adultNum,
    childNum,
    taxesTotalAmount,
  };
});

function getDuration(start, end) {
  let startDate = new Date(start);
  let endDate = new Date(end);
  let durationInMS = endDate.getTime() - startDate.getTime();
  return dateUtils.formatHourMinute(durationInMS / 1000 / 60);
}
function getAirlinePicture(airlineCode) {
  return "http://images.skywidetech.com/airlines/" + airlineCode + ".png";
}

//城市名
function getAirportCityName(code) {
  if (!flightStore.airportCityMap || !flightStore.airportCityMap[code])
    return code;
  //可以通过语言获取不同类型的名字
  return flightStore.airportCityMap[code].cityName;
}
//航空公司
function getAirlineName(airlineCode) {
  let airlineNameKey = "airlineName." + airlineCode;
  return airlineNameKey;
}

function getAirportName(airportCode) {
  let airportNameKey = "airport." + airportCode;
  return airportNameKey;
}

function getAircraftName(aircraftCode) {
  let aircraftNameKey = "aircraft." + aircraftCode;
  return aircraftNameKey;
}

let flightStore = useFlightStore();
let templateStore = useTemplateStore();
flightStore.updatePricedFlightViewItemsGroup(pricedFlightViewItemsGroup);
flightStore.updateAirportCityMap(airportCityMap);
flightStore.updateOfferViewItemMap(offerViewItemMap);
flightStore.updateFlightPassengersFormModel(flightPassengersFormModel);
flightStore.updatePassengerTicketFareBreakdownItems(
  passengerTicketFareBreakdownItems
);

async function goToPaymentPage() {
  let quoteID = getQuoteId(pricedFlightViewItemsGroup);
  let username = user.username;
  let flightBookingFormModel = {
    travelerInfos: flightStore.flightPassengersFormModel.passengers,
    quoteID,
    username,
  };
  templateStore.pageLoader({ mode: "on" });
  await flightStore.airBook(flightBookingFormModel);
  let airBookRS = flightStore.airBookRS;
  if (!airBookRS.success) {
    let shortText = airBookRS.errors.errors[0].shortText;
    router.push({ name: "error-500", query: { message: shortText } });
    templateStore.pageLoader({ mode: "off" });
  } else {
    sessionStorage.setItem("airBookRS", JSON.stringify(airBookRS));
    templateStore.pageLoader({ mode: "off" });
    router.push({
      name: "payment-methods",
      query: {
        bookingId: getIdMap(airBookRS).bookingId,
        invoiceId: getIdMap(airBookRS).invoiceId,
        productType: "flight",
      },
    });
  }
}

function getIdMap(airBookRS) {
  let invoiceId = null;
  let bookingId = null;
  airBookRS.airReservation.bookingReferenceIdList.forEach(
    (bookingReferenceId) => {
      if ("INVOICE_ID" == bookingReferenceId.type) {
        invoiceId = bookingReferenceId.id;
      } else if ("BOOKING_ID" == bookingReferenceId.type) {
        bookingId = bookingReferenceId.id;
      }
    }
  );
  return {
    invoiceId,
    bookingId,
  };
}

function getQuoteId(pricedFlightViewItemsGroup) {
  let firstKey = Object.keys(pricedFlightViewItemsGroup[0])[0];
  let airItineraryRPH =
    pricedFlightViewItemsGroup[0][firstKey][0].airItineraryRPH;
  return airItineraryRPH;
}

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <!-- Common Banner Area -->
  <Banner :navigate="navigation" :background-image-url="backgroundImageUrl" />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <h3 class="heading_theme">{{ $t("booking-preview") }}</h3>
      <div class="row">
        <div class="col-lg-9">
          <div class="tou_booking_form_Wrapper">
            <div
              class="booking_tour_form"
              v-if="
                flightStore.pricedFlightViewItemsGroup &&
                flightStore.offerViewItemMap &&
                flightStore.airportCityMap
              "
            >
              <BaseFlightItineraryPanel
                :priced-flight-view-items-group="
                  flightStore.pricedFlightViewItemsGroup
                "
                :airport-city-map="flightStore.airportCityMap"
                :offer-view-item-map="flightStore.offerViewItemMap"
              />
            </div>
            <div class="booking_tour_form">
              <BaseFlightTravelerPanel
                :equipment-type="equipmentType"
                v-if="flightStore.flightPassengersFormModel"
                :passengers="flightStore.flightPassengersFormModel.passengers"
              />
            </div>
            <div class="booking_tour_form">
              <BaseFlightPriceSummaryPanel
                v-if="flightStore.passengerTicketFareBreakdownItems"
                :passenger-ticket-fare-breakdown-items="
                  flightStore.passengerTicketFareBreakdownItems
                "
                :total-amount-content="totalAmountContent"
              />
            </div>
            <div
              class="booking_tour_form_submit"
              v-if="equipmentType != 'iphone'"
            >
              <router-link
                to="/sales/flight/flight-passengers"
                class="btn btn_theme btn_md"
                >{{ $t("go-back") }}</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <BaseFlightPriceBreakdownPanel
            :equipment-type="equipmentType"
            :total-amount-content="totalAmountContent"
            @nextStep="goToPaymentPage"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
