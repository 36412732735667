import axios from "@/utils/axiosUtils";

//method=common&invoiceId=132&bookingType=hotel&paymentGatewayId=STRIPE&currencyCode=HKD
function payByCredit(invoiceId, productType, bookingRQ) {
  let url =
    "/api/v3/accounting/receipts/initialization?method=CREDIT&invoiceId=" +
    invoiceId +
    "&bookingType=" +
    productType +
    "&paymentGatewayId=";
  return axios.post(url, bookingRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function saveNecessaryInfo(invoiceId, productType, bookingRQ) {
  let url =
    "/api/v3/accounting/receipts/necessary-info?invoiceId=" +
    invoiceId +
    "&bookingType=" +
    productType;
  return axios.post(url, bookingRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function payByPaymentMethod(
  invoiceId,
  productType,
  paymentMethod,
  bookingRQ,
  cardType
) {
  let url =
    "/api/v3/accounting/receipts/initialization?method=common&invoiceId=" +
    invoiceId +
    "&bookingType=" +
    productType +
    "&paymentGatewayId=" +
    paymentMethod +
    "&cardType=" +
    cardType;
  return axios.post(url, bookingRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function commonInitiatePayment(
  invoiceId,
  bookingType,
  paymentGatewayId,
  bookRQ,
  currencyCode
) {
  let url =
    "/api/v3/accounting/receipts/initialization?method=common&invoiceId=" +
    invoiceId +
    "&bookingType=" +
    bookingType +
    "&paymentGatewayId=" +
    paymentGatewayId +
    "&currencyCode=" +
    currencyCode;
  return axios.post(url, bookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  payByCredit,
  payByPaymentMethod,
  saveNecessaryInfo,
  commonInitiatePayment,
};
