<script setup>
import { ref, watch } from "vue";
import { Rate } from "ant-design-vue";

let props = defineProps({
  item: {},
});

const ARate = Rate;
const itemRef = ref();
watch(
  () => props.item,
  (newValue) => {
    itemRef.value = newValue;
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <div class="theme_common_box_two img_hover" v-if="itemRef">
    <div class="theme_two_box_img" style="height: 250px; overflow: hidden">
      <img
        :src="itemRef.mainImage"
        class="img-fluid rounded"
        style="width: 100%; height: 100%; object-fit: cover"
      />
      <p><i class="fas fa-map-marker-alt"></i>{{ itemRef.address }}</p>
    </div>
    <div class="theme_two_box_content">
      <h4>
        <div class="hotelName">{{ itemRef.hotelName }}</div>
      </h4>
      <div class="text-muted rate">
        <a-rate :value="itemRef.starRating" disabled />
      </div>
      <p>
        <span class="review_rating">
          {{ itemRef.userRating }}/5 {{ $t("trip-advisor") }}
        </span>
      </p>
      <h3 v-if="itemRef.bestAvgUnitPrice" class="text-end">
        <span> {{ itemRef.currencyCode }}</span>
        {{ itemRef.bestAvgUnitPrice.toFixed(2) }}
      </h3>
    </div>
  </div>
</template>

<style scoped></style>
