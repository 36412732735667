<script setup>
import { ref, watch, watchEffect } from "vue";
import { Modal } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import flightCommonTools from "../../../../../../../tools/v2/flight/common/flightCommonTools";

const props = defineProps({
  service: {},
  currentPassenger: {},
  currentSegment: {},
  flightPriceFormModel: {},
  airlineItemMap: {},
});

const emits = defineEmits();

const { t, locale } = useI18n(); // 解构调用函数
const AModal = Modal;
const serviceRef = ref(null);
const serviceSelectedRef = ref(null);
const flightPriceFormModelRef = ref(null);

watch(
  () => props.flightPriceFormModel,
  (newValue) => {
    flightPriceFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watchEffect(() => {
  serviceRef.value = props.service;
  serviceSelectedRef.value = JSON.parse(JSON.stringify(props.service));
  serviceSelectedRef.value.quantity = 0; //有一个问题是再次进来的时候又进行了初始化

  if (
    props.service &&
    props.flightPriceFormModel &&
    props.flightPriceFormModel.servicesSelected &&
    props.flightPriceFormModel.servicesSelected.length > 0
  ) {
    const ancillaryId = props.service.ancillaryId;
    const flightSegmentRPH = props.service.flightSegmentRPH;
    const travelerRPH = props.service.travelerRPH;

    const servicesSelected = props.flightPriceFormModel.servicesSelected;
    if (servicesSelected && servicesSelected.length > 0) {
      let serviceItem = servicesSelected.find(
        (serviceItem) =>
          serviceItem.ancillaryId === ancillaryId &&
          serviceItem.flightSegmentRPH === flightSegmentRPH &&
          serviceItem.travelerRPH === travelerRPH
      );

      if (serviceItem) {
        serviceSelectedRef.value.quantity =
          serviceItem.quantity == null ? 0 : serviceItem.quantity;
      }
    }
  }
});

function updateServiceSelected(serviceSelected, type) {
  let quantity = serviceSelected.quantity;
  if (type === "ADD") {
    if (serviceSelected.bookingInstruction || serviceSelected.quantity === 0) {
      quantity += 1;
      serviceSelected.quantity = quantity;
    }
  } else if (type === "REDUCE" && serviceSelected.quantity >= 1) {
    quantity -= 1;
    serviceSelected.quantity = quantity;
  }
}

function cancelService() {
  emits("closeOperationForm");
}

function addService(serviceSelected, currentSegment) {
  const quantity = serviceSelected.quantity;
  serviceSelected.flightSegmentRPH = currentSegment.rph;
  if (quantity > 0) {
    flightPriceFormModelRef.value.updateServiceSelected(serviceSelected);
    emits("closeOperationForm");
  }
}
</script>

<template>
  <div class="service-operation">
    <h3>{{ $t("purchase-additional-service") }}</h3>
    <div class="font_weight font-18">
      {{ currentSegment.departureAirportCode }}

      <i class="bi bi-arrow-right mg-l-8 mg-r-8 main_color"></i>
      {{ currentSegment.arrivalAirportCode }}
    </div>
    <span class="text-muted font-18">
      {{
        flightCommonTools.getAirlineName(
          locale,
          currentSegment.marketingAirlineCode,
          airlineItemMap
        )
      }}
      {{ currentSegment.flightNumber }}
    </span>

    <div class="font-18">
      {{ $t("passenger") }}
      {{ currentPassenger.travelerRefNumberRPH }} ({{
        currentPassenger.passengerTypeCode
      }})
    </div>

    <div class="position_center font-18" v-if="serviceRef">
      <div class="mg-l-10">
        <h5>{{ serviceRef.name }}</h5>
        <div class="text-primary">
          {{ serviceRef.currencyCode }} {{ serviceRef.amount }}
          <span class="text-muted">{{ $t("per-unit") }}</span>
        </div>

        <div class="input-group mg-t-10" style="width: 20%">
          <button
            class="btn btn-alt-primary"
            type="button"
            @click="updateServiceSelected(serviceSelectedRef, 'REDUCE')"
          >
            -
          </button>
          <input
            v-model="serviceSelectedRef.quantity"
            class="form-control"
            placeholder="0"
            type="number"
            :disabled="!serviceRef.bookingInstruction"
            @change="updateServiceSelected(serviceSelectedRef, 'INPUT')"
          />
          <button
            class="btn btn-alt-primary"
            type="button"
            @click="updateServiceSelected(serviceSelectedRef, 'ADD')"
          >
            +
          </button>
        </div>
        <!--        <div-->
        <!--          class="input-group text-center text-danger"-->
        <!--          style="width: 50%"-->
        <!--        ></div>-->
      </div>
    </div>
    <div class="mg-t-20 mg-l-10">
      <button class="btn btn-outline-danger" @click="cancelService">
        {{ $t("cancel") }}
      </button>
      <button
        class="btn btn-outline-primary mg-l-8"
        @click="addService(serviceSelectedRef, currentSegment)"
      >
        {{ $t("confirm") }}
      </button>
    </div>
  </div>
</template>

<style lang="scss"></style>
