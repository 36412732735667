import { defineStore } from "pinia";
import airContentApi from "@/apis/v2/content/airContentApi";

export const useAirContentStore = defineStore("airContentStore", {
  state: () => ({
    airportCityItemMap: null,
    airportCityItems: null,
    airportCityItem: null,
    airlineItems: null,
    airlineItemMap: null,
    airlineItem: null,
    aircraftItems: null,
    aircraftItemMap: null,
    aircraftItem: null,
    totalElements: 0,
  }),
  actions: {
    findAirportCities(airportCodes) {
      const thisRef = this;
      if (!airportCodes || airportCodes.length === 0) {
        thisRef.airportCityItems = null;
        return Promise.resolve();
      }
      const promises = [];
      for (const airportCode of airportCodes) {
        const airportCityPromise = airContentApi.airportCity(airportCode);
        promises.push(airportCityPromise);
      }

      return Promise.all(promises)
        .then((resArr) => {
          thisRef.airportCityItems = [];
          for (const res of resArr) {
            const data = res.data;
            thisRef.airportCityItems.push(data);
          }
        })
        .catch((err) => {
          thisRef.airportCityItems = null;
        });
    },
    findAirportCityMap(airportCodes) {
      const thisRef = this;
      if (!airportCodes || airportCodes.length === 0) {
        thisRef.airportCityItemMap = null;
        return Promise.resolve();
      }
      const promises = [];
      for (const airportCode of airportCodes) {
        const airportCityPromise = airContentApi.airportCity(airportCode);
        promises.push(airportCityPromise);
      }

      return Promise.all(promises)
        .then((resArr) => {
          thisRef.airportCityItemMap = {};
          for (const res of resArr) {
            const data = res.data;
            thisRef.airportCityItemMap[data.code] = data; //统一shopping部分airportCityMap
            // thisRef.airportCityItems.push(data);
          }
        })
        .catch((err) => {
          thisRef.airportCityItemMap = null;
        });
    },
    findAirportCity(airportCode) {
      const thisRef = this;
      return airContentApi
        .airportCity(airportCode)
        .then((res) => {
          thisRef.airportCityItem = res.data;
        })
        .catch((err) => {
          thisRef.airportCityItem = null;
        });
    },
    findAirlineMap() {
      const thisRef = this;
      thisRef.airlineItemMap = {};
      return airContentApi.airlines().then((res) => {
        for (const element of res.data) {
          if (!element.code) continue;
          thisRef.airlineItemMap[element.code] = element;
        }
      });
    },
    findAirlines(airlineCodes) {
      const thisRef = this;
      if (!airlineCodes || airlineCodes.length === 0) {
        thisRef.airlineItems = null;
        return Promise.resolve();
      }
      const promises = [];
      for (const airlineCode of airlineCodes) {
        const airlinePromise = airContentApi.airline(airlineCode);
        promises.push(airlinePromise);
      }

      return Promise.all(promises)
        .then((resArr) => {
          thisRef.airlineItems = [];
          for (const res of resArr) {
            const data = res.data;
            thisRef.airlineItems.push(data);
          }
        })
        .catch((err) => {
          thisRef.airlineItems = null;
        });
    },
    findAirline(airlineCode) {
      const thisRef = this;

      if (!airlineCode) {
        thisRef.airlineItem = null;
        return Promise.resolve();
      }

      return airContentApi.airline(airlineCode).then((res) => {
        thisRef.airlineItem = res.data;
      });
    },
    findAircrafts(aircraftCodes) {
      const thisRef = this;
      if (!aircraftCodes || aircraftCodes.length === 0) {
        thisRef.aircraftItems = null;
        return Promise.resolve();
      }

      const promises = [];
      for (const aircraftCode of aircraftCodes) {
        const aircraftPromise = airContentApi.aircraft(aircraftCode);
        promises.push(aircraftPromise);
      }

      return Promise.all(promises)
        .then((resArr) => {
          thisRef.aircraftItems = [];
          for (const res of resArr) {
            const data = res.data;
            thisRef.aircraftItems.push(data);
          }
        })
        .catch((err) => {
          thisRef.aircraftItems = null;
        });
    },
    findAircraftsMap() {
      const thisRef = this;
      thisRef.aircraftItemMap = {};
      return airContentApi.aircrafts().then((res) => {
        for (const element of res.data) {
          thisRef.aircraftItemMap[element.code] = element;
        }
      });
    },
    findAircraft(aircraftCode) {
      const thisRef = this;
      if (!aircraftCode) {
        thisRef.aircraftItem = null;
        return Promise.resolve();
      }

      return airContentApi.aircraft(aircraftCode).then((res) => {
        thisRef.airlineItem = res.data;
      });
    },
  },
});
