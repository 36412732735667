<script setup>
import ContactArea from "@/components/templates/contact/ContactArea.vue";
import { useCmsContentStore } from "@/stores/cms";
import Banner from "@/components/custom/Banner.vue";
import { reactive, ref, watch } from "vue";

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();
cmsContentStore.getAboutContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const navigation = reactive({
  title: "Contact us",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Contact us",
    },
  ],
});
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />

  <!-- Contact Area -->
  <ContactArea :contact-info="cmsContentStore.contactContent" />
</template>

<style scoped></style>
