<script setup>
import { useRoute } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useFlightAncillaryServiceStore } from "@/stores/v2/flight/ancillaryservice/flightAncillaryServiceStore";
import { Menu } from "ant-design-vue";
import { ref, watch } from "vue"; // Main store and Route

// Main store and Route
const store = useTemplateStore();
const route = useRoute();
const AMenu = Menu;
const ancillaryServiceStore = useFlightAncillaryServiceStore();
const emits = defineEmits();

const props = defineProps({
  nodes: {
    type: Array,
    description: "The nodes of the navigation",
  },
});

const itemsRef = ref(null);

watch(
  () => props.nodes,
  (newValue) => {
    itemsRef.value = transformObjectKeys(newValue);
  },
  { immediate: true, deep: true }
);

function transformObjectKeys(obj) {
  // 检查传入的是否是数组
  if (Array.isArray(obj)) {
    // 如果是数组，递归地转换数组中的每个元素
    return obj.map((item) => transformObjectKeys(item));
  }

  // 检查传入的是否是对象
  if (obj !== null && typeof obj === "object") {
    // 创建一个新的对象来存储转换后的键值对
    const newObj = {};
    for (let key in obj) {
      if (key === "code") {
        // 转换 'code' 为 'key'
        newObj.key = obj[key];
      } else if (key === "name") {
        // 转换 'name' 为 'label'
        newObj.label = obj[key] + " (" + obj.quantity + ") ";
      } else if (key === "subGroups") {
        // 如果是 'subGroups'，递归地转换子数组
        newObj.children = transformObjectKeys(obj[key]);
      } else {
        // 对于其他键，直接复制到新对象
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }
  return obj;
}

function chooseServiceCode(e) {
  let code = null;
  if (Array.isArray(e)) {
    //打开的是菜单
    if (e && e.length > 0) {
      code = e[e.length - 1];
    }
  } else if (e !== null && typeof e === "object") {
    //最里层
    code = e.key;
  }
  ancillaryServiceStore.changeTaxonomyCode(code);
}
</script>

<template>
  <a-menu
    id="dddddd"
    style="width: 256px"
    mode="inline"
    :items="itemsRef"
    @select="chooseServiceCode"
    @openChange="chooseServiceCode"
  ></a-menu>
</template>
