<script setup>
import { useI18n } from "vue-i18n";
import { ref, watch } from "vue";
import HotelAvailRoomPassengerChildAgeFormItem from "@/views/v2/sales/hotel/items/form/availability/HotelAvailRoomPassengerChildAgeFormItem.vue";

const props = defineProps({
  roomPassengerFormItemModel: {},
  roomIndex: {},
});
const emits = defineEmits(["removeRoomPassengers"]);
const { t, locale } = useI18n(); // 解构调用函数

const roomPassengerFormItemModelRef = ref(null);

watch(
  () => props.roomPassengerFormItemModel,
  (newValue) => {
    roomPassengerFormItemModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function removeRoomPassengers(roomIndex) {
  emits("removeRoomPassengers", roomIndex);
}

function updateCount(type, passenger, roomPassengerFormItemModel) {
  if (type === "SUBTRACT") {
    if (passenger === "ADULT" && roomPassengerFormItemModel.adultNum > 1)
      roomPassengerFormItemModel.adultNum--;
    if (passenger === "CHILD" && roomPassengerFormItemModel.childNum > 0)
      roomPassengerFormItemModel.removeChildNum();
  } else {
    if (passenger === "ADULT") roomPassengerFormItemModel.adultNum++;
    if (passenger === "CHILD") roomPassengerFormItemModel.addChildNum();
  }
}
</script>

<template>
  <div class="margin-top-20">
    <h6>{{ $t("room") }} {{ roomIndex + 1 }}</h6>
    <div class="passenger-form">
      <div class="mb-4 row">
        <div class="col-7">
          <label class="form-label" for="dropdown-content-form-adults">{{
            $t("adults")
          }}</label>
        </div>
        <div class="col-5 number-choice">
          <div class="passenger_operator">
            <button
              class="btn btn_md"
              :id="'sub-adult-button-' + roomIndex"
              @click="
                updateCount('SUBTRACT', 'ADULT', roomPassengerFormItemModelRef)
              "
              :class="{
                disabled: roomIndex > 0,
              }"
            >
              -
            </button>
          </div>
          <input
            type="text"
            :value="roomPassengerFormItemModelRef.adultNum"
            class="text-center"
            placeholder="0"
            id="dropdown-content-form-adults"
            name="dropdown-content-form-adults"
          />
          <div class="passenger_operator">
            <button
              class="btn btn_md"
              :id="'sub-adult-button-' + roomIndex"
              @click="
                updateCount('ADD', 'ADULT', roomPassengerFormItemModelRef)
              "
              :class="{
                disabled: roomIndex > 0,
              }"
            >
              +
            </button>
          </div>
        </div>
      </div>

      <div class="mb-4 row">
        <div class="col-7">
          <label class="form-label" for="dropdown-content-form-children">{{
            $t("children-ages")
          }}</label>
        </div>

        <div class="col-5 number-choice">
          <div class="passenger_operator">
            <button
              class="btn btn_md"
              :id="'sub-child-button-' + roomIndex"
              @click="
                updateCount('SUBTRACT', 'CHILD', roomPassengerFormItemModelRef)
              "
              :class="{
                disabled: roomIndex > 0,
              }"
            >
              -
            </button>
          </div>
          <input
            type="text"
            :value="roomPassengerFormItemModelRef.childNum"
            class="empty-border text-center"
            placeholder="0"
            id="dropdown-content-form-children"
            name="dropdown-content-form-children"
            :class="{
              disabled: roomIndex > 0,
            }"
          />
          <div class="passenger_operator">
            <button
              class="btn btn_md"
              @click="
                updateCount('ADD', 'CHILD', roomPassengerFormItemModelRef)
              "
              :class="{
                disabled: roomIndex > 0,
              }"
            >
              +
            </button>
          </div>
        </div>
      </div>

      <div class="mb-4 row">
        <HotelAvailRoomPassengerChildAgeFormItem
          :child-age="childAge"
          :child-index="childIndex"
          :key="childIndex"
          v-for="(
            childAge, childIndex
          ) in roomPassengerFormItemModelRef.childAges"
          :disabled="roomIndex > 0"
        />
      </div>
      <a v-if="roomIndex > 0" class="flex justify-content-sm-end"
        ><span
          class="cursor-point"
          style="font-size: 16px"
          @click="removeRoomPassengers(roomIndex)"
          >{{ $t("remove-room") }}</span
        ></a
      >
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
