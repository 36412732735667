import { TripPassengerDocumentVO } from "@/viewobject/trip/common/tripPassengerDocumentVO";
import {
  adultNameTitleOptions,
  childNameTitleOptions,
  genderOptions,
} from "@/formmodel/sales/flight/common/options/flightCommonOption";

export class FlightBookFormModel {
  /**
   *
   * @param airItineraryRPH string，行程唯一标识
   * @param quoteID string，验价ID
   * @param passengers Array，FlightPassengerFormItemVO对象数组
   * @param servicesSelected Array，TripAirItemAncillaryFareVO对象数组
   * @param paymentDetails TripAirItemPaymentDetailVO对象
   */
  constructor(
    airItineraryRPH,
    quoteID,
    passengers,
    servicesSelected,
    paymentDetails
  ) {
    this.airItineraryRPH = airItineraryRPH;
    this.quoteID = quoteID;
    this.passengers = passengers;
    this.servicesSelected = servicesSelected;
    this.paymentDetails = paymentDetails;
  }
}

export class FlightPassengerFormItem {
  /**
   *
   * @param passengerTypeCode string，乘客类型，例如，ADT, B15, BNN, C15, CHD, CMA, CMP, EMI, HOF, INF, INN, INS, ITF, ITS, ITX,  JCB, JNF, JNN, JNS, LBR, LIF, LNN, MIS, OFW, SEA, SPS, SRC, STU, VFR, YTH
   * @param travelerRefNumberRPH string，乘客唯一标识
   * @param quantity number，该乘客类型数量
   * @param ffpNumber string，常飞计划用户代码，格式为常飞计划代码所属航司/用户代码，例如，SQ/234232
   * @param nameTitle string，称呼，例如，MR，MS，MRS，DR等等
   * @param gender string，性别
   * @param givenName string，乘客名字
   * @param surname string，乘客姓氏
   * @param birthDate string，出生日期，yyyy-MM-dd
   * @param phoneUseType string，电话类型
   * @param dialAreaCode string，电话区号，例如，852，86
   * @param phoneNumber number，电话号码
   * @param emailAddress string，邮箱
   * @param documents Array，
   * @param redressNumber
   * @param knownTravelerNumber
   */
  constructor(
    passengerTypeCode,
    travelerRefNumberRPH,
    quantity,
    ffpNumber,
    nameTitle,
    gender,
    givenName,
    surname,
    birthDate,
    phoneUseType,
    dialAreaCode,
    phoneNumber,
    emailAddress,
    documents,
    redressNumber,
    knownTravelerNumber
  ) {
    this.passengerTypeCode = passengerTypeCode;
    this.travelerRefNumberRPH = travelerRefNumberRPH;
    this.quantity = quantity;
    this.ffpNumber = ffpNumber;
    this.nameTitle = nameTitle;
    this.gender = gender;
    this.givenName = givenName;
    this.surname = surname;
    this.birthDate = birthDate;
    this.phoneUseType = phoneUseType;
    this.dialAreaCode = dialAreaCode;
    this.phoneNumber = phoneNumber;
    this.emailAddress = emailAddress;
    this.documents = documents;
    this.redressNumber = redressNumber;
    this.knownTravelerNumber = knownTravelerNumber;

    if (passengerTypeCode && !nameTitle) {
      if (
        passengerTypeCode === "CHD" ||
        passengerTypeCode === "INF" ||
        passengerTypeCode === "CNN"
      ) {
        this.nameTitle = childNameTitleOptions[0].code;
      } else {
        this.nameTitle = adultNameTitleOptions[0].code;
      }
    }

    if (!gender) {
      this.gender = genderOptions[0].code;
    }
  }

  addNewDocument(
    docType,
    docID,
    issueCountry,
    docHolderNationality,
    expiryDate
  ) {
    const document = new TripPassengerDocumentVO(
      docType,
      docID,
      issueCountry,
      docHolderNationality,
      expiryDate
    );
    if (this.documents == null) this.documents = [];
    this.documents.push(document);

    return document;
  }
}
