<script setup>
import { Empty, Modal, Popover, Spin, Tabs } from "ant-design-vue";
import { ref } from "vue";
import flightApi from "@/apis/flightApi";
import { useFlightShoppingStore } from "@/stores/v2/flight/shopping/flightShoppingStore";
import dateUtils from "@/utils/dateUtils";

const props = defineProps({
  itineraryFareOptionKeyMap: {},
  fareOptionsSelected: {},
  fareItem: {},
  flightInfo: {},
  fareClock: {},
  fareActiveKeyIndex: {},
  fareKey: {},
  fareIndex: {},
  activeFlightGroupNumber: {},
  priceClasses: {},
});

const AModal = Modal;
const AEmpty = Empty;
const APopover = Popover;
const ATabs = Tabs;
const emits = defineEmits([]);

const mode = ref("left");
const activeKey = ref(1);

function callback(val) {
  console.log(val);
}

const flightShoppingStore = useFlightShoppingStore();

//单独请求fare的时候使用
const fareDescriptionsRef = ref([]); //單獨請求
const hasFareDescriptionRef = ref(false);
const fareRuleRPHRef = ref(null);
const fareDescriptionModalOpenRef = ref(false);
const fareActiveDesIndexRef = ref(null);

function changeFare(fareOptionsSelected, fareClock, activeKeyIndex, fareItem) {
  // fareDescriptionsRef.value = [];
  if (fareItem.outOfPolicy) return;
  clearInterval(fareClock);
  if (fareItem.expirationDateTime) emits("clock", fareItem.expirationDateTime);
  emits("changeFareActiveKeyIndex", activeKeyIndex);
  fareOptionsSelected[props.activeFlightGroupNumber] = fareItem;
}

function getFareOptionLabel(fareItem) {
  let label = null;
  if (fareItem.outOfPolicy) {
    label = "out-of-policy-uppercase";
  } else if (fareItem.approvalRequired) {
    label = "approval-required-uppercase";
  }
  return label;
}

function filterFareDescriptions(priceClassId) {
  if (
    flightShoppingStore.priceClasses &&
    flightShoppingStore.priceClasses.length
  ) {
    return flightShoppingStore.priceClasses.find(
      (item) => item.priceClassId === priceClassId
    );
  } else {
    return {};
  }
}

const isLoadingRef = ref(true);

function getFareRules(
  itineraryFareOptionKeyMap,
  supplierCode,
  originDestinationKey,
  flightInfo,
  fareBasisCodes,
  fareIndex
) {
  fareDescriptionModalOpenRef.value = true;
  isLoadingRef.value = true;
  if (fareIndex !== fareActiveDesIndexRef.value) {
    fareDescriptionsRef.value = [];

    hasFareDescriptionRef.value = false;
    if (itineraryFareOptionKeyMap) {
      for (const itineraryFareOptionKeyMapKey in itineraryFareOptionKeyMap) {
        if (itineraryFareOptionKeyMapKey.indexOf(supplierCode) !== -1) {
          fareRuleRPHRef.value =
            itineraryFareOptionKeyMap[itineraryFareOptionKeyMapKey];
          break;
        }
      }
    }
    const flightSegmentsCopy = JSON.parse(
      JSON.stringify(flightInfo.flightSegments)
    );

    if (
      flightSegmentsCopy &&
      flightSegmentsCopy.length > 0 &&
      fareBasisCodes &&
      fareBasisCodes.length > 0
    ) {
      const segment = flightSegmentsCopy[0];
      const fareBasisCode =
        fareBasisCodes[0].indexOf("/") >= 0
          ? fareBasisCodes[0].split("/")[0]
          : fareBasisCodes[0];
      const departureAirportCode = segment.departureAirportCode;
      const arrivalAirportCode = getArrivalLocationShared(
        flightSegmentsCopy,
        fareBasisCodes
      );
      const marketingAirlineCode = segment.marketingAirlineCode;
      const departureDate = segment.departureDateTime.split("T")[0];

      const fareRuleRQ = {
        abbreviatedRuleTextInd: false,
        ruleReqInfo: {
          rph: fareRuleRPHRef.value,
          departureAirportCode: departureAirportCode,
          arrivalAirportCode: arrivalAirportCode,
          marketingAirlineCode: marketingAirlineCode,
          departureDate: departureDate,
          fareReferences: [
            {
              resBookDesigCode: fareBasisCode,
            },
          ],
        },
      };

      flightApi.fareRules(fareRuleRQ).then((res) => {
        isLoadingRef.value = false;
        if (res && res.data && res.data.fareRuleResponseInfos) {
          const fareRuleResponseInfos = res.data.fareRuleResponseInfos;
          let subSections = null;
          if (fareRuleResponseInfos && fareRuleResponseInfos.length > 0) {
            subSections = fareRuleResponseInfos[0].fareRules.subSections;
            if (subSections) {
              for (const subSection of subSections) {
                const subTitle = subSection.subTitle;
                const text = subSection.paragraphs[0].text.replaceAll(
                  "\n",
                  "<br/>"
                );
                fareDescriptionsRef.value.push({ subTitle, text });
              }
            }
          }
          hasFareDescriptionRef.value = true;
          fareRuleRPHRef.value = null;
        } else {
          // const text = res.data.errors.errors[0].stringValue;
          fareDescriptionsRef.value = null;
        }
      });
    }

    fareActiveDesIndexRef.value = fareIndex;
  }
}

function getArrivalLocationShared(flightSegments, fareBasisCodes) {
  if (fareBasisCodes.length === 1)
    return flightSegments[flightSegments.length - 1].arrivalAirportCode;

  return flightSegments[0].arrivalAirportCode;
}

function closeFareDescriptionModal() {
  fareDescriptionModalOpenRef.value = false;
  // fareDescriptionsRef.value = [];
  descriptionActiveIndexRef.value = 0;
}

const descriptionActiveIndexRef = ref(0);

function changeActiveIndex(index) {
  descriptionActiveIndexRef.value = index;
}
</script>

<template>
  <div
    :class="{
      active: fareActiveKeyIndex === fareKey + '-' + fareIndex,
    }"
    class="theme_common_box_two img_hover cursor-point"
    @click="
      changeFare(
        fareOptionsSelected,
        fareClock,
        fareKey + '-' + fareIndex,
        fareItem
      )
    "
  >
    <div id="flight_type_1`" class="flight_ticket_content">
      <h4 v-if="fareItem.priceClassId" class="text-muted">
        {{ filterFareDescriptions(fareItem.priceClassId).priceClassName }}
      </h4>
      <h4>
        {{ fareItem.currencyCode }}
        {{ fareItem.totalPrice.toFixed(2) }}
      </h4>
      <h6 v-if="fareItem.fareTypeName || fareItem.fareTypeCode">
        {{ fareItem.fareTypeName }} ({{ fareItem.fareTypeCode }})
      </h6>
      <div>
        <div v-if="fareItem.expirationDateTime">
          {{ $t("expire-date") }}:
          <span class="text-warning">{{
            dateUtils.formatDateTime(fareItem.expirationDateTime)
          }}</span>
        </div>
        <div v-if="fareItem.cabinTypes && fareItem.cabinTypes.length > 0">
          <span>{{ $t("cabin") }}: {{ fareItem.cabinTypes.toString() }}</span>
        </div>
        <div class="text-center form-check">
          <input
            :checked="fareActiveKeyIndex === fareKey + '-' + fareIndex"
            class="form-check-input"
            style="width: 20px; height: 20px"
            type="radio"
          />
        </div>

        <div
          v-if="
            fareItem.priceClassId &&
            filterFareDescriptions(priceClasses, fareItem.priceClassId) &&
            filterFareDescriptions(priceClasses, fareItem.priceClassId)
              .priceClassName
          "
          class="main_color cursor-point text-end"
        >
          <span @click="fareDescriptionModalOpenRef = true">{{
            $t("see-fare-details")
          }}</span>
        </div>

        <div v-else class="main_color cursor-point text-end">
          <span
            @click="
              getFareRules(
                itineraryFareOptionKeyMap,
                fareItem.supplierCode,
                fareItem.originDestinationKey,
                flightInfo,
                fareItem.fareBasisCodes,
                fareIndex
              )
            "
            >{{ $t("see-fare-details") }}</span
          >
        </div>
      </div>

      <div
        v-if="fareItem.priceClassId"
        :id="'collapseExample' + 'C' + fareIndex"
        class="collapse"
      >
        <ul
          v-if="
            filterFareDescriptions(fareItem.priceClassId) &&
            filterFareDescriptions(fareItem.priceClassId).priceDescriptions &&
            filterFareDescriptions(fareItem.priceClassId).priceDescriptions
              .length
          "
        >
          <li
            v-for="(
              descriptionItem, descriptionIndex
            ) in filterFareDescriptions(fareItem.priceClassId)
              .priceDescriptions"
            :key="descriptionIndex"
          >
            <div v-if="descriptionItem.code" style="font-weight: 600">
              {{ descriptionItem.code }}
            </div>
            <span class="review_count mg-t-5">{{
              descriptionItem.description
            }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- fare 描述 -->
  <a-modal
    v-model:open="fareDescriptionModalOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    :maskClosable="false"
    :z-index="11111"
    @cancel="closeFareDescriptionModal"
    wrapClassName="fare-modal"
    width="45%"
  >
    <div class="mg-t-20" style="margin-left: -20px">
      <div v-if="fareItem.priceClassId">
        <div
          v-if="
            filterFareDescriptions(priceClasses, fareItem.priceClassId) &&
            filterFareDescriptions(priceClasses, fareItem.priceClassId)
              .priceDescriptions &&
            filterFareDescriptions(priceClasses, fareItem.priceClassId)
              .priceDescriptions.length
          "
        >
          <ul
            class="nav nav-tabs nav-tabs-block justify-content-end justify-content-md-start flex-md-column col-md-4"
            role="tablist"
          >
            <li
              class="nav-item d-md-flex flex-md-column"
              v-for="(
                descriptionItem, descriptionIndex
              ) in filterFareDescriptions(priceClasses, fareItem.priceClassId)
                .priceDescriptions"
              :key="descriptionIndex"
            >
              <button
                class="nav-link text-md-start"
                v-if="descriptionItem.code"
                :class="{
                  active: descriptionIndex === descriptionActiveIndexRef,
                }"
                data-bs-toggle="tab"
                :data-bs-target="'#btabs-vertical2-' + descriptionIndex"
                role="tab"
                :aria-controls="'btabs-vertical2-' + descriptionIndex"
                aria-selected="true"
                @click="changeActiveIndex(descriptionIndex)"
              >
                {{ descriptionItem.code }}
              </button>
            </li>
          </ul>
          <div
            class="tab-content col-md-8"
            v-if="
              filterFareDescriptions(priceClasses, fareItem.priceClassId) &&
              filterFareDescriptions(priceClasses, fareItem.priceClassId)
                .priceDescriptions &&
              filterFareDescriptions(priceClasses, fareItem.priceClassId)
                .priceDescriptions.length > 0 &&
              filterFareDescriptions(priceClasses, fareItem.priceClassId)
                .priceDescriptions[0].code
            "
          >
            <div
              v-for="(
                descriptionItem, descriptionIndex
              ) in filterFareDescriptions(priceClasses, fareItem.priceClassId)
                .priceDescriptions"
              :key="descriptionIndex"
              class="block-content tab-pane"
              :class="{
                active: descriptionIndex === descriptionActiveIndexRef,
              }"
              role="tabpanel"
              :aria-labelledby="'btabs-vertical2-' + descriptionIndex + '-tab'"
              tabindex="0"
            >
              <span
                class="review_count mg-t-5"
                v-html="descriptionItem.description"
              ></span>
            </div>
          </div>
          <div class="mg-l-20" v-else>
            <h4>{{ $t("fare-rules") }}</h4>
            <div
              v-for="(
                descriptionItem, descriptionIndex
              ) in filterFareDescriptions(priceClasses, fareItem.priceClassId)
                .priceDescriptions"
              :key="descriptionIndex"
            >
              <span
                class="review_count mg-t-5"
                v-html="descriptionItem.description"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          <div class="text-center" v-if="!hasFareDescriptionRef">
            Loading
            <Spin></Spin>
          </div>
          <div
            class="flex"
            v-if="fareDescriptionsRef && fareDescriptionsRef.length > 0"
          >
            <ul
              class="nav nav-tabs justify-content-end justify-content-md-start flex-md-column col-md-4"
              role="tablist"
            >
              <li
                class="nav-item d-md-flex flex-md-column"
                v-for="(
                  descriptionItem, descriptionIndex
                ) in fareDescriptionsRef"
                :key="descriptionIndex"
              >
                <button
                  class="nav-link text-md-start"
                  :class="{
                    active: descriptionIndex === descriptionActiveIndexRef,
                  }"
                  data-bs-toggle="tab"
                  :data-bs-target="'#btabs-vertical2-' + descriptionIndex"
                  role="tab"
                  :aria-controls="'btabs-vertical2-' + descriptionIndex"
                  aria-selected="true"
                  @click="changeActiveIndex(descriptionIndex)"
                >
                  {{ descriptionItem.subTitle }}
                </button>
              </li>
            </ul>
            <div class="tab-content col-md-8">
              <div
                v-for="(
                  descriptionItem, descriptionIndex
                ) in fareDescriptionsRef"
                :key="descriptionIndex"
                class="block-content tab-pane"
                :class="{
                  active: descriptionIndex === descriptionActiveIndexRef,
                }"
                role="tabpanel"
                :aria-labelledby="
                  'btabs-vertical2-' + descriptionIndex + '-tab'
                "
                tabindex="0"
              >
                <span
                  class="review_count mg-t-5"
                  v-html="descriptionItem.text"
                ></span>
              </div>
            </div>
          </div>
          <div v-else-if="!isLoadingRef">
            <AEmpty :description="$t('no-data')"></AEmpty>
          </div>
        </div>
      </div>
    </div>
  </a-modal>

  <!-- fare描述 END -->
</template>

<style>
.fare-modal {
  .ant-modal-content {
    max-height: 700px;
    overflow-y: scroll;
  }
}
</style>
