<script setup>
import { createVNode, reactive, ref, watch } from "vue";
import { useCmsContentStore } from "@/stores/cms";
import { useTemplateStore } from "@/stores/template";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { FlightPriceFormModel } from "@/formmodel/sales/flight/price/flightPriceFormModel";
import { useFlightPriceStore } from "@/stores/v2/flight/price/flightPriceStore";
import { useVuelidate } from "@vuelidate/core";
import { FlightBookFormModel } from "@/formmodel/sales/flight/book/flightBookFormModel";
import { useFlightBookStore } from "@/stores/v2/flight/book/flightBookStore";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import FlightItinararyInfoPanel from "@/views/v2/sales/flight/items/panel/FlightItinararyInfoPanel.vue";
import FlightContactInfoForm from "@/views/v2/sales/flight/items/form/FlightContactInfoForm.vue";
import FlightPassengerForm from "@/views/v2/sales/flight/items/form/FlightPassengerForm.vue";
import FlightPriceSummaryOrBreakdownPanel from "@/views/v2/sales/flight/items/panel/FlightPriceSummaryOrBreakdownPanel.vue";
import { FlightAncillaryServiceFormModel } from "@/formmodel/sales/flight/ancillaryservice/flightAncillaryServiceFormModel";
import { useFlightAncillaryServiceStore } from "@/stores/v2/flight/ancillaryservice/flightAncillaryServiceStore";
import FlightAncillaryServicePanel from "@/views/v2/sales/flight/items/panel/ancillaryservice/FlightAncillaryServicePanel.vue";
import FlightSeatSelectionPanel from "@/views/v2/sales/flight/items/panel/ancillaryservice/FlightSeatSelectionPanel.vue";
import FlightServicesPriceSummaryPanel from "@/views/v2/sales/flight/items/panel/FlightServicesPriceSummaryPanel.vue";

const { t, locale } = useI18n(); // 解构调用函数

const AModal = Modal;
const route = useRoute();
const router = useRouter();
let templateStore = useTemplateStore();
const flightPriceStore = useFlightPriceStore();
const ancillaryServiceStore = useFlightAncillaryServiceStore();
const flightBookStore = useFlightBookStore();

const navigation = reactive({
  title: "Check Out",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Flight Search Result",
      path: "/sales/v2/flight/flight-list",
    },
    {
      name: "Check Out",
    },
  ],
});

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();
let flightCheckoutFormModels = JSON.parse(
  sessionStorage.getItem("flightCheckoutFormModels")
);

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const airItineraryRPH = ref(route.query.airItineraryRPH);
const errorServerMessage = ref("");
const flightPriceFormModelRef = ref(
  new FlightPriceFormModel("PRICE", airItineraryRPH.value, [], null)
);

const passengerFormModelItemsRef = ref(null);
const tripAirItemRef = ref(null);
const priceClassesRef = ref(false); //价格类型数组

const ancillaryServiceShowRef = ref(false); //附加服務
const seatSelectionShowRef = ref(false); //座位选择
const isAncillaryPanelCanCloseRef = ref(true);
const isSeatPanelCanCloseRef = ref(true);

const v = useVuelidate();
watch(
  () => flightPriceStore.passengerFormModelItems,
  (newValue) => {
    if (newValue) {
      passengerFormModelItemsRef.value = newValue;
    }
    if (
      passengerFormModelItemsRef.value &&
      flightCheckoutFormModels &&
      flightCheckoutFormModels.passengerFormModelItems &&
      flightCheckoutFormModels.passengerFormModelItems.length > 0
    ) {
      let passengerFormModelItems =
        flightCheckoutFormModels.passengerFormModelItems;
      for (let passengerFormModelItem of passengerFormModelItemsRef.value) {
        for (let passengerFormModelItemSession of passengerFormModelItems) {
          if (
            passengerFormModelItemSession.travelerRefNumberRPH ===
            passengerFormModelItem.travelerRefNumberRPH
          ) {
            for (let key in passengerFormModelItem) {
              passengerFormModelItem[key] = passengerFormModelItemSession[key];
            }
          }
        }
      }
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => flightPriceStore.tripAirItem,
  (newValue) => {
    tripAirItemRef.value = newValue;
  },
  { deep: true, immediate: true }
);

watch(
  () => flightPriceStore.priceClasses,
  (newValue) => {
    priceClassesRef.value = newValue;
  },
  { deep: true, immediate: true }
);

//Call backend
airPrice(flightPriceFormModelRef.value);

const tripContactInfoRef = ref({
  phoneNumber: null,
  emailAddress: null,
  countryAccessCode: null,
});
if (
  flightCheckoutFormModels &&
  flightCheckoutFormModels.tripContactInfo &&
  Object.keys(flightCheckoutFormModels.tripContactInfo).length > 0
) {
  for (const tripContactInfoKey in tripContactInfoRef.value) {
    tripContactInfoRef.value[tripContactInfoKey] =
      flightCheckoutFormModels.tripContactInfo[tripContactInfoKey];
  }
} else {
  tripContactInfoRef.value = {
    phoneNumber: null,
    emailAddress: null,
    countryAccessCode: null,
  };
}

const paymentMethodOpenRef = ref(false); //选择支付方式
const paymentFormOpenRef = ref(false); //支付信息表单

function airPrice(flightPriceFormModel) {
  templateStore.pageLoader({ mode: "on" });
  flightPriceStore.airPrice(flightPriceFormModel).then((res) => {
    if (res && !res.success) {
      if (res.errors && res.errors.errors && res.errors.errors.length > 1) {
        let error = res.errors.errors.find((item) => item.type === "ERR");
        if (error) {
          errorServerMessage.value = error.stringValue;
        } else {
          errorServerMessage.value = "Sever error!";
        }
      } else {
        errorServerMessage.value =
          res.errors && res.errors.errors && res.errors.errors.length > 0
            ? res.errors.errors[0].stringValue
            : "Sever error!";
      }
      let user = sessionStorage.getItem("user");
      if (user) {
        openMassageModal(errorServerMessage.value, 2, "AIR_PRICE");
      }
      templateStore.pageLoader({ mode: "off" });
    } else {
      let flightAncillaryServiceFormModel = new FlightAncillaryServiceFormModel(
        flightPriceFormModel.airItineraryRPH,
        null,
        null,
        null,
        null
      );
      //UA单独处理
      if (res.pricedItineraryList && res.pricedItineraryList.length > 0) {
        const pricedItinerary = res.pricedItineraryList[0];
        const airItineraryRPH =
          pricedItinerary &&
          pricedItinerary.airItinerary &&
          pricedItinerary.airItinerary.airItineraryRPH
            ? pricedItinerary.airItinerary.airItineraryRPH
            : null;

        const supplierCode =
          pricedItinerary.airItineraryPricingInfo &&
          pricedItinerary.airItineraryPricingInfo.priceRequestInformation &&
          pricedItinerary.airItineraryPricingInfo.priceRequestInformation
            .negotiatedFareCodeList
            ? pricedItinerary.airItineraryPricingInfo.priceRequestInformation
                .negotiatedFareCodeList[0].supplierCode
            : pricedItinerary.airItineraryPricingInfo.pricingSource;

        if ("FARELOGIX-UA" === supplierCode) {
          flightAncillaryServiceFormModel.airItineraryRPH = airItineraryRPH;
        }
      }

      ancillaryServiceStore
        .airGetOffers(flightAncillaryServiceFormModel)
        .then((offerRS) => {
          templateStore.pageLoader({ mode: "off" });
        });
    }
  });
}

function goBack() {
  window.close();
}

async function confirmBooking(
  flightPriceFormModel,
  passengerFormModelItems,
  quoteID
) {
  const result = await v.value.$validate();
  if (!result) return;
  if (flightPriceFormModel) {
    const airItineraryRPH = flightPriceFormModel.airItineraryRPH;
    const servicesSelected = flightPriceFormModel.servicesSelected;
    const flightBookFormModel = new FlightBookFormModel(
      airItineraryRPH,
      quoteID,
      passengerFormModelItems,
      servicesSelected,
      null
    );

    AModal.confirm({
      content: t("you-will-confirm-the-reservation-now"),
      icon: createVNode(ExclamationCircleOutlined),
      centered: true,
      width: 600,
      wrapClassName: "confirmModal",
      onOk() {
        return new Promise((resolve, reject) => {
          const flightBookFormModelCopy = JSON.parse(
            JSON.stringify(flightBookFormModel)
          );
          const bookFormModel = mergeFormModel(
            flightBookFormModelCopy,
            tripContactInfoRef.value
          );
          console.log(bookFormModel);
          const flightCheckoutFormModels = {
            tripContactInfo: tripContactInfoRef.value,
            passengerFormModelItems: passengerFormModelItemsRef.value,
          };
          sessionStorage.setItem(
            "flightCheckoutFormModels",
            JSON.stringify(flightCheckoutFormModels)
          );
          // 进行air book
          templateStore.pageLoader({ mode: "on" });
          flightBookStore
            .airBook(bookFormModel)
            .then((res) => {
              if (res && res.success) {
                const bookingInfo = getBookingInfo(
                  res.airReservation.bookingReferenceIdList
                );
                sessionStorage.removeItem("flightCheckoutFormModels");
                flightPriceStore.$reset();
                AModal.destroyAll();
                goToPaymentPage(bookingInfo.tripId, bookingInfo.invoiceId);
              } else if (res) {
                errorServerMessage.value = res.errors.errors[0].shortText
                  ? res.errors.errors[0].shortText
                  : res.errors.errors[0].stringValue;
                AModal.destroyAll();
                openMassageModal(errorServerMessage.value, 2);
              } else if (!res) {
                AModal.destroyAll();
              }
              templateStore.pageLoader({ mode: "off" });
            })
            .catch((err) => {
              console.log(err);
              // router.push({
              //   name: "error-500",
              //   query: { message: "Server Error!" },
              // });
              AModal.destroyAll();
              templateStore.pageLoader({ mode: "off" });
            });
        }).catch((err) => {
          console.log(err);
          // router.push({
          //   name: "error-500",
          //   query: { message: "Server Error!" },
          // });
          AModal.destroyAll();
          templateStore.pageLoader({ mode: "off" });
        });
      },
      cancelText: "Cancel",
      onCancel() {
        AModal.destroyAll();
      },
    });
  }
}

function goToPaymentPage(bookingId, invoiceId) {
  router.push({
    name: "payment-methods",
    query: {
      bookingId: bookingId,
      invoiceId: invoiceId,
      productType: "flight",
    },
  });
}

function getBookingInfo(bookingReferenceIdList) {
  let recordLocator = null;
  let idContext = null;
  let airItemId = null;
  let tripId = null;
  let invoiceId = null;
  if (bookingReferenceIdList && bookingReferenceIdList.length > 0) {
    bookingReferenceIdList.forEach((item) => {
      if (item.type === "RECORD_LOCATOR") {
        recordLocator = item.id;
        idContext = item.id_Context;
      } else if (item.type === "BOOKING_AIR_ITEM_ID") {
        airItemId = item.id;
      } else if (item.type === "BOOKING_ID") {
        tripId = item.id;
      } else if (item.type === "INVOICE_ID") {
        invoiceId = item.id;
      }
    });
  }

  return {
    recordLocator,
    idContext,
    airItemId,
    tripId,
    invoiceId,
  };
}

function mergeFormModel(flightBookFormModel, tripContactInfo) {
  const passengers = flightBookFormModel.passengers;

  if (passengers && passengers.length > 0 && tripContactInfo) {
    passengers.forEach((passenger) => {
      if (!passenger.phoneNumber) {
        passenger.phoneNumber = tripContactInfo.phoneNumber;
        passenger.dialAreaCode = tripContactInfo.countryAccessCode;
      }
      if (!passenger.emailAddress) {
        passenger.emailAddress = tripContactInfo.emailAddress;
      }
    });
  }

  return flightBookFormModel;
}

function openMassageModal(message, type, functionType) {
  if (type === 1) {
    AModal.success({
      title: "This is a success message",
      content: message,
      centered: true,
      width: 600,
    });
  } else {
    if (functionType === "AIR_PRICE") {
      AModal.error({
        title: "This is an error message",
        content: message,
        centered: true,
        width: 600,
        onOk() {
          return new Promise((resolve, reject) => {
            window.close(); //air price的时候出问题直接关闭页面回到list页面重选
          }).catch(() => console.log("Oops errors!"));
        },
      });
    } else {
      AModal.error({
        title: "This is an error message",
        content: message,
        centered: true,
        width: 600,
      });
    }
  }
}

function closePaymentForm() {
  paymentFormOpenRef.value = false;
  paymentMethodOpenRef.value = false;
}

//region 设备区分
const equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
//endregion

const promotionModalOpenRef = ref(false);

function openPromotionModal() {
  promotionModalOpenRef.value = true;
}

function confirmPromotion(flightPriceFormModel) {
  const promotionCode = flightPriceFormModel.promotionCode;
  if (promotionCode) {
    promotionModalOpenRef.value = false;
    airPrice(flightPriceFormModel);
  } else {
    AModal.warning({
      title: t("this-is-an-error-message"),
      content: "Please enter promo code !",
      centered: true,
      width: 600,
      wrapClassName: "confirmModal",
      onOk() {
        return new Promise((resolve, reject) => {
          AModal.destroyAll();
        }).catch(() => console.log("Oops errors!"));
      },
    });
  }
}

function openAncillaryService() {
  ancillaryServiceShowRef.value = true;
}

function openSeatSelection() {
  seatSelectionShowRef.value = true;
}
</script>

<template>
  <!--   Banner Area -->
  <section id="page_banner">
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("check-out") }}</h2>
        <ul>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("check-out") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <h3 class="heading_theme">{{ $t("check-out") }}</h3>
      <div class="row" v-if="equipmentType !== 'iphone'">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goBack">
              <i
                class="bi bi-arrow-left-square"
                size="40"
                style="
                  font-size: 28px;
                  color: #7ae3c3;
                  cursor: pointer;
                  margin-right: 10px;
                "
              ></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-10">
          <!-- Itinerary Info -->
          <FlightItinararyInfoPanel
            :price-classes="priceClassesRef"
            :trip-air-item="tripAirItemRef"
          />
          <!-- Itinerary Info END -->

          <!-- Contact Info Form -->
          <FlightContactInfoForm :tripContactInfo="tripContactInfoRef" />
          <!-- Contact Info Form END -->

          <!-- Passenger Form -->
          <FlightPassengerForm
            :passenger-form-model-items="passengerFormModelItemsRef"
            :trip-air-item="tripAirItemRef"
            @openAncillaryService="openAncillaryService"
            @openSeatSelection="openSeatSelection"
          />
          <!-- Passenger Form END-->

          <!-- service summary -->
          <FlightServicesPriceSummaryPanel
            v-if="
              flightPriceFormModelRef.servicesSelected &&
              flightPriceFormModelRef.servicesSelected.length > 0
            "
            :form-model="flightPriceFormModelRef"
            :passenger-items="passengerFormModelItemsRef"
            :trip-air-item="tripAirItemRef"
          />
          <!-- service summary -->

          <FlightPriceSummaryOrBreakdownPanel
            :flight-price-form-model="flightPriceFormModelRef"
            :passenger-form-model-items="passengerFormModelItemsRef"
            :services-selected="flightPriceFormModelRef.servicesSelected"
            :trip-air-item="tripAirItemRef"
          />

          <div class="text-center">
            <button
              class="btn btn_theme"
              style="margin-right: 10px"
              @click="openPromotionModal"
            >
              Add Promo Code
            </button>
            <button
              class="btn btn_theme"
              @click="
                confirmBooking(
                  flightPriceFormModelRef,
                  passengerFormModelItemsRef,
                  tripAirItemRef ? tripAirItemRef.quoteID : null
                )
              "
            >
              {{ $t("confirm-booking") }}
            </button>
          </div>

          <!--    promotion 无效信息 -->
          <div
            v-if="
              tripAirItemRef &&
              tripAirItemRef.tripAirItemFare &&
              tripAirItemRef.tripAirItemFare.promotionRemarks &&
              tripAirItemRef.tripAirItemFare.promotionRemarks.indexOf(
                'InvalidPromotionCodeException'
              ) !== -1
            "
            class="mg-t-10"
          >
            <div class="font_weight text-warning">
              This promo code is invalid !
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--  promotion content-->
  <a-modal
    v-model:open="promotionModalOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    width="30%"
    :maskClosable="false"
    @cancel="promotionModalOpenRef = false"
  >
    <div class="mg-t-20">
      <h3>Promotion</h3>
    </div>
    <div class="mg-t-20">
      <label class="form-label" for="passenger-form-surname"
        >{{ $t("promo-code")
        }}<small class="text-lowercase"><code>*</code></small></label
      >
      <input
        v-model="flightPriceFormModelRef.promotionCode"
        :placeholder="$t('promo-code')"
        autocomplete="off"
        class="form-control"
        name="passenger-form-surname"
        type="text"
      />
    </div>
    <div class="mg-t-20 text-center">
      <button
        class="btn btn-outline-primary mg-b-20 mg-t-10"
        style="margin-right: 10px"
        type="button"
        @click="confirmPromotion(flightPriceFormModelRef)"
      >
        Apply
      </button>
      <button
        class="btn btn-outline-danger mg-b-20 mg-t-10"
        type="button"
        @click="promotionModalOpenRef = false"
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </a-modal>
  <!--  promotion content END-->

  <!--  Ancillary modal content-->
  <a-modal
    v-model:open="ancillaryServiceShowRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :closable="isAncillaryPanelCanCloseRef"
    :maskClosable="false"
    :ok-button-props="{ style: { display: 'none' } }"
    :zIndex="11111"
    centered
    width="100%"
    wrapClassName="ancillary-full-modal"
    @cancel="ancillaryServiceShowRef = false"
  >
    <FlightAncillaryServicePanel
      :air-itinerary-r-p-h="airItineraryRPH"
      :flight-price-form-model="flightPriceFormModelRef"
      :passenger-form-model-items="passengerFormModelItemsRef"
      :trip-air-item="tripAirItemRef"
      @closeAncillaryService="ancillaryServiceShowRef = false"
    />
  </a-modal>
  <!--  Ancillary modal content END-->

  <!--  Seat selection modal content-->
  <a-modal
    v-model:open="seatSelectionShowRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :closable="isSeatPanelCanCloseRef"
    :maskClosable="false"
    :ok-button-props="{ style: { display: 'none' } }"
    :zIndex="11111"
    centered
    width="100%"
    wrapClassName="ancillary-full-modal"
    @cancel="seatSelectionShowRef = false"
  >
    <FlightSeatSelectionPanel
      :air-itinerary-r-p-h="airItineraryRPH"
      :flight-price-form-model="flightPriceFormModelRef"
      :passenger-form-model-items="passengerFormModelItemsRef"
      :trip-air-item="tripAirItemRef"
      @closeSeatSectionModalPanel="seatSelectionShowRef = false"
    />
  </a-modal>
  <!--   Seat selection modal content END-->
</template>

<style lang="scss"></style>
