<script setup>
import SignInForm from "@/views/auth/items/SignInForm.vue";
import { useCmsContentStore } from "@/stores/cms";
import { reactive, ref, watch } from "vue";

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();
cmsContentStore.getAboutContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const navigation = reactive({
  title: "Sign in",
});
</script>

<template>
  <!--   Banner Area -->
  <section id="page_banner">
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("sign-in") }}</h2>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->
  <section id="common_author_area" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="common_author_boxed">
            <div class="common_author_heading">
              <h3>{{ $t("sign-in-your-account") }}</h3>
              <h2>{{ $t("logged-in-to-stay-in-touch") }}</h2>
            </div>
            <div class="common_author_form">
              <SignInForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
