const starRatingOption = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
];

const mealPlanOption = [
  {
    value: "19",
    label: "breakfast-included",
  },
  {
    value: "21",
    label: "lunch-included",
  },
  {
    value: "22",
    label: "dinner-included",
  },
  {
    value: "1",
    label: "all-inclusive",
  },
];

const guestRatingOption = [
  {
    value: 4.5,
    label: "wonderful-4.5+",
  },
  {
    value: 4.0,
    label: "very-good-4.0+",
  },
  {
    value: 3.5,
    label: "good-3.5+",
  },
];

export default {
  starRatingOption,
  guestRatingOption,
  mealPlanOption,
};
