import tripAirItemTools from "@/tools/v2/trip/tripAirItemTools";
import { TripItemVO } from "@/viewobject/trip/tripItemVO";
import tripHotelItemTools from "@/tools/v2/trip/tripHotelItemTools";

function buildTripsReadRQ(tripsReadFormModel) {
  let type = tripsReadFormModel.type;
  let id = tripsReadFormModel.id;
  let limit = tripsReadFormModel.limit;
  let offset = tripsReadFormModel.offset;

  let readRequests = null;
  let status = tripsReadFormModel.status;
  let start = tripsReadFormModel.start;
  let end = tripsReadFormModel.end;
  if ((status != null && status != "") || start != null || end != null) {
    readRequests = {
      globalReservationReadRequests: [],
    };
    let globalReservationReadRequest = {};
    if (start) {
      globalReservationReadRequest = {
        // bookingTypeId: "Hotel",
        status: status != null && status !== "" ? status : null,
        start,
        end,
        dateType: "BookingDate",
      };
    } else {
      globalReservationReadRequest = {
        // bookingTypeId: "Hotel",
        status: status != null && status !== "" ? status : null,
      };
    }

    readRequests.globalReservationReadRequests.push(
      globalReservationReadRequest
    );
  }
  let uniqueID = null;
  if (id) {
    uniqueID = {
      type: type,
      id: id,
      id_context: "ERP",
    };
  }

  return {
    uniqueID: uniqueID,
    offset: offset,
    limit: limit,
    readRequests: readRequests,
  };
}

function buildResRetrieveRQ(id, type, idContext) {
  return {
    uniqueID: {
      type: type,
      id: id,
      id_context: idContext,
    },
  };
}

/**
 *
 * @param status string，取消请求类型，如QUOTE-请求取消罚款信息，COMMIT-提交取消请求
 * @param tripId
 * @param tripItemId
 * @param recordLocator
 * @param idContext
 * @returns {{cancelType: string, uniqueIDs: [{id_Context, id, type: string},{id_Context: string, id, type: string}]}}
 */
function buildTripAirItemCancelRQ(
  status,
  tripId,
  tripItemId,
  recordLocator,
  idContext
) {
  return {
    uniqueIDs: [
      {
        type: "BOOKING_ID",
        id: tripId,
        id_Context: "ERP",
      },
      {
        type: "BOOKING_ITEM_ID",
        id: tripItemId,
        id_Context: "ERP",
      },
      {
        type: "RECORD_LOCATOR",
        id: recordLocator,
        id_Context: idContext,
      },
    ],
    cancelType: status,
  };
}

function buildTicketCancelRQ(ticketNumber, recordLocator, idContext) {
  return {
    uniqueIDs: [
      {
        type: "TICKET",
        id: ticketNumber,
        instance: recordLocator,
        id_Context: idContext,
      },
    ],
    cancelType: "CANCEL",
  };
}

function buildTripItems(reservations) {
  let tripItemVOs = null;
  if (!reservations || reservations.length === 0) return null;
  tripItemVOs = [];
  for (const reservation of reservations) {
    const tripItemVO = buildTripItem(reservation);
    tripItemVOs.push(tripItemVO);
  }
  return tripItemVOs;
}

function buildTripItem(reservation) {
  //common info
  let bookingId = null;
  let bookingIdInfo = null;

  let requestor = null;
  let tripTotalAmount = 0;
  let createdDateTime = null;
  const receiptStatus = reservation.receiptStatusId;
  const bookingStatus = reservation.statusId;
  let globalReservations = reservation.globalReservations;
  if (globalReservations && globalReservations.length > 0) {
    globalReservations.forEach((globalReservation) => {
      let uniqueID = globalReservation.uniqueID;
      if (uniqueID.type === "BOOKING_ID" && uniqueID.id_context === "ERP") {
        bookingId = uniqueID.id;
        bookingIdInfo = uniqueID;
      }
    });
  }
  reservation.attributes.forEach((attribute) => {
    if ("requestor_name" === attribute.name) {
      requestor = attribute.value;
    } else if ("booking_sellingTotal" === attribute.name) {
      tripTotalAmount = parseInt(attribute.value);
    } else if ("booking_dateCreated" === attribute.name) {
      createdDateTime = attribute.value;
    }
  });
  const tripItemVO = new TripItemVO(
    bookingId,
    bookingStatus,
    null,
    tripTotalAmount,
    createdDateTime,
    requestor
  );

  const airReservations = reservation.airReservations;
  const hotelReservations = reservation.hotelReservations;
  const cruiseReservations = reservation.cruiseReservations;
  const vehicleReservations = reservation.vehicleReservations;
  const railReservations = reservation.railReservations;
  const tourActivityReservations = reservation.tourActivityReservations;
  const travelDocReservations = reservation.travelDocReservations;
  const groundReservations = reservation.groundReservations;

  if (airReservations != null && airReservations.length > 0) {
    airReservations.forEach((airReservation) => {
      const tripAirItemVO = buildTripAirItem(airReservation);
      tripItemVO.addNewAirItem(tripAirItemVO);
      if (!tripItemVO.currencyCode)
        tripItemVO.currencyCode = tripAirItemVO.tripAirItemFare.currencyCode;
      if (tripAirItemVO.passengers && tripAirItemVO.passengers.length > 0) {
        const passenger = tripAirItemVO.passengers[0];
        tripItemVO.passengerName =
          passenger.surname + "/" + passenger.givenName;
      }
    });
  }

  if (hotelReservations != null && hotelReservations.length > 0) {
    for (const hotelReservation of hotelReservations) {
      const tripHotelItemVO = buildTripHotelItem(hotelReservation);
      tripItemVO.addNewTripHotelItem(tripHotelItemVO);

      if (!tripItemVO.currencyCode)
        tripItemVO.currencyCode = tripHotelItemVO.currencyCode;

      tripItemVO.totalAmount = tripHotelItemVO.totalPrice;

      if (tripHotelItemVO.passengers && tripHotelItemVO.passengers.length > 0) {
        const passengers = tripHotelItemVO.passengers;
        if (passengers && passengers.length > 0) {
          const passenger = passengers.find(
            (item) =>
              (item.ageQualifyingCode === "10" &&
                item.surname.indexOf("TBA") < 0) ||
              item.mainIndicator
          );
          if (passenger)
            tripItemVO.passengerName =
              passenger.surname + "/" + passenger.givenName;
        }
      }
    }
  }

  return tripItemVO;
}

function buildTripAirItem(airReservation) {
  return tripAirItemTools.buildTripAirItem(airReservation);
}

function buildTripHotelItem(hotelReservation) {
  return tripHotelItemTools.buildTripHotelItem(hotelReservation);
}

function buildTripCruiseItem(cruiseReservation) {
  // return tripHotelItemTools.buildTripCruiseItem(cruiseReservation);
  return cruiseReservation;
}

function buildTripCarRentalItem(vehicleReservation) {
  // return tripHotelItemTools.buildTripCruiseItem(vehicleReservation);
  return vehicleReservation;
}

function buildTripRailItem(railReservation) {
  // return tripHotelItemTools.buildTripCruiseItem(railReservation);
  return railReservation;
}

function buildTripActivityItem(tourActivityReservation) {
  // return tripHotelItemTools.buildTripCruiseItem(tourActivityReservation);
  return tourActivityReservation;
}

function buildTripTravelDocumentItem(travelDocReservation) {
  // return tripHotelItemTools.buildTripCruiseItem(travelDocReservation);
  return travelDocReservation;
}

function buildTripGroundItem(groundReservation) {
  // return tripHotelItemTools.buildTripCruiseItem(travelDocReservation);
  return groundReservation;
}

export default {
  buildTripsReadRQ,
  buildResRetrieveRQ,
  buildTripAirItemCancelRQ,
  buildTripItems,
  buildTicketCancelRQ,
};
