<script setup>
import { reactive, ref, watch } from "vue";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import useVuelidate from "@vuelidate/core";
import FlatPickr from "vue-flatpickr-component";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { useRoute } from "vue-router";
import { required } from "@vuelidate/validators";

const route = useRoute();
const props = defineProps({
  documentItem: {},
  earlyDepartureDate: {},
  passportIndex: {},
  isPassportMandatory: {},
});

const { t } = useI18n();

const warnExpireDate = ref(false); //护照过期提示

const documentFormModelRef = ref(null);

let rules = reactive({
  docID: {},
  expireDate: {},
});

if (props.isPassportMandatory) {
  rules.docID = { required };
  rules.expireDate = { required };
}

watch(
  () => props.documentItem,
  (newValue) => {
    documentFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

// Use vuelidate
const v$ = useVuelidate(rules, documentFormModelRef);

//region 护照

function passportCountryChange(value) {
  documentFormModelRef.value.docIssueCountry = value.iso2;
  documentFormModelRef.value.docHolderNationality = value.iso2;
}

function passportNumberInput(value, event) {
  if (typeof value !== "string") return;
  documentFormModelRef.value.docID = value;
  if (event && event.country) {
    documentFormModelRef.value.docIssueCountry = event.country.iso2;
    documentFormModelRef.value.docHolderNationality = event.country.iso2;
  }
}

//endregion 护照

//region 判断护照是否即将过期
function chooseExpireDate(expireDate) {
  warnExpireDate.value = false;
  //判断是否过期时间在旅行期间六个月内，是则提示
  const isEarlyExpired =
    new Date(
      dayjs(new Date(props.earlyDepartureDate))
        .add(6, "month")
        .format("YYYY-MM-DD")
    ).getTime() >= new Date(expireDate).getTime();
  const isLatestExpired =
    new Date(
      dayjs(new Date(props.earlyDepartureDate))
        .add(6, "month")
        .format("YYYY-MM-DD")
    ).getTime() >= new Date(expireDate).getTime();
  if (isEarlyExpired || isLatestExpired) {
    warnExpireDate.value = true;
  }
}

//endregion 判断护照是否即将过期
</script>

<template>
  <div class="col-5">
    <label class="form-label" for="example-text-input"
      >{{ $t("passport-number") }}
    </label>
    <VueTelInput
      v-model="v$.docID.$model"
      :autoFormat="false"
      :class="{
        'is-invalid': v$.docID.$errors.length > 0,
      }"
      :dropdownOptions="{
        showDialCodeInList: false,
        showDialCodeInSelection: false,
        showFlags: true,
      }"
      :inputOptions="{
        placeholder: $t('enter-your-passport-optional'),
        autocomplete: false,
      }"
      autocomplete="off"
      class="form-control flex"
      mode="international"
      @blur="v$.docID.$touch"
      @input="passportNumberInput"
      @country-changed="passportCountryChange"
    />
  </div>
  <div class="col-5">
    <label class="form-label" for="example-text-input"
      >{{ $t("expire-date") }}
    </label>
    <FlatPickr
      id="example-flatpickr-default"
      v-model="v$.expireDate.$model"
      :class="{
        'is-invalid': v$.expireDate.$errors.length > 0,
      }"
      :config="{
        minDate: 'today',
      }"
      :placeholder="$t('expire-date-optional')"
      class="form-control"
      @blur="v$.expireDate.$touch"
      @on-change="chooseExpireDate"
    />
  </div>
  <span v-if="warnExpireDate" class="passport-expired text-warning"
    ><i class="bi bi-exclamation-circle"></i
    >{{ $t("passport-expiry-date") }}</span
  >
</template>

<style lang="scss">
.vue-tel-input {
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #dfe3ea;
  text-align: left;
}
</style>
