export const passwordRegx =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$%=])[A-Za-z\d$@$%=]{8,}/;
export const phoneNumberRegx = /^[1-9][0-9]{3,}/;
export const numberRegx = /^[0-9]+$/;
export const nameRegx = /^[a-zA-Z]+[a-zA-Z]$/;
export const givenNameRegx = /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/;
export const surnameRegx = /^[a-zA-Z]+$/;

// export const codeRegx = /^(\[a-zA-Z0-9]){2}(\[a-zA-Z0-9]$){2,}+$/;
export const codeRegx = /^[A-Z0-9]{2}[\/]{1}[a-zA-Z0-9]+$/;
