<script setup>
import { computed, reactive, ref } from "vue";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import securityApi from "@/apis/securityApi";
import { useTemplateStore } from "@/stores/template";

let formModel = reactive({
  emailAddress: null,
});
const rules = computed(() => {
  return {
    emailAddress: {
      required,
      email,
    },
  };
});

let templateStore = useTemplateStore();

// Use vuelidate
const v$ = useVuelidate(rules, formModel);
let formRef = ref(null);

async function reactiveAccount() {
  const result = await v$.value.$validate();
  if (!result) {
    return;
  }
  templateStore.pageLoader({ mode: "on" });
  let callbackHostName =
    window.location.protocol + "//" + window.location.host + "/api";
  securityApi
    .reactiveAccount(formModel.emailAddress, callbackHostName)
    .then((res) => {
      // formRef.value.clearValidate();
      formModel = reactive({
        emailAddress: null,
      });
      templateStore.pageLoader({ mode: "off" });
      if (res.data.success) {
        openAlert(
          "We sent a reactive link to your email. You can active your account by the link.",
          "success"
        );
      } else {
        openAlert(res.data.errors.errors[0].shortText, "failed");
      }
    });
}

let notifyMessage = ref(null);
let alterType = ref(null);

function openAlert(message, type) {
  const errorAlert = document.getElementById(type + "notifyMessageAlert");
  errorAlert.classList.add("show");
  notifyMessage.value = message;
  alterType.value = type;
  setTimeout(() => {
    errorAlert.classList.remove("show");
  }, 15000);
}
</script>

<template>
  <!-- ALERT -->
  <div
    id="successnotifyMessageAlert"
    class="alert alert-dismissible alert-success fade"
    role="alert"
    v-show="notifyMessage && 'success' === alterType"
  >
    <p class="mb-0">
      {{ notifyMessage }}
    </p>
    <button
      aria-label="Close"
      class="btn-close"
      data-bs-dismiss="alert"
      type="button"
    ></button>
  </div>
  <div
    id="failednotifyMessageAlert"
    class="alert alert-dismissible alert-danger fade"
    role="alert"
    v-show="notifyMessage && 'failed' === alterType"
  >
    <p class="mb-0" v-if="notifyMessage">
      {{ notifyMessage }}
    </p>
    <button
      aria-label="Close"
      class="btn-close"
      data-bs-dismiss="alert"
      type="button"
    ></button>
  </div>
  <!-- ALERT -->
  <form
    action="#"
    id="main_author_form"
    @submit.prevent="reactiveAccount"
    ref="formRef"
  >
    <div class="form-group">
      <input
        id="regis-email"
        v-model="formModel.emailAddress"
        :class="{
          'is-invalid': v$.emailAddress.$errors.length,
        }"
        class="form-control"
        name="regis-email"
        :placeholder="$t('enter-a-email-address')"
        type="text"
        @blur="v$.emailAddress.$touch"
      />
      <div
        v-if="v$.emailAddress.$errors.length"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("enter-the-email") }}
      </div>
    </div>
    <div class="common_form_submit">
      <button class="btn btn_theme btn_md">{{ $t("send-links") }}</button>
    </div>
  </form>
</template>

<style scoped></style>
