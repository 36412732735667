<script setup>
import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import data from "@/data";
import Banner from "@/components/custom/Banner.vue";
import { useDynamicPackageStore } from "@/stores/dynamicPackage";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import flightTools from "@/tools/flightTools";
import dayjs from "dayjs";
import BaseFlightItemPanel from "@/views/sales/flight/items/panel/BaseFlightItemPanel";

import dynamicPackageTools from "@/tools/dynamicPackageTools";
import { Empty, Spin, Drawer } from "ant-design-vue";
const AEmpty = Empty;
const ASpin = Spin;
const ADrawer = Drawer;
import { useFlightStore } from "@/stores/flight";
import BasePackageFlightFilterForm from "@/views/sales/package/items/form/BasePackageFlightFilterForm.vue";
import BasePackageFlightSortForm from "@/views/sales/package/items/form/BasePackageFlightSortForm.vue";
import BasePkgHotelItemPanel from "@/views/sales/package/items/pannel/BasePkgHotelItemPanel.vue";
import { useCmsContentStore } from "@/stores/cms";
import BaseFlightFilterForm from "@/views/sales/flight/items/form/BaseFlightFilterForm.vue";
import BaseFlightSortForm from "@/views/sales/flight/items/form/BaseFlightSortForm.vue";
import BaseFlightItemMobilePanel from "@/views/sales/flight/items/panel/BaseFlightItemMobilePanel.vue";
//region 数据初始化
const navigation = reactive({
  title: "Flight Search Result",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Hotel Search Result",
      path: "/sales/package/hotel-list",
    },
    {
      name: "Hotel Details",
      path: "/sales/package/hotel-details",
    },
    {
      name: "Flight Search Result",
    },
  ],
});

const route = useRoute();
const router = useRouter();

const dynamicPackageStore = useDynamicPackageStore();
let flightStore = useFlightStore();
const templateStore = useTemplateStore();
// let pkgMatchFlightViewItemsGroup = JSON.parse(
//   sessionStorage.getItem("pkgMatchFlightViewItemsGroup")
// );
// let pkgFlightViewItemsGroup = JSON.parse(
//   sessionStorage.getItem("pkgFlightViewItemsGroup")
// );
// let pkgPricedItineraries = reactive(
//   JSON.parse(sessionStorage.getItem("pkgPricedItineraries"))
// );
// let pkgOrigDestGroupMap = reactive(
//   JSON.parse(sessionStorage.getItem("pkgOrigDestGroupMap"))
// );
// let pkgAirportCityMap = reactive(
//   JSON.parse(sessionStorage.getItem("pkgAirportCityMap"))
// );
// let wrapPkgRoomViewItem = reactive(
//   JSON.parse(sessionStorage.getItem("wrapPkgRoomViewItem"))
// );
//
// dynamicPackageStore.updateFlightViewItemsGroup(pkgFlightViewItemsGroup);
// dynamicPackageStore.updateMatchFlightViewItemsGroup(
//   pkgMatchFlightViewItemsGroup
// );
// flightStore.updateAirportCityMap(pkgAirportCityMap);
// flightStore.updateOrigDestGroupMap(pkgOrigDestGroupMap);
// dynamicPackageStore.updateOrigDestGroupMap(pkgOrigDestGroupMap);
// dynamicPackageStore.updatePricedItineraries(pkgPricedItineraries);
let pkgAirSearchQuery = JSON.parse(sessionStorage.getItem("pkgAirSearchQuery"));
let wrapPkgRoomViewItem = reactive(
  JSON.parse(sessionStorage.getItem("wrapPkgRoomViewItem"))
);
searchHotelViewItemsByPackage(
  getPackageHotelSearchFormModel(pkgAirSearchQuery)
);

async function searchHotelViewItemsByPackage(packageHotelSearchFormModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await dynamicPackageStore.availDynamicPkg(
    packageHotelSearchFormModel
  );

  sessionStorage.setItem(
    "pkgAirportCityMap",
    JSON.stringify(dynamicPackageStore.airportCityMap)
  );

  templateStore.pageLoader({ mode: "off" });
}
function getPackageHotelSearchFormModel(pkgAirSearchQuery) {
  let childAges = null;
  if (pkgAirSearchQuery.childAgesFormat) {
    let childAgesFormat = pkgAirSearchQuery.childAgesFormat;
    // console.info(childAgesFormat);
    if (typeof childAgesFormat === "string") {
      childAgesFormat = [childAgesFormat];
    }
    childAges = parseChildAges(childAgesFormat);
  }

  let adultNum = pkgAirSearchQuery.adultNum;
  let childNum = pkgAirSearchQuery.childNum;
  let cabinClass = pkgAirSearchQuery.cabinClass;
  let departureDate = pkgAirSearchQuery.departureDate;
  let destinationAirportName = pkgAirSearchQuery.destinationAirportName;
  let destinationLocationCode = pkgAirSearchQuery.destinationLocationCode;
  let destinationLocationName = pkgAirSearchQuery.destinationLocationName;
  let originAirportName = pkgAirSearchQuery.originAirportName;
  let originLocationCode = pkgAirSearchQuery.originLocationCode;
  let originLocationName = pkgAirSearchQuery.originLocationName;
  let returnDate = pkgAirSearchQuery.returnDate;
  let roomNum = parseInt(pkgAirSearchQuery.roomNum);
  let ttiCode = pkgAirSearchQuery.ttiCode;

  return {
    adultNum: adultNum,
    childNum: childNum,
    cabinClass: cabinClass,
    childAges: childAges,
    departureDate: departureDate,
    destinationAirportName: destinationAirportName,
    destinationLocationCode: destinationLocationCode,
    destinationLocationName: destinationLocationName,
    originAirportName: originAirportName,
    originLocationCode: originLocationCode,
    originLocationName: originLocationName,
    returnDate: returnDate,
    roomNum: roomNum,
    ttiCode: ttiCode,
  };
}
function parseChildAges(formats) {
  let childAges = [];
  if (formats && formats.length > 0) {
    formats.forEach((format) => {
      let splits = format.split(":");
      let roomId = splits[0];
      let age = splits[1];

      let childAge = {
        roomId: roomId,
        age: age,
      };
      childAges.push(childAge);
    });
  }
  return childAges;
}
let items = reactive([]);
let value = reactive([0, 75]);
let flightViewItemsShow = ref(null);
let flightViewItemsShowLength = ref(null);
let hotelViewShow = ref({});
let offerViewItemMap = reactive(null);
let anyLength = ref(0); //确定初始化显示的总条数
let activeGroupNumber = ref(0); //默认第一程
let filterOptionRef = reactive({
  priceRange: [0, 10000],
  stopNumber: [],
  airlineCode: [],
  cabinClass: [],
  departureTimeQuantum: [],
  arrivalTimeQuantum: [],
});
let sortOptionRef = ref("");
let showMaxIndex = ref(6);
let isShowMore = ref(true);
let preEquateKeys = ref([]);

//endregion 数据初始化

//region 页面vue生命周期事件
onMounted(() => {
  items.push(...data.flightData);
});

//endregion 页面vue生命周期事件

//region 页面vue监听事件
watchEffect(() => {
  if (wrapPkgRoomViewItem) {
    //
    if (wrapPkgRoomViewItem.hotelViewItem) {
      hotelViewShow.value.hotelName =
        wrapPkgRoomViewItem.hotelViewItem.hotelName;
      hotelViewShow.value.mealName = wrapPkgRoomViewItem.mealName;
      hotelViewShow.value.checkIn = wrapPkgRoomViewItem.checkIn;
      hotelViewShow.value.checkOut = wrapPkgRoomViewItem.checkOut;
      hotelViewShow.value.roomViewItem = wrapPkgRoomViewItem.roomViewItem;
      hotelViewShow.value.refundable = wrapPkgRoomViewItem.refundable;
      hotelViewShow.value.address = wrapPkgRoomViewItem.hotelViewItem.address;
      hotelViewShow.value.roomTypeNames =
        wrapPkgRoomViewItem.roomTypeInfo.roomTypeName;
      hotelViewShow.value.roomNum = wrapPkgRoomViewItem.roomNum;
      let roomViewItems = [];
      let number = hotelViewShow.value.roomNum;
      for (let i = 0; i < number; i++) {
        roomViewItems.push(wrapPkgRoomViewItem.roomViewItems[0]);
      }
      hotelViewShow.value.roomViewItems = roomViewItems;
    }
  } else {
  }
});
watchEffect(() => {
  if (dynamicPackageStore.matchFlightViewItemsGroup) {
    templateStore.pageLoader({ mode: "on" });
    anyLength = ref(
      Object.keys(dynamicPackageStore.matchFlightViewItemsGroup).length
    );
    setTimeout(() => {
      flightViewItemsShow.value = JSON.parse(
        JSON.stringify(dynamicPackageStore.matchFlightViewItemsGroup)
      );
      //統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return a[0].score == b[0].score
            ? b[0].totalPrice - a[0].totalPrice
            : b[0].score - a[0].score;
        });
      };

      flightViewItemsShowLength.value = Object.keys(
        flightViewItemsShow.value
      ).length;

      templateStore.pageLoader({ mode: "off" });
    }, 1000);
  } else {
    flightViewItemsShow.value = null;
  }
});

watchEffect(() => {
  if (wrapPkgRoomViewItem) {
    //
    if (wrapPkgRoomViewItem.hotelViewItem) {
      hotelViewShow.value.hotelNme =
        wrapPkgRoomViewItem.hotelViewItem.hotelName;
      hotelViewShow.value.mealName = wrapPkgRoomViewItem.mealName;
      hotelViewShow.value.checkIn = wrapPkgRoomViewItem.checkIn;
      hotelViewShow.value.checkOut = wrapPkgRoomViewItem.checkOut;
      hotelViewShow.value.roomViewItem = wrapPkgRoomViewItem.roomViewItem;
      hotelViewShow.value.refundable = wrapPkgRoomViewItem.refundable;
      hotelViewShow.value.address = wrapPkgRoomViewItem.hotelViewItem.address;
      hotelViewShow.value.roomType =
        wrapPkgRoomViewItem.roomTypeInfo.roomTypeName;
      hotelViewShow.value.roomNum = wrapPkgRoomViewItem.roomNum;
      let roomViewItems = [];
      let number = hotelViewShow.value.roomNum;
      for (let i = 0; i < number; i++) {
        roomViewItems.push(wrapPkgRoomViewItem.roomViewItems[0]);
      }
      hotelViewShow.value.roomViewItems = roomViewItems;
    }
  } else {
  }
});
let formAndTo = reactive(null);
watchEffect(() => {
  if (dynamicPackageStore.origDestGroupMap) {
    let from = getAirportCityName(
      dynamicPackageStore.origDestGroupMap[activeGroupNumber.value]
        .originLocationName
    );
    let to = getAirportCityName(
      dynamicPackageStore.origDestGroupMap[activeGroupNumber.value]
        .destinationLocationName
    );
    formAndTo = reactive({
      from: from,
      to: to,
    });
  } else {
    formAndTo = reactive(null);
  }
});
watchEffect(() => {
  if (dynamicPackageStore.offerViewItemMap) {
    offerViewItemMap = dynamicPackageStore.offerViewItemMap;
  } else {
    offerViewItemMap = null;
  }
});

const filterByOptionsOptions = computed(() => {
  let flightViewItemsGroup = dynamicPackageStore.matchFlightViewItemsGroup
    ? dynamicPackageStore.matchFlightViewItemsGroup
    : {};
  let flightOptions = {
    stopsFilterOptions: [],
    cabinClassFliterOptions: [],
    airlineFilterOptions: [],
  };
  let stopsArr = [];
  let cabinClassArr = [];
  let airlineArr = [];
  let stopTimes = {};
  let cabinClassTimes = {};
  let airlineTimes = {};
  let cabinType = [];
  let airlineType = [];
  if (flightViewItemsGroup) {
    for (let key in flightViewItemsGroup) {
      //所有的stops
      stopsArr.push(flightViewItemsGroup[key][0].segments.length);
      stopTimes = countTotalNumber(stopsArr); //{"1":8,"2":25}

      //所有的cabinClass--只要有一个满足就算
      //1，先看有多少种类型
      flightViewItemsGroup[key][0].segments.forEach((item) => {
        cabinType.push(item.cabinClass);
      });
      cabinType = Array.from(new Set(cabinType));

      //2.计算每个类型有多少个航程
      //如果有一个航段中包含这个类型，类型代表的数量+1
      cabinType.forEach((cabin) => {
        let res = flightViewItemsGroup[key][0].segments.some((cabinItem) => {
          return cabinItem.cabinClass == cabin;
        });
        if (res) {
          //当前遍历的类型在该航程存在
          cabinClassArr.push(cabin);
        }
      });
      cabinClassTimes = countTotalNumber(cabinClassArr);

      flightViewItemsGroup[key][0].segments.forEach((item) => {
        airlineType.push(item.marketingAirlineCode);
      });

      airlineType = Array.from(new Set(airlineType));

      //2.计算每个类型有多少个航程
      //如果有一个航段中包含这个类型，类型代表的数量+1
      airlineType.forEach((airline) => {
        let res = flightViewItemsGroup[key][0].segments.some((airlineItem) => {
          return airlineItem.marketingAirlineCode == airline;
        });
        if (res) {
          //当前遍历的类型在该航程存在
          airlineArr.push(airline);
        }
      });
      airlineTimes = countTotalNumber(airlineArr);
    }

    flightOptions.stopsFilterOptions = getFlightFilterOptions(
      stopTimes,
      "stop"
    );
    flightOptions.cabinClassFliterOptions = getFlightFilterOptions(
      cabinClassTimes,
      "cabinClass"
    );
    flightOptions.airlineFilterOptions = getFlightFilterOptions(
      airlineTimes,
      "airline"
    );
  }
  return flightOptions;
});

//endregion 页面vue监听事件

//region 表单事件
//过滤排序总入口
function sortAndFilter(sortingOption, filterOption) {
  flightViewItemsShow.value = null;
  templateStore.pageLoader({ mode: "on" });
  setTimeout(() => {
    if (sortingOption) {
      sortOptionRef.value = sortingOption;
    }
    if (filterOption) {
      filterOptionRef = filterOption;
    }

    if (filterOptionRef) {
      filterByOptions(filterOptionRef);
    }

    if (sortOptionRef.value) {
      sortByOptions(sortOptionRef.value);
    }
    // ("After sort ", Object.keys(flightViewItemsShow.value).length);
    resetShowMore();
    templateStore.pageLoader({ mode: "off" });
  }, 1000);
}

//endregion 表单事件

//region 辅助函数

//选择下一程
async function selectNext(equateKey, airItineraryRPH) {
  //重置筛选条件
  filterOptionRef = reactive({
    priceRange: [0, 10000],
    stopNumber: null,
    airlineCode: "",
    cabinClass: "",
  });
  templateStore.pageLoader({ mode: "on" });
  if (activeGroupNumber.value == 1) {
    //已经到了第二程
    let pricedItineraries = dynamicPackageStore.pricedItineraries;
    let specifiedPricedItinerary =
      dynamicPackageTools.getSpecifiedPricedItinerary(
        airItineraryRPH,
        pricedItineraries
      );
    // (specifiedPricedItinerary);
    let pkgPassengerFormModel = dynamicPackageTools.getPkgPassengerFormModel(
      wrapPkgRoomViewItem,
      wrapPkgRoomViewItem.roomNum,
      specifiedPricedItinerary.airItinerary
    );
    sessionStorage.setItem(
      "pkgPassengerFormModel",
      JSON.stringify(pkgPassengerFormModel)
    );
    dynamicPackageStore
      .verifyPriceDynamicPkg(pkgPassengerFormModel)
      .then((res) => {
        templateStore.pageLoader({ mode: "off" });
        if (Object.keys(res).length > 0) {
          sessionStorage.setItem("pkgItineraryReview", JSON.stringify(res));
          router.push({ name: "sales-package-itinerary-review" });
        }
      })
      .catch(() => {
        templateStore.pageLoader({ mode: "off" });
      });
  } else {
    //第一程
    //拿到第一个航程的key值
    setTimeout(() => {
      dynamicPackageStore.changeMatchFlightViewItemsGroup(1, equateKey);
      templateStore.pageLoader({ mode: "off" });
      activeGroupNumber.value++;
    }, 500);
  }
}
//排序相关
function sortByOptions(sortingOptionRef) {
  let sortingOptionObj = null;
  if (sortingOptionRef.value) {
    const splits = sortingOptionRef.value.split("-");
    let type = splits[0];
    let order = splits[1];
    sortingOptionObj = new flightTools.SortingOption(type, order);
    // templateStore.pageLoader({ mode: "on" });
    let flightViewItemsShowCopy = JSON.parse(
      JSON.stringify(flightViewItemsShow.value)
    );
    sort(
      sortingOptionObj.type,
      sortingOptionObj.order,
      flightViewItemsShowCopy
    );
  }
}
//排序实现方法
function sort(type, order, flightViewItemsShowCopy) {
  if (flightViewItemsShowCopy) {
    flightViewItemsShow.value = flightViewItemsShowCopy;
    flightViewItemsShow.value[Symbol.iterator] = function* () {
      if (type === "PRICE" && order === "ASC") {
        //升序
        yield* [...Object.values(this)].sort((a, b) => {
          return a[0].totalPrice - b[0].totalPrice;
        });
      } else if (type === "PRICE" && order === "DSC") {
        //降序
        yield* [...Object.values(this)].sort((a, b) => {
          return b[0].totalPrice - a[0].totalPrice;
        });
      } else if (type === "" || order === "") {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      }
    };

    flightViewItemsShowLength.value = Object.keys(
      flightViewItemsShow.value
    ).length;
  }
}

//过滤相关
function checkTimeRange(compareStr, dateStr) {
  let dateDefaultPre = "2000-01-01 ";
  let minDate = dayjs(
    dateDefaultPre + dateStr.split("-")[0].replace("+", " "),
    "YYYY-MM-DD h:mm a"
  );
  let maxDate = dayjs(
    dateDefaultPre + dateStr.split("-")[1].replace("+", " "),
    "YYYY-MM-DD h:mm a"
  );
  // let compareFormat = dayjs(compareStr).format("h:mm a");
  let dateTimeCompare = dayjs(
    dateDefaultPre + " " + dayjs(compareStr).format("h:mm a")
  );
  let below = dateTimeCompare.diff(minDate, "minutes", false);
  let upper = dateTimeCompare.diff(maxDate, "minutes", false);
  if (below >= 0 && upper <= 0) {
    return true;
  } else {
    return false;
  }
}
function filterByOptions(filterOption) {
  let flightViewItemsShowTemp = null;

  if (dynamicPackageStore.matchFlightViewItemsGroup) {
    flightViewItemsShowTemp = JSON.parse(
      JSON.stringify(dynamicPackageStore.matchFlightViewItemsGroup)
    );
  }

  //根据价格区间筛选
  if (filterOption.priceRange && filterOption.priceRange.length > 0) {
    // //过滤
    for (const key in flightViewItemsShowTemp) {
      if (
        flightViewItemsShowTemp[key] &&
        flightViewItemsShowTemp[key].length > 0
      ) {
        if (filterOption.priceRange[1] == 10000) {
          //这个时候选择的价格是xx-10000+,只需要做最小值处理
          if (
            !(
              flightViewItemsShowTemp[key][0].totalPrice >=
              filterOption.priceRange[0]
            )
          ) {
            delete flightViewItemsShowTemp[key];
          }
        } else {
          if (
            !(
              flightViewItemsShowTemp[key][0].totalPrice >=
                filterOption.priceRange[0] &&
              flightViewItemsShowTemp[key][0].totalPrice <=
                filterOption.priceRange[1]
            )
          ) {
            delete flightViewItemsShowTemp[key];
          }
        }
      }
    }
    flightViewItemsShow.value = flightViewItemsShowTemp;
    //統一迭代邏輯
    flightViewItemsShow.value[Symbol.iterator] = function* () {
      yield* [...Object.values(this)].sort((a, b) => {
        return 0;
      });
    };
  }

  //根据departure Time筛选
  if (
    filterOption.departureTimeQuantum &&
    filterOption.departureTimeQuantum.length > 0
  ) {
    let departureDateTimeKeyMap = {};
    //1.第一次遍历先选出key
    for (const key in flightViewItemsShowTemp) {
      //出发时间
      let departureDateTime = flightViewItemsShowTemp[key][0].departureDateTime;
      filterOption.departureTimeQuantum.forEach((departureTimeQuantum) => {
        let res = checkTimeRange(departureDateTime, departureTimeQuantum);
        if (res) {
          departureDateTimeKeyMap[key] = "1";
        }
      });
    }
    //2.再次去遍历时删除不符合上面的key
    for (const key in flightViewItemsShowTemp) {
      //4.如果当前的key和上面的都不符合，就delete
      if (!departureDateTimeKeyMap[key]) {
        delete flightViewItemsShowTemp[key];
      }
    }
    flightViewItemsShow.value = flightViewItemsShowTemp;
    //統一迭代邏輯
    flightViewItemsShow.value[Symbol.iterator] = function* () {
      yield* [...Object.values(this)].sort((a, b) => {
        return 0;
      });
    };
  }

  //根据arrival Time筛选
  if (
    filterOption.arrivalTimeQuantum &&
    filterOption.arrivalTimeQuantum.length > 0
  ) {
    let arrivalDateTimeKeyMap = {};
    //1.第一次遍历先选出key
    for (const key in flightViewItemsShowTemp) {
      //到达时间
      let arrivalDateTime = flightViewItemsShowTemp[key][0].arrivalDateTime;
      filterOption.arrivalTimeQuantum.forEach((arrivalTimeQuantum) => {
        let res = checkTimeRange(arrivalDateTime, arrivalTimeQuantum);
        if (res) {
          arrivalDateTimeKeyMap[key] = "1";
        }
      });
    }
    //2.再次去遍历时删除不符合上面的key
    for (const key in flightViewItemsShowTemp) {
      //4.如果当前的key和上面的都不符合，就delete
      if (!arrivalDateTimeKeyMap[key]) {
        delete flightViewItemsShowTemp[key];
      }
    }
    flightViewItemsShow.value = flightViewItemsShowTemp;
    //統一迭代邏輯
    flightViewItemsShow.value[Symbol.iterator] = function* () {
      yield* [...Object.values(this)].sort((a, b) => {
        return 0;
      });
    };
  }

  //根据stopNumber筛选
  if (filterOption.stopNumber && filterOption.stopNumber.length > 0) {
    if (filterOption.stopNumber.indexOf("Any") > -1) {
      //如果选择了any 統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    } else {
      let stopKeyMap = {};
      //1.第一次遍历先选出key
      for (const key in flightViewItemsShowTemp) {
        filterOption.stopNumber.forEach((stopNum) => {
          let stopNumber = parseInt(stopNum);
          if (flightViewItemsShowTemp[key][0].segments.length == stopNumber) {
            //等于的key先保存一份
            stopKeyMap[key] = "1";
          }
        });
      }
      //2.再次去遍历时删除不符合上面的key
      for (const key in flightViewItemsShowTemp) {
        //4.如果当前的key和上面的都不符合，就delete
        if (!stopKeyMap[key]) {
          delete flightViewItemsShowTemp[key];
        }
      }
      flightViewItemsShow.value = flightViewItemsShowTemp;
      //統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    }
  }

  //根据座位筛选
  if (filterOption.cabinClass && filterOption.cabinClass.length > 0) {
    if (filterOption.cabinClass.indexOf("Any") > -1) {
      //如果选择了any 統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    } else {
      let cabinClassKeyMap = {};
      //1.第一次遍历先选出key
      for (const key in flightViewItemsShowTemp) {
        filterOption.cabinClass.forEach((cabinClass) => {
          if (flightViewItemsShowTemp[key][0].segments.length > 0) {
            let res = flightViewItemsShowTemp[key][0].segments.some(
              (item) => item.cabinClass == cabinClass
            );
            if (res) {
              //等于的key先保存一份
              cabinClassKeyMap[key] = "1";
            }
          }
        });
      }
      //2.再次去遍历时删除不符合上面的key
      for (const key in flightViewItemsShowTemp) {
        //4.如果当前的key和上面的都不符合，就delete
        if (!cabinClassKeyMap[key]) {
          delete flightViewItemsShowTemp[key];
        }
      }
      flightViewItemsShow.value = flightViewItemsShowTemp;
      //統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    }
  }

  //根据航空公司筛选
  if (filterOption.airlineCode && filterOption.airlineCode.length > 0) {
    if (filterOption.airlineCode.indexOf("anyAirline") > -1) {
      //如果选择了any 統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    } else {
      let airlineCodeKeyMap = {};
      //1.第一次遍历先选出key
      for (const key in flightViewItemsShowTemp) {
        filterOption.airlineCode.forEach((airlineCode) => {
          if (flightViewItemsShowTemp[key][0].segments.length > 0) {
            let res = flightViewItemsShowTemp[key][0].segments.some(
              (item) => item.marketingAirlineCode == airlineCode
            );
            if (res) {
              // (key);
              //等于的key先保存一份
              airlineCodeKeyMap[key] = "1";
            }
          }
        });
      }
      //2.再次去遍历时删除不符合上面的key
      for (const key in flightViewItemsShowTemp) {
        //4.如果当前的key和上面的都不符合，就delete
        if (!airlineCodeKeyMap[key]) {
          delete flightViewItemsShowTemp[key];
        }
      }
      flightViewItemsShow.value = flightViewItemsShowTemp;
      //統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    }
  }

  flightViewItemsShowLength.value = Object.keys(
    flightViewItemsShow.value
  ).length;
}

//组合flightFilterOptions的每一项
function getFlightFilterOptions(times, type) {
  let item = {};
  let itemDefault = {};
  let option = [];
  if (type == "stop") {
    itemDefault.stopNumber = "Any";
    itemDefault.quality = anyLength.value;
  } else if (type == "cabinClass") {
    itemDefault.cabinClass = "Any";
    itemDefault.quality = anyLength.value;
  } else {
    itemDefault.airlineCode = "anyAirline";
    itemDefault.quality = anyLength.value;
  }

  option.push(itemDefault);
  for (let key in times) {
    if (type == "stop") {
      item.stopNumber = key;
      item.quality = times[key];
    } else if (type == "cabinClass") {
      item.cabinClass = key;
      item.quality = times[key];
    } else {
      item.airlineCode = key;
      item.quality = times[key];
    }
    let itemCopy = JSON.parse(JSON.stringify(item));
    option.push(itemCopy);
  }
  return option;
}

//计算数组中元素出现的次数
function countTotalNumber(data) {
  return data.reduce(function (time, name) {
    if (name in time) {
      time[name]++;
    } else {
      time[name] = 1;
    }
    return time;
  }, {});
}

//数据加载相关
function resetShowMore() {
  showMaxIndex.value = 6;
  isShowMore.value = true;
}
function loadMoreItems() {
  showMaxIndex.value += 6;
  if (showMaxIndex.value >= flightViewItemsShowLength.value) {
    isShowMore.value = false;
  }
}

//endregion 辅助函数

//城市名
function getAirportCityName(code) {
  if (!flightStore.airportCityMap || !flightStore.airportCityMap[code])
    return code;
  //可以通过语言获取不同类型的名字
  return flightStore.airportCityMap[code].cityName;
}
//格式化
function formatChildAges(childAges) {
  let formatArr = [];
  childAges.forEach((childAge) => {
    let format = childAge.roomId + ":" + childAge.age;
    formatArr.push(format.toString());
  });
  return formatArr;
}
function goBack() {
  let formModel = JSON.parse(sessionStorage.getItem("packageSearchFormModel"));
  //跳转前
  let packageSearchFormModelCopy = JSON.parse(JSON.stringify(formModel));
  //首页跳转传参
  let format = formatChildAges(packageSearchFormModelCopy.childAges);
  formModel.childAgesFormat = format;
  let query = formModel;
  query.ttiCode = JSON.parse(sessionStorage.getItem("packageHotelTtiCode"));
  router.push({ name: "sales-package-hotel-details", query: query });
}

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);

const bannerContentStore = useCmsContentStore();
let bannerContent = reactive(null);
bannerContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrlRef = ref("");
let backgroundImageUrlRef = ref("");
watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrlRef.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.packageBackgroundImage
      ? bannerContentStore.productPageBackgroundImage.packageBackgroundImage.url
      : "";
  backgroundImageUrlRef.value = baseUrl + imageUrlRef.value;
});

//ant design
let filterOpen = ref(false);
const showFilterDrawer = () => {
  filterOpen.value = true;
};
const closeFilterDrawer = () => {
  filterOpen.value = false;
};

//region 移动端加载
function handleScroll() {
  let distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;

  if (distance == 0) {
    setTimeout(() => {
      loadMoreItems();
    }, 500);
  }
}
onMounted(() => {
  if (equipmentType.value == "iphone") {
    //是手机端的采用滚动
    window.addEventListener("scroll", handleScroll);
  }
});
//endregion
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />

  <!-- Flight Search Areas -->

  <section id="explore_area" class="section_padding">
    <div class="container">
      <!-- Section Heading -->
      <div class="row" v-if="equipmentType == 'iphone'">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mg-t-10">
          <div class="filter-sort">
            <div class="filtrate_btn h5" @click="showFilterDrawer">
              {{ $t("filtrate") }}<i class="fa fa-fw fa-angle-down"></i>
            </div>
            <div class="sort_btn">
              <BasePackageFlightSortForm
                @sort="
                  (sortOption) => sortAndFilter(sortOption, filterOptionRef)
                "
              />
            </div>
          </div>
          <!--  filter部分 -->
          <a-drawer
            title="Filter Option"
            placement="bottom"
            :open="filterOpen"
            @close="closeFilterDrawer"
          >
            <BasePackageFlightFilterForm
              @filter="
                (filterOption) => sortAndFilter(sortOptionRef, filterOption)
              "
              :filter-option-props="filterOptionRef"
              :filter-flight-options="filterByOptionsOptions"
            />
          </a-drawer>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12" style="display: flex">
          <div
            class="header_back"
            style="width: 25%"
            v-if="equipmentType != 'iphone'"
          >
            <a @click="goBack">
              <i
                class="bi bi-arrow-left-square"
                size="40"
                style="
                  font-size: 28px;
                  color: #7ae3c3;
                  cursor: pointer;
                  margin-right: 10px;
                "
              ></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
          <div
            class="section_heading_center"
            style="width: 80%"
            v-if="flightViewItemsShowLength"
          >
            <h2>
              {{
                $t("num-flights-found", {
                  flightViewItemsShowLength: flightViewItemsShowLength,
                })
              }}
            </h2>
          </div>
          <div class="section_heading_center" style="width: 80%" v-else>
            <h2>
              {{ $t("num-flights-found", { flightViewItemsShowLength: 0 }) }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3" v-if="equipmentType != 'iphone'">
          <div class="left_side_search_area">
            <BasePackageFlightSortForm
              @sort="(sortOption) => sortAndFilter(sortOption, filterOptionRef)"
            />
            <BasePackageFlightFilterForm
              @filter="
                (filterOption) => sortAndFilter(sortOptionRef, filterOption)
              "
              :filter-option-props="filterOptionRef"
              :filter-flight-options="filterByOptionsOptions"
            />
          </div>
        </div>

        <div class="col-lg-9">
          <!--hotel相关-->
          <BasePkgHotelItemPanel :hotel-view-show="hotelViewShow" />

          <!--flight相关-->
          <div class="row">
            <div class="col-lg-12">
              <div class="row" v-if="dynamicPackageStore.origDestGroupMap">
                <div class="col-lg-12 flight_header">
                  <a
                    v-for="(
                      item, key, index
                    ) in dynamicPackageStore.origDestGroupMap"
                    style="margin-left: 5px"
                    :href="
                      key == activeGroupNumber ? 'javascript:void(0);' : null
                    "
                  >
                    <span>
                      {{
                        $t("from-to", {
                          from: item.originLocationName,
                          to: item.destinationLocationName,
                        })
                      }}
                    </span>
                    <i
                      class="fa fa-arrow-circle-right"
                      v-if="
                        index <
                        Object.keys(dynamicPackageStore.origDestGroupMap)
                          .length -
                          1
                      "
                      style="margin-left: 5px; color: #4ecca6"
                    ></i
                  ></a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div
                    class="flight_search_result_wrapper"
                    id="baseFlightItemPanel"
                    v-if="flightViewItemsShow"
                  >
                    <div
                      v-for="(flightInfos, flightIndex) in flightViewItemsShow"
                      :key="flightIndex"
                      class="flight_search_item_wrappper"
                    >
                      <BaseFlightItemPanel
                        v-if="
                          showMaxIndex > flightIndex &&
                          equipmentType != 'iphone'
                        "
                        :flight-info-index="flightIndex"
                        :flight-infos="flightInfos"
                        :offer-view-item-map="offerViewItemMap"
                        @select="selectNext"
                      />
                      <BaseFlightItemMobilePanel
                        v-else-if="
                          showMaxIndex > flightIndex &&
                          equipmentType == 'iphone'
                        "
                        :flight-info-index="flightIndex"
                        :flight-infos="flightInfos"
                        :offer-view-item-map="offerViewItemMap"
                        @select="selectNext"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12" v-else><AEmpty></AEmpty></div>

                  <div
                    class="load_more_flight"
                    v-if="
                      flightViewItemsShowLength > 6 &&
                      isShowMore &&
                      equipmentType != 'iphone'
                    "
                  >
                    <button class="btn btn_md" @click="loadMoreItems()">
                      <i class="fas fa-spinner"></i> {{ $t("load-more") }}..
                    </button>
                  </div>
                  <div
                    class="text-center"
                    v-if="
                      flightViewItemsShowLength > 6 &&
                      isShowMore &&
                      equipmentType == 'iphone'
                    "
                  >
                    <Spin></Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
