import { defineStore } from "pinia";
import tripApi from "@/apis/v2/trip/tripApi";
import tripCommonTools from "@/tools/v2/trip/tripCommonTools";

export const useTripStore = defineStore("trip", {
  state: () => ({ tripItems: null, totalElements: null }),
  actions: {
    tripsRead(tripsReadFormModel) {
      const thisRef = this;
      thisRef.tripItems = null;
      const tripsReadRQ = tripCommonTools.buildTripsReadRQ(tripsReadFormModel);
      return tripApi
        .tripsRead(tripsReadRQ)
        .then((res) => {
          const tripsReadRS = res.data;
          this.totalElements = tripsReadRS.totalElements;
          if (tripsReadRS.totalElements && tripsReadRS.elements.length > 0) {
            thisRef.tripItems = tripCommonTools.buildTripItems(
              tripsReadRS.elements
            );
          } else {
            thisRef.tripItems = null;
            thisRef.totalElements = null;
          }

          return tripsReadRS;
        })
        .catch((error) => {
          console.info(error);
          thisRef.tripItems = null;
          thisRef.totalElements = null;
        });
    },
    tripsCancelQuote(tripId, tripItemId, recordLocator, idContext) {
      const tripAirItemCancelRQ = tripCommonTools.buildTripAirItemCancelRQ(
        "QUOTE",
        tripId,
        tripItemId,
        recordLocator,
        idContext
      );

      return tripApi.tripCancel(tripAirItemCancelRQ).then((res) => {
        //console.info(res.data);
        return res.data;
      });
    },
    tripsCancel(tripId, tripItemId, recordLocator, idContext) {
      const tripAirItemCancelRQ = tripCommonTools.buildTripAirItemCancelRQ(
        "CANCEL",
        tripId,
        tripItemId,
        recordLocator,
        idContext
      );

      return tripApi.tripCancel(tripAirItemCancelRQ).then((res) => {
        //console.info(res.data);
        return res.data;
      });
    },
    tripsCancelCommit(tripId, tripItemId, recordLocator, idContext) {
      const tripAirItemCancelRQ = tripCommonTools.buildTripAirItemCancelRQ(
        "COMMIT",
        tripId,
        tripItemId,
        recordLocator,
        idContext
      );

      return tripApi.tripCancel(tripAirItemCancelRQ).then((res) => {
        return res.data;
      });
    },
    tripHotelPrintVoucher(bookingId, type, itemId) {
      return tripApi.printVoucher({ bookingId, type, itemId }).then((res) => {
        return res;
      });
    },
    tripImportPNR(importPNRFormModal) {
      return tripApi
        .importPNR(
          importPNRFormModal,
          importPNRFormModal.bookingId,
          importPNRFormModal.infoSource,
          importPNRFormModal.ID
        )
        .then((res) => {
          return res.data;
        });
    },
  },
});
