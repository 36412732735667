<script setup>
import {computed, reactive, ref, watch, watchEffect} from "vue";
import { useFlightStore } from "@/stores/flight";
import commonUtils from "../../../../../utils/commonUtils";

const props = defineProps({
  // flightInfos: {},
  toPath: {
    default: "/sales/flight/flight-itinerary-review",
  },
  flightInfos: {},
  flightInfoIndex: {},
  offerViewItemMap: {},
});

let flightStore = useFlightStore();
const flightInfosRef = ref(null);
const flightInfoRef = ref(null);
const airlinePicRef = ref(null);
const originTimeRef = ref(null);
const destinationTimeRef = ref(null);

watch(
    () => props.flightInfos,
    (newValue) => {
        let flightInfos = JSON.parse(JSON.stringify(newValue));
        flightInfos.sort((a, b) => a.bestPrice - b.bestPrice);
        flightInfosRef.value = flightInfos;
        flightInfoRef.value = flightInfos[0];
        airlinePicRef.value = getAirlinePicture(
            flightInfos[0].segments[0].marketingAirlineCode
        );

        //获得出发时间和到达时间
        let segments = flightInfoRef.value.segments;
        if (segments.length > 0) {
            for (let i = 0; i < segments.length; i++) {
                originTimeRef.value = formatEnglishDate(
                    segments[0].departureDateTime
                ).time;
                destinationTimeRef.value = formatEnglishDate(
                    segments[segments.length - 1].arrivalDateTime
                ).time;
            }
        }
    },
    { deep: true, immediate: true }
);

// 将分钟数量转换为小时和分钟字符串
function formatHourMinute(minutes) {
  let time = {
    hour:
      Math.floor(minutes / 60) < 10 && Math.floor(minutes / 60) > 0
        ? "0" + Math.floor(minutes / 60)
        : Math.floor(minutes / 60),
    minute:
      minutes % 60 < 10 && minutes % 60 > 0
        ? "0" + (minutes % 60)
        : minutes % 60,
  };
  return time.hour + "h " + time.minute + "m";
}

//价格千分位表示
function formatNumber(str) {
  return str
    .split("")
    .reverse()
    .reduce((prev, next, index) => {
      return (index % 3 ? next : next + ",") + prev;
    });
}

function getAirlinePicture(airlineCode) {
  return "http://images.skywidetech.com/airlines/" + airlineCode + ".png";
}

//美式英语的时间格式
function formatEnglishDate(date) {
  date = new Date(date);
  let monthArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Spt",
    "Oct",
    "Nov",
    "Dec",
  ];
  let suffix = ["st", "nd", "rd", "th"];

  let year = date.getFullYear(); //年
  let month = monthArr[date.getMonth()]; //月
  let ddate = date.getDate(); //日
  let time = date.toLocaleTimeString("chinese", { hour12: false }).slice(0, 5);

  if (ddate % 10 < 1 || ddate % 10 > 3) {
    ddate = ddate + suffix[3];
  } else if (ddate % 10 == 1) {
    ddate = ddate + suffix[0];
  } else if (ddate % 10 == 2) {
    ddate = ddate + suffix[1];
  } else {
    ddate = ddate + suffix[2];
  }
  return { time: time, ddate: ddate, month: month, year: year };
}

function TimeDecrement(timeOne, timeTwo) {
  timeOne = new Date(timeOne);
  timeTwo = new Date(timeTwo);
  let time = null;
  if (timeTwo > timeOne) {
    time = parseInt(timeTwo - timeOne) / 1000 / 60;
  } else {
    time = parseInt(timeOne - timeTwo) / 1000 / 60;
  }
  return formatHourMinute(time);
}

//城市名
function getAirportCityName(code) {
  if (!flightStore.airportCityMap || !flightStore.airportCityMap[code])
    return code;
  //可以通过语言获取不同类型的名字
  return flightStore.airportCityMap[code].cityName;
}

//航空公司
function getAirlineName(airlineCode) {
  let airlineNameKey = "airlineName." + airlineCode;
  return airlineNameKey;
}

function getAirportName(airportCode) {
  let airportNameKey = "airport." + airportCode;
  return airportNameKey;
}

function getAircraftName(aircraftCode) {
  let aircraftNameKey = "aircraft." + aircraftCode;
  return aircraftNameKey;
}

let activeIndex = ref(0);
function selectFlightInfo(flightItem, index) {

  flightInfoRef.value = flightItem;
  activeIndex.value = index;
}

function filterFlightInfosRef(filterFlightInfosRef) {
  // 判断如果传入数组为空数组则直接返回
  if (filterFlightInfosRef.length === 0) return;
  let dupArr = []; // 接受去重之后的数组
  const map = {}; // 存放去重时得到的对象数据

  filterFlightInfosRef.forEach((item) => {
    let key = item.bundleID + item.equateKey;
    if (!map[key]) {
      map[key] = item; // 如果当前属性值没有，则添加到map中
    }
  });
  dupArr = Object.values(map);
  return dupArr;
}
const emits = defineEmits()
function selectSegments(equateKey, airItineraryRPH) {
    resetDropdown();
    window.scrollTo(0, 0);
    emits("select", equateKey, airItineraryRPH);
}
function resetDropdown() {
    let all = document.querySelectorAll(".collapse");
    all.forEach((ele) => {
        ele.classList.remove("show");
    });
}
</script>

<template>
  <div
    style="background: #ffffff; border: 1px solid #dddddd; border-radius: 12px"
  >
    <!--      取flightInfos第0个渲染-->
    <div class="row" style="padding: 0">
      <div class="col-8">
        <div
          style="padding-top: 20px; padding-left: 20px; padding-bottom: 20px"
        >
          <h6 style="font-weight: 600">
            {{ originTimeRef }} - {{ destinationTimeRef }}
          </h6>
          <div
            style="
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-top: 5px;
            "
          >
            {{ getAirportCityName(flightInfoRef.originLocation) }} ({{
              flightInfoRef.originLocation
            }}) -
            {{ getAirportCityName(flightInfoRef.destinationLocation) }} ({{
              flightInfoRef.destinationLocation
            }})
          </div>
          <div style="margin-top: 5px">
            {{ formatHourMinute(flightInfoRef.durationInMins) }} (
            {{ flightInfoRef.segments.length - 1 }}-stop)
          </div>
          <div style="display: flex; height: 23px; margin-top: 5px">
            <img
              :src="airlinePicRef"
              alt="img"
              style="
                width: 23px;
                height: 23px;
                border-radius: 100%;
                object-fit: contain;
                overflow: hidden;
              "
            />
            <div
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 23px;
                line-height: 23px;
                margin-left:5px
              "
            >
              <span
                v-for="(segment, segmentIndex) in flightInfoRef.segments"
                :key="segmentIndex"
              >
                {{ $t(getAirlineName(segment.marketingAirlineCode)) }}
                <span
                  v-if="segmentIndex < flightInfoRef.segments.length - 1"
                  style="font-weight: 600; margin-left: 5px; margin-right: 5px"
                  >-</span
                ></span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-4"
        style="margin-left: -20px; background: var(--bg-color)"
      >
        <div
          style="
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <h3 style="font-weight: 600">
            {{ flightInfoRef.currency }}
            {{ formatNumber(flightInfoRef.totalPrice.toString()) }}
          </h3>
          <div style="font-size: 12px; white-space: nowrap; margin-top: 5px">
            {{ $t("taxes-fees-included") }}
          </div>
          <button
            class="btn btn_theme btn-sm"
            @click="
            selectSegments(
              flightInfoRef.equateKey,
              flightInfoRef.airItineraryRPH
            )
          "
            style="width: 140px; height: 45px"
          >
            {{ $t("select") }}
          </button>
          <div
            :data-bs-target="'#collapseExample' + props.flightInfoIndex"
            aria-controls="collapseExample"
            aria-expanded="false"
            data-bs-toggle="collapse"
            class="text-center"
          >
            {{ $t("show-more")
            }}<i class="fas fa-chevron-down" style="margin: 5px"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    :id="'collapseExample' + flightInfoIndex"
    class="flight_policy_refund collapse"
    v-if="flightInfoRef"
  >
    <div
      class="flight_show_down_wrapper"
      v-for="(segment, segmentIndex) in flightInfoRef.segments"
      :key="segmentIndex"
    >
      <div class="row" style="width: 80%">
        <div class="flight-shoe_dow_item col-12">
          <div class="airline-details">
            <div class="img">
              <img
                :src="getAirlinePicture(segment.marketingAirlineCode)"
                alt="img"
              />
            </div>
            <span class="airlineName fw-500"
              >{{ $t(getAirlineName(segment.marketingAirlineCode)) }}
              {{ segment.flightNumber }} &nbsp;</span
            >
            <span class="flightNumber">{{
              $t(getAircraftName(segment.equipmentCode))
            }}</span>
          </div>
          <div class="flight_inner_show_component row">
            <div class="flight_det_wrapper col-5">
              <div class="flight_det">
                <div class="code_time" style="width: 300px">
                  <span class="code">{{
                    getAirportCityName(segment.departureAirportCode)
                  }}</span>
                  <span class="time">{{
                    formatEnglishDate(segment.departureDateTime).time
                  }}</span>
                </div>
                <p class="airport">
                  {{ $t(getAirportName(segment.departureAirportCode)) }}
                </p>
                <p class="date">
                  {{ formatEnglishDate(segment.departureDateTime).ddate }}
                  {{ formatEnglishDate(segment.departureDateTime).month }}
                  {{ formatEnglishDate(segment.departureDateTime).year }}
                </p>
              </div>
            </div>
            <div class="flight_duration col-2">
              <div class="arrow_right"></div>
              <span>{{
                TimeDecrement(
                  segment.arrivalDateTime,
                  segment.departureDateTime
                )
              }}</span>
            </div>
            <div class="flight_det_wrapper col-5">
              <div class="flight_det">
                <div class="code_time" style="width: 300px">
                  <span class="code">{{
                    getAirportCityName(segment.arrivalAirportCode)
                  }}</span>
                  <span class="time">
                    {{ formatEnglishDate(segment.arrivalDateTime).time }}</span
                  >
                </div>
                <p class="airport">
                  {{ $t(getAirportName(segment.arrivalAirportCode)) }}
                </p>
                <p class="date">
                  {{ formatEnglishDate(segment.arrivalDateTime).ddate }}
                  {{ formatEnglishDate(segment.arrivalDateTime).month }}
                  {{ formatEnglishDate(segment.arrivalDateTime).year }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="flight_refund_policy">-->
      <!--        <div class="TabPanelInner flex_widht_less">-->
      <!--          <h4>Refund Policy</h4>-->
      <!--          <p class="fz12">-->
      <!--            1. Refund and Date Change are done as per the following policies.-->
      <!--          </p>-->
      <!--          <p class="fz12">-->
      <!--            2. Refund Amount= Refund Charge (as per airline policy + ShareTrip-->
      <!--            Convenience Fee).-->
      <!--          </p>-->
      <!--          <p class="fz12">-->
      <!--            3. Date Change Amount= Date Change Fee (as per Airline Policy +-->
      <!--            ShareTrip Convenience Fee).-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <div class="TabPanelInner">-->
      <!--          <h4>Baggage</h4>-->
      <!--          <div class="flight_info_taable">-->
      <!--            <h3>DAC-SPD</h3>-->
      <!--            <p><span>20KG /</span> person</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <!--      不同 bundId 对应的信息-->
    <div class="row flight_ticket_list" style="margin-top: 5px">
      <div
        class="col-lg-4 col-md-6 col-sm-6 col-12"
        v-for="(item, itemIndex) in filterFlightInfosRef(flightInfosRef)"
        :key="itemIndex"
      >
        <div class="theme_common_box_two img_hover">
          <div
            id="flight_type_1`"
            class="flight_ticket_content"
            :class="{ active: activeIndex === itemIndex }"
            @click="selectFlightInfo(item, itemIndex)"
          >
            <h3 v-if="item.totalPrice">{{ item.currency }} {{ item.totalPrice }}</h3>

            <h4 v-if="offerViewItemMap && offerViewItemMap[item.bundleID]">
              {{ offerViewItemMap[item.bundleID].name }}
            </h4>
            <h3>
              <span
                >{{ $t("cabin") }}:
                {{ flightInfoRef.segments[0].cabinClass }}</span
              >
            </h3>
            <!--            <h6 class="mg-t-10">-->
            <!--              {{ $t("info-source", { supplierCode: item.supplierCode }) }}-->
            <!--            </h6>-->
            <ul v-if="offerViewItemMap && offerViewItemMap[item.bundleID]">
              <li
                v-for="(description, descriptionIndex) in offerViewItemMap[
                  item.bundleID
                ].descriptions"
                :key="descriptionIndex"
              >
                <p>
                    <div style="font-weight: 600">{{
                        commonUtils.stringFirstUpper(
                            description.subCode.replaceAll("_", " ").toLowerCase()
                        )
                        }}</div>
                  <span class="review_count">{{ description.text }}</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped></style>
