import { createI18n } from "vue-i18n";
import EN from "@/i18n/en";
import airport from "@/i18n/airport";
import aircraft from "@/i18n/aircraft";
import airlineName from "@/i18n/airlineName";
import CN from "@/i18n/zh_CN";
// import HK from "@/i18n/zh_HK";
// import TW from "@/i18n/zh_TW";

const i18n = createI18n({
  locale: "en",
  legacy: false,
  globalInjection: true,
  messages: {
    en: {
      ...EN,
      airport: { ...airport.messages.en },
      aircraft: {
        ...aircraft.messages.en,
      },
      airlineName: {
        ...airlineName.messages.en,
      },
    },
    zh: {
      ...CN,
    },
    // zh_HK: {
    //   ...HK,
    // },
    // zh_TW: {
    //   ...TW,
    // },
  },
});

export default i18n;
