<script setup>
import BaseDailyRatesContent from "@/views/sales/hotel/items/panel/BaseDailyRatesPanel.vue";
import BaseCancelPolicyContent from "@/views/sales/hotel/items/panel/BaseCancelPolicyPanel.vue";
import { ref } from "vue";

const props = defineProps({
  roomNum: {
    type: Number,
  },
  roomItem: {
    type: Object,
  },
  roomTypeItem: {
    type: Object,
  },
  modelIndex: {
    type: String,
  },
  roomIndex: {},
  showMore: {},
});

let showItem = ref(props.roomIndex != null && props.roomIndex < 2);

// const roomItemShow = ref(false);

function getDailyPriceModelKey(modelIndex) {
  return "D" + modelIndex;
}

function getPolicyModelKey(modelIndex) {
  return "C" + modelIndex;
}
</script>

<template>
  <tr style="border-bottom: 1px solid #cccccc" v-show="showMore || showItem">
    <td style="width: 50%; border-right: 1px solid #cccccc">
      <div
        style="display: flex; flex-direction: column; justify-content: center"
      >
        <!--        <div v-if="roomItem.brand">-->
        <!--          <i class="fa fa-handshake"></i> {{ $t("supplier") }}-->
        <!--          {{ roomItem.brand }}-->
        <!--        </div>-->
        <div v-if="roomItem.bedTypeName">
          <i class="fa fa-bed"></i> {{ roomItem.bedTypeName }}
        </div>
        <div v-if="roomItem.mealsIncluded">
          <i class="fa fa-bell-concierge"></i>
          {{ roomItem.mealName }}
        </div>
        <p
          class="import-content mb-2"
          v-if="roomItem.refundable"
          data-bs-toggle="modal"
          :data-bs-target="'#' + getPolicyModelKey(modelIndex)"
          style="color: red"
        >
          {{ $t("refundable") }} <i class="fa fa-circle-info"></i>
        </p>
        <p class="import-content mb-2" v-else style="color: red">
          {{ $t("non-refundable") }} <i class="fa fa-circle-info"></i>
        </p>

        <div v-if="roomItem.rateComments">
          <p v-html="roomItem.rateComments"></p>
        </div>
        <div v-if="roomItem.rateDescription">
          <p v-html="roomItem.rateDescription"></p>
        </div>

        <!-- Cancel Policies Slide Left Block Modal -->
        <div
          class="modal fade"
          :id="getPolicyModelKey(modelIndex)"
          tabindex="-1"
          role="dialog"
          :aria-labelledby="getPolicyModelKey(modelIndex)"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <BaseCancelPolicyContent
                :title="'Cancellation Policies'"
                :room-type-name="roomTypeItem.roomTypeName"
                :items="[roomItem]"
              ></BaseCancelPolicyContent>
            </div>
          </div>
        </div>
        <!-- END Cancel Policies Slide Left Block Modal -->
      </div>
    </td>
    <td style="width: 20%; border-right: 1px solid #cccccc">
      <p class="fs-sm text-muted" style="padding: 45% 10%">
        <span v-if="roomTypeItem.maxOccupancy">
          <i class="fa fa-user-group"></i> {{ $t("sleeps") }}
          {{ roomTypeItem.maxOccupancy }}
        </span>
      </p>
    </td>
    <td style="width: 30%">
      <div style="display: flex; justify-content: space-between">
        <div style="padding-top: 10px">
          <h3 style="font-weight: 600">
            {{ roomItem.currency }} {{ roomItem.roomPrice.toFixed(2) }}
          </h3>
          <p class="fs-sm fw-medium">
            <span class="text-muted">
              {{
                $t("price-total", {
                  currency: roomItem.currency,
                  price: (roomItem.totalPrice * roomNum).toFixed(2),
                })
              }}</span
            >
          </p>
          <p class="fs-sm fw-medium mb-2">
            <span class="text-muted"
              >{{ $t("for-room", { roomNum: roomNum }) }}(s)</span
            >
          </p>
          <p class="fs-sm fw-medium mb-2">
            <span class="text-muted">{{ $t("includes-taxes-fees") }}</span>
          </p>

          <a
            data-bs-toggle="modal"
            :data-bs-target="'#' + getDailyPriceModelKey(modelIndex)"
            class="text-pointer"
            style="cursor: pointer"
            >{{ $t("price-details") }}<i class="fa fa-angle-right"></i
          ></a>
        </div>
        <div style="align-self: center">
          <button
            type="button"
            class="btn btn_theme btn_sm"
            @click="$emit('submit', roomItem, roomNum)"
          >
            {{ $t("reserve") }}
          </button>
        </div>
      </div>
    </td>
  </tr>
  <!-- Daily Rates Slide Left Block Modal -->
  <div
    class="modal fade"
    :id="getDailyPriceModelKey(modelIndex)"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="getDailyPriceModelKey(modelIndex)"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div>
        <BaseDailyRatesContent
          :title="'Daily Prices'"
          :room-type-name="name"
          :items="roomItem.dailyRates"
          :totalPrice="roomItem.totalPrice"
          :currency="roomItem.currency"
          :roomNum="roomNum"
          :rate-description="roomItem.rateDescription"
        ></BaseDailyRatesContent>
      </div>
    </div>
  </div>
  <!-- END Daily Rates Slide Left Block Modal -->
</template>

<style scoped lang="scss"></style>
