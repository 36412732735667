<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { Empty, Modal, Popover } from "ant-design-vue";
import { useTemplateStore } from "@/stores/template";
import { useFlightAncillaryServiceStore } from "@/stores/v2/flight/ancillaryservice/flightAncillaryServiceStore";
import FlightSeatSelectedPanel from "@/views/v2/sales/flight/items/panel/ancillaryservice/FlightSeatSelectedPanel.vue";
import flightAncillaryServiceTools from "@/tools/v2/flight/ancillaryservice/flightAncillaryServiceTools";

const route = useRoute();
const props = defineProps({
  airItineraryRPH: {},
  tripAirItem: {},
  passengerFormModelItems: {},
  flightPriceFormModel: {},
});
const APopover = Popover;
const AEmpty = Empty;
const AModal = Modal;
const emits = defineEmits();
const templateStore = useTemplateStore();
const ancillaryServiceStore = useFlightAncillaryServiceStore();

const flightPriceFormModelRef = ref(null);
const tripSegmentsRef = ref([]);
const currentSegmentRef = ref({});
const currentPassengerRef = ref({});

const ancillaryOptionsRef = ref(null);
const ancillaryServiceMenuRef = ref(null);

const paxSegmentSeatOptionsRef = ref(null);

watch(
  () => props.passengerFormModelItems,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      currentPassengerRef.value = newValue[0];
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => props.tripAirItem,
  (newValue) => {
    tripSegmentsRef.value = [];
    if (newValue && newValue.flightItems && newValue.flightItems.length > 0) {
      newValue.flightItems.forEach((item, index) => {
        tripSegmentsRef.value.push(...item.flightSegments);
        if (index === 0)
          currentSegmentRef.value =
            item.flightSegments && item.flightSegments.length > 0
              ? item.flightSegments[0]
              : {};
      });
    }
  },
  { deep: true, immediate: true }
);
watch(
  () => props.flightPriceFormModel,
  (newValue) => {
    flightPriceFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

const seatMapOptionsRef = ref({});

watch(
  () => ancillaryServiceStore.seatMaps,
  (newValue) => {
    if (newValue) {
      for (const seatMapIndex in newValue) {
        const seatMap = newValue[seatMapIndex];
        const itinerarySegmentRPH = seatMap.itinerarySegmentRPH;
        //每个航段飞机上的座位可选集合
        seatMapOptionsRef.value[itinerarySegmentRPH] = seatMap.cabinClasses;
      }
    }
  },
  { immediate: true, deep: true }
);
watch(
  () => ancillaryServiceStore.ancillaryServiceMenu,
  (newValue) => {
    ancillaryServiceMenuRef.value = newValue;
  },
  { immediate: true, deep: true }
);
watch(
  () => ancillaryServiceStore.ancillaryOptions,
  (newValue) => {
    ancillaryOptionsRef.value = newValue;
    if (newValue) {
      paxSegmentSeatOptionsRef.value =
        flightAncillaryServiceTools.findSpecificAncillaryOptions(
          newValue,
          "SEAT",
          "189C",
          currentSegmentRef.value.rph,
          currentPassengerRef.value.travelerRefNumberRPH
        );
    }
  },
  { immediate: true, deep: true }
);

//region 辅助函数

//切换当前航段和乘客
function changeSegmentAndPassenger(
  segmentRPH,
  travelerRPH,
  currentTaxonomyCode
) {
  paxSegmentSeatOptionsRef.value =
    flightAncillaryServiceTools.findSpecificAncillaryOptions(
      ancillaryOptionsRef.value,
      "SEAT",
      "189C",
      segmentRPH,
      travelerRPH
    );
}

function closeSeatSectionModalPanel() {
  emits("closeSeatSectionModalPanel");
}

function getCabinClassName(
  seatInfo,
  rowNumber,
  paxSegmentSeatOptions,
  servicesSelected,
  segmentKey,
  paxKey
) {
  //判断该座位是否已选择
  if (servicesSelected && servicesSelected.length > 0) {
    let seatSelected = servicesSelected.find(
      (serviceItem) =>
        seatInfo.columnNumber === serviceItem.seatColumnNumber &&
        rowNumber === serviceItem.seatRowNumber &&
        serviceItem.flightSegmentRPH === segmentKey
    );
    if (seatSelected) return "sk-seat-selected";
  }

  if (paxSegmentSeatOptions && paxSegmentSeatOptions.length > 0) {
    let match = false;
    const services = seatInfo.services;
    for (const paxSegmentSeatOption of paxSegmentSeatOptions) {
      const ancillaryId = paxSegmentSeatOption.ancillaryId;
      const service = services.find((value) => value.code === ancillaryId);
      if (!service) continue;
      match = true;
    }
    if (!match) return "seat-unavailable";
  } else {
    return "seat-unavailable";
  }

  if (seatInfo && seatInfo.summary) {
    if (!seatInfo.summary.availableInd) return "seat-unavailable";
  } else {
    if (seatInfo && seatInfo.availability === "SeatOccupied")
      return "seat-unavailable";
  }

  //其余情况默认样式
  return "seat-available";
}

function reformat(rowInfos) {
  if (!rowInfos) return null;

  //to fix SQ seat map structure
  //if exists seat type in first row
  let seatContain = false;
  // if (
  //   rowInfos[0].seatInfos &&
  //   rowInfos[0].seatInfos[0].componentType !== "SEAT"
  // )
  //   return rowInfos;
  if (rowInfos[0].seatInfos) {
    rowInfos[0].seatInfos.forEach((seatInfo) => {
      if (seatInfo.componentType === "SEAT") {
        seatContain = true;
      }
    });

    if (!seatContain) return rowInfos;
  }

  let parse = JSON.parse(JSON.stringify(rowInfos));
  let header = JSON.parse(JSON.stringify(parse[0]));
  header.rowNumber = null;

  if (header.seatInfos && header.seatInfos.length > 0) {
    header.seatInfos.forEach((seatInfo) => {
      seatInfo.componentType = "CUSTOM_HEADER";
    });
  }

  parse.unshift(header);

  return parse;
}

function selectSeat(
  seatInfo,
  rowNumber,
  flightPriceFormModel,
  paxSegmentSeatOptions,
  flightSegmentRPH,
  travelerRPH
) {
  if (paxSegmentSeatOptions && paxSegmentSeatOptions.length > 0) {
    for (const paxSegmentSeatOption of paxSegmentSeatOptions) {
      const travelerRPHCompare = paxSegmentSeatOption.travelerRPH;
      const flightSegmentRPHCompare = paxSegmentSeatOption.flightSegmentRPH;

      if (
        flightSegmentRPHCompare.indexOf(flightSegmentRPH) !== -1 &&
        travelerRPH === travelerRPHCompare
      ) {
        const paxSegmentSeatOptionCopy = JSON.parse(
          JSON.stringify(paxSegmentSeatOption)
        );
        const ancillaryId = paxSegmentSeatOption.ancillaryId;
        const services = seatInfo.services;
        const service = services.find((value) => value.code === ancillaryId);
        if (!service) continue;

        paxSegmentSeatOptionCopy.seatColumnNumber = seatInfo.columnNumber;
        paxSegmentSeatOptionCopy.seatRowNumber = rowNumber;

        flightPriceFormModel.updateServiceSelected(paxSegmentSeatOptionCopy);
      }
    }
  }
}

function getSeatInfoView(paxSegmentSeatOptions, seatInfo) {
  let seatInfoShow = null;
  if (paxSegmentSeatOptions && paxSegmentSeatOptions.length > 0) {
    const services = seatInfo.services;
    for (const paxSegmentSeatOption of paxSegmentSeatOptions) {
      const ancillaryId = paxSegmentSeatOption.ancillaryId;
      const service = services.find((value) => value.code === ancillaryId);
      if (!service) continue;
      seatInfoShow = paxSegmentSeatOption;
    }
  }
  return seatInfoShow;
}

//endregion
</script>

<template>
  <div class="seat-selection p-5">
    <h3>{{ $t("seat-selection") }}</h3>

    <div class="row">
      <div class="col-4 border-end">
        <div class="row">
          <div
            v-if="tripSegmentsRef && tripSegmentsRef.length > 0"
            class="col-6"
          >
            <label class="form-label" for="example-text-input"
              >{{ $t("current-segment") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <select
              v-model="currentSegmentRef"
              aria-label="Floating label select example"
              class="form-select"
              @change="
                changeSegmentAndPassenger(
                  currentSegmentRef.rph,
                  currentPassengerRef.travelerRefNumberRPH,
                  ancillaryServiceStore.currentTaxonomyCode
                )
              "
            >
              <option
                v-for="(segment, segIndex) in tripSegmentsRef"
                :key="segIndex"
                :selected="segIndex === 0"
                :value="segment"
              >
                {{ $t("segment") }} {{ segIndex + 1 }}:
                {{ segment.departureAirportCode }}-{{
                  segment.arrivalAirportCode
                }}
              </option>
            </select>
          </div>
          <div class="col-6">
            <label class="form-label" for="example-text-input"
              >{{ $t("current-passenger") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <select
              v-model="currentPassengerRef"
              aria-label="Floating label select example"
              class="form-select"
              @change="
                changeSegmentAndPassenger(
                  currentSegmentRef.rph,
                  currentPassengerRef.travelerRefNumberRPH,
                  ancillaryServiceStore.currentTaxonomyCode
                )
              "
            >
              <option
                v-for="(passenger, psIndex) in passengerFormModelItems"
                :key="psIndex"
                :value="passenger"
              >
                {{ $t("passenger") }} {{ psIndex + 1 }} :
                {{ passenger.travelerRefNumberRPH }} -
                {{ passenger.passengerTypeCode }}
              </option>
            </select>
          </div>
        </div>

        <div class="seat-selected-summary">
          <FlightSeatSelectedPanel
            :flight-price-form-model="flightPriceFormModelRef"
            :passengers="passengerFormModelItems"
            :segments="tripSegmentsRef"
            :id-context="tripAirItem ? tripAirItem.supplierCode : null"
            :services-selected="flightPriceFormModelRef.servicesSelected"
            @closeSeatSectionModalPanel="closeSeatSectionModalPanel"
          />
        </div>
      </div>
      <!--座位选择区域  -->
      <div class="col-8">
        <div id="sk-seat-container">
          <!--    Seat Info-->
          <div class="sk-seat-option">
            <ul class="sk-seat-option-item-container">
              <li class="sk-seat-option-item">
                <div class="sk-seat-option-item-tag seat-available"></div>
                <div class="sk-seat-option-item-description">
                  {{ $t("available") }}
                </div>
              </li>
              <li class="sk-seat-option-item">
                <div class="sk-seat-option-item-tag seat-unavailable"></div>
                <div class="sk-seat-option-item-description">
                  {{ $t("unavailable") }}
                </div>
              </li>
              <li class="sk-seat-option-item">
                <div class="sk-seat-option-item-tag sk-seat-selected"></div>
                <div class="sk-seat-option-item-description">
                  {{ $t("selected") }}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div
          v-if="seatMapOptionsRef[currentSegmentRef.rph]"
          id="sk-seat-container"
        >
          <!--    Seat Option Table-->
          <div
            v-for="(
              segmentSeatMapOption, segmentSeatMapOptionIndex
            ) in seatMapOptionsRef[currentSegmentRef.rph]"
            :key="segmentSeatMapOptionIndex"
            class="sk-seat-map"
          >
            <div
              v-if="
                segmentSeatMapOption.rowInfo && segmentSeatMapOptionIndex === 0
              "
              class="sk-seat-front"
            >
              {{ $t("front") }}
            </div>
            <div
              v-for="(rowInfo, rowIndex) in reformat(
                segmentSeatMapOption.rowInfo
              )"
              :key="rowIndex"
              class="sk-seat-row"
            >
              <!--    行标签      -->
              <div class="sk-seat-header">
                <div v-if="rowIndex === 0"></div>
                <div v-else-if="rowInfo.rowNumber">
                  {{ rowInfo.rowNumber }}
                </div>
              </div>
              <div
                v-for="(seatInfo, seatInfoIndex) in rowInfo.seatInfos"
                :key="seatInfoIndex"
                class="sk-seat-column"
              >
                <!--    列标签      -->
                <div v-if="rowIndex === 0" class="sk-aisle">
                  {{ seatInfo.columnNumber }}
                </div>
              </div>
              <div
                v-for="(seatInfo, seatInfoIndex) in rowInfo.seatInfos"
                :key="seatInfoIndex"
                class="sk-seat-column"
              >
                <!--    列标签      -->
                <!--            <div class="sk-sea-header" v-if="seatInfoIndex === 0"></div>-->

                <div
                  v-if="seatInfo.componentType === 'SEAT'"
                  :class="[
                    getCabinClassName(
                      seatInfo,
                      rowInfo.rowNumber,
                      paxSegmentSeatOptionsRef,
                      flightPriceFormModelRef.servicesSelected,
                      currentSegmentRef.rph,
                      currentPassengerRef.travelerRefNumberRPH
                    ),
                  ]"
                  class="sk-seat"
                  @click="
                    selectSeat(
                      seatInfo,
                      rowInfo.rowNumber,
                      flightPriceFormModelRef,
                      paxSegmentSeatOptionsRef,
                      currentSegmentRef.rph,
                      currentPassengerRef.travelerRefNumberRPH
                    )
                  "
                >
                  <a-popover placement="right" :z-index="22222">
                    <template #content>
                      <div
                        v-if="
                          getSeatInfoView(paxSegmentSeatOptionsRef, seatInfo)
                        "
                      >
                        <div class="font-18 font_weight">
                          {{
                            getSeatInfoView(paxSegmentSeatOptionsRef, seatInfo)
                              .name
                          }}
                        </div>
                        <div class="text-center text-info h5">
                          {{
                            getSeatInfoView(paxSegmentSeatOptionsRef, seatInfo)
                              .currencyCode
                          }}
                          {{
                            getSeatInfoView(
                              paxSegmentSeatOptionsRef,
                              seatInfo
                            ).amount.toFixed(2)
                          }}
                        </div>
                      </div>
                    </template>
                    {{ seatInfo.summary.seatNumber }}
                  </a-popover>
                </div>

                <div v-else-if="rowIndex > 0" class="sk-aisle"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center mg-t-20">
          <AEmpty :title="$t('no-seats-available')"></AEmpty>
        </div>
      </div>
      <!--座位选择区域END -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#sk-seat-container {
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 25px;
  align-items: flex-start;

  .sk-seat-front {
    display: flex;
    justify-content: center;
    color: #b0b0b0;
    border-radius: 3px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #f5f5f5;
    width: 100%;
    margin-bottom: 10px;
  }

  .sk-seat-map {
    .sk-seat-row {
      display: flex;

      .sk-seat-column {
        .sk-seat {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 42px;
          width: 42px;
          margin: 4px;
          box-sizing: border-box;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          user-select: none;
          transition: opacity 0.1s ease-in-out;
          color: white;

          &:hover {
            font-size: 120%;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }

        .sk-aisle {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 42px;
          width: 42px;
          margin: 4px;
          box-sizing: border-box;
          user-select: none;
          transition: opacity 0.1s ease-in-out;
        }
      }
    }

    .sk-seat-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: 42px;
      margin: 4px;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      user-select: none;
      transition: opacity 0.1s ease-in-out;
    }
  }

  .sk-seat-option {
    .sk-seat-selected-option-container {
      width: 320px;
      margin-bottom: 20px;
    }

    .sk-seat-selected-option-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 10px 10px;

      .sk-seat-selected-option-title {
        font-weight: bold;
        margin: 0;
      }
    }

    .sk-seat-selected-option-footer {
      display: flex;
      flex-direction: column;

      .sk-seat-selected-option-total {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        margin: 0 10px 15px;
        padding: 0;
        text-align: right;
      }

      .sky-seat-selected-option-button {
        width: 100%;
        background-color: #e53c4a;
        color: white;
        border-radius: 4px;
        transition: background-color 0.15s ease-in-out;
        user-select: none;
        border: none;
        cursor: pointer;
        font-size: 14px;
        height: 35px;
        margin-bottom: 2px;
      }
    }

    .sk-seat-selected-option {
      height: 45px;
      margin-bottom: 15px;
      overflow: auto;
      border: 1px solid #bbb;
      border-radius: 5px;

      .sk-seat-selected-option-table {
        display: table;
        border-collapse: separate;
        box-sizing: border-box;
        text-indent: initial;
        border-spacing: 2px;
        border-color: gray;
        width: 100%;

        td {
          text-align: center;
          vertical-align: middle;
          padding: 2px;
        }

        .sc-main-container {
          display: flex;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 16px;
          padding: 25px;
          align-items: flex-start;
        }

        .sk-seat-ticket {
          display: flex;
          justify-content: center;
          font-size: 14px;
          height: 35px;
          border: 2px solid black;
          box-sizing: border-box;
          border-radius: 4px;

          .sk-seat-ticket-stripes {
            width: 8px;
            background: repeating-linear-gradient(
              45deg,
              black,
              black 3px,
              transparent 3px,
              transparent 6px
            );
          }

          .sk-seat-ticket-label {
            border-right: 1px dashed black;
            box-sizing: border-box;
            flex: 1;
          }

          .sk-seat-ticket-type {
            flex: 2;
          }

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
          }
        }
      }
    }

    .sk-seat-option-item-container {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;

      .sk-seat-option-item {
        margin-left: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 0;

        .sk-seat-option-item-tag {
          width: 22px;
          height: 22px;
          border-radius: 5px 5px 0 0;
        }

        .sk-seat-option-item-description {
          margin: 0 0 0 10px;
        }

        p {
          display: block;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
        }
      }
    }
  }
}

.sk-selected-passenger {
  border-color: #0b80b7 !important;
}

.sk-ticket-btn {
  border-radius: 4px;
  transition: background-color 0.15s ease-in-out;
  user-select: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  height: 35px;
}

.sk-ticket-btn-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e53c4a;
  height: 22px;
  width: 22px;
}

.sk-ticket-btn-icon {
  height: 3px;
  width: 12px;
  border-radius: 5px;
  background-color: white;
}

.seat-unavailable {
  background-color: rgba(168, 176, 188, 0.8) !important;
  pointer-events: none;
}

.sk-seat-selected {
  background-color: rgba(60, 69, 84, 0.8) !important;
}

.seat-available {
  background-color: #43aa8b !important;
}
</style>
