import axios from "@/utils/axiosUtils.js";

/*
 * 酒店地点自动补全
 * @param keyword string 必填，地点关键字，自动补全采用prefix匹配模式
 * @param language string 可选，响应内容的语言内容，默认为en
 */
function hotelPlaces(keyword, language) {
  language = language == null ? "en" : language;
  let url = "/api/v3/content/hotel/places";
  return axios.get(url, {
    params: {
      language: language,
      keyword: keyword,
      type: "http",
    },
  });
}

function childrenPlaces(placeId) {
  let url = "/api/v3/content/hotel/places/" + placeId + "/children";
  return axios.get(url);
}

export default {
  hotelPlaces,
  childrenPlaces,
};
