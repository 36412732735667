<script setup>
import Banner from "@/components/custom/Banner.vue";
import { reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useCmsContentStore } from "@/stores/cms";

const navigation = reactive({
  title: "Booking Success",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Booking Success",
    },
  ],
});

const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();

const recordLocator = route.query.recordLocator;
const idContext = route.query.idContext;
const tripAirItemId = route.query.tripAirItemId;
const tripId = route.query.tripId;

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);

//endregion cms内容管理

function viewBookingDetails() {
  router.push({
    name: "sales-booking-details",
    query: {
      bookingId: tripId,
    },
  });
}
</script>

<template>
  <!-- Common Banner Area -->
  <!-- Banner Area -->
  <Banner
    :background-image-url="backgroundImageUrlRef"
    :navigate="navigation"
  />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row margin-top-20">
        <div class="col-lg-8" style="margin-left: 15%">
          <div class="tou_booking_form_Wrapper">
            <div class="tour_booking_form_box mb-4">
              <div class="booking_success_arae">
                <div class="booking_success_img">
                  <img src="@/assets/img/icon/right.png" alt="img" />
                </div>
                <div class="booking_success_text">
                  <h3>
                    {{ $t("booking-confirmed", { bookingId: tripId }) }}
                  </h3>
                  <h6>
                    {{ $t("check-email-info") }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <a class="btn btn_theme btn_md mg-r-8" @click="router.push('/')">{{
            $t("go-to-home")
          }}</a>
          <a @click="viewBookingDetails" class="btn btn_theme btn_md">{{
            $t("view-your-booking")
          }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
