<script setup>
import dateUtils from "@/utils/dateUtils";
import { useFlightStore } from "@/stores/flight";
import { useTemplateStore } from "@/stores/template";
import { Modal } from "ant-design-vue";
import { ref } from "vue";
import BaseBookingFlightItineraryItem from "@/views/v2/sales/booking/items/flight/group/BaseBookingFlightItineraryItem.vue";

const AModal = Modal;
const flightStore = useFlightStore();
const templateStore = useTemplateStore();
let props = defineProps({
  flightViewItems: {},
  itineraryIndex: {},
  offerViewItemMap: {
    type: Object,
    default: null,
  },
  airportCityMap: {},
});

function getDuration(start, end) {
  let startDate = new Date(start);
  let endDate = new Date(end);
  let durationInMS = endDate.getTime() - startDate.getTime();
  return dateUtils.formatHourMinute(durationInMS / 1000 / 60);
}

function getAirlinePicture(airlineCode) {
  return "http://images.skywidetech.com/airlines/" + airlineCode + ".png";
}

//城市名
function getAirportCityName(code) {
  if (!props.airportCityMap || !props.airportCityMap[code]) return code;
  //可以通过语言获取不同类型的名字
  return props.airportCityMap[code].cityName;
}

//航空公司
function getAirlineName(airlineCode) {
  let airlineNameKey = "airlineName." + airlineCode;
  return airlineNameKey;
}

function getAirportName(airportCode) {
  let airportNameKey = "airport." + airportCode;
  return airportNameKey;
}

function getAircraftName(aircraftCode) {
  let aircraftNameKey = "aircraft." + aircraftCode;
  return aircraftNameKey;
}

let fareRulesShow = ref(false);

let fareRuleRef = ref();

function seeFareRules(flightViewItem) {
  let ruleKey =
    flightViewItem.departureDateTime.split("T")[0] +
    ":" +
    flightViewItem.originLocation +
    "-" +
    flightViewItem.destinationLocation;
  if (flightStore.flightRuleViewItemMap) {
    const ruleDescriptions =
      flightStore.flightRuleViewItemMap[ruleKey].ruleDescriptions;
    if (ruleDescriptions && ruleDescriptions.length > 0) {
      let ruleDescription = ruleDescriptions.find(
        (rule) => rule.subCode === "16"
      );
      if (ruleDescription) {
        fareRuleRef.value = ruleDescription.content;
      }
    }
    fareRulesShow.value = true;
  }
}
</script>

<template>
  <div
    v-for="(flightItem, flightIndex) in flightViewItems"
    :key="flightIndex"
    class="itinerary-item"
  >
    <BaseBookingFlightItineraryItem
      :flight-index="flightIndex"
      :flight-item="flightItem"
      :flight-num="flightViewItems.length"
      :price-class="null"
    />
  </div>
</template>

<style scoped></style>
