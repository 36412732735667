<script setup>
import { ref, watch } from "vue";
import { useTripStore } from "@/stores/v2/trip/tripStore";
import ReservationContactInfoPanel from "@/views/v2/sales/booking/items/panel/common/reservation/ReservationContactInfoPanel.vue";
import FlightPassengerInfoPanel from "@/views/v2/sales/booking/items/panel/flight/reservation/items/panel/FlightPassengerInfoPanel.vue";
import FlightPriceSummaryOrBreakdownPanel from "@/views/v2/sales/flight/items/panel/FlightPriceSummaryOrBreakdownPanel.vue";
import { useAirContentStore } from "@/stores/v2/content/airContentStore";
import { FlightModifyFormModel } from "@/formmodel/sales/flight/modify/flightModifyFormModel";
import { TripAirItemPaymentDetailVO } from "@/viewobject/trip/flight/payment/tripAirItemPaymentDetailVO";
import FlightItinararyInfoPanel from "@/views/v2/sales/flight/items/panel/FlightItinararyInfoPanel.vue";
import BookingOperationPanel from "@/views/v2/sales/booking/commom/BookingOperationPanel.vue";
import FlightServicesPriceSummaryPanel from "@/views/v2/sales/flight/items/panel/FlightServicesPriceSummaryPanel.vue";
import BookingOverViewPanel from "@/views/v2/sales/booking/commom/BookingOverViewPanel.vue";

const props = defineProps({
  recordLocator: {},
  idContext: {},
  tripId: {},
  tripItemId: {},
  tripItemType: {},
  tripAirItemId: {
    default: null,
  },
  isFromTripList: {},
  equipmentType: {},
});

const emits = defineEmits();
const tripStore = useTripStore();
const airContentStore = useAirContentStore();
airContentStore.findAircraftsMap();
airContentStore.findAirlineMap();

const tripAirItemRef = ref(null);
const tripAirPriceClassesRef = ref(null);
const airlineCapabilityRef = ref([]);

//用于service summary部分管理offer
const flightModifyFormModelRef = ref(
  new FlightModifyFormModel(
    null,
    props.tripId,
    props.tripAirItemId,
    null,
    props.recordLocator,
    props.idContext,
    null,
    new TripAirItemPaymentDetailVO(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  )
);

const itineraryCancelledRef = ref(true);
const ticketVoidedRef = ref(true);
let expireInSecondRef = ref(null);
let isPaymentTimeExpired = ref(false);
let createdDateTimeRef = ref(null);
let bookingStatusRef = ref(null);
watch(
  () => tripStore.tripItems,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      const tripAirItems = newValue[0].tripAirItems;
      createdDateTimeRef.value = newValue[0].createdDateTime;
      bookingStatusRef.value = newValue[0].tripStatus;
      if (tripAirItems && tripAirItems.length > 0) {
        tripAirItemRef.value = tripAirItems[tripAirItems.length - 1];
      }

      //赋值后，再进行AirlineCapability和AirportCityMap搜索,留意tripAirItemRef被修改的地方会导致监听多次
      if (tripAirItemRef.value) {
        //1.获取所有airportCodes
        const flightItems = tripAirItemRef.value.flightItems;

        if (createdDateTimeRef.value && bookingStatusRef.value) {
          let createTime = new Date(createdDateTimeRef.value).getTime();
          if (
            bookingStatusRef.value !== "CANCELLED" &&
            bookingStatusRef.value !== "CONFIRMED"
          ) {
            let nowTime = new Date().getTime();
            expireInSecondRef.value = (createTime - nowTime) / 1000 + 15 * 60;
            if (expireInSecondRef.value < 0) {
              isPaymentTimeExpired.value = true;
            }
          }
        }
        const segments = [];
        if (flightItems && flightItems.length > 0) {
          flightItems.forEach((flight) => {
            segments.push(...flight.flightSegments);
          });
        }

        const airportCodeMap = {};
        if (segments && segments.length > 0) {
          segments.forEach((segment) => {
            if (!airportCodeMap[segment.departureAirportCode]) {
              const departureAirportCode = segment.departureAirportCode;
              airportCodeMap[departureAirportCode] = null;
            }
            if (!airportCodeMap[segment.arrivalAirportCode]) {
              const arrivalAirportCode = segment.arrivalAirportCode;
              airportCodeMap[arrivalAirportCode] = null;
            }
          });
        }
        airContentStore.findAirportCityMap(Object.keys(airportCodeMap));

        //2.将所有选择的附加服务放到flightModifyFormModel中传给需要操作修改附加服务费组件中
        const tripAirItemFare = tripAirItemRef.value.tripAirItemFare;
        if (tripAirItemFare) {
          flightModifyFormModelRef.value.servicesSelected =
            tripAirItemFare.ancillaryFares;
        }
      }
    }
  },
  { deep: true, immediate: true }
);

//region 辅助函数

function buildContactInfo(passengers) {
  if (passengers && passengers.length > 0) {
    const passenger = passengers.find((item) => item.contractPhones);
    const dialAreaCode =
      passenger &&
      passenger.contractPhones &&
      passenger.contractPhones.length > 0
        ? passenger.contractPhones[0].dialAreaCode
        : null;

    const phoneNumber =
      passenger &&
      passenger.contractPhones &&
      passenger.contractPhones.length > 0
        ? passenger.contractPhones[0].phoneNumber
        : null;

    const emailAddress =
      passenger &&
      passenger.contractEmails &&
      passenger.contractEmails.length > 0
        ? passenger.contractEmails[0].emailAddress
        : null;

    return {
      dialAreaCode: dialAreaCode,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
    };
  }
}

//endregion

function syncSessionAndSearch() {
  emits("syncSessionAndSearch");
}
</script>

<template>
  <div v-if="tripAirItemRef" class="row">
    <div class="col-10">
      <!-- booking manage -->
      <BookingOperationPanel
        v-if="
          bookingStatusRef !== 'CANCELLED' &&
          bookingStatusRef !== 'BOOKING_CANCELLED'
        "
        :booking-id="tripId"
        :equipment-type="equipmentType"
        :trip-item-type="tripItemType"
        :booking-status="bookingStatusRef"
        :record-locator="tripAirItemRef.recordLocatorId"
        :invoice-id="tripAirItemRef.invoiceId"
        :id-context="tripAirItemRef.idContext"
        :show-continue-to-payment="
          expireInSecondRef > 0 &&
          tripItemType === 'HOTEL' &&
          bookingStatusRef !== 'CANCELLED' &&
          bookingStatusRef !== 'CONFIRMED' &&
          bookingStatusRef !== 'BOOKING_RECEIVED'
        "
        @syncSessionAndSearch="syncSessionAndSearch"
      />
      <!-- booking manage END-->

      <BookingOverViewPanel
        :trip-air-item="tripAirItemRef"
        :id-context="idContext"
        :is-from-trip-list="isFromTripList"
        :record-locator="recordLocator"
        :trip-air-item-id="tripAirItemId"
        :trip-id="tripId"
        :trip-item-id="tripItemId"
        :airline-capability="airlineCapabilityRef"
        :itinerary-cancelled="itineraryCancelledRef"
        :ticket-voided="ticketVoidedRef"
        :trip-item-type="tripItemType"
        :expire-in-second="expireInSecondRef"
        :created-date-time="createdDateTimeRef"
        :booking-status="bookingStatusRef"
      />

      <!-- Itinerary Info -->

      <FlightItinararyInfoPanel
        :price-classes="tripAirPriceClassesRef"
        :trip-air-item="tripAirItemRef"
      />

      <!-- Itinerary Info END -->

      <!-- contact Info -->
      <ReservationContactInfoPanel
        :contact-info="buildContactInfo(tripAirItemRef.passengers)"
      />

      <!-- contact Info END-->

      <!-- Passenger Info -->
      <FlightPassengerInfoPanel
        :passengers="tripAirItemRef.passengers"
        :contact-info="buildContactInfo(tripAirItemRef.passengers)"
      />
      <!-- Passenger Info END-->

      <!-- Services Summary -->

      <FlightServicesPriceSummaryPanel
        v-if="
          flightModifyFormModelRef.servicesSelected &&
          flightModifyFormModelRef.servicesSelected.length > 0
        "
        :form-model="flightModifyFormModelRef"
        :trip-air-item="tripAirItemRef"
        :is-reservation-page="true"
        :passenger-items="tripAirItemRef.passengers"
        :airline-capability="airlineCapabilityRef"
        :show-title="true"
        :id-context="idContext"
      />

      <!--Services Summary  END -->

      <!-- Price Summary -->
      <FlightPriceSummaryOrBreakdownPanel
        v-if="
          tripAirItemRef &&
          tripAirItemRef.tripAirItemFare &&
          tripAirItemRef.tripAirItemFare.passengerFares
        "
        :is-flight-reservation="true"
        :trip-air-item="tripAirItemRef"
      />
      <!-- Price Summary  END -->
    </div>

    <div class="col-5">
      <!--      <FlightReservationSummaryPanel-->
      <!--        :trip-air-item="tripAirItemRef"-->
      <!--        :id-context="idContext"-->
      <!--        :is-from-trip-list="isFromTripList"-->
      <!--        :record-locator="recordLocator"-->
      <!--        :trip-air-item-id="tripAirItemId"-->
      <!--        :trip-id="tripId"-->
      <!--        :trip-item-id="tripItemId"-->
      <!--        :airline-capability="airlineCapabilityRef"-->
      <!--        :itinerary-cancelled="itineraryCancelledRef"-->
      <!--        :ticket-voided="ticketVoidedRef"-->
      <!--        :trip-item-type="tripItemType"-->
      <!--      />-->
    </div>
  </div>
  <!-- END Page Content -->
</template>

<style lang="css" scoped></style>
