import { TripAirItemAncillaryFareVO } from "@/viewobject/trip/flight/ancillary/tripAirItemAncillaryFareVO";
import { flightAncillaryServiceMenu } from "@/formmodel/sales/flight/ancillaryservice/options/flightAncillaryServiceMenu";

function buildAirGetOfferRQ(flightAncillaryServiceFormModel) {
  const airItineraryRPH = flightAncillaryServiceFormModel.airItineraryRPH;
  const flightSegmentRPHs = flightAncillaryServiceFormModel.flightSegmentRPHs;
  const id = flightAncillaryServiceFormModel.id;
  const type = flightAncillaryServiceFormModel.type;
  const idContext = flightAncillaryServiceFormModel.idContext;

  let airItineraryDetail = null;
  let confirmedBookingInfo = null;
  if (flightSegmentRPHs && flightSegmentRPHs.length > 0) {
    const flightSegments = [];
    for (const flightSegmentRPH of flightSegmentRPHs) {
      flightSegments.push({
        rph: flightSegmentRPH,
      });
    }

    airItineraryDetail = {
      originDestinationOptions: [
        {
          flightSegments: flightSegments,
        },
      ],
    };
  }

  if (id && idContext) {
    confirmedBookingInfo = {
      bookingReferenceIDs: [
        {
          id: id,
          type: type,
          id_context: idContext,
        },
      ],
    };
  }

  let airItinerary = null;
  if (airItineraryDetail) {
    airItinerary = {
      itineraries: [
        {
          airItineraryRPH: airItineraryRPH,
          airItinerary: airItineraryDetail,
        },
      ],
    };
  } else if (airItineraryRPH) {
    airItinerary = {
      itineraries: [
        {
          airItineraryRPH: airItineraryRPH,
        },
      ],
    };
  }

  return {
    requestCriterion: {
      confirmedBookingInfo: confirmedBookingInfo,
      airItinerary: airItinerary,
    },
  };
}

function buildAncillaryOptions(pricedOffers, status) {
  if (!pricedOffers || pricedOffers.length === 0) return [];

  const ancillaryOptions = [];
  for (const pricedOffer of pricedOffers) {
    const name = pricedOffer.name
      ? pricedOffer.name
      : pricedOffer.shortDescription;

    const id = pricedOffer.id;
    const travelerRPH = pricedOffer.travelerRPH;
    const flightSegmentRPH = pricedOffer.flightSegmentRPH;
    const pricing = pricedOffer.pricing;
    const amount = pricing.amount;
    const pricingCurrency = pricing.pricingCurrency;
    const offerQty = pricing.offerQty ? pricedOffer.offerQty : 1;
    const bookingInstruction = pricedOffer.bookingInstruction;
    const serviceFamily = pricedOffer.serviceFamily;
    const multimedia = pricedOffer.multimedia;
    const longDescription = pricedOffer.longDescription;
    let bookingInstructionValue = null;
    let imageUrl = null;
    let type = null;
    let code = null;
    if (
      bookingInstruction &&
      bookingInstruction.ssrinfoList &&
      bookingInstruction.ssrinfoList.length > 0
    ) {
      bookingInstructionValue = bookingInstruction.ssrinfoList[0].text;
    }

    if (
      multimedia &&
      multimedia.length > 0 &&
      multimedia[0].imageItems &&
      multimedia[0].imageItems.length > 0 &&
      multimedia[0].imageItems[0].url
    ) {
      imageUrl = multimedia[0].imageItems[0].url;
    }

    if (serviceFamily) {
      code = serviceFamily.serviceCode;
    }

    if ("189C" === code) {
      type = "SEAT";
    } else {
      type = "SERVICE";
    }

    const airItemAncillaryFareVO = new TripAirItemAncillaryFareVO(
      status,
      type,
      code,
      name,
      pricingCurrency,
      amount,
      id,
      flightSegmentRPH,
      travelerRPH,
      offerQty,
      null,
      null,
      bookingInstructionValue,
      imageUrl
    );

    if (
      longDescription &&
      longDescription.length !== 0 &&
      longDescription[0] &&
      longDescription[0].subSections &&
      longDescription[0].subSections.length > 0
    ) {
      const subSections = longDescription[0].subSections;
      for (const subSection of subSections) {
        const paragraphs = subSection.paragraphs;
        if (!paragraphs || paragraphs.length === 0) continue;

        for (const paragraph of paragraphs) {
          const name = paragraph.name;
          const text = paragraph.text;
          airItemAncillaryFareVO.addNewDescription(name, text);
        }
      }
    }

    if (
      serviceFamily &&
      serviceFamily.productGroups &&
      serviceFamily.productGroups.length > 0
    ) {
      let productGroups = serviceFamily.productGroups;
      for (const productGroup of productGroups) {
        let groupCode = productGroup.code;
        let groupName = productGroup.description;
        let subGroupCode = null;
        let subGroupName = null;
        if (productGroup.subGroups && productGroup.subGroups.length > 0) {
          let subGroup = productGroup.subGroups[0];
          subGroupCode = subGroup.code;
          subGroupName = subGroup.description;
        }

        airItemAncillaryFareVO.addNewGroup(
          groupCode,
          groupName,
          subGroupCode,
          subGroupName
        );
      }
    }

    ancillaryOptions.push(airItemAncillaryFareVO);
  }

  return ancillaryOptions;
}

function findSpecificAncillaryOptions(
  ancillaryOptions,
  type,
  taxonomyCode,
  flightSegmentRPH,
  travelerRPH
) {
  return ancillaryOptions.filter((ancillaryOption) => {
    const code = ancillaryOption.code;
    const typeCompare = ancillaryOption.type;
    const flightSegmentRPHCompare = ancillaryOption.flightSegmentRPH;
    const travelerRPHCompare = ancillaryOption.travelerRPH;

    if (type && type !== typeCompare) {
      return false;
    }

    if (taxonomyCode) {
      let allMatch = false;

      if (code === taxonomyCode) {
        allMatch = true;
      }

      if (
        !allMatch &&
        ancillaryOption.groups &&
        ancillaryOption.groups.length > 0
      ) {
        const groups = ancillaryOption.groups;
        for (const group of groups) {
          if (group.groupCode && group.groupCode === taxonomyCode) {
            allMatch = true;
          } else if (
            group.subGroupCode &&
            group.subGroupCode === taxonomyCode
          ) {
            allMatch = true;
          }
        }
      }

      if (!allMatch) return false;
    }

    if (flightSegmentRPH && flightSegmentRPH !== flightSegmentRPHCompare) {
      return false;
    }

    if (travelerRPH && travelerRPHCompare !== travelerRPH) {
      return false;
    }

    return true;
  });
}

function buildAncillaryServiceMenu(ancillaryOptions) {
  const menu = JSON.parse(JSON.stringify(flightAncillaryServiceMenu));
  iterator(menu, ancillaryOptions);
  return menu;
}

function iterator(array, ancillaryOptions) {
  if (!ancillaryOptions || ancillaryOptions.length === 0) return array;
  for (const menuEle of array) {
    menuEle.quantity = calculateQuantity(menuEle, ancillaryOptions);
    const subGroups = menuEle.subGroups;
    if (subGroups && subGroups.length > 0) {
      iterator(subGroups, ancillaryOptions);
    }
  }
}

function calculateQuantity(ele, ancillaryOptions) {
  const taxonomyCode = ele.code;
  const codesContain = [];
  let quantity = 0;
  for (const ancillaryOption of ancillaryOptions) {
    const code = ancillaryOption.code;
    const name = ancillaryOption.name;
    const key = code + ":" + name;
    if (codesContain.indexOf(key) >= 0) continue;

    if (taxonomyCode === code) {
      codesContain.push(key);
      quantity++;
      continue;
    }

    const groups = ancillaryOption.groups;
    if (groups && groups.length > 0) {
      for (const group of groups) {
        const groupCode = group.groupCode;
        const subGroupCode = group.subGroupCode;
        if (taxonomyCode === groupCode) {
          codesContain.push(key);
          quantity++;
        }
        if (taxonomyCode === subGroupCode) {
          codesContain.push(key);
          quantity++;
        }
      }
    }
  }

  return quantity;
}

export default {
  buildAirGetOfferRQ,
  buildAncillaryOptions,
  buildAncillaryServiceMenu,
  findSpecificAncillaryOptions,
};
