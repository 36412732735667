import { defineStore } from "pinia";
import bookingTools from "@/tools/bookingTools";
import bookingApi from "@/apis/bookingApi";
import router from "@/router/modulesRouter";
import hotelApi from "@/apis/hotelApi";
import contentApi from "@/apis/contentApi";

export const useBookingStore = defineStore("booking", {
  state: () => ({
    bookingViewItems: null,
    totalViewItems: null,
    bookingDetailsViewItem: null,
    resGuestDetails: null,
    passengerInfos: null,
    airportCityMap: null,
    isPaymentTimeExpired: false,
  }),
  actions: {
    async searchBookings(formModel) {
      let readRQ = bookingTools.getReadRQ(formModel);
      const res = await bookingApi.bookingsRetrieve(readRQ).catch((err) => {
        console.error(err);
        router.push({
          name: "error-500",
          query: { message: "booking unavailable" },
        });
      });
      let _this = this;
      if (res) {
        const { data } = res;
        const bookingsRetrieveRS = data;
        this.totalViewItems = bookingsRetrieveRS.totalElements;
        this.bookingViewItems =
          bookingTools.getBookingViewItems(bookingsRetrieveRS);
        if (this.bookingViewItems) {
          this.bookingViewItems.forEach((bookingViewItem) => {
            let airportCodeMap = {};
            if (bookingViewItem.flightItems) {
              bookingViewItem.flightItems.forEach((flightItem) => {
                flightItem.flightViewItems.forEach((flightViewItem) => {
                  flightViewItem.segments.forEach((segment) => {
                    const departureAirportCode = segment.departureAirportCode;
                    const arrivalAirportCode = segment.arrivalAirportCode;
                    airportCodeMap[departureAirportCode] = null;
                    airportCodeMap[arrivalAirportCode] = null;
                  });
                });
              });

              _this.airportCityMap = {};
              for (const airportCodeMapKey in airportCodeMap) {
                contentApi.airportCity(airportCodeMapKey).then((res) => {
                  let data = res.data;
                  if (!_this.airportCityMap) {
                    _this.airportCityMap = {};
                  }
                  _this.airportCityMap[data.code] = data;
                });
              }
            }
          });
        }
      }
    },
    async searchBookingDetails(formModel) {
      let readRQ = bookingTools.getReadRQ(formModel);

      const { data } = await bookingApi.bookingsRetrieve(readRQ).catch(() => {
        router.push({
          name: "error-500",
          query: { message: "booking unavailable" },
        });
      });
      const bookingRetrieveRS = data;
      this.bookingDetailsViewItem =
        bookingTools.getBookingDetailsViewItem(bookingRetrieveRS);

      if (this.bookingDetailsViewItem.flightItems) {
        let airportCodeMap = {};
        this.bookingDetailsViewItem.flightItems.forEach((flightItem) => {
          flightItem.flightViewItems.forEach((flightViewItem) => {
            flightViewItem.segments.forEach((segment) => {
              const departureAirportCode = segment.departureAirportCode;
              const arrivalAirportCode = segment.arrivalAirportCode;
              airportCodeMap[departureAirportCode] = null;
              airportCodeMap[arrivalAirportCode] = null;
            });
          });
        });

        this.airportCityMap = {};
        for (const airportCodeMapKey in airportCodeMap) {
          contentApi.airportCity(airportCodeMapKey).then((res) => {
            let data = res.data;
            this.airportCityMap[data.code] = data;
          });
        }
      }
    },
    async cancelBooking(bookingId) {
      const cancelRQ = bookingTools.getCancelRQ("CANCEL", bookingId);
      const res = await bookingApi.bookingCancel(cancelRQ).catch(() => {
        router.push({
          name: "error-500",
          query: { message: "flight unavailable" },
        });
      });
      const { data } = res;
      return data.success
        ? { success: true, message: "Cancelled Success." }
        : {
            success: false,
            message: data.errors.errors[0].shortText,
          };
    },
    async synchronizeBooking(bookingId, pnr) {
      const synchronizeRQ = bookingTools.getSynchronizeRQ(bookingId, pnr);
      const res = await hotelApi.hotelResModify(synchronizeRQ).catch(() => {
        router.push({
          name: "error-500",
          query: { message: "booking unavailable" },
        });
      });
      const { data } = res;
      // openAlert(shortText, "failed");
      return data.success
        ? { success: true, message: "Synchronize Success." }
        : {
            success: false,
            message: data.errors.errors[0].shortText,
          };
    },
    printVoucher(bookingId, type, itemId) {
      return bookingApi
        .printVoucher({ bookingId, type, itemId })
        .then((res) => {
          return res;
        });
    },
  },
});
