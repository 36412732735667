const timePeriodOptions = [
  {
    label: "Early morning",
    value: "00:00-04:59",
  },
  {
    label: "Morning",
    value: "05:00-11:59",
  },
  {
    label: "Afternoon",
    value: "12:00-17:59",
  },
  {
    label: "Evening",
    value: "18:00-23:59",
  },
];

const travelAndBaggageOptions = [
  {
    label: "Seat choice included",
    value: "SEAT_CHOICE",
  },
  {
    label: "Baggage included",
    value: "Baggage_CHOICE",
  },
  {
    label: "No cancel fee",
    value: "NO_CANCEL_FEE",
  },
  {
    label: "No change fee",
    value: "NO_CHANGE_FEE",
  },
];

export default {
  timePeriodOptions,
  travelAndBaggageOptions,
};
