import { defineStore } from "pinia";
import cmsApi from "@/apis/cmsApi";
import themeColorManagement from '@/data/cms/themeColorManagement.json'
import headerContent from '@/data/cms/headerContent.json'
import contentManagement from '@/data/cms/contentManagement.json'

import promotionManagement from '@/data/cms/promotionManagement.json'
import frontPagePromotionDeals from '@/data/cms/frontPagePromotionDeals.json'
import frontPagePromotionFlights from '@/data/cms/frontPagePromotionFlights.json'
import frontPagePromotionHotels from '@/data/cms/frontPagePromotionHotels.json'
import frontPageOurPartners from '@/data/cms/frontPageOurPartners.json'
import footerContent from '@/data/cms/footerContent.json'
import flightPageHeaderContent from '@/data/cms/flightPageHeaderContent.json'
export const useCmsContentStore = defineStore("cms", {
  state: () => {
    return {
      aboutContent: null,
      productPageBackgroundImage: null,
      contactContent: null,
      advanceQuestions: null,
      regularQuestions: null,
      generalQuestions: null,
      flightHeaderContent: null,
      flightPromotionDeals: null,
      footerContent: null,
      headerContent: null,
      hotelHeaderContent: null,
      hotelPromotionDeals: null,
      ourPartners: null,
      privacyPolicy: null,
      promotionDeals: null,
      flightPromotionPrimaryDeals: null,
      hotelPromotionPrimaryDeals: null,
      promotionManagement: null,
      themeColors: null,
      contentManagement: null,
    };
  },
  actions: {
    getAboutContent() {
      let _this = this;
      cmsApi
        .getAbout()
        .then((res) => {
          _this.aboutContent = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getProductBannerBackground() {
      let _this = this;
      // console.info("search");
      return cmsApi
        .getBannerBackgroundImage()
        .then((res) => {
          // (res.data);
          _this.productPageBackgroundImage = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getContactContent() {
      let _this = this;
      cmsApi
        .getContact()
        .then((res) => {
          _this.contactContent = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getAdvanceQuestions() {
      let _this = this;
      cmsApi
        .getAdvanceQuestions()
        .then((res) => {
          _this.advanceQuestions = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getGeneralQuestions() {
      let _this = this;
      cmsApi
        .getGeneralQuestions()
        .then((res) => {
          _this.generalQuestions = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getRegularQuestions() {
      let _this = this;
      cmsApi
        .getRegularQuestions()
        .then((res) => {
          _this.regularQuestions = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFlightHeaderContent() {
      let _this = this;
      cmsApi
        .getFlightHeaderContent()
        .then((res) => {
          if(res && res.data){
            if(res && res.data){
              _this.flightHeaderContent = res.data;
            }else{
              _this.flightHeaderContent =  flightPageHeaderContent
            }
          }else{
          }

        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFlightPromotionDeals() {
      let _this = this;
      cmsApi
        .getFlightPromotionHotDeals()
        .then((res) => {
         if(res && res.data){
           _this.flightPromotionDeals = res.data;
         }else{
           _this.flightPromotionDeals = frontPagePromotionFlights
         }

        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFooterContent() {
      let _this = this;
      cmsApi
        .getFooterContent()
        .then((res) => {
          if(res && res.data){
            _this.footerContent = res.data;
          }else{
            _this.footerContent =  footerContent
          }

        })
        .catch((err) => {
          console.error(err);
        });
    },
    getHeaderContent() {
      let _this = this;
      cmsApi
        .searchHeaderContent()
        .then((res) => {
          if(res && res.data){
            _this.headerContent = res.data;
          }else{
            _this.headerContent = headerContent
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getHotelHeaderContent() {
      let _this = this;
      cmsApi
        .getHotelHeaderContent()
        .then((res) => {
          _this.hotelHeaderContent = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getHotelPromotionDeals() {
      let _this = this;
      cmsApi
        .getHotelPromotionHotDeals()
        .then((res) => {
          _this.hotelPromotionDeals = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getOurPartner() {
      let _this = this;
      cmsApi
        .getPartners()
        .then((res) => {
          if(res && res.data){
            _this.ourPartners = res.data;
          }else{
            _this.ourPartners = frontPageOurPartners
          }

        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPrivacyPolicy() {
      let _this = this;
      cmsApi
        .getPrivacyPolicyApi()
        .then((res) => {
          _this.privacyPolicy = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPromotionDeals() {
      let _this = this;
      cmsApi
        .getPromotionDeals()
        .then((res) => {
          if(res && res.data){
            _this.promotionDeals = res.data;
          }else{
            _this.promotionDeals = frontPagePromotionDeals;
          }

        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFlightPromotionPrimaryDeals() {
      let _this = this;
      cmsApi
        .getPromotionFlights()
        .then((res) => {
          if(res && res.data){
            _this.flightPromotionPrimaryDeals = res.data;
          }else{
            _this.flightPromotionPrimaryDeals = frontPagePromotionFlights;
          }

        })
        .catch((err) => {
          console.error(err);
        });
    },
    getHotelPromotionPrimaryDeals() {
      let _this = this;
      cmsApi
        .getPromotionHotels()
        .then((res) => {
          if(res && res.data){
            _this.hotelPromotionPrimaryDeals = res.data;
          }else{
            _this.hotelPromotionPrimaryDeals = frontPagePromotionHotels;

          }

        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPromotionManagements() {
      let _this = this;
      cmsApi
        .getPromotionManagement()
        .then((res) => {

          if (res && res.data){
            _this.promotionManagement = res.data;
          }else{
            _this.promotionManagement = promotionManagement
          }

        })
        .catch((err) => {
          console.error(err);
        });
    },
    getThemeColorManagement() {
      let _this = this;
      return cmsApi
        .getThemeColorManagement()
        .then((res) => {
          if(res &&  res.data){
            _this.themeColors = res.data;
            return res.data;
          }else{
            _this.themeColors =   themeColorManagement
            return themeColorManagement
          }


        })
        .catch((err) => {
          console.error(err);
        });
    },
    getContentManagements() {
      let _this = this;
      cmsApi
        .getContentManagement()
        .then((res) => {
          if(res && res.data){
            _this.contentManagement = res.data;
          }else{
            _this.contentManagement = contentManagement
          }

        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
});
