<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { email, integer, minLength, required } from "@vuelidate/validators";
import { VueTelInput } from "vue3-tel-input";
import useVuelidate from "@vuelidate/core";
import "vue3-tel-input/dist/vue3-tel-input.css";

const props = defineProps({
  profileFormModel: {},
});

const emits = defineEmits([]);
const profileFormModelRef = ref(null);

watch(
  () => props.profileFormModel,
  (newValue) => {
    profileFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);
const rules = computed(() => {
  return {
    surname: {
      required,
      minLength: minLength(2),
    },
    givenName: {
      required,
      minLength: minLength(2),
    },
    emailAddress: {
      required,
      email,
    },
    phoneNumber: {
      required,
      integer,
    },
  };
});
// Use vuelidate
const v$ = useVuelidate(rules, profileFormModelRef);

function phoneNUmberInput(value, event) {
  if (
    event != null &&
    event.nationalNumber != null &&
    event.nationalNumber != ""
  ) {
    profileFormModelRef.value.phoneNumber = event.nationalNumber;
  } else if (
    event != null &&
    event.nationalNumber == null &&
    event.formatted != null
  ) {
    profileFormModelRef.value.phoneNumber = event.formatted;
  }
}

function phoneBlur() {
  validatePhoneNumber();
}

function countryChange(value) {
  profileFormModelRef.value.countryAccessCode = value.dialCode;
}

function validatePhoneNumber() {
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
}

onMounted(() => {
  let phoneEle = document.getElementById("phoneEle");
  phoneEle.style.border = "none";
  for (let i = 0; i < phoneEle.children.length; i++) {
    let childListElement = phoneEle.children[i];
    // (childListElement);
    // (childListElement.tagName);
    if (childListElement.tagName === "INPUT") {
      childListElement.classList.add("form-control");
    }
  }
});

async function modifyProfile(profileFormModel) {
  const result = await v$.value.$validate();
  validatePhoneNumber();
  if (!result) {
    return;
  }
  emits("modifyProfile", profileFormModel);
}
</script>

<template>
  <form
    action="!#"
    id="profile_form_area"
    @submit.prevent="modifyProfile(profileFormModelRef)"
  >
    <div class="row" v-if="profileFormModelRef">
      <div class="col-lg-6">
        <div class="form-group">
          <label for="f-name">{{ $t("first-name") }}</label>
          <input
            type="text"
            class="form-control"
            id="profile-surname"
            name="profile-surname"
            :class="{
              'is-invalid': v$.surname.$errors.length,
            }"
            @blur="v$.surname.$touch"
            placeholder="Your Name"
            v-model="profileFormModelRef.surname"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label for="l-name">{{ $t("last-name") }}</label>
          <input
            type="text"
            class="form-control"
            id="profile-givenName"
            name="profile-givenName"
            :class="{
              'is-invalid': v$.givenName.$errors.length,
            }"
            @blur="v$.givenName.$touch"
            v-model="profileFormModelRef.givenName"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label for="mail-address">{{ $t("email-address") }}</label>
          <input
            type="text"
            class="form-control"
            id="profile-emailAddress"
            name="profile-emailAddress"
            :class="{
              'is-invalid': v$.emailAddress.$errors.length,
            }"
            @blur="v$.emailAddress.$touch"
            v-model="profileFormModelRef.emailAddress"
            placeholder="(e.g. xxxxxxx@gmail.com)"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label for="mobil-number">{{ $t("phone-number") }}</label>
          <VueTelInput
            mode="international"
            :class="{
              'is-invalid': v$.phoneNumber.$errors.length > 0,
            }"
            id="phoneEle"
            autocomplete="off"
            :autoFormat="false"
            :value="profileFormModelRef.phoneNumber"
            @input="phoneNUmberInput"
            @blur="phoneBlur"
            @country-changed="countryChange"
          />
        </div>
      </div>
      <!--        <div class="col-lg-6">-->
      <!--          <div class="form-group">-->
      <!--            <label for="mobil-number">Password</label>-->
      <!--            <input-->
      <!--              type="password"-->
      <!--              class="form-control"-->
      <!--              id="profile-password"-->
      <!--              placeholder="Enter your password"-->
      <!--              name="profile-password"-->
      <!--              :class="{-->
      <!--                'is-invalid': v$.password.$errors.length,-->
      <!--              }"-->
      <!--              @blur="v$.password.$touch"-->
      <!--              v-model="profileFormModelRef.password"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <div class="col-lg-12" align="center" style="padding-top: 35px">
        <button class="btn btn_theme btn_md">{{ $t("submit") }}</button>
      </div>
      <!-- change password-->
      <!--        <div class="col-lg-6">-->
      <!--          <div class="form-group change_password_field">-->
      <!--            <label for="password">Password</label>-->
      <!--            <input-->
      <!--              type="password"-->
      <!--              class="form-control"-->
      <!--              id="password"-->
      <!--              value="cdkdkdd"-->
      <!--            />-->
      <!--            <p>Change password</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="change_password_input_boxed">-->
      <!--          <h3>Change password</h3>-->
      <!--          <div class="row">-->
      <!--            <div class="col-lg-6">-->
      <!--              <div class="form-group">-->
      <!--                <input-->
      <!--                  type="password"-->
      <!--                  class="form-control"-->
      <!--                  placeholder="Old Password"-->
      <!--                />-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="col-lg-6">-->
      <!--              <div class="form-group">-->
      <!--                <input-->
      <!--                  type="password"-->
      <!--                  class="form-control"-->
      <!--                  placeholder="New Password"-->
      <!--                />-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
    </div>
  </form>
</template>

<style scoped></style>
