<script setup>
import { ref, watch } from "vue";

import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import HotelRoomTypeRoomItem from "@/views/v2/sales/hotel/items/group/HotelRoomTypeRoomItem.vue";

const props = defineProps({
  roomTypeItem: {},
  roomTypeIndex: {},
  hotelAvailFormModel: {},
});

const { t, locale } = useI18n(); // 解构调用函数
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();

const isAllShowRef = ref(false);

const roomsShowRef = ref(null);
const hotelAvailFormModelRef = ref(null);

watch(
  () => props.roomTypeItem,
  (newValue) => {
    if (newValue && newValue.rooms && newValue.rooms.length > 0) {
      roomsShowRef.value = sortRoomByPrice(newValue.rooms.slice(0, 2));
    }
  },
  { immediate: true, deep: true }
);
watch(
  () => props.hotelAvailFormModel,
  (newValue) => {
    hotelAvailFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

//region 选择房间相关+append query
async function selectRoom(roomItem, roomTypeName) {
  //为了方便后续路由跳转，当前的query参数不要改变，而是追加新页面需要的参数
  let queryModel = appendPageQuery(route.query, roomItem);
  queryModel.roomTypeName = roomTypeName;
  router.push({
    name: "sales-hotel-checkout-v2",
    query: queryModel,
  });
}

function appendPageQuery(query, roomItem) {
  const queryCopy = JSON.parse(JSON.stringify(query));
  queryCopy.checkIn = roomItem.checkInDate;
  queryCopy.checkOut = roomItem.checkOutDate;
  queryCopy.referenceId = roomItem.referenceId;
  queryCopy.bedTypeName = roomItem.bedTypeName;
  return queryCopy;
}

//endregion

//region 辅助函数

function isRoomsAllShow(rooms, isAll) {
  if (isAll) {
    isAllShowRef.value = true;
    roomsShowRef.value = sortRoomByPrice(rooms);
  } else {
    isAllShowRef.value = false;
    roomsShowRef.value = sortRoomByPrice(rooms.slice(0, 2));
  }
}

// 房间按照价格排序
function sortRoomByPrice(rooms) {
  if (!rooms) return rooms;

  return rooms.sort((a, b) => {
    return a.roomPrice - b.roomPrice;
  });
}

//endregion
</script>

<template>
  <thead v-if="roomTypeItem.roomDescription" class="font-14 mg-t-5">
    {{
      roomTypeItem.roomDescription
    }}
  </thead>

  <thead class="room-type-thead">
    <tr>
      <th>{{ $t("your-choice") }}</th>
      <th>{{ $t("sleeps") }}</th>
      <th>{{ $t("today-price") }}</th>
      <th>{{ $t("operation") }}</th>
    </tr>
  </thead>

  <tbody v-if="roomsShowRef && roomsShowRef.length > 0" class="hotel-rooms">
    <tr
      v-for="(roomItem, roomIndex) in roomsShowRef"
      :key="roomIndex"
      class="border-bottom room-item"
    >
      <HotelRoomTypeRoomItem
        :room-index="roomIndex"
        :room-item="roomItem"
        :room-key="roomTypeIndex + '-' + roomIndex"
        :room-type-item="roomTypeItem"
        @selectRoom="selectRoom"
      />
    </tr>
  </tbody>

  <!--  show more-->
  <div
    v-if="roomTypeItem.rooms.length > 2"
    class="mg-t-10"
    style="
      color: #7ae3c3;
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      left: 45%;
    "
  >
    <a
      v-if="!isAllShowRef"
      @click="isRoomsAllShow(roomTypeItem.rooms, true)"
      class="cursor-point"
      >{{ $t("show-more-room-rate") }} <i class="fa fa-fw fa-angle-down"></i
    ></a>
    <a
      v-else
      @click="isRoomsAllShow(roomTypeItem.rooms, false)"
      class="cursor-point"
      >{{ $t("show-less") }} <i class="fa fa-fw fa-angle-up"></i
    ></a>
  </div>
  <!--  show more END-->
</template>

<style scoped></style>
