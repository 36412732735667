<script setup>
import { reactive, ref, watch } from "vue";
import HotelCheckOutRoomPassengerFormItem from "@/views/v2/sales/hotel/items/form/checkout/HotelCheckOutRoomPassengerFormItem.vue";

const props = defineProps({
  roomPassengerItems: {},
  bedTypeName: {},
  equipmentType: {},
});
const roomPassengerItemsRef = ref([]);

const state = reactive({
  upStatusList: [],
});
watch(
  () => props.roomPassengerItems,
  (newValue) => {
    roomPassengerItemsRef.value = newValue;

    if (newValue && newValue.length > 0) {
      newValue.forEach(() => {
        state.upStatusList.push(true);
      });
    }
  },
  { deep: true, immediate: true }
);

function changeUpStatus(index) {
  state.upStatusList[index] = !state.upStatusList[index];
}
</script>

<template>
  <div class="tou_booking_form_Wrapper">
    <div class="booking_tour_form">
      <div
        class="tour_booking_form_box"
        v-if="roomPassengerItemsRef && roomPassengerItemsRef.length > 0"
      >
        <!-- Room Passenger Form -->
        <HotelCheckOutRoomPassengerFormItem
          v-for="(roomResGuest, roomIndex) in roomPassengerItemsRef"
          :key="roomIndex"
          :res-guests="roomResGuest.resGuests"
          :room-index="roomIndex"
          :sub-title="roomResGuest.bedTypeName"
          :title="roomResGuest.roomTypeName + ' ' + (roomIndex + 1)"
          :equipment-type="equipmentType"
        >
          <template v-slot:content>
            <a href="javascript:void(0)" @click="changeUpStatus(roomIndex)"
              >{{ $t("special-requests-optional") }}
              <i
                v-if="state.upStatusList[roomIndex]"
                class="fa fa-angles-up fa-xs"
              ></i
              ><i v-else class="fa fa-angles-down fa-xs"></i
            ></a>
            <textarea
              v-show="!state.upStatusList[roomIndex]"
              id="example-textarea-input"
              v-model="roomResGuest.remarks"
              :placeholder="$t('hotel-remarks')"
              class="form-control"
              name="example-textarea-input"
              rows="3"
            ></textarea>
          </template>
        </HotelCheckOutRoomPassengerFormItem>
        <!-- END Room Passenger Form -->
      </div>
    </div>
  </div>
</template>

<style scoped></style>
