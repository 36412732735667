<script setup>
import { useCmsContentStore } from "@/stores/cms";
import dayjs from "dayjs";

defineProps({
  generalQuestions: {},
  regularQuestions: {},
  advanceQuestions: {},
});

const cmsContentStore = useCmsContentStore();
cmsContentStore.getContactContent();
</script>
<template>
  <section id="faqs_main_arae" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t("frequent-answer-and-question") }}</h2>
          </div>
        </div>
      </div>
      <div class="faqs_area_top">
        <div class="row">
          <div class="col-lg-8">
            <div class="faqs_three_area_wrapper">
              <!-- Item One -->
              <div
                class="faqs_item_wrapper"
                v-if="generalQuestions && generalQuestions.length > 0"
              >
                <h3>{{ $t("general-question") }}</h3>
                <div class="faqs_main_item">
                  <div class="accordion" id="accordionExample">
                    <div
                      class="accordion-item"
                      v-for="(
                        generalQuestion, generalIndex
                      ) in generalQuestions"
                      :key="generalIndex"
                    >
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {{ generalQuestion.question }}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ generalQuestion.answer }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!--                    <div class="accordion-item">-->
                    <!--                      <h2 class="accordion-header" id="headingTwo">-->
                    <!--                        <button-->
                    <!--                          class="accordion-button collapsed"-->
                    <!--                          type="button"-->
                    <!--                          data-bs-toggle="collapse"-->
                    <!--                          data-bs-target="#collapseTwo"-->
                    <!--                          aria-expanded="false"-->
                    <!--                          aria-controls="collapseTwo"-->
                    <!--                        >-->
                    <!--                          It is a long established fact that a reader will be-->
                    <!--                          distracted by the readable content?-->
                    <!--                        </button>-->
                    <!--                      </h2>-->
                    <!--                      <div-->
                    <!--                        id="collapseTwo"-->
                    <!--                        class="accordion-collapse collapse"-->
                    <!--                        aria-labelledby="headingTwo"-->
                    <!--                        data-bs-parent="#accordionExample"-->
                    <!--                      >-->
                    <!--                        <div class="accordion-body">-->
                    <!--                          <p>-->
                    <!--                            There are many variations of passages of Lorem Ipsum-->
                    <!--                            available, but the majority have suffered alteration-->
                    <!--                            in some form, by injected humour, or randomised-->
                    <!--                            words which don't look even slightly believable.-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="accordion-item">-->
                    <!--                      <h2 class="accordion-header" id="headingThree">-->
                    <!--                        <button-->
                    <!--                          class="accordion-button collapsed"-->
                    <!--                          type="button"-->
                    <!--                          data-bs-toggle="collapse"-->
                    <!--                          data-bs-target="#collapseThree"-->
                    <!--                          aria-expanded="false"-->
                    <!--                          aria-controls="collapseThree"-->
                    <!--                        >-->
                    <!--                          It is a long established fact that a reader will be-->
                    <!--                          distracted by the readable content?-->
                    <!--                        </button>-->
                    <!--                      </h2>-->
                    <!--                      <div-->
                    <!--                        id="collapseThree"-->
                    <!--                        class="accordion-collapse collapse"-->
                    <!--                        aria-labelledby="headingThree"-->
                    <!--                        data-bs-parent="#accordionExample"-->
                    <!--                      >-->
                    <!--                        <div class="accordion-body">-->
                    <!--                          <p>-->
                    <!--                            There are many variations of passages of Lorem Ipsum-->
                    <!--                            available, but the majority have suffered alteration-->
                    <!--                            in some form, by injected humour, or randomised-->
                    <!--                            words which don't look even slightly believable.-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="accordion-item">-->
                    <!--                      <h2 class="accordion-header" id="headingFour">-->
                    <!--                        <button-->
                    <!--                          class="accordion-button collapsed"-->
                    <!--                          type="button"-->
                    <!--                          data-bs-toggle="collapse"-->
                    <!--                          data-bs-target="#collapseFour"-->
                    <!--                          aria-expanded="false"-->
                    <!--                          aria-controls="collapseFour"-->
                    <!--                        >-->
                    <!--                          It is a long established fact that a reader will be-->
                    <!--                          distracted by the readable content?-->
                    <!--                        </button>-->
                    <!--                      </h2>-->
                    <!--                      <div-->
                    <!--                        id="collapseFour"-->
                    <!--                        class="accordion-collapse collapse"-->
                    <!--                        aria-labelledby="headingFour"-->
                    <!--                        data-bs-parent="#accordionExample"-->
                    <!--                      >-->
                    <!--                        <div class="accordion-body">-->
                    <!--                          <p>-->
                    <!--                            There are many variations of passages of Lorem Ipsum-->
                    <!--                            available, but the majority have suffered alteration-->
                    <!--                            in some form, by injected humour, or randomised-->
                    <!--                            words which don't look even slightly believable.-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>

              <!-- Item Two -->
              <div
                class="faqs_item_wrapper"
                v-if="regularQuestions && regularQuestions.length > 0"
              >
                <h3>{{ $t("regular-question") }}</h3>
                <div
                  class="faqs_main_item"
                  v-for="(regularQuestion, regularIndex) in regularQuestions"
                  :key="regularIndex"
                >
                  <div class="accordion" id="accordionExampleTwo">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef1">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef1"
                          aria-expanded="true"
                          aria-controls="collapseOnef1"
                        >
                          {{ regularQuestion.question }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef1"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOnef1"
                        data-bs-parent="#accordionExampleTwo"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ regularQuestion.answer }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!--                    <div class="accordion-item">-->
                    <!--                      <h2 class="accordion-header" id="headingTwof1">-->
                    <!--                        <button-->
                    <!--                          class="accordion-button collapsed"-->
                    <!--                          type="button"-->
                    <!--                          data-bs-toggle="collapse"-->
                    <!--                          data-bs-target="#collapseTwof1"-->
                    <!--                          aria-expanded="false"-->
                    <!--                          aria-controls="collapseTwof1"-->
                    <!--                        >-->
                    <!--                          It is a long established fact that a reader will be-->
                    <!--                          distracted by the readable content?-->
                    <!--                        </button>-->
                    <!--                      </h2>-->
                    <!--                      <div-->
                    <!--                        id="collapseTwof1"-->
                    <!--                        class="accordion-collapse collapse"-->
                    <!--                        aria-labelledby="headingTwof1"-->
                    <!--                        data-bs-parent="#accordionExampleTwo"-->
                    <!--                      >-->
                    <!--                        <div class="accordion-body">-->
                    <!--                          <p>-->
                    <!--                            There are many variations of passages of Lorem Ipsum-->
                    <!--                            available, but the majority have suffered alteration-->
                    <!--                            in some form, by injected humour, or randomised-->
                    <!--                            words which don't look even slightly believable.-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="accordion-item">-->
                    <!--                      <h2 class="accordion-header" id="headingThreef1">-->
                    <!--                        <button-->
                    <!--                          class="accordion-button collapsed"-->
                    <!--                          type="button"-->
                    <!--                          data-bs-toggle="collapse"-->
                    <!--                          data-bs-target="#collapseThreef1"-->
                    <!--                          aria-expanded="false"-->
                    <!--                          aria-controls="collapseThreef1"-->
                    <!--                        >-->
                    <!--                          It is a long established fact that a reader will be-->
                    <!--                          distracted by the readable content?-->
                    <!--                        </button>-->
                    <!--                      </h2>-->
                    <!--                      <div-->
                    <!--                        id="collapseThreef1"-->
                    <!--                        class="accordion-collapse collapse"-->
                    <!--                        aria-labelledby="headingThreef1"-->
                    <!--                        data-bs-parent="#accordionExampleTwo"-->
                    <!--                      >-->
                    <!--                        <div class="accordion-body">-->
                    <!--                          <p>-->
                    <!--                            There are many variations of passages of Lorem Ipsum-->
                    <!--                            available, but the majority have suffered alteration-->
                    <!--                            in some form, by injected humour, or randomised-->
                    <!--                            words which don't look even slightly believable.-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="accordion-item">-->
                    <!--                      <h2 class="accordion-header" id="headingFourf1">-->
                    <!--                        <button-->
                    <!--                          class="accordion-button collapsed"-->
                    <!--                          type="button"-->
                    <!--                          data-bs-toggle="collapse"-->
                    <!--                          data-bs-target="#collapseFourf1"-->
                    <!--                          aria-expanded="false"-->
                    <!--                          aria-controls="collapseFourf1"-->
                    <!--                        >-->
                    <!--                          It is a long established fact that a reader will be-->
                    <!--                          distracted by the readable content?-->
                    <!--                        </button>-->
                    <!--                      </h2>-->
                    <!--                      <div-->
                    <!--                        id="collapseFourf1"-->
                    <!--                        class="accordion-collapse collapse"-->
                    <!--                        aria-labelledby="headingFourf1"-->
                    <!--                        data-bs-parent="#accordionExampleTwo"-->
                    <!--                      >-->
                    <!--                        <div class="accordion-body">-->
                    <!--                          <p>-->
                    <!--                            There are many variations of passages of Lorem Ipsum-->
                    <!--                            available, but the majority have suffered alteration-->
                    <!--                            in some form, by injected humour, or randomised-->
                    <!--                            words which don't look even slightly believable.-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
              <!-- Item Three -->
              <div
                class="faqs_item_wrapper"
                v-if="advanceQuestions && advanceQuestions.length > 0"
              >
                <h3>{{ $t("advance-question") }}</h3>
                <div
                  class="faqs_main_item"
                  v-for="(advanceQuestion, advanceIndex) in advanceQuestions"
                  :key="advanceIndex"
                >
                  <div class="accordion" id="accordionExampleThree">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef2">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef2"
                          aria-expanded="true"
                          aria-controls="collapseOnef2"
                        >
                          {{ advanceQuestion.question }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef2"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOnef2"
                        data-bs-parent="#accordionExampleThree"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ advanceQuestion.answer }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!--                    <div class="accordion-item">-->
                    <!--                      <h2 class="accordion-header" id="headingTwof2">-->
                    <!--                        <button-->
                    <!--                          class="accordion-button collapsed"-->
                    <!--                          type="button"-->
                    <!--                          data-bs-toggle="collapse"-->
                    <!--                          data-bs-target="#collapseTwof2"-->
                    <!--                          aria-expanded="false"-->
                    <!--                          aria-controls="collapseTwof2"-->
                    <!--                        >-->
                    <!--                          It is a long established fact that a reader will be-->
                    <!--                          distracted by the readable content?-->
                    <!--                        </button>-->
                    <!--                      </h2>-->
                    <!--                      <div-->
                    <!--                        id="collapseTwof2"-->
                    <!--                        class="accordion-collapse collapse"-->
                    <!--                        aria-labelledby="headingTwof2"-->
                    <!--                        data-bs-parent="#accordionExampleThree"-->
                    <!--                      >-->
                    <!--                        <div class="accordion-body">-->
                    <!--                          <p>-->
                    <!--                            There are many variations of passages of Lorem Ipsum-->
                    <!--                            available, but the majority have suffered alteration-->
                    <!--                            in some form, by injected humour, or randomised-->
                    <!--                            words which don't look even slightly believable.-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="accordion-item">-->
                    <!--                      <h2 class="accordion-header" id="headingThreef2">-->
                    <!--                        <button-->
                    <!--                          class="accordion-button collapsed"-->
                    <!--                          type="button"-->
                    <!--                          data-bs-toggle="collapse"-->
                    <!--                          data-bs-target="#collapseThreef2"-->
                    <!--                          aria-expanded="false"-->
                    <!--                          aria-controls="collapseThreef2"-->
                    <!--                        >-->
                    <!--                          It is a long established fact that a reader will be-->
                    <!--                          distracted by the readable content?-->
                    <!--                        </button>-->
                    <!--                      </h2>-->
                    <!--                      <div-->
                    <!--                        id="collapseThreef2"-->
                    <!--                        class="accordion-collapse collapse"-->
                    <!--                        aria-labelledby="headingThreef2"-->
                    <!--                        data-bs-parent="#accordionExampleThree"-->
                    <!--                      >-->
                    <!--                        <div class="accordion-body">-->
                    <!--                          <p>-->
                    <!--                            There are many variations of passages of Lorem Ipsum-->
                    <!--                            available, but the majority have suffered alteration-->
                    <!--                            in some form, by injected humour, or randomised-->
                    <!--                            words which don't look even slightly believable.-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="accordion-item">-->
                    <!--                      <h2 class="accordion-header" id="headingFourf2">-->
                    <!--                        <button-->
                    <!--                          class="accordion-button collapsed"-->
                    <!--                          type="button"-->
                    <!--                          data-bs-toggle="collapse"-->
                    <!--                          data-bs-target="#collapseFourf2"-->
                    <!--                          aria-expanded="false"-->
                    <!--                          aria-controls="collapseFourf2"-->
                    <!--                        >-->
                    <!--                          It is a long established fact that a reader will be-->
                    <!--                          distracted by the readable content?-->
                    <!--                        </button>-->
                    <!--                      </h2>-->
                    <!--                      <div-->
                    <!--                        id="collapseFourf2"-->
                    <!--                        class="accordion-collapse collapse"-->
                    <!--                        aria-labelledby="headingFourf2"-->
                    <!--                        data-bs-parent="#accordionExampleThree"-->
                    <!--                      >-->
                    <!--                        <div class="accordion-body">-->
                    <!--                          <p>-->
                    <!--                            There are many variations of passages of Lorem Ipsum-->
                    <!--                            available, but the majority have suffered alteration-->
                    <!--                            in some form, by injected humour, or randomised-->
                    <!--                            words which don't look even slightly believable.-->
                    <!--                          </p>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="faqs_call_area">
              <img src="../../../assets/img/icon/call.png" alt="img" />
              <h5>
                {{ $t("contact-us") }}
                {{ dayjs(new Date().getTime()).format("MM/DD") }}
              </h5>
              <h3 v-if="cmsContentStore.contactContent">
                <a href="tel:+00-123-456-789">{{
                  cmsContentStore.contactContent.phoneNumber
                }}</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
