import axios from "../utils/axiosUtils";

function getAbout() {
  let url = "/api/v3/content/collections/about-us-content";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getContact() {
  let url = "/api/v3/content/collections/contact-us-content";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getAdvanceQuestions() {
  let url = "/api/v3/content/collections/faq-advance-questions";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getGeneralQuestions() {
  let url = "/api/v3/content/collections/faq-general-questions";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getRegularQuestions() {
  let url = "/api/v3/content/collections/faq-regular-questions";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getFlightHeaderContent() {
  let url = "/api/v3/content/collections/flight-page-header-content";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getFlightPromotionHotDeals() {
  let url = "/api/v3/content/collections/flight-page-promotion-hot-deals";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getFooterContent() {
  let url = "/api/v3/content/collections/footer-content";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function searchHeaderContent() {
  let url = "/api/v3/content/collections/header-content";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getHotelHeaderContent() {
  let url = "/api/v3/content/collections/hotel-page-header-content";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getHotelPromotionHotDeals() {
  let url = "/api/v3/content/collections/hotel-page-promotion-hot-deals";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getPartners() {
  let url = "/api/v3/content/collections/front-page-our-partners";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getPrivacyPolicyApi() {
  let url = "/api/v3/content/collections/privacy-policy-contents";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getBannerBackgroundImage() {
  let url = "/api/v3/content/collections/product-page-background-images";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getPromotionDeals() {
  let url = "/api/v3/content/collections/front-page-promotion-deals";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getPromotionFlights() {
  let url = "/api/v3/content/collections/front-page-promotion-flights";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function getPromotionHotels() {
  let url = "/api/v3/content/collections/front-page-promotion-hotels";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//Promotion Management
function getPromotionManagement() {
  let url = "/api/v3/content/collections/promotion-management";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//Theme Color Management
function getThemeColorManagement() {
  let url = "/api/v3/content/collections/theme-color-management";
  return axios.get(url);
}
//Content Management
function getContentManagement() {
  let url = "/api/v3/content/collections/content-management";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
/*
 * 获取优惠活动集合
 *@param promotionsName string 必填，优惠活动集合名，例如，popular-holiday-destinations，popular-hotel-destinations
 * */
function getPromotions(promotionsName) {
  let url = "/api/v3/content/collections/" + promotionsName;
  return axios.get(url);
}

/*
 * 获取指定优惠活动
 * @param promotionsName string 必填，优惠活动集合名，例如，popular-holiday-destinations，popular-hotel-destinations
 * @param promotionId number 必填，优惠活动Id，由获取优惠活动集合的响应中获取，例如，1
 * */
function getPromotion(promotionsName, promotionId) {
  let url = "/api/v3/content/collections/" + promotionsName + "/" + promotionId;
  return axios.get(url);
}

/*
 * 获取优惠活动的图片
 * @param imageName string 必填，优惠活动的图片名，由获取活动集合，或者由获取指定优惠活动的响应中获取，例如，thumbnail_PIC_2006_000682_b4ff639bfe.jpg
 */
function getPromotionImage(imageName) {
  let url = "/api/v3/content/collections/images/uploads/" + imageName;
  return axios.get(url);
}

export default {
  getPromotions,
  getPromotion,
  getPromotionImage,
  getAbout,
  getContact,
  getAdvanceQuestions,
  getGeneralQuestions,
  getRegularQuestions,
  getFlightHeaderContent,
  getFlightPromotionHotDeals,
  getFooterContent,
  searchHeaderContent,
  getHotelHeaderContent,
  getHotelPromotionHotDeals,
  getPartners,
  getPrivacyPolicyApi,
  getBannerBackgroundImage,
  getPromotionDeals,
  getPromotionFlights,
  getPromotionHotels,
  getPromotionManagement,
  getThemeColorManagement,
  getContentManagement,
};
