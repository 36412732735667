import { FlightPriceClassVO } from "@/viewobject/sales/flight/shopping/flightPriceClassVO";

//region Price Classes

function buildPriceClasses(offer) {
  if (!offer || !offer.summary || offer.summary.length === 0) return null;

  const summary = offer.summary;

  const priceClasses = [];
  for (const summaryItem of summary) {
    const name = summaryItem.name;
    const bundleID = summaryItem.bundleID;
    const subSections =
      summaryItem.shortDescription && summaryItem.shortDescription.subSections
        ? summaryItem.shortDescription.subSections
        : null;

    const flightPriceClassVO = new FlightPriceClassVO(bundleID, name, null);

    if (subSections) {
      for (const subSection of subSections) {
        const subCode = subSection.subCode;
        const text = subSection.paragraphs[0].text;
        flightPriceClassVO.addNewPriceDescription(subCode, text);
      }
    }

    priceClasses.push(flightPriceClassVO);
  }

  return priceClasses;
}

//endregion Price Class

//获取当前语言下航空公司名
function getAirlineName(languageCode, airlineCode, airlineItemMap) {
  let airlineName = airlineCode;
  if (airlineItemMap && airlineItemMap[airlineCode]) {
    const airlineItem = airlineItemMap[airlineCode];
    airlineName =
      languageCode === "cn" || languageCode === "tw"
        ? airlineItem.decodeHK
          ? airlineItem.decodeHK
          : airlineItem.decode
        : airlineItem.decode;
  }
  return airlineName;
}

//获取当前语言下航班城市名
function getAirportCityName(languageCode, code, airportCityMap) {
  let airportCityName = code;
  if (airportCityMap && airportCityMap[code]) {
    const airportCityItem = airportCityMap[code];
    if (languageCode === "cn") {
      airportCityName = airportCityItem.cityNameCN;
    } else if (languageCode === "tw") {
      airportCityName = airportCityItem.cityNameHK;
    } else if (languageCode === "en") {
      airportCityName = airportCityItem.cityName;
    }
  }

  return airportCityName;
}

//获取当前语言下航班名
function getAirportName(languageCode, code, airportCityMap) {
  let airportName = code;
  if (airportCityMap && airportCityMap[code]) {
    const airportCityItem = airportCityMap[code];
    if (languageCode === "cn") {
      airportName = airportCityItem.airportCN;
    } else if (languageCode === "tw") {
      airportName = airportCityItem.airportHK;
    } else if (languageCode === "en") {
      airportName = airportCityItem.airport;
    }
  }

  return airportName;
}

//获取当前飞机型号
function getAircraftName(aircraftCode, aircraftItemMap) {
  let aircraftName = aircraftCode;
  if (aircraftItemMap && aircraftItemMap[aircraftCode]) {
    aircraftName = aircraftItemMap[aircraftCode].decode;
  }
  return aircraftName;
}

//获取订单出票状态
function getTicketStatus(ticketingInfos) {
  if (!ticketingInfos || ticketingInfos.length === 0) return "UNTICKETED";

  for (const ticketingInfo of ticketingInfos) {
    if (ticketingInfo.status) {
      return ticketingInfo.status;
    }
  }
  return "TICKETED";
}

//返回对应功能的支付方式
function filterPaymentOptions(
  airlineCapability,
  workflowStep,
  module,
  functionCode
) {
  //所有支付方式
  const paymentMethodOptions = [
    { name: "deferred-payment", code: "DEFERRED", color: "btn-alt-info" },
    {
      name: "cash",
      code: "CASH",
      color: "btn-alt-danger",
    },
    {
      name: "directly-to-airline",
      code: "DIRECT",
      color: "btn-alt-primary",
    },
    {
      name: "credit-card",
      code: "CREDITCARD",
      color: "btn-alt-warning",
    },
  ];

  let optionsResult = [];

  //根据订单阶段，比如是否出票，拿到airlineCapability对应判断层完成过滤
  const taxonomy =
    airlineCapability && airlineCapability.taxonomy
      ? airlineCapability.taxonomy
      : null;
  if (!taxonomy || !workflowStep) return paymentMethodOptions; //如果没有默认返回所有支付方式

  let paymentMethod = {};

  if (workflowStep === "PREBOOKING" && taxonomy.preBooking) {
    paymentMethod = taxonomy.preBooking.payment;
  } else if (workflowStep === "POSTBOOKING" && taxonomy.postBooking) {
    if (module === "ANCILLARY" && functionCode === "SEAT") {
      paymentMethod = taxonomy.postBooking.ancillary.seat.payment;
    } else if (module === "ANCILLARY" && functionCode === "SERVICE") {
      paymentMethod = taxonomy.postBooking.ancillary.service.payment;
    } else if (module === "SERVICING" && functionCode === "RESHOP") {
      paymentMethod = taxonomy.postBooking.servicing.reshop.payment;
    } else if (module === "SERVICING" && functionCode === "TICKETING") {
      paymentMethod = taxonomy.postBooking.servicing.ticketing.payment;
    }
  } else if (workflowStep === "AFTERTICKETING" && taxonomy.afterTicketing) {
    if (module === "ANCILLARY" && functionCode === "SEAT") {
      paymentMethod = taxonomy.afterTicketing.ancillary.seat.payment;
    } else if (module === "ANCILLARY" && functionCode === "SERVICE") {
      paymentMethod = taxonomy.afterTicketing.ancillary.service.payment;
    } else if (module === "SERVICING" && functionCode === "RESHOP") {
      paymentMethod = taxonomy.afterTicketing.servicing.reshop.payment;
    } else if (module === "SERVICING" && functionCode === "TICKETING") {
      paymentMethod = taxonomy.afterTicketing.servicing.ticketing.payment;
    }
  }

  if (paymentMethod) {
    for (let paymentMethodKey in paymentMethod) {
      if (paymentMethod[paymentMethodKey]) {
        paymentMethodOptions.forEach((item) => {
          if (
            item.code.toUpperCase().indexOf(paymentMethodKey.toUpperCase()) !==
            -1
          )
            optionsResult.push(item);
        });
      }
    }
  }

  return optionsResult;
}

export default {
  buildPriceClasses,
  getAirlineName,
  getAirportCityName,
  getAirportName,
  getAircraftName,
  getTicketStatus,
  filterPaymentOptions,
};
