import { defineStore } from "pinia";
import profileApi from "@/apis/profileApi";
import profileTools from "@/tools/profileTools";
import { useRouter } from "vue-router";

let router = useRouter();

export const useProfileStore = defineStore("profile", {
  state: () => {
    return {
      userProfile: null,
      profileViewItem: null,
      profileModifyRS: false,
    };
  },
  actions: {
    loadUserProfile(username) {
      let _this = this;
      let profileReadRQ = profileTools.getProfileReadRQ(username);
      profileApi
        .profileRead(profileReadRQ)
        .then((res) => {
          // data = res.data;
          let data = res.data;
          if (data.success) {
            _this.userProfile = res.data.profiles;
            _this.profileViewItem = profileTools.getProfileViewItem(
              res.data.profiles
            );
          } else {
            _this.userProfile = null;
          }
        })
        .catch((err) => {
          console.error(err);
          router.push({
            name: "error-500",
            query: { message: "user profile unavailable" },
          });
        });
    },
    modifyUserProfile(profiles) {
      let _this = this;

      let profileModifyRQ = profileTools.getProfileModifyRQ(profiles);
      return profileApi.profileModify(profileModifyRQ).then((res) => {
        if (res.status == 200) {
          _this.profileModifyRS = true;
        } else {
          _this.profileModifyRS = false;
        }
      });
    },
  },
});
