<script setup>
defineProps({
  passengers: {},
  contactInfo: {},
});

function viewPassengerInfo(passenger) {
  router.push({ name: "passenger-info-change", query: passenger });
}

function notSameAsContact(contactInfo, phone, email) {
  let result = {
    emailSame: true,
    phoneSame: false,
  };

  if (contactInfo && phone)
    result.phoneSame = contactInfo.phoneNumber !== phone.phoneNumber;
  if (contactInfo && email)
    result.emailSame = contactInfo.emailAddress !== email.emailAddress;

  return result;
}
</script>

<template>
  <div
    class="tour_booking_form_box reservation-review"
    v-if="passengers && passengers.length > 0"
  >
    <h4>{{ $t("passenger-info") }}</h4>

    <div class="row">
      <div
        v-for="(passenger, paxIndex) in passengers"
        :key="paxIndex"
        class="col-12"
      >
        <div class="font_weight">
          {{ $t("passenger") }} {{ paxIndex + 1 }}
          <!--              <i-->
          <!--                class="fa-solid fa-eye main_color cursor-point mg-l-5"-->
          <!--                @click="viewPassengerInfo(passenger)"-->
          <!--              ></i>-->
        </div>
        <div class="p-1">
          <div class="flex justify-content-between align-content-center">
            <div>{{ $t("guest-name") }}</div>
            <div>
              <span v-if="passenger.nameTitle">{{ passenger.nameTitle }}. </span
              >{{ passenger.surname }} / {{ passenger.givenName }}
              <span v-if="passenger.gender"
                >{{ $t("gender") }} : {{ passenger.gender }}</span
              >
            </div>
          </div>
          <div
            v-if="passenger.birthDate"
            class="flex justify-content-between align-content-center"
          >
            <div>{{ $t("birth-date") }}</div>
            <div>
              <span>{{ passenger.birthDate }} </span>
            </div>
          </div>
          <div
            v-if="
              passenger.contractPhones &&
              passenger.contractPhones.length > 0 &&
              notSameAsContact(
                contactInfo,
                passenger.contractPhones[0],
                null
              ) &&
              notSameAsContact(contactInfo, passenger.contractPhones[0], null)
                .phoneSame
            "
            class="flex justify-content-between align-content-center"
          >
            <div>{{ $t("phone-number") }}</div>
            <div>
              <span
                ><span v-if="passenger.contractPhones[0].dialAreaCode">
                  +{{ passenger.contractPhones[0].dialAreaCode }}</span
                >
                {{ passenger.contractPhones[0].phoneNumber }}
              </span>
            </div>
          </div>
          <div
            v-if="
              passenger.contractEmails &&
              passenger.contractEmails.length > 0 &&
              notSameAsContact(
                contactInfo,
                null,
                passenger.contractEmails[0]
              ) &&
              notSameAsContact(contactInfo, null, passenger.contractEmails[0])
                .emailSame
            "
            class="flex justify-content-between align-content-center"
          >
            <div>{{ $t("email-address") }}</div>
            <div>
              <span>{{ passenger.contractEmails[0].emailAddress }} </span>
            </div>
          </div>
          <div v-if="passenger.documents && passenger.documents.length > 0">
            <div
              v-if="passenger.documents[0] && passenger.documents[0].docID"
              class="flex justify-content-between align-content-center"
            >
              <div>{{ $t("passport-number") }}</div>
              <div>
                <span
                  >{{ passenger.documents[0].docHolderNationality }}
                  {{ passenger.documents[0].docID }}
                </span>
              </div>
            </div>
            <div
              v-if="passenger.documents[0] && passenger.documents[0].docID"
              class="flex justify-content-between align-content-center mg-b-10"
            >
              <div>{{ $t("expire-date") }}</div>
              <div>
                <span>{{ passenger.documents[0].expireDate }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
