<script setup>
import { onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useSecurityStore } from "@/stores/security";
import { useCmsContentStore } from "@/stores/cms";
import { useTemplateStore } from "@/stores/template";
//i18n引入
import { useI18n } from "vue-i18n";
import { Modal } from "ant-design-vue";

const AModal = Modal;
let language = ref(
  sessionStorage.getItem("TespiLanguage")
    ? sessionStorage.getItem("TespiLanguage")
    : "en"
);
let currency = ref("EN");
let isSticky = ref(false);

const { t, locale } = useI18n(); // 解构调用函数
let userRef = reactive({
  user: null,
});

let securityStore = useSecurityStore();

let headerContentStore = useCmsContentStore();
headerContentStore.getHeaderContent();
headerContentStore.getContentManagements();

const templateStore = useTemplateStore();

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
watch(
  () => securityStore.userInfo,
  (newValue, oldValue) => {
    userRef.user = reactive(newValue);
  }
);
watch(
  () => language.value,
  (newValue) => {
    sessionStorage.setItem("TespiLanguage", language.value);
    locale.value = newValue;
  },
  { immediate: true }
);

if (securityStore.userInfo) {
  userRef.user = securityStore.userInfo;
} else {
  userRef.user = reactive(JSON.parse(sessionStorage.getItem("user")));
}

const router = useRouter();
onMounted(() => {
  window.addEventListener("scroll", () => {
    let scroll = window.scrollY;
    if (scroll >= 200) {
      isSticky.value = true;
    } else {
      isSticky.value = false;
    }
  });

  document.addEventListener("click", function (e) {
    // Hamburger menu
    if (e.target.classList.contains("hamburger-toggle")) {
      e.target.children[0].classList.toggle("active");
    }
  });
});

/*
logOut
 */
async function logOut() {
  sessionStorage.removeItem("user");
  await securityStore.signOut();
  userRef.user = null;
  router.push({ name: "home" });
}

const baseUrl = "/api/v3/content/collections/images";

//region 语言切换
//模态框打开关闭
const open = ref(false);
const showModal = () => {
  open.value = true;
};

//当前语言
let currentLanguageIndex = ref(
  sessionStorage.getItem("AgencyportalLanguage")
    ? sessionStorage.getItem("AgencyportalLanguage").split("-")[1]
    : 0
);

//语言切换列表
const languages = reactive([
  {
    language: "English",
    icon: "/assets/flagIcon/en.png",
    type: "en",
  },
  {
    language: "简体中文",
    icon: "/assets/flagIcon/cn.png",
    type: "zh",
  },
]);

//切换语言方法
function changeLanguage(index) {
  currentLanguageIndex.value = index;
  const language = languages[index].type;
  sessionStorage.setItem("TespiLanguage", language + "-" + index);
  sessionStorage.setItem("languageCode", language);
  locale.value = language;
}

//endregion
</script>

<template>
  <div>
    <header class="main_header_arae">
      <!-- Top Bar -->
      <div class="topbar-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <ul class="topbar-list" v-if="headerContentStore.headerContent">
                <!-- phone-->
                <li v-if="headerContentStore.contentManagement">
                  <a
                    href="#!"
                    v-if="
                      headerContentStore.contentManagement
                        .showHeaderPhoneNumberContent
                    "
                    ><span>{{
                      headerContentStore.headerContent.phoneNumber
                    }}</span></a
                  >
                </li>
                <li v-if="headerContentStore.contentManagement">
                  <a
                    href="#!"
                    v-if="
                      headerContentStore.contentManagement
                        .showHeaderEmailContent
                    "
                    ><span>{{
                      headerContentStore.headerContent.emailAddress
                    }}</span></a
                  >
                </li>
              </ul>
            </div>
            <div class="col-lg-5 col-md-5" v-if="equipmentType != 'iphone'">
              <ul class="header-topbar">
                <li v-if="!userRef.user">
                  <router-link to="/security/sign-in"
                    >{{ $t("sign-in") }}
                  </router-link>
                </li>
                <li v-if="!userRef.user">
                  <router-link to="/security/sign-up"
                    >{{ $t("sign-up") }}
                  </router-link>
                </li>
                <li v-if="userRef.user" class="user">
                  <div class="dropdown">
                    <button
                      type="button"
                      class="btn btn-sm d-flex align-items-center header-user-dropdown-btn"
                      id="page-header-user-dropdown"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <!--                      <img-->
                      <!--                        class="rounded-circle"-->
                      <!--                        src="/assets/media/avatars/avatar10.jpg"-->
                      <!--                        alt="Header Avatar"-->
                      <!--                        style="width: 21px"-->
                      <!--                      />-->
                      <i
                        class="bi bi-person-circle"
                        style="font-size: 21px; color: white"
                      ></i>
                      <span
                        class="d-none d-sm-inline-block ms-2"
                        style="color: white"
                        >{{ userRef.user.username }}</span
                      >
                      <i
                        class="bi bi-chevron-down"
                        style="color: white; margin-top: 4px"
                      ></i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-md dropdown-menu-end p-0 border-0 header-dropdown-menu"
                      aria-labelledby="page-header-user-dropdown"
                    >
                      <div class="p-2">
                        <RouterLink
                          :to="{ name: 'security-change-password' }"
                          class="dropdown-item d-flex align-items-center justify-content-between"
                        >
                          <span class="fs-sm fw-medium">{{
                            $t("reset-password")
                          }}</span>
                        </RouterLink>
                      </div>
                      <div class="p-2">
                        <RouterLink
                          :to="{ name: 'profiles-customer-dashboard' }"
                          class="dropdown-item d-flex align-items-center justify-content-between"
                        >
                          <span class="fs-sm fw-medium">{{
                            $t("dashboard")
                          }}</span>
                        </RouterLink>
                      </div>
                      <div class="p-2">
                        <a
                          class="dropdown-item d-flex align-items-center justify-content-between"
                          @click="logOut"
                        >
                          <span class="fs-sm fw-medium">{{
                            $t("log-out")
                          }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- END User Dropdown -->
                </li>
                <li v-if="userRef.user">
                  <router-link :to="{ name: 'profiles-customer-dashboard' }"
                    >{{ $t("booking") }}
                  </router-link>
                </li>
                <li @click="showModal" class="flex">
                  <!--                  <div class="dropdown language-option">-->
                  <!--                    <select v-model="language">-->
                  <!--                      <option value="en">{{ $t("english") }}</option>-->
                  <!--                      <option value="zh">{{ $t("chinese") }}</option>-->
                  <!--                    </select>-->
                  <!--                  </div>-->
                  <img
                    class="ms-2"
                    :src="languages[currentLanguageIndex].icon"
                    style="width: 30px; height: 20px; cursor: pointer"
                  />
                  <div style="color: white" class="mg-l-8">
                    {{ languages[currentLanguageIndex].language }}
                  </div>
                </li>
                <!--                <li>-->
                <!--                  <div class="dropdown language-option">-->
                <!--                    <select v-model="currency">-->
                <!--                      <option value="USD">USD</option>-->
                <!--                      <option value="BD">BD</option>-->
                <!--                      <option value="URO">URO</option>-->
                <!--                    </select>-->
                <!--                  </div>-->
                <!--                </li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Navbar Bar -->
      <div
        class="navbar-area"
        :class="{ 'is-sticky': isSticky && equipmentType !== 'iphone' }"
      >
        <div class="main-responsive-nav">
          <div class="container">
            <div class="main-responsive-menu mean-container">
              <nav class="navbar">
                <div class="container">
                  <div class="logo" v-if="headerContentStore.headerContent">
                    <router-link to="/">
                      <!--                      <img src="../../assets/img/logo.png" alt="logo" />-->
                      <img
                        v-if="
                          headerContentStore.headerContent.logo &&
                          headerContentStore.headerContent.logo.url
                        "
                        style="max-height: 100px"
                        :src="
                          baseUrl + headerContentStore.headerContent.logo.url
                        "
                        alt="logo"
                      />
                      <img
                        v-else
                        style="max-height: 100px"
                        src="../../assets/img/logo.png"
                        alt="logo"
                      />
                    </router-link>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      justify-content: space-around;
                      align-items: center;
                    "
                  >
                    <button
                      class="navbar-toggler collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbar-content"
                    >
                      <div class="hamburger-toggle">
                        <div class="hamburger">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </button>
                    <div
                      class="collapse navbar-collapse mean-nav"
                      id="navbar-content"
                    >
                      <ul
                        class="navbar-nav mr-auto mb-2 mb-lg-0"
                        v-if="headerContentStore.headerContent"
                      >
                        <li class="nav-item dropdown" v-if="!userRef.user">
                          <router-link to="/security/sign-in"
                            >{{ $t("sign-in") }}
                          </router-link>
                        </li>
                        <li class="nav-item dropdown" v-if="!userRef.user">
                          <router-link to="/security/sign-up"
                            >{{ $t("sign-up") }}
                          </router-link>
                        </li>
                        <li class="nav-item dropdown user" v-if="userRef.user">
                          <div class="dropdown" style="margin-left: 10px">
                            <button
                              type="button"
                              class="btn btn-sm d-flex align-items-center header-user-dropdown-btn"
                              id="page-header-user-dropdown"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              style="
                                width: 31%;
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <i
                                class="bi bi-person-circle"
                                style="font-size: 21px"
                              ></i>
                              <span>{{ userRef.user.username }}</span>
                              <i
                                class="bi bi-chevron-down"
                                style="margin-top: 4px"
                              ></i>
                            </button>
                            <div
                              class="dropdown-menu dropdown-menu-md dropdown-menu-end p-0 border-0 header-dropdown-menu"
                              aria-labelledby="page-header-user-dropdown"
                            >
                              <div class="p-2">
                                <RouterLink
                                  :to="{ name: 'security-change-password' }"
                                  class="dropdown-item d-flex align-items-center justify-content-between"
                                >
                                  <span class="fs-sm fw-medium">{{
                                    $t("reset-password")
                                  }}</span>
                                </RouterLink>
                              </div>
                              <div class="p-2">
                                <RouterLink
                                  :to="{ name: 'profiles-customer-dashboard' }"
                                  class="dropdown-item d-flex align-items-center justify-content-between"
                                >
                                  <span class="fs-sm fw-medium">{{
                                    $t("dashboard")
                                  }}</span>
                                </RouterLink>
                              </div>
                              <div class="p-2">
                                <a
                                  class="dropdown-item d-flex align-items-center justify-content-between"
                                  @click="logOut"
                                >
                                  <span class="fs-sm fw-medium">{{
                                    $t("log-out")
                                  }}</span>
                                </a>
                              </div>
                            </div>
                          </div>
                          <!-- END User Dropdown -->
                        </li>
                        <li class="nav-item dropdown">
                          <router-link
                            :to="{ name: 'profiles-customer-dashboard' }"
                            class="dropdown-item"
                            >{{ $t("bookings") }}
                          </router-link>
                        </li>

                        <li class="nav-item dropdown">
                          <router-link to="/" class="dropdown-item"
                            >{{ $t("home") }}
                          </router-link>
                        </li>
                        <li class="nav-item dropdown">
                          <a
                            @click="
                              router.push({ name: 'sales-hotel-home-v2' })
                            "
                            class="dropdown-item dropdown-toggle"
                            >{{ $t("hotel") }}
                          </a>
                        </li>
                        <li class="nav-item dropdown">
                          <a
                            @click="
                              router.push({ name: 'sales-flight-home-v2' })
                            "
                            class="dropdown-item dropdown-toggle"
                            >{{ $t("flight") }}
                          </a>
                        </li>
                        <!--                        <li class="nav-item dropdown">-->
                        <!--                          <router-link-->
                        <!--                            to="/sales/package/package-home"-->
                        <!--                            class="dropdown-item dropdown-toggle"-->
                        <!--                            >{{ $t("package") }}-->
                        <!--                          </router-link>-->
                        <!--                        </li>-->
                      </ul>
                    </div>
                    <select
                      v-model="language"
                      style="
                        color: white;
                        margin-left: 15px;
                        margin-top: 10px;
                        background-color: #7ae3c3;
                        border: none;
                      "
                      class="header-language"
                    >
                      <option value="en">{{ $t("english") }}</option>
                      <option value="zh">{{ $t("chinese") }}</option>
                    </select>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="main-navbar">
          <div class="container">
            <nav
              class="navbar navbar-expand-md navbar-light"
              v-if="headerContentStore.headerContent"
            >
              <router-link class="navbar-brand" to="/"
                ><img
                  v-if="
                    headerContentStore.headerContent.logo &&
                    headerContentStore.headerContent.logo.url
                  "
                  style="max-height: 100px"
                  :src="baseUrl + headerContentStore.headerContent.logo.url"
                  alt="logo"
                />
                <img
                  v-else
                  style="max-height: 100px"
                  src="../../assets/img/logo.png"
                  alt="logo"
                />
              </router-link>

              <div
                class="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <router-link to="/" class="nav-link">
                      {{ $t("home") }}
                    </router-link>
                  </li>
                  <li
                    class="nav-item cursor-point"
                    @click="router.push({ name: 'sales-hotel-home-v2' })"
                  >
                    <a class="nav-link">
                      {{ $t("hotel") }}
                    </a>
                  </li>
                  <li
                    class="nav-item cursor-point"
                    @click="router.push({ name: 'sales-flight-home-v2' })"
                  >
                    <a class="nav-link">
                      {{ $t("flight") }}
                    </a>
                  </li>
                  <!--                  <li class="nav-item">-->
                  <!--                    <router-link-->
                  <!--                      to="/sales/package/package-home"-->
                  <!--                      class="nav-link"-->
                  <!--                    >-->
                  <!--                      {{ $t("package") }}-->
                  <!--                    </router-link>-->
                  <!--                  </li>-->
                  <!--                  <li class="nav-item">-->
                  <!--                    <router-link to="/sales/package/home" class="nav-link"-->
                  <!--                      >Package</router-link-->
                  <!--                    >-->
                  <!--                  </li>-->
                </ul>
                <!--                <div class="others-options d-flex align-items-center">-->
                <!--                  <div class="option-item">-->
                <!--                    <router-link to="/become-vendor" class="btn btn_navber"-->
                <!--                      >Become a partner</router-link-->
                <!--                    >-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </nav>
          </div>
        </div>
        <div class="others-option-for-responsive">
          <div class="container">
            <div class="container">
              <div class="option-inner">
                <div class="others-options d-flex align-items-center">
                  <div class="option-item">
                    <a href="#" class="search-box"
                      ><i class="fas fa-search"></i
                    ></a>
                  </div>
                  <div class="option-item">
                    <router-link to="/contact" class="btn btn_navber"
                      >{{ $t("get-free-quote") }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>

  <a-modal
    v-model:open="open"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
  >
    <div class="mg-t-10">
      <h4>{{ $t("current-language") }}</h4>
      <div class="row mg-b-20">
        <div class="col-12">
          <img
            class="ms-2"
            :src="languages[currentLanguageIndex].icon"
            style="width: 40px; height: 30px; cursor: pointer"
          />

          <span
            style="font-weight: 600; font-size: 14px; color: #4c78dd"
            class="mg-l-8"
            >{{ languages[currentLanguageIndex].language }}
            <span v-if="languages[currentLanguageIndex].language === 'English'"
              >- US</span
            ></span
          >
        </div>
      </div>
      <h4>{{ $t("all-languages") }}</h4>

      <div class="row">
        <div
          class="col-4 mg-b-20"
          style="cursor: pointer"
          v-for="(item, index) in languages"
          :key="index"
          @click="changeLanguage(index)"
        >
          <img
            class="ms-2"
            :src="item.icon"
            style="width: 40px; height: 30px"
          />
          <span
            style="font-weight: 600; font-size: 14px"
            class="mg-l-8"
            :class="{ main_color: index === currentLanguageIndex }"
            >{{ item.language }}</span
          >
        </div>
      </div>
    </div>
  </a-modal>
</template>
