//region Format
import { HotelAvailFormModel } from "@/formmodel/sales/hotel/availability/hotelAvailFormModel";

function formatHotelAvailFormModel(hotelAvailFormModel) {
  const shoppingQueryModel = {};

  for (const key of Object.keys(hotelAvailFormModel)) {
    const value = hotelAvailFormModel[key];

    if (value instanceof Array) continue;
    shoppingQueryModel[key] = hotelAvailFormModel[key];
  }

  return shoppingQueryModel;
}

function formatRoomPassengers(roomPassengers) {
  let formatArr = [];
  if (roomPassengers && roomPassengers.length > 0) {
    roomPassengers.forEach((roomPassegner) => {
      let childAgesFormat = formatSimpleArray(roomPassegner.childAges);
      let format = "";
      for (const key of Object.keys(roomPassegner)) {
        const value = roomPassegner[key];
        if (typeof value !== "string" && typeof value !== "number") continue; //排除对象类型
        format = appendFormatQueryVariable(key, format, value);
      }
      format = appendFormatQueryVariable(
        "childAgesFormat",
        format,
        childAgesFormat
      );
      formatArr.push(format);
    });
  }
  return formatArr;
}

function formatSimpleArray(array) {
  let format = "";
  if (array == null || array.length === 0) return format;

  for (const ele of array) {
    //遍历数组中的每一项
    if (!ele || (typeof ele === "object" && Object.keys(ele) === 0)) {
      continue;
    }

    if (typeof ele === "string") {
      format += ele; //如果是字符串数组则直接与元素分隔符拼接
    } else {
      for (const key of Object.keys(ele)) {
        if (!key) continue;
        const value = ele[key] ? ele[key] : "";
        if (!value) continue;
        if (format !== "") format += "@"; //变量分隔符
        format += key + "=" + value;
      }
    }
    format += "||"; //元素分隔符
  }
  return format;
}

function appendFormatQueryVariable(key, format, formatAppend) {
  if (!formatAppend || "" === formatAppend) return format;

  if (format.length > 0) key = "&" + key;

  format += key + "!!" + formatAppend;
  return format;
}

//endregion Format

//region Parse
function parseHotelAvailFormModel(query) {
  const bestOnlyIndicator = query.bestOnlyIndicator;
  const languageCode = query.languageCode;
  const locationType = query.locationType;
  const locationCode = query.locationCode;
  const locationName = query.locationName;
  const checkInDate = query.checkInDate;
  const checkOutDate = query.checkOutDate;

  const roomPassengers = parseRoomPassengers(query.roomPassengersFormat);

  const hotelAvailFormModel = new HotelAvailFormModel(
    bestOnlyIndicator,
    languageCode,
    locationType,
    locationCode,
    locationName,
    checkInDate,
    checkOutDate,
    null
  );
  if (roomPassengers && roomPassengers.length > 0) {
    const roomPassenger = roomPassengers[0];
    for (let i = 0; i < roomPassengers.length; i++) {
      hotelAvailFormModel.addNewRoomPassenger(
        roomPassenger.adultNum,
        roomPassenger.childNum,
        roomPassenger.childAges
      );
    }
  }

  return hotelAvailFormModel;
}

//endregion Parse

//region support methods
function parseRoomPassengers(formats) {
  let roomPassengers = [];

  if (formats && formats instanceof Array && formats.length > 0) {
    formats.forEach((format) => {
      const roomPassengerItem = parseRoomPassengerFormItem(format);
      roomPassengers.push(roomPassengerItem);
    });
  } else {
    const roomPassengerItem = parseRoomPassengerFormItem(formats);

    roomPassengers.push(roomPassengerItem);
  }
  return roomPassengers;
}

function parseRoomPassengerFormItem(format) {
  let splits = format.split("&");
  let roomPassengerQueryModel = {};
  for (const keyValues of splits) {
    const keyValue = keyValues.split("!!");
    const key = keyValue[0];
    const value =
      keyValue[1] === null || keyValue[1] === "" ? null : keyValue[1];
    let isArrayVariable = false;
    if (value.indexOf("||") > 0) isArrayVariable = true;

    if (isArrayVariable) {
      let keyParse = key.replace("Format", "");
      roomPassengerQueryModel[keyParse] = parseSimpleArray(value);
    } else {
      roomPassengerQueryModel[key] = value;
    }
  }

  const adultNum = roomPassengerQueryModel.adultNum;
  const childNum = roomPassengerQueryModel.childNum
    ? roomPassengerQueryModel.childNum
    : 0;
  const childAges = roomPassengerQueryModel.childAges
    ? roomPassengerQueryModel.childAges
    : [];

  return { adultNum, childNum, childAges };
}

function parseSimpleArray(format) {
  if (!format || format === "") return null;
  const parseArray = [];
  for (const keyValues of format.split("||")) {
    if (!keyValues || keyValues === "") continue;

    let isStringArr = false;
    if (keyValues.indexOf("=") < 0) isStringArr = true;

    if (isStringArr) {
      parseArray.push(keyValues);
    } else {
      const keyValueSplit = keyValues.split("@"); //
      const parseObj = {};
      for (const keyValue of keyValueSplit) {
        if (!keyValue || keyValue === "") continue;
        const split = keyValue.split("=");
        const key = split[0];
        if (!key || key === "") continue;
        parseObj[key] = split[1] === null || split[1] === "" ? null : split[1];
      }
      parseArray.push(parseObj);
    }
  }
  return parseArray;
}

//endregion support methods

export default {
  formatHotelAvailFormModel,
  formatRoomPassengers,
  parseHotelAvailFormModel,
  parseRoomPassengers,
};
