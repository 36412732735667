<script setup>
import ForgetPasswordForm from "@/views/auth/items/ForgetPasswordForm.vue";
import { useCmsContentStore } from "@/stores/cms";
import { ref, watch } from "vue";
import Banner from "@/components/custom/Banner.vue";

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!-- Common Banner Area -->
  <Banner :background-image-url="backgroundImageUrlRef" />

  <section id="common_author_area" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3 offset-lg-2">
          <div class="header_back">
            <a @click="$router.back">
              <i class="bi bi-arrow-left-square back_icon"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="common_author_boxed">
            <div class="common_author_heading">
              <h3>{{ $t("forgot-password") }}</h3>
              <h2>{{ $t("reset-password") }}</h2>
            </div>
            <div class="common_author_form">
              <ForgetPasswordForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
