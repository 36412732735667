//region 构建请求对象
/**/
import hotelTools from "@/tools/hotelTools";
import flightTools from "@/tools/flightTools";

function getReadRQ(bookingHistoryFormModel) {
  let type = bookingHistoryFormModel.type;
  let id = bookingHistoryFormModel.id;
  let limit = bookingHistoryFormModel.limit;
  let offset = bookingHistoryFormModel.offset;

  let readRequests = null;
  let status = bookingHistoryFormModel.status;
  let start = bookingHistoryFormModel.start;
  let end = bookingHistoryFormModel.end;
  if ((status != null && status != "") || start != null || end != null) {
    readRequests = {
      globalReservationReadRequests: [],
    };
    let globalReservationReadRequest = {
      status: status != null && status != "" ? status : null,
      start,
      end,
    };
    readRequests.globalReservationReadRequests.push(
      globalReservationReadRequest
    );
  }

  return {
    uniqueID: {
      type: type,
      id: id,
      id_context: "ERP",
    },
    offset: offset,
    limit: limit,
    readRequests: readRequests,
  };
}

function getCancelRQ(canceType, bookingId, bookingItemId) {
  let uniqueIDs = [];
  if (bookingId) {
    uniqueIDs.push({
      type: "BOOKING_ID",
      id: bookingId,
      id_context: "ERP",
    });
  }
  if (bookingItemId) {
    uniqueIDs.push({
      type: "BOOKING_ITEM_ID",
      id: bookingItemId,
      id_context: "ERP",
    });
  }

  return {
    cancelType: canceType,
    uniqueIDs: uniqueIDs,
  };
}

function getSynchronizeRQ(bookingId, pnr) {
  let uniqueIDs = [];
  if (bookingId) {
    uniqueIDs.push({
      type: "BOOKING_ID",
      id: bookingId,
      id_context: "ERP",
    });
  }

  //if pnr is null, will create a new PNR in Sabre.
  uniqueIDs.push({
    type: "PNR",
    id: pnr,
    id_context: "SABRE",
  });

  return {
    uniqueIDs: uniqueIDs,
  };
}

//endregion

//region 构建表单对象
class BookingHistoryFormModel {
  constructor(type, id, limit, offset, status, start, end) {
    this.type = type;
    this.id = id;
    this.limit = limit;
    this.offset = offset;
    this.status = status;
    this.start = start;
    this.end = end;
  }
}

//endregion

//region 构建显示对象
function getBookingViewItems(bookingsRetrieveRS) {
  let bookingViewItems = [];
  if (
    bookingsRetrieveRS.totalElements === 0 ||
    bookingsRetrieveRS.elements == null ||
    bookingsRetrieveRS.elements.length === 0
  )
    return bookingViewItems;
  bookingsRetrieveRS.elements.forEach((element) => {
    let bookingViewItem = getBookingViewItem(element);
    bookingViewItems.push(bookingViewItem);
  });
  // console.info(bookingViewItems);
  return bookingViewItems;
}

/*
 *
 * @param details Boolean 为true时，BookingViewItem实例将会包含房间信息
 */
function getBookingViewItem(element) {
  //common info
  let bookingId = null;
  let invoiceId = null;
  let confirmationCode = null;
  let supplierName = null;
  let recordLocators = [];
  let productType = null;
  let bookingStatus = element.statusId;
  let receiptStatusId = element.receiptStatusId;
  let totalPrice = 0;
  let currency = null;
  let createdDate = null;
  let guestName = null;
  let globalReservations = element.globalReservations;
  if (globalReservations && globalReservations.length > 0) {
    globalReservations.forEach((globalReservation) => {
      let uniqueID = globalReservation.uniqueID;
      if (uniqueID.type === "BOOKING_ID" && uniqueID.id_context === "ERP") {
        bookingId = uniqueID.id;
      }
    });
  }

  //hotel
  let hotelReservations = element.hotelReservations;
  let hotelItems = null;
  let productTypes = [];
  if (hotelReservations != null && hotelReservations.length > 0) {
    productTypes.push("Hotel");
    hotelItems = [];
    let itemId = null;
    hotelReservations.forEach((hotelReservation) => {
      let total = hotelReservation.resGlobalInfo.total;
      bookingStatus = hotelReservation.resStatus;
      totalPrice += total.amountIncludingMarkup;
      if (currency == null) currency = total.currencyCode;
      if (createdDate == null) createdDate = hotelReservation.createDateTime;

      let reservationRoomInfo = hotelTools.getReservationRoomInfo({
        hotelReservationList: [hotelReservation],
      });

      const passengers = reservationRoomInfo.passengers;
      let guest = null;

      if (passengers && passengers.length > 0) {
        guest = passengers.find((item) => item.primaryIndicator);

        if (guest) {
          const nameTitle = guest.nameTitle ? guest.nameTitle : "";
          if (nameTitle) {
            guestName =
              nameTitle + " / " + guest.surname + " / " + guest.givenName;
          } else {
            guestName = guest.surname + " / " + guest.givenName;
          }
        }
      }

      const bookingHotelItemIds = [];
      let tripHotelItemId = null;
      hotelReservation.uniqueIDList.forEach((uniqueID) => {
        if (
          uniqueID.type === "CONFIRMATION_CODE" &&
          uniqueID.id_context == "ERP"
        ) {
          confirmationCode = uniqueID.id;

          if (uniqueID.companyName && uniqueID.companyName.code) {
            supplierName = uniqueID.companyName.code;
          }
        } else if (
          uniqueID.type === "REFERENCE_ID" &&
          uniqueID.id_context == "SABRE"
        ) {
          recordLocators.push(uniqueID);
        } else if (
          uniqueID.type === "SALES_INVOICE_ID" &&
          uniqueID.id_context == "ERP"
        ) {
          invoiceId = uniqueID.id;
        } else if (
          uniqueID.type === "BOOKING_HOTEL_ITEM_ID" &&
          uniqueID.id_context == "ERP"
        ) {
          itemId = uniqueID.id;
        } else if (uniqueID.type === "BOOKING_HOTEL_ITEM_ID") {
          tripHotelItemId = uniqueID.id;
        }

        if (
          uniqueID.type === "BOOKING_HOTEL_ITEM_ID" &&
          uniqueID.id_context === "ERP"
        ) {
          bookingHotelItemIds.push(uniqueID.id);
        }
      });
      reservationRoomInfo.itemId = itemId;
      reservationRoomInfo.bookingHotelItemIds = bookingHotelItemIds;
      reservationRoomInfo.tripHotelItemId = tripHotelItemId;
      hotelItems.push(reservationRoomInfo);
    });
  }

  let flightItems = null;
  let airReservations = element.airReservations;
  if (airReservations != null && airReservations.length > 0) {
    productTypes.push("Flight");
    flightItems = [];

    airReservations.forEach((airReservation) => {
      if (!createdDate && airReservation)
        createdDate = airReservation.createDateTime;
      let pricedItineraryViewItem =
        flightTools.getPricedItineraryViewItem(airReservation);
      const passengers = pricedItineraryViewItem.passengers;

      let guest = null;
      if (passengers && passengers.length > 0) {
        guest = passengers.find((item) => item.emailAddress != null);

        if (guest) {
          let nameTitle = guest.nameTitle ? guest.nameTitle + "/" : "";
          guestName = nameTitle + guest.surname + "/" + guest.givenName;
        }
      }

      flightItems.push(pricedItineraryViewItem);

      airReservation.bookingReferenceIdList.forEach((uniqueID) => {
        if (
          uniqueID.type === "SALES_INVOICE_ID" &&
          uniqueID.id_context == "ERP"
        ) {
          invoiceId = uniqueID.id;
        }
      });
    });

    let flightBookingTotalPrice = getFlightBookingTotalPrice(flightItems);
    currency = flightBookingTotalPrice.currency;
    totalPrice += flightBookingTotalPrice.totalPrice;
  }

  productType = productTypes.toString();

  if ("CONFIRMED" === receiptStatusId) {
    bookingStatus = receiptStatusId;
  }
  return new BookingViewItem(
    bookingId,
    invoiceId,
    confirmationCode,
    productType,
    bookingStatus,
    totalPrice,
    currency,
    createdDate,
    recordLocators,
    supplierName,
    hotelItems,
    flightItems,
    guestName
  );
}

function getFlightBookingTotalPrice(flightItems) {
  let totalPrice = 0;
  let currency = null;
  flightItems.forEach((flightItem) => {
    flightItem.passengerFareViewItems.forEach((passengerFareViewItem) => {
      let totalFare = passengerFareViewItem.totalFare;
      currency = passengerFareViewItem.currency;
      totalPrice += totalFare;
    });
  });

  return {
    totalPrice,
    currency,
  };
}

function getBookingDetailsViewItem(bookingRetrieveRS) {
  if (
    bookingRetrieveRS.totalElements === 0 ||
    bookingRetrieveRS.elements == null ||
    bookingRetrieveRS.elements.length === 0
  )
    return null;
  let element = bookingRetrieveRS.elements[0];
  return getBookingViewItem(element);
}

class BookingViewItem {
  /*
   *
   * hotelItem hotelToos.HotelRoomBasicInfoView类对象
   * */
  constructor(
    bookingId,
    invoiceId,
    confirmationCode,
    productType,
    bookingStatus,
    totalPrice,
    currency,
    createdDate,
    recordLocators,
    supplierName,
    hotelItems,
    flightItems,
    guestName
  ) {
    this.bookingId = bookingId;
    this.invoiceId = invoiceId;
    this.confirmationCode = confirmationCode;
    this.productType = productType;
    this.bookingStatus = bookingStatus;
    this.totalPrice = totalPrice;
    this.currency = currency;
    this.createDate = createdDate;
    this.recordLocators = recordLocators;
    this.supplierName = supplierName;
    this.hotelItems = hotelItems;
    this.flightItems = flightItems;
    this.guestName = guestName;
  }
}

//endregion

export default {
  getReadRQ,
  getCancelRQ,
  getSynchronizeRQ,
  getBookingViewItems,
  getBookingDetailsViewItem,
  BookingHistoryFormModel,
};
