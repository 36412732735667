export class UserProfileFormModel {
  constructor(
    nameTitle,
    gender,
    surname,
    givenName,
    emailAddress,
    countryAccessCode,
    phoneNumber
  ) {
    this.nameTitle = nameTitle;
    this.gender = gender;
    this.surname = surname;
    this.givenName = givenName;
    this.emailAddress = emailAddress;
    this.countryAccessCode = countryAccessCode;
    this.phoneNumber = phoneNumber;
  }
}
