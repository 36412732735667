export class FlightFareOption {
  /**
   *
   * @param supplierCode string，机票供应商代码数组，例如，"SQ"
   * @param bookingSourceType string，机票来源系统，例如，"GDS"
   * @param priceClassId string，价格类型ID
   * @param prePriceClassIdsKey string，前置价格类型id组合键
   * @param preItineraryKey string，前置行程类型id组合键
   * @param originDestinationKey string，航班键值
   * @param currencyCode string，货币单位
   * @param totalPrice string，该航班所属行程的总价格
   * @param cabinTypes
   * @param negotiatedFareCode
   * @param bookingClassCodes
   * @param fareTypeCode
   * @param fareTypeName
   * @param fareBasisCodes
   * @param expirationDateTime
   */
  constructor(
    supplierCode,
    bookingSourceType,
    priceClassId,
    prePriceClassIdsKey,
    preItineraryKey,
    originDestinationKey,
    currencyCode,
    totalPrice,
    cabinTypes,
    negotiatedFareCode,
    bookingClassCodes,
    fareTypeCode,
    fareTypeName,
    fareBasisCodes,
    expirationDateTime
  ) {
    this.supplierCode = supplierCode;
    this.bookingSourceType = bookingSourceType;
    this.priceClassId = priceClassId;
    this.prePriceClassIdsKey = prePriceClassIdsKey;
    this.preItineraryKey = preItineraryKey;
    this.originDestinationKey = originDestinationKey;
    this.currencyCode = currencyCode;
    this.totalPrice = totalPrice;
    this.cabinTypes = cabinTypes;
    this.negotiatedFareCode = negotiatedFareCode;
    this.bookingClassCodes = bookingClassCodes;
    this.fareTypeCode = fareTypeCode;
    this.fareTypeName = fareTypeName;
    this.fareBasisCodes = fareBasisCodes;
    this.expirationDateTime = expirationDateTime;
  }

  addFareBasisCode(fareBasisCode) {
    if (this.fareBasisCodes == null) this.fareBasisCodes = [];
    if (this.fareBasisCodes.indexOf(fareBasisCode) >= 0) return;

    this.fareBasisCodes.push(fareBasisCode);
  }

  addBookingClassCode(bookingClassCode) {
    if (this.bookingClassCodes == null) this.bookingClassCodes = [];
    if (this.bookingClassCodes.indexOf(bookingClassCode) >= 0) return;

    this.bookingClassCodes.push(bookingClassCode);
  }
  addCabinType(cabinType) {
    if (this.cabinTypes == null) this.cabinTypes = [];

    if (this.cabinTypes.indexOf(cabinType) >= 0) return;

    this.cabinTypes.push(cabinType);
  }
  addNegotiatedFareCode(negotiatedFareCode) {
    this.negotiatedFareCode = negotiatedFareCode;
  }
}
