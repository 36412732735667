import { defineStore } from "pinia";
import hotelAvailabilityTools from "@/tools/v2/hotel/availability/hotelAvailabilityTools";
import hotelAvailabilityApi from "@/apis/v2/hotel/hotelAvailabilityApi";
import hotelDescriptiveInfoTools from "@/tools/v2/hotel/availability/hotelDescriptiveInfoTools";
import hotelFilterTools from "@/tools/v2/hotel/availability/hotelFilterTools";
import hotelSortTools from "@/tools/v2/hotel/availability/hotelSortTools";
import { HotelItemsSortFormModel } from "@/formmodel/sales/hotel/availability/hotelItemsSortFormModel";

export const useHotelAvailabilityStore = defineStore("hotelAvailability", {
  state: () => ({
    hotelAvailFormModel: null,
    hotelItems: null,
    hotelItemsMatch: null,
    totalResult: 0,
    hotelItemsShow: null,
    filterOptions: null,
    limit: 12, //每页限制条数
    currentPage: 1,
    pageSize: 0, //总页数
  }),
  actions: {
    hotelAvail(hotelAvailFormModel) {
      const hotelAvailRQ =
        hotelAvailabilityTools.buildHotelAvailRQ(hotelAvailFormModel);
      const thisRef = this;
      if (
        hotelAvailFormModel.locationType === "HOTEL" &&
        hotelAvailFormModel.locationCode !== "" &&
        !hotelAvailFormModel.bestOnlyIndicator
      ) {
        const ttiCode = hotelAvailFormModel.locationCode;
        const languageCode = hotelAvailFormModel.languageCode;
        const hotelDescriptiveRQ =
          hotelDescriptiveInfoTools.buildHotelDescriptiveRQ(
            ttiCode,
            languageCode
          );

        const hotelDescriptiveRSP = hotelAvailabilityApi
          .descriptive(hotelDescriptiveRQ)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.error(err);
          });

        const hotelAvailRSP = hotelAvailabilityApi
          .avail(hotelAvailRQ)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.error(err);
          });
        return Promise.all([hotelDescriptiveRSP, hotelAvailRSP]).then((res) => {
          const hotelDescriptiveRS = res[0]; //酒店详情返回结果
          const hotelAvailRS = res[1];
          thisRef.hotelItems = hotelAvailabilityTools.buildHotelItems(
            hotelAvailRS,
            hotelDescriptiveRS
          );
        });
      } else {
        // thisRef.hotelItems =
        //   hotelAvailabilityTools.buildHotelItems(hotelAvailRS);
        return hotelAvailabilityApi.avail(hotelAvailRQ).then((res) => {
          const hotelAvailRS = res.data;
          thisRef.hotelItems =
            hotelAvailabilityTools.buildHotelItems(hotelAvailRS);
          if (!thisRef.hotelItems) thisRef.hotelItems = [];
          thisRef.pageSize = Math.ceil(
            thisRef.hotelItems.length / thisRef.limit
          );
          thisRef.totalResult = thisRef.hotelItems.length;
          if (thisRef.hotelItems && thisRef.hotelItems.length > 0) {
            thisRef.hotelItems = hotelSortTools.sortHotelItems(
              thisRef.hotelItems,
              new HotelItemsSortFormModel("PRICE", "ASC")
            );
          }
          thisRef.hotelItemsShow =
            hotelAvailabilityTools.findSpecificHotelItems(
              thisRef.hotelItems,
              1,
              thisRef.limit
            );

          thisRef.hotelItemsMatch = thisRef.hotelItems;
        });
      }
    },
    updateRoomInfos(hotelAvailFormModel) {
      const hotelAvailRQ =
        hotelAvailabilityTools.buildHotelAvailRQ(hotelAvailFormModel);
      const thisRef = this;
      console.info("Update");
      return hotelAvailabilityApi
        .avail(hotelAvailRQ)
        .then((res) => {
          const hotelAvailRS = res.data;
          const hotelItems = hotelAvailabilityTools.buildHotelItems(
            hotelAvailRS,
            null
          );

          if (
            thisRef.hotelItems &&
            thisRef.hotelItems.length > 0 &&
            (hotelItems == null || hotelItems.length === 0)
          ) {
            thisRef.hotelItems.forEach((hotelItem) => {
              hotelItem.roomTypes = [];
            });
          } else {
            thisRef.hotelItems.forEach((hotelItem) => {
              for (const hotelItemEle of hotelItems) {
                if (hotelItemEle.ttiCode === hotelItemEle.ttiCode) {
                  hotelItem.roomTypes = hotelItemEle.roomTypes;
                }
              }
            });
          }

          return res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    changeHotelItemsShow(
      currentPage,
      isLoadMore,
      hotelItemsFilterFormModel,
      hotelItemsSortFormModel
    ) {
      const thisRef = this;
      if (isLoadMore) {
        thisRef.currentPage = currentPage;
        thisRef.hotelItemsShow = hotelAvailabilityTools.findSpecificHotelItems(
          thisRef.hotelItemsMatch,
          currentPage,
          thisRef.limit
        );
      } else {
        thisRef.currentPage = 1;
        let filterHotelItemsMatch = [];
        if (hotelItemsFilterFormModel) {
          filterHotelItemsMatch = hotelFilterTools.filterHotelItems(
            thisRef.hotelItems,
            hotelItemsFilterFormModel
          );
        }

        if (hotelItemsSortFormModel) {
          filterHotelItemsMatch = hotelSortTools.sortHotelItems(
            filterHotelItemsMatch,
            hotelItemsSortFormModel
          );
        }
        thisRef.hotelItemsMatch = filterHotelItemsMatch;
        if (filterHotelItemsMatch) {
          thisRef.totalResult = filterHotelItemsMatch.length;
          thisRef.hotelItemsShow =
            hotelAvailabilityTools.findSpecificHotelItems(
              filterHotelItemsMatch,
              1,
              thisRef.limit
            );
        } else {
          thisRef.totalResult = 0;
          thisRef.hotelItemsShow = [];
        }
      }
    },
  },
});
