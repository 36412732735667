export class HotelCancelPolicyVO {
    /**
     *
     * @param beginDate string，政策应用开始日期，格式为2022-11-11
     * @param currencyCode string，货币单位，如HKD
     * @param nmbrOfUnit number，对应单位的数值，可表示价格和天数
     * @param unitType string，单位，有Amount和Night，表示价格或天数
     */
    constructor(beginDate, currencyCode, nmbrOfUnit, unitType) {
        this.beginDate = beginDate;
        this.currencyCode = currencyCode;
        this.nmbrOfUnit = nmbrOfUnit;
        this.unitType = unitType;
    }
}