<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, watch } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import dateUtils from "@/utils/dateUtils";
import FlatPickr from "vue-flatpickr-component";
import HotelAvailRoomPassengerForm from "@/views/v2/sales/hotel/items/form/availability/HotelAvailRoomPassengerForm.vue";
import HotelItemOverviewPanel from "@/views/v2/sales/hotel/items/panel/tab/HotelItemOverviewPanel.vue";
import HotelAmenityPanel from "@/views/v2/sales/hotel/items/panel/tab/HotelAmenityPanel.vue";
import HotelPoliciesPanel from "@/views/v2/sales/hotel/items/panel/tab/HotelPoliciesPanel.vue";
import HotelRoomTypesItem from "@/views/v2/sales/hotel/items/group/HotelRoomTypesItem.vue";
import hotelCommonTools from "@/tools/v2/hotel/common/hotelCommonTools";

const props = defineProps({
  hotelItemDetails: {},
  hotelAvailFormModel: {},
  equipmentType: {},
});

const emits = defineEmits(["searchHotelByFormModel"]);
const route = useRoute();
const router = useRouter();
const hotelItemDetailsShowRef = ref(null);
const activeIndexRef = ref(0);

watch(
  () => props.hotelItemDetails,
  (newValue) => {
    hotelItemDetailsShowRef.value = newValue;
  },
  { immediate: true, deep: true }
);

//region 关于酒店搜索条件
const hotelAvailFormModelRef = ref(null);
const childAgesValidCheckRef = ref(false);

const flatPickrStateRef = ref({
  // Initial values
  dateRange: [],
  configRange: {
    mode: "range",
    minDate: "today",
    dateFormat: "Y-m-d",
  },
});

watch(
  () => props.hotelAvailFormModel,
  (newValue) => {
    hotelAvailFormModelRef.value = newValue;
    flatPickrStateRef.value.dateRange = [
      hotelAvailFormModelRef.value.checkInDate,
      hotelAvailFormModelRef.value.checkOutDate,
    ];
  },
  { immediate: true, deep: true }
);

const rules = {
  checkInDate: {
    required,
  },
  checkOutDate: {
    required,
  },
};
const v = useVuelidate(rules, hotelAvailFormModelRef);

async function submitHotelAvailFormModel(hotelAvailFormModel) {
  childAgesValidCheckRef.value = false;
  //在父组件中调用，则会校验所有子组件的规则，并统一返回校验结果
  const validateResult = await v.value.$validate();
  if (v.value.$errors && v.value.$errors.length > 0) {
    v.value.$errors.forEach((item) => {
      if (item != null && item.$property === "age") {
        childAgesValidCheckRef.value = true;
      }
    });
  }
  if (!validateResult) return;

  //改变当前query
  const query = formatPageQuery(route.query, hotelAvailFormModel);
  query.type = "UPDATE";
  router.replace({ query: query });
}

function formatPageQuery(query, hotelAvailFormModel) {
  const queryCopy = JSON.parse(JSON.stringify(query));
  queryCopy.checkInDate = hotelAvailFormModel.checkInDate;
  queryCopy.checkOutDate = hotelAvailFormModel.checkOutDate;
  queryCopy.roomPassengersFormat = hotelCommonTools.formatRoomPassengers(
    hotelAvailFormModel.roomPassengers
  );
  return queryCopy;
}

//endregion

//region 辅助函数
function updateCheckInAndCheckOutDates(dates) {
  if (dates.length === 2) {
    const checkIn = dateUtils.format(dates[0]);
    const checkOut = dateUtils.format(dates[1]);
    hotelAvailFormModelRef.value.checkInDate = checkIn;
    hotelAvailFormModelRef.value.checkOutDate = checkOut;
  } else {
    hotelAvailFormModelRef.value.checkInDate = null;
    hotelAvailFormModelRef.value.checkOutDate = null;
  }
}

function getNights(checkInDate, checkOutDate) {
  return dateUtils.getDaysByDateRange(checkInDate, checkOutDate);
}

//endregion
</script>

<template>
  <div v-if="hotelItemDetailsShowRef">
    <!-- overview-->
    <HotelItemOverviewPanel :hotel-view-item="hotelItemDetailsShowRef" />
    <!-- overview end-->

    <!-- search form-->
    <div class="tour_details_boxed" v-if="hotelAvailFormModelRef">
      <h3 class="heading_theme">{{ $t("select-your-room") }}</h3>
      <div class="room_select_area">
        <div class="tab-content" id="myTabContent">
          <div class="row">
            <div class="col-lg-12">
              <form action="javascript:void(0);">
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="form_search_date">
                      <div class="search_boxed">
                        <p>{{ $t("check-in-to-check-out") }}</p>
                        <FlatPickr
                          class="form-control"
                          name="flight-search-date"
                          placeholder="Y-m-d"
                          v-model="flatPickrStateRef.dateRange"
                          :class="{
                            'is-invalid':
                              v.checkInDate.$errors.length > 0 ||
                              v.checkOutDate.$errors.length > 0 ||
                              v.checkInDate.$model === v.checkOutDate.$model,
                          }"
                          :config="flatPickrStateRef.configRange"
                          @on-change="updateCheckInAndCheckOutDates"
                        />
                        <span class="mg-l-8"
                          >{{
                            getNights(
                              hotelAvailFormModelRef.checkInDate,
                              hotelAvailFormModelRef.checkOutDate
                            )
                          }}
                          {{ $t("night") }} (s)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <HotelAvailRoomPassengerForm
                      :child-ages-valid-check="childAgesValidCheckRef"
                      :hotel-avail-form-model="hotelAvailFormModelRef"
                      :equipment-type="equipmentType"
                    />
                  </div>

                  <div class="col-lg-2 top_form_search_button">
                    <button
                      class="btn btn_theme"
                      :class="{ btn_md: equipmentType !== 'iphone' }"
                      type="button"
                      @click="submitHotelAvailFormModel(hotelAvailFormModelRef)"
                    >
                      {{ $t("search") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- search form end-->

    <!--room type -->
    <div
      class="row"
      v-if="hotelItemDetailsShowRef && hotelItemDetailsShowRef.roomTypes"
    >
      <div
        v-for="(roomType, roomTypeIndex) in hotelItemDetailsShowRef.roomTypes"
        :key="roomTypeIndex"
        style="margin-top: 25px"
      >
        <div class="tour_details_boxed">
          <h3 class="heading_theme" v-if="roomType.roomTypeName">
            {{ roomType.roomTypeName }}
          </h3>
          <h3 v-else class="heading_theme">{{ $t("no-room-type-name") }}</h3>
          <table class="table table-hover" style="margin-bottom: 25px">
            <HotelRoomTypesItem
              :room-type-item="roomType"
              :room-type-index="roomTypeIndex"
              :hotel-avail-form-model="hotelAvailFormModelRef"
            />
          </table>
        </div>
      </div>
    </div>
    <!--room type end-->

    <!-- Amenity  -->
    <HotelAmenityPanel
      v-if="
        hotelItemDetailsShowRef &&
        hotelItemDetailsShowRef.amenities &&
        hotelItemDetailsShowRef.amenities.length > 0
      "
      :amenities="hotelItemDetailsShowRef.amenities"
    />
    <!-- End Amenity -->

    <!-- Policy  -->
    <HotelPoliciesPanel
      v-if="
        hotelItemDetailsShowRef &&
        hotelItemDetailsShowRef.policies &&
        hotelItemDetailsShowRef.policies.length > 0
      "
      :policies="hotelItemDetailsShowRef.policies"
    />
    <!-- End Policy -->
  </div>
</template>

<style scoped></style>
