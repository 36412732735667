import FlightHomeView from "@/views/v2/sales/flight/FlightHomeView.vue";
import FlightListAndFareOptionsView from "@/views/v2/sales/flight/FlightListAndFareOptionsView.vue";
import FlightCheckOutView from "@/views/v2/sales/flight/FlightCheckOutView.vue";

export default [
  {
    path: "/sales/v2/flight",
    redirect: "/sales/v2/flight/flight-home",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "flight-home",
        name: "sales-flight-home-v2",
        meta: { requiresAuth: false },
        component: FlightHomeView,
      },
      {
        path: "flight-list",
        name: "sales-flight-list-v2",
        meta: { requiresAuth: false },
        component: FlightListAndFareOptionsView,
      },
      {
        path: "flight-check-out",
        name: "sales-flight-check-out-v2",
        meta: { requiresAuth: false },
        component: FlightCheckOutView,
      },
    ],
  },
];
