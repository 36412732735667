<script setup>
import { reactive, ref } from "vue";
import BaseFlightSearchPassengersForm from "@/views/sales/flight/items/form/BaseFlightSearchPassengersForm.vue";
import dateUtils from "@/utils/dateUtils";
import { useFlightStore } from "@/stores/flight";
import BaseFlightSearchFormOrigDestDateItem from "@/views/sales/flight/items/form/BaseFlightSearchFormOrigDestDateItem.vue";
import { useTemplateStore } from "@/stores/template";

const templateStore = useTemplateStore();
import { useRouter } from "vue-router";

const router = useRouter();

let flightStore = useFlightStore();

//定义一个行程类型接受父组件传值
const props = defineProps({
  tripType: {},
  isOriginLocationShow: {},
  isDestinationLocationShow: {},
  flightSearchFormModel: {},
  equipmentType: {},
});

let flightSearchFormModel = reactive(props.flightSearchFormModel);

const state = reactive({
  suggestFlightPlaces: [],
  airportFrom: "",
  airportTo: "",
  day: dateUtils.format(new Date()),
  checkInWeek: dateUtils.getWeek(dateUtils.getOffsetDate(new Date(), 7)),
  checkOutWeek: "",
  flightPassengers: {},
  isHidden: false,
});

//航班乘客与座位信息
function changeFlightPassengers(flightPassengers) {
  flightSearchFormModel.adultNum = flightPassengers.adultNum;
  flightSearchFormModel.cabinClass = flightPassengers.cabinClass;
  flightSearchFormModel.childNum = flightPassengers.childNum;
  flightSearchFormModel.infantNum = flightPassengers.infantNum;
}

let num = ref(1);
function addFlightSearchFormOrigDestDateItem() {
  state.isHidden = true;
  let item = {
    departureDateTime: dateUtils.getOffsetDate(state.day, 8),
    originLocationCode: null,
    originLocationName: null,
    originAirportName: null,
    destinationLocationCode: null,
    destinationLocationName: null,
    destinationAirportName: null,
  };
  flightSearchFormModel.originDestinations.push(item);
  // (flightSearchFormModel.originDestinations.length - 1);
  changeDate(flightSearchFormModel.originDestinations.length - 1, true);
}

function deleteFlightSearchFormOrigDestDateItem(index) {
  flightSearchFormModel.originDestinations.splice(index, 1);
}

function changeDate(flightIndex, isAdd) {
  if (isAdd) {
    if (
      flightSearchFormModel.originDestinations[flightIndex].departureDateTime <
      flightSearchFormModel.originDestinations[flightIndex - 1]
        .departureDateTime
    ) {
      // ("xxxxxxxxxxxxxxxxxxxxxxxx");
      flightSearchFormModel.originDestinations[flightIndex].departureDateTime =
        flightSearchFormModel.originDestinations[
          flightIndex - 1
        ].departureDateTime;
    }
  } else {
    // (2222);
    let originDestination =
      flightSearchFormModel.originDestinations[flightIndex];
    let departureDateTime = originDestination.departureDateTime;

    for (let i = 0; i < flightSearchFormModel.originDestinations.length; i++) {
      let originDestinationCompare =
        flightSearchFormModel.originDestinations[i];
      //遍历项
      let departureDateTimeCompare = originDestinationCompare.departureDateTime;
      //对比当前之前
      if (i < flightIndex) {
        if (departureDateTime < departureDateTimeCompare) {
          //比遍历项早
          flightSearchFormModel.originDestinations[i].departureDateTime =
            departureDateTime;
        }
      } else {
        //对比当前之后
        // (333, departureDateTimeCompare, departureDateTimeCompare);
        if (departureDateTimeCompare < departureDateTime) {
          // (44444);
          //遍历项比当前时间早
          flightSearchFormModel.originDestinations[i].departureDateTime =
            departureDateTime;
        }
      }
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="oneway_search_form">
        <form action="javascript:void(0);">
          <div
            v-if="props.tripType == 'multi_way'"
            class="row"
            style="margin-bottom: 10px"
          >
            <!--如果是多程-->
            <div class="col-12" v-if="equipmentType === 'iphone'">
              <BaseFlightSearchPassengersForm
                :flight-passengers="{
                  adultNum: flightSearchFormModel.adultNum,
                  childNum: flightSearchFormModel.childNum,
                  infantNum: flightSearchFormModel.infantNum,
                  cabinClass: flightSearchFormModel.cabinClass,
                }"
                @submitFlightPassengers="changeFlightPassengers"
              />
            </div>
            <div class="col-4" v-else>
              <BaseFlightSearchPassengersForm
                :flight-passengers="{
                  adultNum: flightSearchFormModel.adultNum,
                  childNum: flightSearchFormModel.childNum,
                  infantNum: flightSearchFormModel.infantNum,
                  cabinClass: flightSearchFormModel.cabinClass,
                }"
                @submitFlightPassengers="changeFlightPassengers"
              />
            </div>
          </div>

          <div v-if="props.tripType != 'multi_way'" class="row">
            <div class="col-12" v-if="equipmentType == 'iphone'">
              <BaseFlightSearchFormOrigDestDateItem
                v-for="(originDestinationDateItem, flightIndex) in props
                  .flightSearchFormModel.originDestinations"
                :key="flightIndex"
                :origin-destination-date-item="originDestinationDateItem"
                :trip-type="props.tripType"
                :is-origin-location-show="isOriginLocationShow"
                :is-destination-location-show="isDestinationLocationShow"
              />
            </div>
            <div class="col-10" v-else>
              <BaseFlightSearchFormOrigDestDateItem
                v-for="(originDestinationDateItem, flightIndex) in props
                  .flightSearchFormModel.originDestinations"
                :key="flightIndex"
                :origin-destination-date-item="originDestinationDateItem"
                :trip-type="props.tripType"
                :is-origin-location-show="isOriginLocationShow"
                :is-destination-location-show="isDestinationLocationShow"
              />
            </div>
            <div
              class="col-xxl-2 col-xl-2 col-lg-5 col-md-12 col-sm-12 col-12 pad_search_passenger"
            >
              <BaseFlightSearchPassengersForm
                :flight-passengers="{
                  adultNum: flightSearchFormModel.adultNum,
                  childNum: flightSearchFormModel.childNum,
                  infantNum: flightSearchFormModel.infantNum,
                  cabinClass: flightSearchFormModel.cabinClass,
                }"
                @submitFlightPassengers="changeFlightPassengers"
              />
            </div>
          </div>

          <div v-else class="row">
            <div class="col-12">
              <div
                v-for="(originDestinationDateItem, flightIndex) in props
                  .flightSearchFormModel.originDestinations"
                :key="flightIndex"
              >
                <BaseFlightSearchFormOrigDestDateItem
                  :origin-destination-date-item="originDestinationDateItem"
                  :is-origin-location-show="isOriginLocationShow"
                  :is-destination-location-show="isDestinationLocationShow"
                  :trip-type="props.tripType"
                  style="margin-top: 20px"
                  :flight-index="flightIndex"
                  @changeDate="changeDate"
                />
                <div
                  v-if="
                    state.isHidden &&
                    flightSearchFormModel.originDestinations.length > 1
                  "
                  class="col-lg-12"
                >
                  <div class="multi_form_remove">
                    <button
                      type="button"
                      @click="
                        deleteFlightSearchFormOrigDestDateItem(flightIndex)
                      "
                    >
                      {{ $t("remove") }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="props.tripType == 'multi_way'" class="add_multy_form">
                <button
                  type="button"
                  @click="addFlightSearchFormOrigDestDateItem"
                >
                  + {{ $t("add-another-flight") }}
                </button>
              </div>
            </div>
          </div>

          <div class="top_form_search_button">
            <button
              class="btn btn_theme btn_md"
              @click="$emit('submitFormModel', flightSearchFormModel)"
            >
              {{ $t("search") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
