import { defineStore } from "pinia";
import flightAncillaryServiceTools from "@/tools/v2/flight/ancillaryservice/flightAncillaryServiceTools";
import flightPrimeBookingApi from "@/apis/v2/flight/flightPrimeBookingApi";

export const useFlightAncillaryServiceStore = defineStore(
  "flightAncillaryService",
  {
    state: () => ({
      ancillaryOptions: null,
      ancillaryServiceMenu: null,
      currentTaxonomyCode: null,
      seatMaps: null,
    }),
    actions: {
      airGetOffers(flightAncillaryServiceFormModel) {
        const airGetOfferRQ = flightAncillaryServiceTools.buildAirGetOfferRQ(
          flightAncillaryServiceFormModel
        );
        const thisRef = this;

        return flightPrimeBookingApi
          .offers(airGetOfferRQ)
          .then((res) => {
            const airOfferRS = res.data;
            // const airOfferRS = airGetOfferRS;
            if (airOfferRS.pricedOffers && airOfferRS.pricedOffers.length > 0) {
              thisRef.ancillaryOptions =
                flightAncillaryServiceTools.buildAncillaryOptions(
                  airOfferRS.pricedOffers,
                  "OPTIONS"
                );
              thisRef.ancillaryServiceMenu =
                flightAncillaryServiceTools.buildAncillaryServiceMenu(
                  thisRef.ancillaryOptions
                );
              thisRef.seatMaps = airOfferRS.seatMaps;
            } else {
              thisRef.ancillaryOptions = null;
              thisRef.ancillaryServiceMenu = null;
            }

            return airOfferRS;
          })
          .catch((error) => {
            thisRef.ancillaryOptions = null;
            thisRef.ancillaryServiceMenu = null;

            return error;
          });
      },
      changeTaxonomyCode(taxonomyCode) {
        this.currentTaxonomyCode = taxonomyCode;
      },
    },
  }
);
