export default class TripAirItemPassengerVO {
  /**
   *
   * @param travelerRefNumber string，乘客唯一标识
   * @param passengerTypeCode string，乘客类型，如ADT，STU，CHD
   * @param givenName string，名
   * @param surname string，姓
   * @param nameTitle string，称呼，如MR，DR
   * @param birthDate string，生日日期，格式为yyyy-MM-dd
   * @param documents Array，TripPassengerDocumentVO对象数组
   * @param contractEmails Array，
   * @param contractPhones Array，
   */
  constructor(
    travelerRefNumber,
    passengerTypeCode,
    givenName,
    surname,
    nameTitle,
    birthDate,
    documents,
    contractEmails,
    contractPhones
  ) {
    this.travelerRefNumber = travelerRefNumber;
    this.passengerTypeCode = passengerTypeCode;
    this.givenName = givenName;
    this.surname = surname;
    this.nameTitle = nameTitle;
    this.birthDate = birthDate;
    this.documents = documents;
    this.contractEmails = contractEmails;
    this.contractPhones = contractPhones;
  }

  addDocument(document) {
    if (this.documents == null) this.documents = [];
    this.documents.push(document);
  }

  addContactEmail(email) {
    if (this.contractEmails == null) this.contractEmails = [];
    this.contractEmails.push(email);
  }

  addContractPhone(phone) {
    if (this.contractPhones == null) this.contractPhones = [];
    this.contractPhones.push(phone);
  }
}
