<script setup>
import { reactive, watchEffect } from "vue";

const props = defineProps({
  promotionFlights: {},
  promotionFlightsIsShow: {},
  promotionHotels: {},
  promotionHotelsIsShow: {},
  showFlightPromotionUserRating: {},
  ShowHotelPromotionUserRating: {},
});
let promotionFlights = reactive([]);
let promotionHotels = reactive([]);

// promotionDeals = reactive(props.promotionDeals)

watchEffect(() => {
  props.promotionFlights, (promotionFlights = props.promotionFlights);
  //按照SequenceNumber排序
  if (promotionFlights && promotionFlights.length > 0) {
    promotionFlights.sort((a, b) => {
      return a.sequenceNumber - b.sequenceNumber;
    });
  }
});
watchEffect(() => {
  props.promotionHotels, (promotionHotels = props.promotionHotels);
  //按照SequenceNumber排序
  if (promotionHotels && promotionHotels.length > 0) {
    promotionHotels.sort((a, b) => {
      return a.sequenceNumber - b.sequenceNumber;
    });
  }
});
const baseUrl = "/api/v3/content/collections/images";

function getAirportName(airportCode) {
  let airportNameKey = "airport." + airportCode;
  return airportNameKey;
}

//价格展示

function twoFixed(value) {
  value = Math.round(parseFloat(value) * 100) / 100;
  let s = value.toString().split(".");
  // 只有整数
  if (s.length === 1) {
    value = value.toString() + ".00";
    return value;
  }
  // 有小数
  if (s.length > 1) {
    // 只有一位小数
    if (s[1].length < 2) {
      value = value.toString() + "0";
    }
    return value;
  }
}

// function changeActive() {
//   if (props.promotionHotelsIsShow) {
//
//   } else {
//   }
// }
</script>
<template>
  <section id="explore_area" class="section_padding_top">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t("explore-our-hot-deals") }}</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="theme_nav_tab">
            <nav class="theme_nav_tab_item">
              <div class="nav nav-tabs" id="nav-tab1" role="tablist">
                <button
                  class="nav-link active"
                  :class="{
                    active: promotionHotelsIsShow,
                  }"
                  id="nav-hotels-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-hotels"
                  type="button"
                  role="tab"
                  aria-controls="nav-hotels"
                  aria-selected="true"
                  v-if="promotionHotelsIsShow"
                >
                  {{ $t("hotels") }}
                </button>
                <button
                  class="nav-link"
                  :class="{
                    active: !promotionHotelsIsShow && promotionFlightsIsShow,
                  }"
                  id="nav-tours-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-tours"
                  type="button"
                  role="tab"
                  aria-controls="nav-tours"
                  aria-selected="false"
                  v-if="promotionFlightsIsShow"
                >
                  {{ $t("flights") }}
                </button>
                <!--                                <button class="nav-link" id="nav-space-tab" data-bs-toggle="tab"-->
                <!--                                    data-bs-target="#nav-space" type="button" role="tab" aria-controls="nav-space"-->
                <!--                                    aria-selected="false">Space</button>-->
                <!--                                <button class="nav-link" id="nav-events-tab" data-bs-toggle="tab"-->
                <!--                                    data-bs-target="#nav-events" type="button" role="tab" aria-controls="nav-events"-->
                <!--                                    aria-selected="false">Events</button>-->
              </div>
            </nav>
          </div>
        </div>
      </div>

      <!-- 数据展示-->
      <div class="row">
        <div class="col-lg-12">
          <div class="tab-content" id="nav-tabContent">
            <!-- Hotel-->
            <div
              class="tab-pane fade"
              :class="{
                active: promotionHotelsIsShow,
                show: promotionHotelsIsShow,
              }"
              id="nav-hotels"
              role="tabpanel"
              aria-labelledby="nav-hotels-tab"
              v-if="promotionHotelsIsShow"
            >
              <div
                class="row"
                v-if="promotionHotels && promotionHotels.length > 0"
              >
                <!--                <div-->
                <!--                  class="col-lg-3 col-md-6 col-sm-6 col-12"-->
                <!--                  v-for="(-->
                <!--                    promotionHotel, promotionHotelIndex-->
                <!--                  ) in promotionHotels"-->
                <!--                  :key="promotionHotelIndex"-->
                <!--                >-->
                <!--                  <div class="theme_common_box_two img_hover">-->
                <!--                    <div class="theme_two_box_img">-->
                <!--                      <a-->
                <!--                        :herf="promotionHotel.links"-->
                <!--                        v-if="promotionHotel && promotionHotel.image"-->
                <!--                      >-->
                <!--                        <img-->
                <!--                          :src="baseUrl + promotionHotel.image.url"-->
                <!--                          alt="img"-->
                <!--                        />-->
                <!--                      </a>-->
                <!--                      <p>-->
                <!--                        <i class="fas fa-map-marker-alt"></i-->
                <!--                        >{{ promotionHotel.address }}-->
                <!--                      </p>-->
                <!--                    </div>-->
                <!--                    <div class="theme_two_box_content">-->
                <!--                      <h4>-->
                <!--                        <a :herf="promotionHotel.links"-->
                <!--                          >{{ promotionHotel.hotelName }},-->
                <!--                          {{ promotionHotel.countryName }}</a-->
                <!--                        >-->
                <!--                      </h4>-->
                <!--                      <p>-->
                <!--                        <span-->
                <!--                          class="review_rating"-->
                <!--                          v-if="ShowHotelPromotionUserRating"-->
                <!--                          >{{ promotionHotel.userRating }}/{{-->
                <!--                            promotionHotel.totalRating-->
                <!--                          }}-->
                <!--                          {{ $t("excellent") }}</span-->
                <!--                        >-->
                <!--                        &lt;!&ndash;                        <span class="review_count">(1214 reviewes)</span>&ndash;&gt;-->
                <!--                      </p>-->
                <!--                      <h3>-->
                <!--                        {{ $t("price-starts-from") }}-->
                <!--                        {{ promotionHotel.currency }}-->
                <!--                        {{ promotionHotel.amount }}-->
                <!--                      </h3>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div class="theme_common_box_two img_hover">
                    <div class="theme_two_box_img">
                      <router-link to="/templates/hotel-details">
                        <img
                          src="../../../assets/img/tab-img/hotel3.png"
                          alt="img"
                        />
                      </router-link>
                      <p><i class="fas fa-map-marker-alt"></i>Kualalampur</p>
                    </div>
                    <div class="theme_two_box_content">
                      <h4>
                        <router-link to="/templates/hotel-details"
                          >Hotel kualalampur
                        </router-link>
                      </h4>
                      <p>
                        <span class="review_rating">4.8/5 Excellent</span>
                        <span class="review_count">(1214 reviewes)</span>
                      </p>
                      <h3>$99.00 <span>Price starts from</span></h3>
                    </div>
                  </div>
                </div>
                <!--                <div class="col-lg-3 col-md-6 col-sm-6 col-12">-->
                <!--                  <div class="theme_common_box_two img_hover">-->
                <!--                    <div class="theme_two_box_img">-->
                <!--                      <router-link to="/templates/hotel-details">-->
                <!--                        <img-->
                <!--                          src="../../../assets/img/tab-img/hotel8.png"-->
                <!--                          alt="img"-->
                <!--                        />-->
                <!--                      </router-link>-->
                <!--                      <p><i class="fas fa-map-marker-alt"></i>Philippine</p>-->
                <!--                    </div>-->
                <!--                    <div class="theme_two_box_content">-->
                <!--                      <h4>-->
                <!--                        <router-link to="/templates/hotel-details"-->
                <!--                          >Manila international resort-->
                <!--                        </router-link>-->
                <!--                      </h4>-->
                <!--                      <p>-->
                <!--                        <span class="review_rating">4.8/5 Excellent</span>-->
                <!--                        <span class="review_count">(1214 reviewes)</span>-->
                <!--                      </p>-->
                <!--                      <h3>$99.00 <span>Price starts from</span></h3>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
            <!-- Flights-->
            <div
              class="tab-pane fade"
              :class="{
                active: !promotionHotelsIsShow && promotionFlightsIsShow,
                show: !promotionHotelsIsShow && promotionFlightsIsShow,
              }"
              id="nav-tours"
              role="tabpanel"
              aria-labelledby="nav-tours-tab"
              v-if="promotionFlightsIsShow"
            >
              <div
                class="row"
                v-if="promotionFlights && promotionFlights.length > 0"
              >
                <!--                <div-->
                <!--                  class="col-lg-3 col-md-6 col-sm-6 col-12"-->
                <!--                  v-for="(-->
                <!--                    promotionFlight, promotionFlightIndex-->
                <!--                  ) in promotionFlights"-->
                <!--                  :key="promotionFlightIndex"-->
                <!--                >-->
                <!--                  <div class="theme_common_box_two img_hover">-->
                <!--                    <div class="theme_two_box_img">-->
                <!--                      <a-->
                <!--                        :herf="promotionFlight.links"-->
                <!--                        v-if="promotionFlight.image"-->
                <!--                      >-->
                <!--                        <img-->
                <!--                          :src="baseUrl + promotionFlight.image.url"-->
                <!--                          alt="img"-->
                <!--                        />-->
                <!--                      </a>-->
                <!--                      <p>-->
                <!--                        <i class="fas fa-map-marker-alt"></i-->
                <!--                        >{{ promotionFlight.address }}-->
                <!--                      </p>-->
                <!--                    </div>-->
                <!--                    <div class="theme_two_box_content">-->
                <!--                      <h4>-->
                <!--                        <a :herf="promotionFlight.links">-->
                <!--                          {{ promotionFlight.description }},-->
                <!--                          {{ promotionFlight.countryName }}</a-->
                <!--                        >-->
                <!--                      </h4>-->
                <!--                      <p>-->
                <!--                        <span-->
                <!--                          class="review_rating"-->
                <!--                          v-if="showFlightPromotionUserRating"-->
                <!--                          >{{ promotionFlight.userRating }}/{{-->
                <!--                            promotionFlight.totalRating-->
                <!--                          }}-->
                <!--                          {{ $t("excellent") }}</span-->
                <!--                        >-->
                <!--                        &lt;!&ndash;                        <span class="review_count">(1214 reviewes)</span>&ndash;&gt;-->
                <!--                      </p>-->
                <!--                      <h3>-->
                <!--                        {{ $t("price-starts-from") }}-->
                <!--                        {{ promotionFlight.currency }}-->
                <!--                        {{ promotionFlight.amount }}-->
                <!--                      </h3>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div class="theme_common_box_two img_hover">
                    <div class="theme_two_box_img">
                      <router-link to="/templates/hotel-details">
                        <img
                          src="../../../assets/img/tab-img/hotel3.png"
                          alt="img"
                        />
                      </router-link>
                      <p><i class="fas fa-map-marker-alt"></i>Hong Kong</p>
                    </div>
                    <div class="theme_two_box_content">
                      <h4>
                        <router-link to="/templates/hotel-details"
                          >Hong Kong Intl Apt
                        </router-link>
                      </h4>
                      <p>
                        <span class="review_rating">4.8/5 Excellent</span>
                        <span class="review_count">(1214 reviewes)</span>
                      </p>
                      <h3>$2403.00 <span>Price starts from</span></h3>
                    </div>
                  </div>
                </div>
                <!--                <div class="col-lg-3 col-md-6 col-sm-6 col-12">-->
                <!--                  <div class="theme_common_box_two img_hover">-->
                <!--                    <div class="theme_two_box_img">-->
                <!--                      <router-link to="/templates/hotel-details">-->
                <!--                        <img-->
                <!--                          src="../../../assets/img/tab-img/hotel8.png"-->
                <!--                          alt="img"-->
                <!--                        />-->
                <!--                      </router-link>-->
                <!--                      <p><i class="fas fa-map-marker-alt"></i>Philippine</p>-->
                <!--                    </div>-->
                <!--                    <div class="theme_two_box_content">-->
                <!--                      <h4>-->
                <!--                        <router-link to="/templates/hotel-details"-->
                <!--                          >Manila international resort-->
                <!--                        </router-link>-->
                <!--                      </h4>-->
                <!--                      <p>-->
                <!--                        <span class="review_rating">4.8/5 Excellent</span>-->
                <!--                        <span class="review_count">(1214 reviewes)</span>-->
                <!--                      </p>-->
                <!--                      <h3>$99.00 <span>Price starts from</span></h3>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
            <!--            <div-->
            <!--              class="tab-pane fade"-->
            <!--              id="nav-space"-->
            <!--              role="tabpanel"-->
            <!--              aria-labelledby="nav-space-tab"-->
            <!--            >-->
            <!--              <div class="row">-->
            <!--                <div class="col-lg-3 col-md-6 col-sm-6 col-12">-->
            <!--                  <div class="theme_common_box_two img_hover">-->
            <!--                    <div class="theme_two_box_img">-->
            <!--                      <router-link to="/templates/hotel-details">-->
            <!--                        <img-->
            <!--                          src="../../../assets/img/tab-img/hotel1.png"-->
            <!--                          alt="img"-->
            <!--                        />-->
            <!--                      </router-link>-->
            <!--                      <p>-->
            <!--                        <i class="fas fa-map-marker-alt"></i>New beach, Thailand-->
            <!--                      </p>-->
            <!--                    </div>-->
            <!--                    <div class="theme_two_box_content">-->
            <!--                      <h4>-->
            <!--                        <router-link to="/templates/hotel-details"-->
            <!--                          >Kantua hotel, Thailand</router-link-->
            <!--                        >-->
            <!--                      </h4>-->
            <!--                      <p>-->
            <!--                        <span class="review_rating">4.8/5 Excellent</span>-->
            <!--                        <span class="review_count">(1214 reviewes)</span>-->
            <!--                      </p>-->
            <!--                      <h3>$99.00 <span>Price starts from</span></h3>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-lg-3 col-md-6 col-sm-6 col-12">-->
            <!--                  <div class="theme_common_box_two img_hover">-->
            <!--                    <div class="theme_two_box_img">-->
            <!--                      <router-link to="/templates/hotel-details">-->
            <!--                        <img-->
            <!--                          src="../../../assets/img/tab-img/hotel4.png"-->
            <!--                          alt="img"-->
            <!--                        />-->
            <!--                      </router-link>-->
            <!--                      <p><i class="fas fa-map-marker-alt"></i>Kualalampur</p>-->
            <!--                    </div>-->
            <!--                    <div class="theme_two_box_content">-->
            <!--                      <h4>-->
            <!--                        <router-link to="/templates/hotel-details"-->
            <!--                          >Hotel kualalampur</router-link-->
            <!--                        >-->
            <!--                      </h4>-->
            <!--                      <p>-->
            <!--                        <span class="review_rating">4.8/5 Excellent</span>-->
            <!--                        <span class="review_count">(1214 reviewes)</span>-->
            <!--                      </p>-->
            <!--                      <h3>$99.00 <span>Price starts from</span></h3>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div-->
            <!--              class="tab-pane fade"-->
            <!--              id="nav-events"-->
            <!--              role="tabpanel"-->
            <!--              aria-labelledby="nav-events-tab"-->
            <!--            >-->
            <!--              <div class="row">-->
            <!--                <div class="col-lg-3 col-md-6 col-sm-6 col-12">-->
            <!--                  <div class="theme_common_box_two img_hover">-->
            <!--                    <div class="theme_two_box_img">-->
            <!--                      <router-link to="/templates/hotel-details">-->
            <!--                        <img-->
            <!--                          src="../../../assets/img/tab-img/hotel1.png"-->
            <!--                          alt="img"-->
            <!--                        />-->
            <!--                      </router-link>-->
            <!--                      <p>-->
            <!--                        <i class="fas fa-map-marker-alt"></i>New beach, Thailand-->
            <!--                      </p>-->
            <!--                    </div>-->
            <!--                    <div class="theme_two_box_content">-->
            <!--                      <h4>-->
            <!--                        <router-link to="/templates/hotel-details"-->
            <!--                          >Kantua hotel, Thailand</router-link-->
            <!--                        >-->
            <!--                      </h4>-->
            <!--                      <p>-->
            <!--                        <span class="review_rating">4.8/5 Excellent</span>-->
            <!--                        <span class="review_count">(1214 reviewes)</span>-->
            <!--                      </p>-->
            <!--                      <h3>$99.00 <span>Price starts from</span></h3>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-lg-3 col-md-6 col-sm-6 col-12">-->
            <!--                  <div class="theme_common_box_two img_hover">-->
            <!--                    <div class="theme_two_box_img">-->
            <!--                      <router-link to="/templates/hotel-details">-->
            <!--                        <img-->
            <!--                          src="../../../assets/img/tab-img/hotel8.png"-->
            <!--                          alt="img"-->
            <!--                        />-->
            <!--                      </router-link>-->
            <!--                      <p><i class="fas fa-map-marker-alt"></i>Philippine</p>-->
            <!--                    </div>-->
            <!--                    <div class="theme_two_box_content">-->
            <!--                      <h4>-->
            <!--                        <router-link to="/templates/hotel-details"-->
            <!--                          >Manila international resort</router-link-->
            <!--                        >-->
            <!--                      </h4>-->
            <!--                      <p>-->
            <!--                        <span class="review_rating">4.8/5 Excellent</span>-->
            <!--                        <span class="review_count">(1214 reviewes)</span>-->
            <!--                      </p>-->
            <!--                      <h3>$99.00 <span>Price starts from</span></h3>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
