<!--本组件包含酒店名，酒店图片，酒店地址，酒店星级，酒店用户评分，房间最低平均单价，房间最低总价，
优惠信息，酒店/房间设施或服务，查看详情入口等-->

<script setup>
import { Loader } from "@googlemaps/js-api-loader";
import { reactive } from "vue";
import { Rate } from "ant-design-vue";

const props = defineProps({
  hotelViewItem: {},
});

const ARate = Rate;

//region 地图展示
const loader = new Loader({
  apiKey: "AIzaSyCe_boE_sbAadOwLs0eOnmEExGlF0ZgLeQ",
  version: "weekly",
  libraries: ["places"],
});
let googleMap = reactive({
  google: null,
  map: null,
  markers: null,
  center: { lat: 25.0425, lng: 121.5468 },
});

if (props.hotelViewItem) {
  initiateMap(props.hotelViewItem.latitude, props.hotelViewItem.longitude);
}

function initiateMap(latitude, longitude) {
  googleMap.center.lat = latitude;
  googleMap.center.lng = longitude;

  let center = googleMap.center;

  const mapOptions = {
    center: center,
    zoom: 12,
    disableDefaultUI: true,
  };
  loader
    .load()
    .then((google) => {
      const map = new google.maps.Map(
        document.getElementById("googleMap"),
        mapOptions
      );
      new google.maps.Marker({
        position: center,
        map,
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

//endregion
</script>

<template>
  <div class="tour_details_boxed" v-if="hotelViewItem">
    <h3 class="heading_theme">{{ $t("overview") }}</h3>
    <div class="tour_details_heading_wrapper">
      <div class="tour_details_top_heading">
        <h2>{{ hotelViewItem.hotelName }}</h2>
        <h5>
          <i class="fas fa-map-marker-alt" style="margin-right: 5px"></i
          >{{ hotelViewItem.address }}
        </h5>
      </div>
      <div class="tour_details_top_heading_right">
        <a-rate :value="hotelViewItem.starRating" disabled />
        <h6>{{ hotelViewItem.userRating }}/5</h6>
      </div>
    </div>

    <h4 v-if="hotelViewItem.bestTotalPrice">
      <span class="text-warning">
        {{ hotelViewItem.currencyCode }}
        {{ hotelViewItem.bestTotalPrice.toFixed(2) }}</span
      >
      <span class="text-muted font-14 mg-l-8">{{
        $t("total-lower-case")
      }}</span>
    </h4>
    <div class="tour_details_boxed_inner">
      <p v-html="hotelViewItem.description"></p>
      <p v-html="hotelViewItem.promotionDescription"></p>
    </div>
    <div class="map_area">
      <!--Map-->
      <div class="detail-map" id="googleMap" style="height: 300px"></div>
    </div>
  </div>
</template>

<style scoped></style>
