<script setup>
import dateUtils from "@/utils/dateUtils";
import { ref, watch } from "vue";

let props = defineProps({
  flightItem: {},
  flightIndex: {},
  flightNum: {},
  priceClass: {},
});

const priceClassRef = ref([]);

watch(
  () => props.priceClass,
  (newValue) => {
    priceClassRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function getDuration(start, end) {
  let startDate = new Date(start);
  let endDate = new Date(end);
  let durationInMS = endDate.getTime() - startDate.getTime();
  return dateUtils.formatHourMinute(durationInMS / 1000 / 60);
}

function getAirlinePicture(airlineCode) {
  return "http://images.skywidetech.com/airlines/" + airlineCode + ".png";
}

//城市名
function getAirportCityName(code) {
  if (!props.airportCityMap || !props.airportCityMap[code]) return code;
  //可以通过语言获取不同类型的名字
  return props.airportCityMap[code].cityName;
}

//航空公司
function getAirlineName(airlineCode) {
  let airlineNameKey = "airlineName." + airlineCode;
  return airlineNameKey;
}

function getAirportName(airportCode) {
  let airportNameKey = "airport." + airportCode;
  return airportNameKey;
}

function getAircraftName(aircraftCode) {
  let aircraftNameKey = "aircraft." + aircraftCode;
  return aircraftNameKey;
}

function TimeDecrement(timeOne, timeTwo) {
  timeOne = new Date(timeOne);
  timeTwo = new Date(timeTwo);
  let time = null;
  if (timeTwo > timeOne) {
    time = parseInt(timeTwo - timeOne) / 1000 / 60;
  } else {
    time = parseInt(timeOne - timeTwo) / 1000 / 60;
  }
  return formatHourMinute(time);
}

// 将分钟数量转换为小时和分钟字符串
function formatHourMinute(minutes) {
  let time = {
    hour:
      Math.floor(minutes / 60) < 10 && Math.floor(minutes / 60) > 0
        ? "0" + Math.floor(minutes / 60)
        : Math.floor(minutes / 60),
    minute:
      minutes % 60 < 10 && minutes % 60 > 0
        ? "0" + (minutes % 60)
        : minutes % 60,
  };
  return time.hour + "h " + time.minute + "m";
}

//价格千分位表示
function formatNumber(str) {
  return str
    .split("")
    .reverse()
    .reduce((prev, next, index) => {
      return (index % 3 ? next : next + ",") + prev;
    });
}

function formatLocalTime(dateTime) {
  let dateTimeStr = dateTime;
  if (dateTimeStr) {
    let timeZone = "";
    let timeSplit = dateTimeStr.split("T")[1];
    if (dateTimeStr.indexOf("Z") >= 0) {
      timeZone = "+00:00";
    } else {
      timeZone = timeSplit.substring(12);
    }

    let time = timeSplit.substring(0, 5);

    return {
      time: time,
      timeZone: timeZone,
    };
  }
}

function getPolicyModelKey(modelIndex) {
  return "C" + modelIndex;
}
</script>

<template>
  <div v-if="flightItem" class="mg-b-20">
    <h5>
      <span class="text-primary p-1">
        {{ $t("flight") }} {{ flightIndex + 1 }} {{ $t("to") }}
        {{ flightItem.destinationLocation }}</span
      >

      <span class="text-muted mg-l-10 font-16"
        >{{ dateUtils.formatEnglishDate(flightItem.departureDateTime).ddate }}
        {{ dateUtils.formatEnglishDate(flightItem.departureDateTime).month }},
        {{
          dateUtils.formatEnglishDate(flightItem.departureDateTime).year
        }}</span
      >
    </h5>
    <h5 v-if="priceClassRef">
      <span
        class="text-muted mg-l-10 font-16 cursor-point"
        :data-bs-target="'#' + getPolicyModelKey(flightIndex)"
        data-bs-toggle="modal"
        >{{ priceClassRef.priceClassName }} <i class="fa-solid fa-eye"></i
      ></span>
    </h5>
    <div class="row">
      <div class="col-10">
        <div class="tab-flight-info">
          <div>
            <div
              class="fw-semibold d-inline-block py-1 px-3 fs-sm bg-light rounded"
            >
              {{
                dateUtils.formatEnglishDate(flightItem.departureDateTime).time
              }}
            </div>
            <div class="text-muted text-center">
              {{ flightItem.departureAirportCode }}
            </div>
          </div>
          <div class="font-14 text-muted text-center">
            <span> {{ formatHourMinute(flightItem.durationInMins) }}</span>
            <div class="flight-line">
              <div><i class="fa-solid fa-plane-departure"></i></div>
              <div class="line"></div>
              <div><i class="fa-solid fa-plane-arrival"></i></div>
            </div>
            <span>{{
              flightItem.numStops === 0
                ? $t("direct")
                : $t("number-stops", { stopNumber: flightItem.numStops })
            }}</span>
          </div>
          <div>
            <div
              class="fw-semibold d-inline-block py-1 px-3 fs-sm bg-light rounded"
            >
              {{ dateUtils.formatEnglishDate(flightItem.arrivalDateTime).time }}
            </div>
            <div class="text-muted text-center">
              {{ flightItem.arrivalAirportCode }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-l-3"
      v-for="(segment, segmentIndex) in flightItem.segments"
      :key="segmentIndex"
    >
      <div class="font_weight font-14">
        {{ dateUtils.formatEnglishDate(segment.departureDateTime).ddate }}
        {{ dateUtils.formatEnglishDate(segment.departureDateTime).month }}
        ,
        {{ dateUtils.formatEnglishDate(segment.departureDateTime).year }}

        <span
          class="fw-semibold d-inline-block py-1 px-3 fs-sm bg-light rounded"
        >
          {{
            dateUtils.formatEnglishDate(segment.departureDateTime).time
          }}</span
        >
        {{ $t("departure-from") }}
        {{ $t(getAirportName(segment.departureAirportCode)) }} ({{
          segment.departureAirportCode
        }})
      </div>
      <div>
        {{ $t("flight-duration") }} :
        {{ TimeDecrement(segment.arrivalDateTime, segment.departureDateTime) }}
      </div>
      <div class="font_weight font-14">
        {{ dateUtils.formatEnglishDate(segment.arrivalDateTime).ddate }}
        {{ dateUtils.formatEnglishDate(segment.arrivalDateTime).month }}
        ,
        {{ dateUtils.formatEnglishDate(segment.arrivalDateTime).year }}

        <span
          class="fw-semibold d-inline-block py-1 px-3 fs-sm bg-light rounded"
          >{{ dateUtils.formatEnglishDate(segment.arrivalDateTime).time }}</span
        >
        {{ $t("arrive-at") }}
        {{ $t(getAirportName(segment.arrivalAirportCode)) }} ({{
          segment.arrivalAirportCode
        }})
      </div>
      <div>
        <span v-if="segment.marketingAirlineCode">
          <img
            :src="getAirlinePicture(segment.marketingAirlineCode)"
            alt="img"
            class="pic-35"
          />{{ $t(getAirlineName(segment.marketingAirlineCode)) }}
          {{ segment.flightNumber }}
        </span>
      </div>
      <div>
        <span v-if="segment.cabinClass"
          >{{ segment.cabinClass }}
          <span v-if="segment.baggageAllowance"> ,</span></span
        >

        <span v-if="segment.baggageAllowance">
          <i class="fa-solid fa-suitcase-rolling"></i>
          {{ segment.baggageAllowance }}
        </span>
      </div>
      <hr v-if="segmentIndex < flightItem.segments.length - 1" />
    </div>

    <!-- priceClass description Slide Left Block Modal -->
    <div
      :id="getPolicyModelKey(flightIndex)"
      :aria-labelledby="getPolicyModelKey(flightIndex)"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
      v-if="priceClassRef"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                {{ priceClassRef.priceClassName }}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="block-content">
              <div class="p-2">
                <div
                  class="fs-sm"
                  v-for="(item, itemIndex) in priceClassRef.priceDescriptions"
                  :key="itemIndex"
                >
                  <p class="fs-sm fw-medium">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END priceClass description Slide Left Block Modal -->
  </div>
</template>

<style lang="scss" scoped></style>
