function getProfileReadRQ(username) {
  return {
    uniqueID: {
      id: username,
      id_context: "USERNAME",
    },
  };
}

//region 构建视图对象
function getProfileViewItem(profiles) {
  const profileInfos = profiles.profileInfos;
  let username,
    givenName,
    surname,
    phoneNumber,
    countryAccessCode,
    emailAddress = null;
  if (profileInfos.length > 0) {
    let profileInfo = profileInfos[0];
    if (profileInfo.profileList.length > 0) {
      let profile = profileInfo.profileList[0];
      let customer = profile.customer;
      let userID = profile.userIDList[0];
      username = userID.id;
      if (customer) {
        let personName = customer.personName;
        let telephoneList = customer.telephoneList;
        let emailList = customer.emailList;
        if (personName) {
          givenName = personName.givenName;
          surname = personName.surname;
        }
        if (telephoneList && telephoneList.length > 0) {
          phoneNumber = telephoneList[0].phoneNumber;
          countryAccessCode = telephoneList[0].countryAccessCode;
        }
        if (emailList && emailList.length > 0) {
          emailAddress = emailList[0].stringValue;
        }
      }
    } else return;
  } else {
    return;
  }
  //用戶资料对象
  let profileViewItem = new ProfileViewItem(
    username,
    givenName,
    surname,
    phoneNumber,
    countryAccessCode,
    emailAddress
  );
  return profileViewItem;
}

function getProfileModifyRQ(profiles) {
  return {
    profileModify: {
      customer: {
        personName: {
          givenName: profiles.givenName,
          surname: profiles.surname,
        },
        telephoneList: [
          {
            phoneNumber: profiles.phoneNumber,
            countryAccessCode: profiles.countryAccessCode,
          },
        ],
        emailList: [
          {
            stringValue: profiles.emailAddress,
          },
        ],
      },
      userIDList: [
        {
          pinNumber: profiles.password,
          id: profiles.username,
        },
      ],
    },
  };
}

class ProfileViewItem {
  constructor(
    username,
    givenName,
    surname,
    phoneNumber,
    countryAccessCode,
    emailAddress,
    password
  ) {
    this.username = username;
    this.givenName = givenName;
    this.surname = surname;
    this.phoneNumber = phoneNumber;
    this.countryAccessCode = countryAccessCode;
    this.emailAddress = emailAddress;
    this.password = password;
  }
}

//endregion

export default {
  getProfileReadRQ,
  getProfileViewItem,
  getProfileModifyRQ,
};
