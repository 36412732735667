//region 构建请求对象

/*
 *
 * @param dynamicPackageSearchFormModel object  DynamicPackageSearchFormModel对象
 * */
import hotelTools from "@/tools/hotelTools";
import flightTools from "@/tools/flightTools";
import dateUtils from "@/utils/dateUtils";
import hotel from "@/router/modules/hotel";

function getAvailRQ(dynamicPackageSearchFormModel) {
  let originLocationCode = dynamicPackageSearchFormModel.originLocationCode;
  let originLocationName = dynamicPackageSearchFormModel.originLocationName;
  let originAirportName = dynamicPackageSearchFormModel.originAirportName;
  let destinationLocationCode =
    dynamicPackageSearchFormModel.destinationLocationCode;
  let destinationLocationName =
    dynamicPackageSearchFormModel.destinationLocationName;
  let destinationAirportName =
    dynamicPackageSearchFormModel.destinationAirportName;
  let departureDate = dynamicPackageSearchFormModel.departureDate;
  let returnDate = dynamicPackageSearchFormModel.returnDate;
  let roomNum = dynamicPackageSearchFormModel.roomNum;
  let adultNum = dynamicPackageSearchFormModel.adultNum;
  let childNum = dynamicPackageSearchFormModel.childNum;
  let childAges = dynamicPackageSearchFormModel.childAges;
  let ttiCode = dynamicPackageSearchFormModel.ttiCode;
  let cabinClass = dynamicPackageSearchFormModel.cabinClass;
  let language = dynamicPackageSearchFormModel.language;

  let hotelRef;
  let bestOnlyIndicator = true;
  if (ttiCode != null && ttiCode !== "") {
    hotelRef = {};
    hotelRef.tticode = ttiCode;
    bestOnlyIndicator = false;
  } else {
    hotelRef = {
      hotelCityCode: destinationLocationCode,
      hotelCodeContext: "AIRPORT",
    };
  }
  let roomStayCandidates = [];
  // childNum = 0;
  let childTotalNum = childNum * roomNum;
  let adultTotalNum = adultNum * roomNum;
  for (let i = 0; i < roomNum; i++) {
    let roomStayCandidate = {
      quantity: 1,
      guestCounts: {
        guestCountList: [
          {
            ageQualifyingCode: "10",
            count: adultNum,
          },
        ],
      },
    };

    // 根据roomId判断儿童在哪个房间
    if (childAges && childAges.length > 0) {
      childAges.forEach((childAge) => {
        if (childAge.roomId == i) {
          let age = childAge.age;
          let childGuest = {
            ageQualifyingCode: "8",
            age: age,
            count: 1,
          };
          roomStayCandidate.guestCounts.guestCountList.push(childGuest);
        }
      });
    }
    roomStayCandidates.push(roomStayCandidate);
  }
  // console.info(roomStayCandidates);

  let passengerTypeQuantities = [
    {
      code: "ADT",
      quantity: adultTotalNum,
    },
  ];

  if (childTotalNum > 0) {
    passengerTypeQuantities.push({
      code: "CNN",
      quantity: childTotalNum,
    });
  }

  let cabinPrefs = null;
  if (cabinClass) {
    cabinPrefs = [
      {
        cabin: cabinClass,
      },
    ];
  }

  return {
    searchCriteria: {
      airSearch: [
        {
          originDestinationInformations: [
            {
              departureDateTime: departureDate,
              originLocation: {
                locationCode: originLocationCode,
              },
              destinationLocation: {
                locationCode: destinationLocationCode,
              },
            },
            {
              departureDateTime: returnDate,
              originLocation: {
                locationCode: destinationLocationCode,
              },
              destinationLocation: {
                locationCode: originLocationCode,
              },
            },
          ],
          travelPreferences: {
            cabinPrefList: cabinPrefs,
          },
          travelRefSummary: {
            passengerTypeQuantities: {
              passengerTypeQuantities: passengerTypeQuantities,
            },
          },
        },
      ],
      hotelSearch: [
        {
          hotelSearchCriteria: {
            bestOnlyIndicator: bestOnlyIndicator,
            criterionList: [
              {
                hotelRefs: [hotelRef],
                stayDayRange: {
                  start: departureDate,
                  end: returnDate,
                },
                roomStayCandidates: {
                  roomStayCandidates: roomStayCandidates,
                },
              },
            ],
          },
        },
      ],
    },
  };
}

function getVerifyPriceRQ(pkgPassengerFormModel) {
  return getResRQ(pkgPassengerFormModel, "verifyprice");
}

function getPrebookRQ(pkgPassengerFormModel) {
  return getResRQ(pkgPassengerFormModel, "initiate");
}

function getBookRQ(preBookRQ, hotelReservation, dynamicPkgIDs) {
  let packageBookRQ = JSON.parse(JSON.stringify(preBookRQ));
  packageBookRQ.dynamicPackage.dynamicPkgAction = "Book";
  packageBookRQ.dynamicPackage.globalInfo = {
    dynamicPkgIDs,
  };
  let hotelComponent =
    packageBookRQ.dynamicPackage.components.hotelComponents[0];
  hotelComponent.roomStayList = hotelReservation.roomStayList;
  hotelComponent.resStatus = "Book";
  hotelComponent.dynamicPkgAction = "Book";
  hotelComponent.uniqueIDList = hotelReservation.uniqueIDList;
  return packageBookRQ;
}

function getPkgPassengerFormModel(roomViewItem, roomQuantity, airItinerary) {
  let start = roomViewItem.checkIn;
  let end = roomViewItem.checkOut;
  // console.info("get rq");
  let pkgPassengerFormModel = new PkgPassengerFormModel(start, end);
  let primaryGuestExist = false;
  for (let i = 0; i < parseInt(roomQuantity); i++) {
    let id = roomViewItem.referenceId;
    let roomResGuest = pkgPassengerFormModel.addNewRoomResGuest(id);

    if (roomViewItem.guestCounts && roomViewItem.guestCounts.length > 0) {
      roomViewItem.guestCounts.forEach((guestCount) => {
        let ageQualifyingCode = guestCount.ageQualifyingCode;
        let defaultNameTitle = "MR";
        if (ageQualifyingCode == "8") {
          defaultNameTitle = "MSTR";
        }
        let resGuestRPH = guestCount.resGuestRPH;
        let age = guestCount.age;
        roomResGuest.addNewResGuest(
          !primaryGuestExist,
          resGuestRPH,
          ageQualifyingCode,
          age,
          null,
          defaultNameTitle
        );
        if (!primaryGuestExist && ageQualifyingCode == "10") {
          primaryGuestExist = true;
        }
      });
    }
  }

  pkgPassengerFormModel.airItinerary = airItinerary;
  return pkgPassengerFormModel;
}

function getSpecifiedPricedItinerary(itineraryRPH, pricedItineraryList) {
  return flightTools.getSpecifiedPricedItinerary(
    itineraryRPH,
    pricedItineraryList
  );
}
function getResRQ(pkgPassengerFormModel, status) {
  const checkIn = pkgPassengerFormModel.checkIn;
  const checkOut = pkgPassengerFormModel.checkOut;
  const roomResGuests = pkgPassengerFormModel.roomResGuests;
  const airItinerary = pkgPassengerFormModel.airItinerary;

  let roomStays = [];
  let resGuests = [];
  let resGuestRPH = 0;
  let roomIndex = 0;
  roomResGuests.forEach((roomResGuest) => {
    let guestCounts = [];
    roomResGuest.resGuests.forEach((resGuest) => {
      let ageQualifyingCode = resGuest.ageQualifyingCode;
      let age = resGuest.age;

      let guestCount = {
        resGuestRPH: resGuestRPH,
        ageQualifyingCode: ageQualifyingCode,
        age: age,
        count: 1,
      };
      let resGuestOta = JSON.parse(JSON.stringify(guestCount));
      if (resGuest.surname && resGuest.givenName) {
        let givenName = resGuest.givenName;
        let surname = resGuest.surname;
        let nameTitle = resGuest.nameTitle;
        let emailAddress = resGuest.emailAddress;
        let countryAccessCode = resGuest.countryAccessCode;
        let phoneNumber = resGuest.phoneNumber;
        let primaryIndicator = resGuest.primaryIndicator;
        let birthDate = resGuest.birthDate;
        let docID = resGuest.docID;
        let docExpireDate = resGuest.docExpireDate;
        let docHolderNationality = resGuest.docHolderNationality;
        let docHolderNationalityName = resGuest.docHolderNationalityName;
        let ffpNumber = resGuest.ffpNumber;
        let flyerAirline = resGuest.flyerAirline;
        let custLoyaltyList = null;
        if (ffpNumber && flyerAirline) {
          custLoyaltyList = [
            {
              vendorCode: flyerAirline,
              membershipID: ffpNumber,
            },
          ];
        }

        let emailList = null;
        let documentList = null;
        let telephoneList = null;
        if (emailAddress) {
          emailList = [
            {
              emailAddress: emailAddress,
              stringValue: emailAddress,
            },
          ];
        }
        if (docID && docHolderNationality) {
          documentList = [
            {
              docID: docID,
              expireDate: docExpireDate,
              docIssueCountry: docHolderNationality,
              birthCountry: docHolderNationality,
              docHolderNationality: docHolderNationality,
            },
          ];
        }
        if (phoneNumber && countryAccessCode) {
          telephoneList = [
            {
              phoneNumber: phoneNumber,
              countryAccessCode: countryAccessCode,
            },
          ];
        }

        resGuestOta = {
          primaryIndicator: primaryIndicator,
          resGuestRPH: resGuestRPH,
          ageQualifyingCode: ageQualifyingCode,
          age: age,
          profiles: {
            profileInfos: [
              {
                profileList: [
                  {
                    customer: {
                      birthDate: birthDate,
                      personName: {
                        namePrefix: nameTitle,
                        givenName: givenName,
                        surnamePrefix: nameTitle,
                        surname: surname,
                        nameTitle: nameTitle,
                      },
                      telephoneList: telephoneList,
                      documentList: documentList,
                      emailList: emailList,
                      custLoyaltyList: custLoyaltyList,
                    },
                  },
                ],
              },
            ],
          },
        };
      }
      resGuests.push(resGuestOta);
      guestCounts.push(guestCount);
      resGuestRPH++;
    });

    let roomStay = {
      roomTypes: [
        {
          quantity: 1,
        },
      ],
      guestCounts: {
        guestCountList: guestCounts,
      },
      reference: {
        type: "UUID",
        id: roomResGuest.referenceId,
      },
      roomStayIndex: roomIndex,
    };
    roomIndex++;
    roomStays.push(roomStay);
  });

  let hotelStatus = "hold";
  let airComponent = {
    airItinerary: airItinerary,
  };
  if (status.toLowerCase() == "initiate") {
    hotelStatus = "Initiate";
    airComponent.quoteID = airItinerary.airItineraryRPH;
  }

  return {
    targetName: "PROD",
    dynamicPackage: {
      dynamicPkgAction: status,
      components: {
        hotelComponents: [
          {
            dynamicPkgAction: hotelStatus,
            resStatus: hotelStatus,
            resGlobalInfo: {
              timeSpan: {
                start: checkIn,
                end: checkOut,
              },
            },
            roomStayList: roomStays,
            resGuestList: resGuests,
          },
        ],
        airComponents: [airComponent],
      },
      resGuests: resGuests,
    },
  };
}
class DynamicPackageSearchFormModel {
  /*
   * @param departureDate string 离开日期，格式为yyyy-MM-dd, 2022-11-21
   * @param returnDate string 返回日期，格式为yyyy-MM-dd, 2022-11-21
   * */
  constructor(
    originLocationCode,
    originLocationName,
    originAirportName,
    destinationLocationCode,
    destinationLocationName,
    destinationAirportName,
    departureDate,
    returnDate,
    roomNum,
    adultNum,
    childAges,
    ttiCode,
    cabinClass,
    language
  ) {
    this.originLocationCode = originLocationCode;
    this.originLocationName = originLocationName;
    this.originAirportName = originAirportName;
    this.destinationLocationCode = destinationLocationCode;
    this.destinationLocationName = destinationLocationName;
    this.destinationAirportName = destinationAirportName;
    this.departureDate = departureDate;
    this.roomNum = roomNum;
    this.adultNum = adultNum;
    this.childAges = childAges;
    this.returnDate = returnDate;
    this.ttiCode = ttiCode;
    this.cabinClass = cabinClass;
    this.language = language;
  }
}

class PkgPassengerFormModel {
  constructor(checkIn, checkOut, roomResGuests, airItinerary) {
    this.checkIn = checkIn;
    this.checkOut = checkOut;
    this.roomResGuests = []; // hotelTools.RoomResGuest数组
    this.airItinerary = airItinerary;
  }

  /*
   *  新增一个房间-住客信息的映射
   * */
  addNewRoomResGuest(referenceId, roomTypeName, bedTypeName) {
    let roomResGuest = new PkgRoomResGuest(
      referenceId,
      roomTypeName,
      bedTypeName
    );
    this.roomResGuests.push(roomResGuest);
    return roomResGuest;
  }
}

class PkgRoomResGuest {
  /*
   * @param referenceId string 房间标识id
   * */
  constructor(
    referenceId,
    roomTypeName,
    bedTypeName,
    adultNum,
    childNum,
    remarks
  ) {
    this.referenceId = referenceId;
    this.resGuests = [];
    this.roomTypeName = roomTypeName;
    this.bedTypeName = bedTypeName;
    this.adultNum = adultNum;
    this.childNum = childNum;
    this.remarks = remarks;
  }

  /*
   *
   * @param primaryIndicator boolean 判断是否为联系人,若为true，则为联系人
   * */
  addNewResGuest(
    primaryIndicator,
    rph,
    guestType,
    age,
    travelerRefNumber,
    nameTitle
  ) {
    let guestInfo = new PkgResGuest(
      primaryIndicator,
      age,
      guestType,
      rph,
      null,
      null,
      nameTitle,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      travelerRefNumber
    );
    this.resGuests.push(guestInfo);
    return guestInfo;
  }
}

class PkgResGuest {
  constructor(
    primaryIndicator,
    age,
    ageQualifyingCode,
    resGuestRPH,
    givenName,
    surname,
    nameTitle,
    emailAddress,
    countryAccessCode,
    phoneNumber,
    mainIndicator,
    birthDate,
    docID,
    docExpireDate,
    docHolderNationality,
    docHolderNationalityName,
    ffpNumber,
    flyerAirline,
    travelerRefNumber
  ) {
    this.primaryIndicator = primaryIndicator;
    this.age = age;
    this.ageQualifyingCode = ageQualifyingCode;
    this.nameTitle = nameTitle;
    this.givenName = givenName;
    this.surname = surname;
    this.resGuestRPH = resGuestRPH;
    this.countryAccessCode = countryAccessCode;
    this.phoneNumber = phoneNumber;
    this.emailAddress = emailAddress;
    this.mainIndicator = mainIndicator;
    this.birthDate = birthDate;
    this.docID = docID;
    this.docExpireDate = docExpireDate;
    this.docHolderNationality = docHolderNationality;
    this.docHolderNationalityName = docHolderNationalityName;
    this.ffpNumber = ffpNumber;
    this.flyerAirline = flyerAirline;
    this.travelerRefNumber = travelerRefNumber;
  }
}

//endregion 构建请求对象

//region 创建视图对象
/*
 *
 */
function getHotelViewItemMap(hotelResults, hotelDescriptiveRS) {
  let packageHotelViewItemMap = {};
  if (
    hotelResults == null ||
    hotelResults.length === 0 ||
    hotelResults[0] == null ||
    hotelResults[0].roomStays == null ||
    hotelResults[0].roomStays.roomStays == null ||
    hotelResults[0].roomStays.roomStays.length === 0
  )
    return null;

  let roomStays = hotelResults[0].roomStays.roomStays;
  let maxPrice = null;
  roomStays.forEach((roomStay) => {
    let ttiCode = roomStay.basicPropertyInfo.tticode;

    if (!packageHotelViewItemMap[ttiCode]) {
      packageHotelViewItemMap[ttiCode] = getHotelViewItem(
        roomStay.basicPropertyInfo,
        hotelDescriptiveRS
      );
    }

    let roomTypeCode = roomStay.roomTypes[0].roomTypeCode;
    let roomTypes = packageHotelViewItemMap[ttiCode].roomTypes;
    //酒店重要信息只初始化一次
    if (Object.keys(roomTypes).length == 0) {
      let vendorMessages = roomStay.basicPropertyInfo.vendorMessages;
      if (vendorMessages) {
        let propTextContent = {};
        vendorMessages.forEach((vendorMessage) => {
          let infoType = vendorMessage.infoType;
          if (vendorMessage.subSections) {
            let paragraphs = vendorMessage.subSections[0].paragraphs;
            propTextContent[infoType] = paragraphs;
          }
          packageHotelViewItemMap[ttiCode].propTextContent = propTextContent;
        });
      }
    }

    //每个房型信息只初始化一次
    if (roomTypes[roomTypeCode] == null) {
      let roomTypeView = hotelTools.getRoomTypeViewItem(roomStay);
      roomTypes[roomTypeView.roomTypeCode] = roomTypeView;
    }

    let rooms = roomTypes[roomTypeCode].rooms;
    let roomViewItem = hotelTools.getRoomViewItem(roomStay);
    if (
      packageHotelViewItemMap[ttiCode].bestPrice == null ||
      packageHotelViewItemMap[ttiCode].bestPrice == 0 ||
      packageHotelViewItemMap[ttiCode].bestPrice > roomViewItem.totalPrice
    ) {
      packageHotelViewItemMap[ttiCode].bestPrice = roomViewItem.totalPrice;
      packageHotelViewItemMap[ttiCode].currencyCode = roomViewItem.currency;
      packageHotelViewItemMap[ttiCode].averagePrice =
        roomViewItem.totalPrice / roomViewItem.nightsStay;
    }

    if (maxPrice == null || maxPrice < roomViewItem.totalPrice) {
      maxPrice = Math.ceil(roomViewItem.totalPrice);
    }

    if (
      roomTypes[roomTypeCode].bestPrice == null ||
      roomTypes[roomTypeCode].bestPrice == 0 ||
      roomTypes[roomTypeCode].bestPrice > roomViewItem.totalPrice
    ) {
      roomTypes[roomTypeCode].bestPrice = roomViewItem.totalPrice;
      roomTypes[roomTypeCode].currencyCode = roomViewItem.currency;
    }
    if (
      roomTypes[roomTypeCode].bestPrice == null ||
      roomTypes[roomTypeCode].bestPrice == 0 ||
      roomTypes[roomTypeCode].bestPrice > roomViewItem.totalPrice
    ) {
      roomTypes[roomTypeCode].bestPrice = roomViewItem.totalPrice;
      roomTypes[roomTypeCode].currencyCode = roomViewItem.currency;
    }
    // const ratePlanCode = roomStay.ratePlans[0].ratePlanCode;
    // if (ratePlanSupplementMap[ratePlanCode]) {
    //   roomViewItem.supplements =
    //       ratePlanSupplementMap[ratePlanCode].supplements;
    // }
    rooms.push(roomViewItem);
  });
  Object.keys(packageHotelViewItemMap).forEach((key) => {
    packageHotelViewItemMap[key].maxPrice = maxPrice;
  });
  // console.info(hotelViewItemMap);
  return packageHotelViewItemMap;
}

function getHotelViewItem(basicPropertyInfo, hotelDescriptionRS) {
  const hotelName = basicPropertyInfo.hotelName;
  const tticode = basicPropertyInfo.tticode;
  const hotelCityName = basicPropertyInfo.hotelCtyName;
  const starRating = basicPropertyInfo.rating;
  const mainImageURL = basicPropertyInfo.mainImageURL;
  const position = basicPropertyInfo.position;
  const latitude = position.latitude;
  const longitude = position.longitude;
  const address = basicPropertyInfo.address.addressString;
  const countryName = basicPropertyInfo.address.countryName;
  const awards = basicPropertyInfo.awards;
  const description = basicPropertyInfo.shortDescriptions;

  let userRating = 0;
  if (awards != null && awards.length > 0) {
    awards.forEach((award) => {
      if (award.provider == "TRIPADVISOR") {
        userRating =
          award.rating == null || award.rating == "" || award.rating == "null"
            ? 0
            : parseFloat(award.rating);
      }
    });
  }

  let facilityInfos = [];
  let images = [];
  if (
    hotelDescriptionRS != null &&
    hotelDescriptionRS.hotelDescriptiveContents != null &&
    hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList !=
      null &&
    hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList
      .length != 0
  ) {
    let content =
      hotelDescriptionRS.hotelDescriptiveContents
        .hotelDescriptiveContentList[0];
    // 服务设施
    if (content.hotelInfo.serviceList != null) {
      facilityInfos = content.hotelInfo.serviceList;
    }
    // 房间设施
    if (
      content.facilityInfo &&
      content.facilityInfo.guestRooms &&
      content.facilityInfo.guestRooms.guestRoomList &&
      content.facilityInfo.guestRooms.guestRoomList[0].amenities &&
      content.facilityInfo.guestRooms.guestRoomList[0].amenities.amenityList
    ) {
      let amenityList =
        content.facilityInfo.guestRooms.guestRoomList[0].amenities.amenityList;
      amenityList.forEach((amenity) => {
        facilityInfos.push({
          codeDetail: amenity.descriptiveText,
          type: "ROOM_VIEW",
        });
      });
    }

    // 图片，文字描述
    if (
      content.multimediaDescriptions != null &&
      content.multimediaDescriptions.multimediaDescriptionList != null &&
      content.multimediaDescriptions.multimediaDescriptionList.length > 0
    ) {
      let multimediaDescription =
        content.multimediaDescriptions.multimediaDescriptionList[0];

      if (multimediaDescription.imageItems) {
        multimediaDescription.imageItems.forEach((imageItem) => {
          let image = {
            url: imageItem.url,
            type: "hotel",
          };
          if (
            imageItem.imageFormatList != null &&
            imageItem.imageFormatList.length > 0 &&
            "bigger" == imageItem.imageFormatList[0].dimensionCategory
          ) {
            let roomTypeCode = imageItem.roomTypeCode;
            if (roomTypeCode != null) {
              image.type = "room";
              image.caption = roomTypeCode;
            }

            images.push(image);
          } else if (imageItem.imageFormatList == null) {
            images.push(image);
          }
        });
      }
    }
  }

  let hotelViewItem = new hotelTools.HotelViewItem(
    hotelName,
    tticode,
    starRating,
    userRating,
    hotelCityName,
    countryName,
    longitude,
    latitude,
    address,
    mainImageURL,
    null,
    null,
    null,
    description,
    facilityInfos,
    {},
    images,
    null,
    null
  );

  return hotelViewItem;
}

function getFlightViewItemsGroup(airResults) {
  if (
    airResults == null ||
    airResults[0] == null ||
    airResults[0].pricedItineraries == null ||
    airResults[0].pricedItineraries.pricedItineraries == null ||
    airResults[0].pricedItineraries.pricedItineraries.length == 0
  )
    return null;

  let pricedItineraries = airResults[0].pricedItineraries.pricedItineraries;
  let flightViewItemsGroup =
    flightTools.getFlightViewItemsGroup(pricedItineraries);
  return flightViewItemsGroup;
}

function getReservationRoomInfo(hotelReservation) {
  let resGlobalInfo = hotelReservation.resGlobalInfo;
  let timeSpan = resGlobalInfo.timeSpan;
  let checkIn = timeSpan.start;
  let checkOut = timeSpan.end;
  let hotelName = null;
  let roomQuantity = 0;
  let refundable = false;
  let roomViewItems = [];
  let address = "";
  let roomTypeNames = [];
  let daysDuration = dateUtils.getDaysByDateRange(checkIn, checkOut);

  let roomStays = hotelReservation.roomStayList;
  roomQuantity = roomStays.length;
  let hotelNameExist = false;
  let propTextContent = null;
  let resGuests = hotelReservation.resGuestList;
  let resGuestMap = {};
  resGuests.forEach((resGuest) => {
    resGuestMap[resGuest.resGuestRPH] = resGuest;
  });
  let passengers = [];

  roomStays.forEach((roomStay) => {
    if (!hotelNameExist) {
      hotelNameExist = true;
      let basicPropertyInfo = roomStay.basicPropertyInfo;
      hotelName = basicPropertyInfo.hotelName;
      if (basicPropertyInfo.address) {
        address = basicPropertyInfo.address.addressString;
      }
      //酒店重要信息只初始化一次
      let vendorMessages = roomStay.basicPropertyInfo.vendorMessages;
      if (vendorMessages) {
        propTextContent = {};
        vendorMessages.forEach((vendorMessage) => {
          let infoType = vendorMessage.infoType;
          if (vendorMessage.subSections) {
            let paragraphs = vendorMessage.subSections[0].paragraphs;
            propTextContent[infoType] = paragraphs;
          }
        });
      }
    }

    const roomTypeOta = roomStay.roomTypes[0];
    let roomTypeName = roomTypeOta.roomType;
    roomTypeNames.push(roomTypeName);

    let roomViewItem = hotelTools.getRoomViewItem(roomStay);
    refundable = roomViewItem.refundable;
    roomViewItems.push(roomViewItem);

    //住客信息
    roomStay.guestCounts.guestCountList.forEach((guestCount) => {
      let ageQualifyingCode = guestCount.ageQualifyingCode;
      let resGuestRPH = guestCount.resGuestRPH;
      let age = guestCount.age;
      let resGuestInfo = resGuestMap[resGuestRPH];
      // if (ageQualifyingCode == "8") {
      //   childNum++;
      // } else if (ageQualifyingCode == "10") {
      //   adultNum++;
      // }
      if (
        resGuestInfo.surname != null &&
        resGuestInfo.surname != "TBA" &&
        resGuestInfo.surname != "TBA-" + resGuestRPH
      ) {
        let resGuest = new ResGuest(
          resGuestInfo.primaryIndicator,
          age,
          ageQualifyingCode,
          resGuestRPH,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );

        if (resGuestInfo.nameTitle != null) {
          resGuest.nameTitle = resGuestInfo.nameTitle;
        }
        if (resGuestInfo.surname != null) {
          resGuest.surname = resGuestInfo.surname;
          resGuest.givenName = resGuestInfo.givenName;
        }
        if (resGuestInfo.emailAddress != null) {
          resGuest.emailAddress = resGuestInfo.emailAddress;
        }
        if (resGuestInfo.countryAccessCode) {
          resGuest.countryAccessCode = resGuestInfo.countryAccessCode;
        }
        if (resGuestInfo.phoneNumber) {
          resGuest.phoneNumber = resGuestInfo.phoneNumber;
        }

        passengers.push(resGuest);
      }
    });
  });

  let hotelRoomBasicInfoView = new hotelTools.HotelRoomBasicInfoView(
    hotelName,
    address,
    roomQuantity,
    roomTypeNames.toString(),
    refundable,
    checkIn,
    checkOut,
    daysDuration,
    roomViewItems,
    propTextContent,
    null,
    passengers
  );
  return hotelRoomBasicInfoView;
}

function getRoomResGuestFormModel(hotelReservation) {
  let start = null;
  let end = null;
  let roomStays = [];
  let resGlobalInfo = hotelReservation.resGlobalInfo;
  let timeSpan = resGlobalInfo.timeSpan;
  start = timeSpan.start;
  end = timeSpan.end;
  if (
    hotelReservation.roomStayList == null ||
    hotelReservation.roomStayList.length == 0
  )
    return;

  roomStays = hotelReservation.roomStayList;
  let pkgPassengerFormModel = new PkgPassengerFormModel(start, end);
  let primaryGuestExist = false;
  let adultIndex = 1;
  let childIndex = 1;
  let infantIndex = 1;
  roomStays.forEach((roomStay) => {
    let id = roomStay.reference.id;
    let roomResGuest = pkgPassengerFormModel.addNewRoomResGuest(id);
    // 房型信息相关
    const roomTypeOta = roomStay.roomTypes[0];
    const roomTypeName = roomTypeOta.roomType;
    const bedTypeName = roomTypeOta.bedTypeName;

    roomResGuest.roomTypeName = roomTypeName;
    roomResGuest.bedTypeName = bedTypeName;
    let mainGuestInRoomExist = false;
    let notExistPrimary = true;
    roomStay.guestCounts.guestCountList.forEach((guestCount) => {
      let ageQualifyingCode = guestCount.ageQualifyingCode;
      let resGuestRPH = guestCount.resGuestRPH;
      let age = guestCount.age;
      let travelerRefNumber = null;
      let defaultNameTitle = "MR";
      if (ageQualifyingCode == "10") {
        travelerRefNumber = "ADT-" + adultIndex;
        adultIndex++;
      } else if (ageQualifyingCode == "8" && age >= 2) {
        travelerRefNumber = "CNN-" + childIndex;
        childIndex++;
        defaultNameTitle = "MSTR";
      } else if (ageQualifyingCode == "8") {
        travelerRefNumber = "INF-" + infantIndex;
        defaultNameTitle = "MSTR";
        infantIndex++;
      }

      let resGuest = roomResGuest.addNewResGuest(
        !primaryGuestExist,
        resGuestRPH,
        ageQualifyingCode,
        age,
        travelerRefNumber,
        defaultNameTitle
      );
      // resGuest.nameTitle = "MR";

      if (!primaryGuestExist && ageQualifyingCode == "10") {
        primaryGuestExist = true;
        notExistPrimary = false;
      }
      //每个房间只需要填写一个人的名字
      if (
        notExistPrimary &&
        ageQualifyingCode == "10" &&
        !mainGuestInRoomExist
      ) {
        mainGuestInRoomExist = true;
        resGuest.mainIndicator = true;
      }
    });
  });

  // console.info(pkgPassengerFormModel);
  return pkgPassengerFormModel;
}

//endregion 创建视图对象
export default {
  getAvailRQ,
  getVerifyPriceRQ,
  getPrebookRQ,
  getBookRQ,
  getRoomResGuestFormModel,
  DynamicPackageSearchFormModel,
  getHotelViewItemMap,
  getFlightViewItemsGroup,
  getPkgPassengerFormModel,
  getSpecifiedPricedItinerary,
  getReservationRoomInfo,
};
