const sortOptions = [
  {
    name: "Price(Lowest)",
    type: "PRICE",
    order: "ASC",
  },
  {
    name: "Price(Highest)",
    type: "PRICE",
    order: "DSC",
  },
  {
    name: "Duration(Shortest)",
    type: "DURATION",
    order: "ASC",
  },
  {
    name: "Duration(Longest)",
    type: "DURATION",
    order: "DSC",
  },
  {
    name: "Departure(Earliest)",
    type: "DEPARTURE",
    order: "ASC",
  },
  {
    name: "Departure(Latest)",
    type: "DEPARTURE",
    order: "DSC",
  },
  {
    name: "Arrival(Earliest)",
    type: "ARRIVAL",
    order: "ASC",
  },
  {
    name: "Arrival(Lastest)",
    type: "ARRIVAL",
    order: "DSC",
  },
];

export default {
  sortOptions,
};
