import axios from "../utils/axiosUtils";

/*
 *用户资料
 */
function profileRead(profileReadRQ) {
  let url = "/api/v3/party/profiles/profileRead";
  return axios.post(url, profileReadRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/***
 * 修改用户资料
 */
function profileModify(profileModifyRQ) {
  let url = "/api/v3/party/profiles/profileModify";
  return axios.post(url, profileModifyRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  profileRead,
  profileModify,
};
