<script setup>
import { computed, reactive, ref, watch, watchEffect } from "vue";
import Banner from "@/components/custom/Banner.vue";
import BaseFlightItineraryPanel from "@/views/sales/flight/items/panel/BaseFlightItineraryPanel.vue";
import { useRouter } from "vue-router";
import BaseHotelAndFlightPricePanel from "@/views/sales/package/items/pannel/BaseHotelAndFlightPricePanel.vue";
import BasePkgHotelItemPanel from "@/views/sales/package/items/pannel/BasePkgHotelItemPanel.vue";
import { useCmsContentStore } from "@/stores/cms";
import { useTemplateStore } from "@/stores/template";

const router = useRouter();
const templateStore = useTemplateStore();
const navigation = reactive({
  title: "Itinerary Review",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Hotel Search Result",
      path: "/sales/package/hotel-list",
    },
    {
      name: "Hotel Details",
      path: "/sales/package/hotel-details",
    },
    {
      name: "Flight Search Result",
      path: "/sales/package/package-flight-list",
    },
    {
      name: "Itinerary Review",
    },
  ],
});

//region 数据初始化
let pkgItineraryReview = JSON.parse(
  sessionStorage.getItem("pkgItineraryReview")
);
let airportCityMap = JSON.parse(sessionStorage.getItem("pkgAirportCityMap"));

let pricedFlightViewItemsGroup = {};
let offerViewItemMap = {};
let passengerTicketFareBreakdownItems = {};
let holdRoomInfo = {};
let roomInfo = {};
let hotelViewShow = {};
if (pkgItineraryReview) {
  pricedFlightViewItemsGroup = pkgItineraryReview.pricedFlightViewItemsGroup;
  offerViewItemMap = pkgItineraryReview.offerViewItemMap;
  passengerTicketFareBreakdownItems =
    pkgItineraryReview.passengerTicketFareBreakdownItems;
  holdRoomInfo = pkgItineraryReview.holdRoomInfo;
  roomInfo =
    pkgItineraryReview.holdRoomInfo.roomViewItems &&
    pkgItineraryReview.holdRoomInfo.roomViewItems.length > 0
      ? pkgItineraryReview.holdRoomInfo.roomViewItems[0]
      : {};

  hotelViewShow = Object.assign(holdRoomInfo, roomInfo);
  hotelViewShow.roomType = holdRoomInfo.roomTypeNames;
  hotelViewShow.roomNum = holdRoomInfo.roomViewItems.length;
}

let totalAmountContent = computed(() => {
  let totalAmount = 0;
  let currency = "";
  let adultTotalAmount = 0;
  let childTotalAmount = 0;
  let infantTotalAmount = 0;
  let adultNum = 0;
  let childNum = 0;
  let infantNum = 0;
  let taxesTotalAmount = 0;
  if (passengerTicketFareBreakdownItems) {
    passengerTicketFareBreakdownItems.forEach((breakdownItem) => {
      let totalFare = breakdownItem.totalFare;
      totalAmount += totalFare;
      taxesTotalAmount += breakdownItem.taxes;
      currency = breakdownItem.currency;

      if (breakdownItem.passengerTypeCode == "ADT") {
        adultNum++;
        adultTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "CNN") {
        childNum++;
        childTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "INF") {
        infantNum++;
        infantTotalAmount += breakdownItem.baseFare;
      }
    });
  }
  return {
    totalAmount,
    currency,
    adultTotalAmount,
    childTotalAmount,
    infantTotalAmount,
    infantNum,
    adultNum,
    childNum,
    taxesTotalAmount,
  };
});
//endregion 数据初始化

//region 辅助函数
function goBack() {
  router.push({ name: "sales-package-flight-list" });
}
function goTo() {
  router.push({ name: "sales-package-passengers" });
}
function getPolicyModelKey(modelIndex) {
  return "C" + modelIndex;
}
//endregion

const bannerContentStore = useCmsContentStore();
let bannerContent = reactive(null);
bannerContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrlRef = ref("");
let backgroundImageUrlRef = ref("");
watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrlRef.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.packageBackgroundImage
      ? bannerContentStore.productPageBackgroundImage.packageBackgroundImage.url
      : "";
  backgroundImageUrlRef.value = baseUrl + imageUrlRef.value;
});

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <h3 class="heading_theme">{{ $t("itinerary-review") }}</h3>
      <div class="row">
        <div class="col-lg-8">
          <div class="tou_booking_form_Wrapper">
            <div class="tou_booking_form_Wrapper">
              <div
                class="booking_tour_form"
                v-if="pricedFlightViewItemsGroup && airportCityMap"
              >
                <!--酒店相关-->
                <div class="booking_tour_form">
                  <BasePkgHotelItemPanel :hotel-view-show="hotelViewShow" />
                </div>
                <!-- 机票相关-->
                <BaseFlightItineraryPanel
                  :priced-flight-view-items-group="pricedFlightViewItemsGroup"
                  :airport-city-map="airportCityMap"
                  :offer-view-item-map="offerViewItemMap"
                />
              </div>
              <div class="booking_tour_form_submit"   v-if="equipmentType != 'iphone'">
                <button
                  @click="goBack"
                  class="btn btn_theme btn_md"

                >
                  {{ $t("go-back") }}
                </button>
                <button
                  @click="goTo"
                  class="btn btn_theme btn_md"
                  style="margin-left: 20px"
                >
                  {{ $t("continue") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <BaseHotelAndFlightPricePanel
            v-if="totalAmountContent"
            :total-amount-content="totalAmountContent"
            :room-info="roomInfo"
            :room-num="
              holdRoomInfo.roomViewItems &&
              holdRoomInfo.roomViewItems.length > 0
                ? holdRoomInfo.roomViewItems.length
                : 0
            "
            :show-button="false"
          />
        </div>
        <div class="col-lg-12 text-center" v-if="equipmentType == 'iphone'"> <button
            @click="goTo"
            class="btn btn_theme "
            style="margin-top: -10px"
        >
          {{ $t("continue") }}
        </button></div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
